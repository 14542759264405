import { FC, ReactNode } from 'react';
import cn from 'classnames';

import styles from './Description.module.scss';

type DescriptionProps = {
  className?: string;
  labelClassName?: string;
  contentClassName?: string;
  label: JSX.Element | string;
  children?: ReactNode;
  fontType?: string;
  isVertical?: boolean;
  hideColon?: boolean;
};

export const Description: FC<DescriptionProps> = (props) => {
  const {
    className,
    labelClassName,
    contentClassName,
    label,
    children,
    fontType = 'p3',
    isVertical,
    hideColon,
  } = props;

  return (
    <div
      className={cn(
        styles.container,
        className,
        fontType,
        isVertical && styles.vertical,
      )}
    >
      <span className={cn(styles.label, labelClassName)}>
        {label}
        {hideColon ? '' : ':'}{' '}
      </span>
      <span className={cn(styles.content, contentClassName)}>{children}</span>
    </div>
  );
};
