import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { useAppSelector } from '@/shared/hooks';

import { billingModel } from '@/entities/billing';

import { NoSubscription } from '../NoSubscription/NoSubscription.tsx';
import { BillingPeriod } from '../BillingPeriod/BillingPeriod.tsx';
import { InventoryItemView } from '../InventoryItemView/InventoryItemView';

import styles from './Packages.module.scss';

type PackagesProps = {
  className?: string;
  testID?: string;
};

export const Packages: FC<PackagesProps> = (props) => {
  const { className, testID } = props;

  const { formatMessage, formatNumber } = useIntl();

  const packages = useAppSelector(
    billingModel.selectors.selectCurrentAddonsAndPackages,
  );

  return (
    <div data-testid={testID} className={cn(styles.container, className)}>
      <header className={styles.header}>
        <h4 className="h4">
          {formatMessage({
            id: 'subscription.packages.header',
            defaultMessage: 'Packages',
          })}
        </h4>
      </header>

      {packages?.length > 0 ? (
        <div>
          {packages.map((packageItem) => (
            <div key={packageItem.ID} className={styles.innerContainer}>
              <InventoryItemView
                inventoryItem={packageItem}
                cost={formatNumber(Number(packageItem?.Model?.Price), {
                  style: 'currency',
                  maximumFractionDigits: 2,
                  currencyDisplay: 'narrowSymbol',
                  currency: packageItem?.Model?.Currency,
                })}
              />

              <BillingPeriod
                startDate={packageItem?.Start?.toDate() as Date}
                endDate={packageItem?.End?.toDate() as Date}
              />
            </div>
          ))}
        </div>
      ) : (
        <NoSubscription
          text={formatMessage({
            id: 'subscription.packages.noPackages',
            defaultMessage: 'No purchased add ons or package yet',
          })}
        />
      )}
    </div>
  );
};
