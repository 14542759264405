import {
  Report,
  ReportType,
} from '@/shared/api/protocol-ts/model/dto_report_pb';

import { GroupedReportsWithStudies } from '../../../pages/PatientProfile/config';
import { StructuredReportsAndStudiesType } from '../config';

import { getLatestDate } from './getLatestDate';
import { sortReportByDate } from './sortReportByDate';

export const getStructuredReportsAndStudies = (
  groupedReportsWithStudies: GroupedReportsWithStudies[],
): StructuredReportsAndStudiesType[] =>
  groupedReportsWithStudies.flatMap(
    (group): StructuredReportsAndStudiesType[] => {
      const baseID = group.sourceStudyIDs[0];

      const createReportEntry = ([reportType, reports]: [
        ReportType,
        Report[],
      ]) => {
        const latestReport =
          reports.length > 0
            ? reports.reduce((latest, current) =>
                sortReportByDate(latest, current) <= 0 ? latest : current,
              )
            : undefined;

        return {
          ID: `${baseID}_${reportType}`,
          activeReportIDs: reports?.map((report) => report.ID) || [],
          reportType: reportType,
          report: latestReport,
          studies: group.studies,
          date: getLatestDate(latestReport, group.studies),
        };
      };

      const reportEntries = Object.entries(group.reportsByType).map(
        ([reportType, reports]) =>
          createReportEntry([Number(reportType), reports]),
      );

      return reportEntries;
    },
  );
