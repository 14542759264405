import { CSSProperties, FC } from 'react';
import cn from 'classnames';

import { ToothStatus } from '@/shared/config';
import { useAppSelector } from '@/shared/hooks';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';

import { organizationModel } from '@/entities/organization';

import { ToothIcon } from '../ToothIcon/ToothIcon';

import styles from './ToothView.module.scss';

export type ToothViewClickParams = {
  id: string;
  ISONumber: number;
  isActive: boolean;
};

export type ToothViewClick = (props: ToothViewClickParams) => void;

export type ToothViewProps = {
  className?: string;
  style?: CSSProperties;
  id: string;
  ISONumber: number;
  viewNumber?: number | string;
  status?: ToothStatus;
  disabled?: boolean;
  isLowerJaw: boolean;
  isSupernumerary?: boolean;
  isActive?: boolean;
  isROIOpen?: boolean;
  isSimplify?: boolean;
  isMissingThirdMolar: boolean;
  blackAndWhiteMode?: boolean;
  toothOfInterestNumber?: number;
  isFocused?: boolean;
  onClick?: ToothViewClick;
  isApproved?: boolean;
};

export const ToothView: FC<ToothViewProps> = (props) => {
  const {
    className,
    style,
    id,
    viewNumber,
    ISONumber,
    status,
    disabled,
    isActive = false,
    isROIOpen,
    isSimplify,
    isLowerJaw,
    isSupernumerary,
    toothOfInterestNumber,
    blackAndWhiteMode = false,
    isMissingThirdMolar,
    isFocused,
    onClick,
    isApproved,
  } = props;

  const isFDA = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_FDA_SubmissionView,
    ),
  );

  const isThirdMolar = ISONumber.toString().includes('8');

  const isToothMissing = status === ToothStatus.missing;

  const handleToothClick = () => {
    if (onClick) {
      onClick({ id, ISONumber, isActive });
    }
  };

  const toothStatus =
    isFDA && status === ToothStatus.missing ? ToothStatus.healthy : status;

  return (
    <div
      style={{ ...style }}
      className={cn(
        isSimplify ? styles.siplyContainer : styles.container,
        {
          [styles.currentActiveTooth]: toothOfInterestNumber === viewNumber,
          [styles.notActive]: !isActive,
          [styles.pointer]: isROIOpen || isActive,
          [styles.isLowerJaw]: isLowerJaw,
          [styles.disabled]: disabled,
          [styles.focused]: isFocused,
          [`approvedToothWrapper`]: isApproved && isFDA,
        },
        blackAndWhiteMode
          ? styles.blackAndWhitePrintToothWrapper
          : `${toothStatus}ToothWrapper`,
        className,
      )}
      onClick={handleToothClick}
    >
      <span
        className={cn(
          styles.position,
          blackAndWhiteMode && styles.blackAndWhitePrintToothNumberColor,
          !isMissingThirdMolar && `${toothStatus}ToothIcon`,
          'p4',
        )}
      >
        {`${viewNumber}${isSupernumerary ? 'S' : ''}`}
      </span>

      {!isSimplify && (
        <div className={styles.iconContainer}>
          <ToothIcon
            isMissing={isToothMissing}
            isThirdMolar={isThirdMolar}
            position={ISONumber}
            blackAndWhiteMode={blackAndWhiteMode}
            toothStatus={status}
            className={cn(`${toothStatus}ToothIcon`)}
          />
        </div>
      )}
    </div>
  );
};
