// @generated by protoc-gen-connect-web v0.6.0 with parameter "target=ts,ts_nocheck=false"
// @generated from file api/core/svc_access.proto (package com.diagnocat.core, syntax proto3)
/* eslint-disable */

import { AcceptInvitationReq, AcceptInvitationResp, CancelInvitationReq, CancelInvitationResp, GetInvitationByTokenReq, GetInvitationByTokenResp, InvitationListStreamResp, InviteReq, InviteResp, RevokeSharingReq, RevokeSharingResp, SetOrganizationInvitationUserInfoReq, SetOrganizationInvitationUserInfoResp, SetPatientTeamMembersReq, SetPatientTeamMembersResp, SetUserOrganizationRolesReq, SetUserOrganizationRolesResp, SharedByMeStreamReq, SharedWithMeStreamReq } from "./svc_access_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service com.diagnocat.core.AccessService
 */
export const AccessService = {
  typeName: "com.diagnocat.core.AccessService",
  methods: {
    /**
     * Shared by me or shared by staff (if Me has admin organization role)
     *
     * @generated from rpc com.diagnocat.core.AccessService.SharedByMeStream
     */
    sharedByMeStream: {
      name: "SharedByMeStream",
      I: SharedByMeStreamReq,
      O: InvitationListStreamResp,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc com.diagnocat.core.AccessService.SharedWithMeStream
     */
    sharedWithMeStream: {
      name: "SharedWithMeStream",
      I: SharedWithMeStreamReq,
      O: InvitationListStreamResp,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * Only for Recipient (Recipient.UserID == meID)
     *
     * @generated from rpc com.diagnocat.core.AccessService.GetInvitationByToken
     */
    getInvitationByToken: {
      name: "GetInvitationByToken",
      I: GetInvitationByTokenReq,
      O: GetInvitationByTokenResp,
      kind: MethodKind.Unary,
    },
    /**
     * Invite:
     * - invite user to organization (only first roles)
     * - sharing patient for user which is not an employee of this patient's organization
     *
     * Permission:
     * - `OrganizationPermissions.CanEditStaff` (if Target == Organization)
     * - `PatientPermissions.CanShare` (if Target == Patient)
     *
     * Errors:
     * 1. Common errors:
     * - type: RequiredArgumentMissingError, Argument: Recipient/recipient contacts/Target - (if the corresponding argument is empty)
     * - type: NotFoundError, Entity: recipient (if user not found by `InviteReq.Recipient.UserID`)
     * 2. If Target == Organization:
     * - type: RequiredArgumentMissingError, Argument: target organizationID - (if the corresponding argument is empty)
     * - type: BadRequestError, Reason: forbidden to invite to owner role (if remote organization found by `InviteReq.Target.Organization.Role` == `TypeOwner`)
     * - type: InvalidArgumentValueError, Argument: target organization user role - (if `InviteReq.Target.Organization.Role` == `TypeInvalidValue`)
     * - type: NotFoundError, Entity: target organization (if organization not found by `InviteReq.Target.Organization.OrganisationID`)
     * - type: BadRequestError, Reason: target organization deleted (if remote organization found by `InviteReq.Target.Organization.OrganisationID`)
     * - type: BadRequestError, Reason: recipient already has organization role
     * - type: AlreadyExistError, Entity: invitation to this organization role
     * 3. If Target == Patient:
     * - type: RequiredArgumentMissingError, Argument: target patientID - (if the corresponding argument is empty)
     * - type: NotFoundError, Entity: target patient (if organization not found by `InviteReq.Target.Patient.PatientID`)
     * - type: BadRequestError, Reason: target patient deleted (if remote patient found by `InviteReq.Target.Patient.PatientID`)
     * - type: BadRequestError, Reason: recipient is an organization employee
     * - type: BadRequestError, Reason: recipient is a patient team member
     * - type: AlreadyExistError, Entity: accepted patient sharing
     * - type: AlreadyExistError, Entity: invitation to this patient
     *
     * @generated from rpc com.diagnocat.core.AccessService.Invite
     */
    invite: {
      name: "Invite",
      I: InviteReq,
      O: InviteResp,
      kind: MethodKind.Unary,
    },
    /**
     * Cancel not accepted invitation (`InvitationStatus` == `StatusPending`)
     *
     * Permission:
     * - Me.ID == `invitation.Created.By`
     * - `OrganizationPermissions.CanEditStaff`
     * - `PatientPermissions.CanCancelOthersSharings`
     *
     * Errors:
     * - type: BadRequestError, Reason: invitation closed
     *
     * @generated from rpc com.diagnocat.core.AccessService.CancelInvitation
     */
    cancelInvitation: {
      name: "CancelInvitation",
      I: CancelInvitationReq,
      O: CancelInvitationResp,
      kind: MethodKind.Unary,
    },
    /**
     * Accept invitation:
     * - if target == organization:
     * - accept current invitation
     * - add organization role
     * - cancel all pending/accepted sharing invitations
     * - replace sharing in organization's patient to team member role
     *
     * Errors:
     * - type: BadRequestError, Reason: invitation already accepted
     * - type: BadRequestError, Reason: invitation canceled
     * - type: BadRequestError, Reason: invitation closed
     * - type: BadRequestError, Reason: incorrect secret
     *
     * @generated from rpc com.diagnocat.core.AccessService.AcceptInvitation
     */
    acceptInvitation: {
      name: "AcceptInvitation",
      I: AcceptInvitationReq,
      O: AcceptInvitationResp,
      kind: MethodKind.Unary,
    },
    /**
     * Edit user information in pending invitation (name, roles, job positions):
     *
     * Permission:
     * - Me.ID == `invitation.Created.By`
     * - `OrganizationPermissions.CanEditStaff`
     *
     * @generated from rpc com.diagnocat.core.AccessService.SetOrganizationInvitationUserInfo
     */
    setOrganizationInvitationUserInfo: {
      name: "SetOrganizationInvitationUserInfo",
      I: SetOrganizationInvitationUserInfoReq,
      O: SetOrganizationInvitationUserInfoResp,
      kind: MethodKind.Unary,
    },
    /**
     * Revoke sharing
     *
     * Permission: PatientPermissions.CanCancelOthersSharings || Sharing.Created.By == meID
     *
     * @generated from rpc com.diagnocat.core.AccessService.RevokeSharing
     */
    revokeSharing: {
      name: "RevokeSharing",
      I: RevokeSharingReq,
      O: RevokeSharingResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set patient doctors(Users).
     * If the user removes a staff member from the list of patient’s treating doctors
     * all tasks that were assigned to this person will also be unassigned from this person.
     *
     * Permission: PatientPermissions.CanEditTeam
     *
     * @generated from rpc com.diagnocat.core.AccessService.SetPatientTeamMembers
     */
    setPatientTeamMembers: {
      name: "SetPatientTeamMembers",
      I: SetPatientTeamMembersReq,
      O: SetPatientTeamMembersResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set organization user roles (if user is already an employee) or
     * Delete User from Organization: if Roles are empty -> the user loses access to the organization
     *
     * Permission: OrganizationPermissions.CanEditStaff
     *
     * Errors:
     * - type: BadRequestError, Reason: Owner role cannot be added
     * - type: BadRequestError, Reason: Owner role cannot be deleted
     *
     * @generated from rpc com.diagnocat.core.AccessService.SetUserOrganizationRoles
     */
    setUserOrganizationRoles: {
      name: "SetUserOrganizationRoles",
      I: SetUserOrganizationRolesReq,
      O: SetUserOrganizationRolesResp,
      kind: MethodKind.Unary,
    },
  }
} as const;

