// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_report_type_studio_common.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { ToothNumeration } from "./dto_report_common_pb.js";
import { AnatomicalBasis3D, FloatPoint3D } from "./dto_common_geometry_pb.js";

/**
 * @deprecation_pending("no substitution exist yet")
 *
 * @generated from message com.diagnocat.model.Report_Studio_ToothPosition
 */
export const Report_Studio_ToothPosition = proto3.makeMessageType(
  "com.diagnocat.model.Report_Studio_ToothPosition",
  () => [
    { no: 1, name: "Tooth", kind: "message", T: ToothNumeration },
    { no: 2, name: "IsMissing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "Position", kind: "message", T: Report_Studio_OriginatedPosition },
  ],
);

/**
 * @deprecation_pending("no substitution exist yet")
 * Coordinate system describing tooth, used in segmentron reports only:
 *  * ReportType_Studio_Implant
 *  * ReportType_Studio_Orhto
 *  * ReportType_CBCT_IOS_Superimposition
 *  * ReportType_CBCT_Segmentation
 *
 * @generated from message com.diagnocat.model.Report_Studio_OriginatedPosition
 */
export const Report_Studio_OriginatedPosition = proto3.makeMessageType(
  "com.diagnocat.model.Report_Studio_OriginatedPosition",
  () => [
    { no: 1, name: "Origin", kind: "message", T: FloatPoint3D },
    { no: 2, name: "Directions", kind: "message", T: Report_Studio_OriginatedPosition_Report_Studio_OriginatedPosition_Directions },
    { no: 3, name: "AnatomicalBasis", kind: "message", T: AnatomicalBasis3D },
  ],
);

/**
 * @generated from message com.diagnocat.model.Report_Studio_OriginatedPosition.Report_Studio_OriginatedPosition_Directions
 */
export const Report_Studio_OriginatedPosition_Report_Studio_OriginatedPosition_Directions = proto3.makeMessageType(
  "com.diagnocat.model.Report_Studio_OriginatedPosition.Report_Studio_OriginatedPosition_Directions",
  () => [
    { no: 1, name: "X", kind: "message", T: FloatPoint3D },
    { no: 2, name: "Y", kind: "message", T: FloatPoint3D },
    { no: 3, name: "Z", kind: "message", T: FloatPoint3D },
  ],
  {localName: "Report_Studio_OriginatedPosition_Report_Studio_OriginatedPosition_Directions"},
);

