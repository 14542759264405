// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_asset_studio_common.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { DerivedData_Image, File } from "./dto_asset_common_pb.js";
import { CBCTSeriesGeometryData, FloatPoint3D, StudioColorProfile } from "./dto_common_geometry_pb.js";

/**
 * @generated from message com.diagnocat.model.AssetContent_Generated_Studio_CBCTDownscaled
 */
export const AssetContent_Generated_Studio_CBCTDownscaled = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Generated_Studio_CBCTDownscaled",
  () => [
    { no: 1, name: "Volume", kind: "message", T: File },
    { no: 2, name: "DownscaleFactor", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "Geometry", kind: "message", T: CBCTSeriesGeometryData },
  ],
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Generated_Studio_PanoramaReformatGeneral
 */
export const AssetContent_Generated_Studio_PanoramaReformatGeneral = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Generated_Studio_PanoramaReformatGeneral",
  () => [
    { no: 1, name: "PanoramaSingle", kind: "message", T: File },
    { no: 2, name: "Derived", kind: "message", T: DerivedData_Image },
    { no: 3, name: "Geometry", kind: "message", T: AssetContent_Generated_Studio_PanoramaReformatGeneral_Geometry },
  ],
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Generated_Studio_PanoramaReformatGeneral_Geometry
 */
export const AssetContent_Generated_Studio_PanoramaReformatGeneral_Geometry = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Generated_Studio_PanoramaReformatGeneral_Geometry",
  () => [
    { no: 1, name: "Points", kind: "message", T: FloatPoint3D, repeated: true },
    { no: 2, name: "Up", kind: "message", T: FloatPoint3D },
    { no: 3, name: "ColorProfile", kind: "message", T: StudioColorProfile },
    { no: 4, name: "Shape", kind: "message", T: AssetContent_Generated_Studio_PanoramaReformatGeneral_Geometry_Geometry_Shape },
    { no: 5, name: "DepthStep", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Generated_Studio_PanoramaReformatGeneral_Geometry.Geometry_Shape
 */
export const AssetContent_Generated_Studio_PanoramaReformatGeneral_Geometry_Geometry_Shape = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Generated_Studio_PanoramaReformatGeneral_Geometry.Geometry_Shape",
  () => [
    { no: 1, name: "Width", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "Height", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "Depth", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
  {localName: "AssetContent_Generated_Studio_PanoramaReformatGeneral_Geometry_Geometry_Shape"},
);

