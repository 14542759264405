import { AssetType } from '@/shared/api/protocol-ts/model/dto_asset_pb';

export const UPLOAD_STUDY_FORM_ID = 'upload-study-form';

export const MAX_BYTES_FOR_STUDIES = 262144000; // 250MB
export const MAX_BYTES_FOR_CBCT = 10737418240; // 10GB

export type UploadStudyType =
  | AssetType.AssetType_Study_CBCT
  | AssetType.AssetType_Document
  | AssetType.AssetType_Study_IntraoralXRay
  | AssetType.AssetType_Study_DentalPhoto
  | AssetType.AssetType_Study_PanoramicXRay
  | AssetType.AssetType_Study_IOS_Meshes;

export const WITHOUT_FILE_FORMAT_ACCEPTED_STUDIES = [
  AssetType.AssetType_Study_CBCT,
  AssetType.AssetType_Study_IntraoralXRay,
  AssetType.AssetType_Study_PanoramicXRay,
];

export const ACCEPTED_FILES_FORMATS_FOR_STUDIES: Record<
  UploadStudyType,
  string[]
> = {
  [AssetType.AssetType_Study_CBCT]: ['.dcm', '.zip'],
  [AssetType.AssetType_Study_IntraoralXRay]: [
    '.jpeg',
    '.jpg',
    '.tiff',
    '.png',
    '.dcm',
    '.zip',
  ],
  [AssetType.AssetType_Study_PanoramicXRay]: [
    '.jpeg',
    '.jpg',
    '.tiff',
    '.png',
    '.dcm',
    '.zip',
  ],
  [AssetType.AssetType_Study_DentalPhoto]: [
    '.jpeg',
    '.jpg',
    '.tiff',
    '.png',
    '.zip',
  ],
  [AssetType.AssetType_Study_IOS_Meshes]: [
    '.stl',
    '.obj',
    '.ply',
    '.drc',
    '.zip',
  ],
  [AssetType.AssetType_Document]: [''],
};
