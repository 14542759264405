// @generated by protoc-gen-connect-web v0.6.0 with parameter "target=ts,ts_nocheck=false"
// @generated from file api/marketing/svc_questionnaire.proto (package com.diagnocat.marketing, syntax proto3)
/* eslint-disable */

import { OrganizationOnboardingQuestionnaireReq_V1, OrganizationOnboardingQuestionnaireResp_V1, PutOrganizationOnboardingQuestionnaireReq_V1, PutOrganizationOnboardingQuestionnaireResp_V1 } from "./svc_questionnaire_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service com.diagnocat.marketing.QuestionnaireService
 */
export const QuestionnaireService = {
  typeName: "com.diagnocat.marketing.QuestionnaireService",
  methods: {
    /**
     * @generated from rpc com.diagnocat.marketing.QuestionnaireService.GetOrganizationOnboardingQuestionnaire_V1
     */
    getOrganizationOnboardingQuestionnaire_V1: {
      name: "GetOrganizationOnboardingQuestionnaire_V1",
      I: OrganizationOnboardingQuestionnaireReq_V1,
      O: OrganizationOnboardingQuestionnaireResp_V1,
      kind: MethodKind.Unary,
    },
    /**
     * Empty fields does not rewrite filled ones
     *
     * @generated from rpc com.diagnocat.marketing.QuestionnaireService.PutOrganizationOnboardingQuestionnaire_V1
     */
    putOrganizationOnboardingQuestionnaire_V1: {
      name: "PutOrganizationOnboardingQuestionnaire_V1",
      I: PutOrganizationOnboardingQuestionnaireReq_V1,
      O: PutOrganizationOnboardingQuestionnaireResp_V1,
      kind: MethodKind.Unary,
    },
  }
} as const;

