import { CBCT_Anatomy } from '@/shared/api/protocol-ts/model/dto_common_anatomy_pb';
import { JAW } from '@/shared/config';
import { Report_CBCT_IOS_Segment } from '@/shared/api/protocol-ts/model/dto_report_common_pb';

import { RUDIMENT_TEETH_MAP } from '@/entities/tooth';

const getTeethISONumbersWithRudimentTeeth = (teethISONumbers: number[]) => {
  const teethISONumbersWithRudimentTeeth = [...teethISONumbers];

  Object.entries(RUDIMENT_TEETH_MAP).forEach(([rudimentTooth, tooth]) => {
    if (teethISONumbers.includes(Number(tooth))) {
      teethISONumbersWithRudimentTeeth.push(Number(rudimentTooth));
    }
  });

  return teethISONumbersWithRudimentTeeth;
};

const getSegmentsOfTeeth = (ISONumbers: number[]): Report_CBCT_IOS_Segment[] =>
  ISONumbers.map(
    (ISONumber) =>
      new Report_CBCT_IOS_Segment({
        Structure: {
          case: 'CBCTToothISO',
          value: ISONumber,
        },
      }),
  );

const getSegmentsOfAllCBCTAnatomies = (): Report_CBCT_IOS_Segment[] =>
  Object.entries(CBCT_Anatomy)
    .filter(
      ([, value]) =>
        !isNaN(Number(value)) &&
        value !== CBCT_Anatomy.CBCT_Anatomy_InvalidValue,
    )
    .map(
      ([, value]) =>
        new Report_CBCT_IOS_Segment({
          Structure: {
            case: 'CBCTAnatomy',
            value: Number(value),
          },
        }),
    );

export const getSegmentsWithTeethAndAnatomies =
  (): Report_CBCT_IOS_Segment[] => [
    ...getSegmentsOfTeeth(
      getTeethISONumbersWithRudimentTeeth([
        ...JAW.quartile1,
        ...JAW.quartile2,
        ...JAW.quartile3,
        ...JAW.quartile4,
      ]),
    ),
    ...getSegmentsOfAllCBCTAnatomies(),
  ];
