import { CBCT_SectionOrientation } from '@/shared/api/protocol-ts/model/dto_asset_common_pb';
import { FormatMessageFunction } from '@/shared/config';

import { guideAxesNames } from '../config/i18n';

export const getGuideAxesName = (
  orient: CBCT_SectionOrientation,
  formatMessage: FormatMessageFunction,
) => {
  switch (orient) {
    case CBCT_SectionOrientation.SectionOrientationInvalidValue:
    case CBCT_SectionOrientation.SectionOrientationAxial:
      return formatMessage(guideAxesNames.SectionOrientationAxial);
    case CBCT_SectionOrientation.SectionOrientationVestibuloOral:
      return formatMessage(guideAxesNames.SectionOrientationVestibuloOral);
    case CBCT_SectionOrientation.SectionOrientationMesioDistal:
      return formatMessage(guideAxesNames.SectionOrientationMesioDistal);
    default:
      return '';
  }
};
