import { FC, ReactNode, useState } from 'react';
import { TooltipContentProps } from '@radix-ui/react-tooltip';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { Tooltip, toastCaller } from '@/shared/ui';

import styles from './SupportTooltip.module.scss';

type SupportTooltipProps = {
  className?: string;
  triggerClassName?: string;
  children: ReactNode;
  side?: TooltipContentProps['side'];
  sideOffset?: number;
  errorMessage?: ReactNode;
  messageClassName?: string;
};

export const SupportTooltip: FC<SupportTooltipProps> = (props) => {
  const {
    children,
    className,
    triggerClassName,
    sideOffset,
    side,
    errorMessage,
    messageClassName = 'p4',
  } = props;

  const [copied, setCopied] = useState(false);

  const { formatMessage } = useIntl();

  const copyErrorToClipBoard = async () => {
    if (copied) {
      return;
    }
    try {
      await navigator.clipboard.writeText(errorMessage as string);
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);

      toastCaller({
        message: formatMessage({
          id: 'global.errorCopied',
          defaultMessage: 'Error text copied',
        }),
        type: 'default',
        heading: '',
        position: 'bottom-right',
      });
    } catch (error) {
      setCopied(false);
    }
  };

  return (
    <Tooltip.Primary
      className={cn(styles.tooltip, className)}
      triggerClassName={triggerClassName}
      side={side}
      sideOffset={sideOffset}
      onTriggerClick={copyErrorToClipBoard}
      content={
        <p className={cn(styles.errorMessage, messageClassName)}>
          {errorMessage}
        </p>
      }
    >
      {children}
    </Tooltip.Primary>
  );
};
