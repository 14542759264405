import { FC } from 'react';
import cn from 'classnames';
import { isEqual } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { useAppSelector } from '@/shared/hooks';
import { Order } from '@/shared/api/protocol-ts/api/billing_new/dto_order_new_pb';

import { billingModel } from '@/entities/billing';

import { Invoice } from './Invoice/Invoice';
import { NoInvoices } from './NoInvoices/NoInvoices';
import styles from './Invoices.module.scss';

type InvoicesProps = {
  className?: string;
};

export const Invoices: FC<InvoicesProps> = (props) => {
  const { className } = props;

  const invoices: Order[] = useAppSelector(
    billingModel.selectors.selectBilledInvoices,
    isEqual,
  );

  return (
    <div className={cn(styles.container, className)}>
      <header className={cn(styles.header, 'h4')}>
        <FormattedMessage id="invoces.title" defaultMessage="My invoices" />
      </header>

      <div className={styles.invoiceContainer}>
        {invoices?.length > 0 ? (
          invoices.map((invoice) => (
            <Invoice key={invoice.ID} invoice={invoice} />
          ))
        ) : (
          <NoInvoices />
        )}
      </div>
    </div>
  );
};
