import { ConditionCode } from '@/shared/api/protocol-ts/model/dto_report_condition_codes_pb';

export enum CombinedConditionGroupCode {
  ANATOMY = 'anatomy',
  CARIES_AND_CROWN = 'caries_and_crown',
  ENDO = 'endo',
  PERIO = 'perio',
  MISSING_NON_RESTORABLE = 'missing_non_restorable',
  ORTHO = 'ortho',
  CROWN_INDICATION = 'crown_indication',
  TREATMENT = 'treatment',
  SURGICAL = 'surgical',
}

type CombinedCondition = {
  id: number;
  code: CombinedConditionGroupCode;
  conditionCodes?: ConditionCode[];
};

export const COMBINED_CONDITIONS: CombinedCondition[] = [
  {
    id: 1,
    code: CombinedConditionGroupCode.ANATOMY,
  },
  {
    id: 2,
    code: CombinedConditionGroupCode.CARIES_AND_CROWN,
    conditionCodes: [
      ConditionCode.Attrition,
      ConditionCode.Abfraction,
      ConditionCode.CrownFracture,
      // With child tags:
      ConditionCode.Child_DefectDepth_Enamel, // Only for crown fracture parent
      ConditionCode.Child_DefectDepth_Dentin, // Only for crown fracture parent
      ConditionCode.Child_DefectDepth_WithPulpExposure, // Only for crown fracture parent
      ConditionCode.Child_DefectDepth_CrownRoot, // Only for crown fracture parent
      //
      ConditionCode.RestorationFracture,
      ConditionCode.CervicalResorption,
      ConditionCode.PreEruptiveResorption,
      ConditionCode.VoidsInTheFilling,
      ConditionCode.Overhang,
      ConditionCode.OpenMargin,
      ConditionCode.LackOfInterproximalContact,
      ConditionCode.CariesSigns,
      // With child tags:
      ConditionCode.Child_DefectDepth_Initial,
      ConditionCode.Child_DefectDepth_Enamel,
      ConditionCode.Child_DefectDepth_Dentin,
      ConditionCode.Child_DefectSurface_Mesial,
      ConditionCode.Child_DefectSurface_Distal,
      ConditionCode.Child_DefectSurface_Occlusial,
      ConditionCode.Child_DefectSurface_IncisalEdge,
      ConditionCode.Child_DefectSurface_Buccal,
      ConditionCode.Child_DefectSurface_Vestibular,
      ConditionCode.Child_DefectSurface_Lingual,
      ConditionCode.Child_DefectSurface_NotDefined,
      //
      ConditionCode.SecondaryCaries,
      // With child tags:
      ConditionCode.Child_DefectDepth_Initial,
      ConditionCode.Child_DefectDepth_Enamel,
      ConditionCode.Child_DefectDepth_Dentin,
      ConditionCode.Child_DefectDepth_WithPulpExposure,
      ConditionCode.Child_DefectDepth_Root,
      ConditionCode.Child_DefectDepth_NotDefined,
    ],
  },
  {
    id: 3,
    code: CombinedConditionGroupCode.ENDO,
    conditionCodes: [
      ConditionCode.CanalObliteration,
      ConditionCode.Hypercementosis,
      ConditionCode.Child_EndoTreated_Obturation_ShortFilling,
      ConditionCode.Child_EndoTreated_Obturation_Overfilling,
      ConditionCode.Child_EndoTreated_Obturation_MissedCanal,
      ConditionCode.Child_EndoTreated_Quality_VoidsPresentInTheRootFilling,
      ConditionCode.Child_EndoTreated_Quality_VoidsInThePostArea,
      ConditionCode.PeriapicalRadiolucency,
      ConditionCode.LateralRadiolucency,
      ConditionCode.PDLSpaceWidening_InPeriapicalRegion,
      ConditionCode.PDLSpaceWidening_AlongTheRoot,
      ConditionCode.PeriapicalRadiopacity,
    ],
  },
  {
    id: 4,
    code: CombinedConditionGroupCode.PERIO,
    conditionCodes: [
      ConditionCode.PeriodontalBoneLoss,
      ConditionCode.Child_PeriodontalBoneLoss_Severity_Mild,
      ConditionCode.Child_PeriodontalBoneLoss_Severity_Moderate,
      ConditionCode.Child_PeriodontalBoneLoss_Type_Horizontal,
      ConditionCode.Child_PeriodontalBoneLoss_Type_Vertical,
      ConditionCode.Child_PeriodontalBoneLoss_Type_Mixed,
      ConditionCode.FurcationLesion,
      ConditionCode.DentalCalculus,
      ConditionCode.PeriImplantitis,
    ],
  },
  {
    id: 5,
    code: CombinedConditionGroupCode.MISSING_NON_RESTORABLE,
    conditionCodes: [
      ConditionCode.Pontic,
      ConditionCode.RootFragment,
      ConditionCode.Missing,
      ConditionCode.Child_PeriodontalBoneLoss_Severity_Severe,
      // Child of Filling
      ConditionCode.Child_DefectDepth_Root,
      // Child of Crown fracture
      ConditionCode.Child_DefectDepth_Root, // NEED CHANGE TO CROWN_ROOT
      // Child of Signs of caries
      ConditionCode.Child_DefectDepth_Root,
      // Child of Secondary caries
      ConditionCode.Child_DefectDepth_Root,
      //
      ConditionCode.InternalResorption,
      ConditionCode.ExternalResorption,
      ConditionCode.HorizontalRootFracture,
      ConditionCode.VerticalRootFracture,
    ],
  },
  {
    id: 6,
    code: CombinedConditionGroupCode.ORTHO,
    conditionCodes: [
      ConditionCode.Impaction, // "impaction",
      ConditionCode.HorizontalDisplacement, // "horizontal_displacement",
      ConditionCode.Overeruption, // "overeruption",
      // "dystopia",
      // "extrusion",
      // "intrusion",
      // "lateral_luxation"
    ],
  },
  {
    id: 7,
    code: CombinedConditionGroupCode.CROWN_INDICATION,
    conditionCodes: [
      ConditionCode.Child_DefectSurface_Mesial, // "filling_surface/mesial", ???
      ConditionCode.Child_DefectSurface_Mesial, // "surface/mesial", ???
      ConditionCode.Child_DefectSurface_Mesial, // "secondary_caries_surface/mesial",
      ConditionCode.EndoTreated, // "endo/treated"
    ],
  },
  {
    id: 8,
    code: CombinedConditionGroupCode.TREATMENT,
    conditionCodes: [
      ConditionCode.Implant, // "implant",
      ConditionCode.Pontic, // "pontic",
      ConditionCode.ArtificialCrown, // "artificial_crown",
      ConditionCode.IndirectRestoration, // "indirect_restoration",
      ConditionCode.OrthodonticAppliance, // "orthodontic_appliance",
      ConditionCode.Pulpotomy, // "pulpotomy",
    ],
  },
  {
    id: 9,
    code: CombinedConditionGroupCode.SURGICAL,
    conditionCodes: [ConditionCode.SingsOfCommunicationWithMaxillarySinus],
  },
];
