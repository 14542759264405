// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_report_cephalometric_measurement_codes.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";

/**
 * https://diagnocat.atlassian.net/wiki/spaces/RES/pages/211058694/Cephalometric+Analysis+Specification
 *
 * @generated from enum com.diagnocat.model.CephalometricMeasure_Code
 */
export const CephalometricMeasure_Code = proto3.makeEnum(
  "com.diagnocat.model.CephalometricMeasure_Code",
  [
    {no: 0, name: "CephalometricMeasure_Code_InvalidValue"},
    {no: 1, name: "CephalometricMeasure_Code_SN_MP"},
    {no: 2, name: "CephalometricMeasure_Code_FH_OP"},
    {no: 3, name: "CephalometricMeasure_Code_Go_Ang"},
    {no: 4, name: "CephalometricMeasure_Code_Mid_Height"},
    {no: 5, name: "CephalometricMeasure_Code_Low_Height"},
    {no: 6, name: "CephalometricMeasure_Code_Beta"},
    {no: 7, name: "CephalometricMeasure_Code_Wits"},
    {no: 8, name: "CephalometricMeasure_Code_NSeAr"},
    {no: 9, name: "CephalometricMeasure_Code_SNA"},
    {no: 10, name: "CephalometricMeasure_Code_SNB"},
    {no: 11, name: "CephalometricMeasure_Code_ANB"},
    {no: 12, name: "CephalometricMeasure_Code_MP"},
    {no: 13, name: "CephalometricMeasure_Code_S_N"},
    {no: 14, name: "CephalometricMeasure_Code_ULip_Eline"},
    {no: 15, name: "CephalometricMeasure_Code_LLip_Eline"},
    {no: 16, name: "CephalometricMeasure_Code_Overjet"},
    {no: 17, name: "CephalometricMeasure_Code_Overbite"},
    {no: 18, name: "CephalometricMeasure_Code_U1_SN"},
    {no: 19, name: "CephalometricMeasure_Code_U1_PP"},
    {no: 20, name: "CephalometricMeasure_Code_U1_TVL"},
    {no: 21, name: "CephalometricMeasure_Code_Interincisal"},
    {no: 22, name: "CephalometricMeasure_Code_Li_MP"},
    {no: 23, name: "CephalometricMeasure_Code_Shimbashi"},
  ],
);

