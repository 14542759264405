import { AssetContent_Generated_CBCT_ToothPathologySlice_PathologyType } from '@/shared/api/protocol-ts/model/dto_asset_pb';
import { AssetContent_Generated_CBCT_Endo_ReportSection } from '@/shared/api/protocol-ts/model/dto_asset_cbct_endo_pb';
import { CBCT_SectionOrientation } from '@/shared/api/protocol-ts/model/dto_asset_common_pb';

import { GroupConfigsType, MedicalImagesGroups } from './medicalImages.types';

export const TOOTH_PAGE_GROUPS: GroupConfigsType[] = [
  {
    groupName: MedicalImagesGroups.OrientationAxial,
    groupCode: CBCT_SectionOrientation.SectionOrientationAxial,
  },
  {
    groupName: MedicalImagesGroups.OrientationVestibuloOral,
    groupCode: CBCT_SectionOrientation.SectionOrientationVestibuloOral,
  },
  {
    groupName: MedicalImagesGroups.OrientationMesioDistal,
    groupCode: CBCT_SectionOrientation.SectionOrientationMesioDistal,
  },
];

export const TOOTH_PAGE_PATHOLOGY_GROUPS: GroupConfigsType[] = [
  {
    groupName: MedicalImagesGroups.Caries,
    groupCode:
      AssetContent_Generated_CBCT_ToothPathologySlice_PathologyType.Caries,
  },
  {
    groupName: MedicalImagesGroups.PeriapicalLesion,
    groupCode:
      AssetContent_Generated_CBCT_ToothPathologySlice_PathologyType.PeriapicalLesion,
  },
];

export const ENDO_REPORT_GROUPS: GroupConfigsType[] = [
  {
    groupName: MedicalImagesGroups.EndoReportSectionRootCanalSpace,
    groupCode:
      AssetContent_Generated_CBCT_Endo_ReportSection.Endo_ReportSection_RootCanalSpace,
  },
  {
    groupName: MedicalImagesGroups.EndoReportSectionRootCanalSystem,
    groupCode:
      AssetContent_Generated_CBCT_Endo_ReportSection.Endo_ReportSection_RootCanalSystem,
  },
  {
    groupName: MedicalImagesGroups.EndoReportSectionRootCanalShape,
    groupCode:
      AssetContent_Generated_CBCT_Endo_ReportSection.Endo_ReportSection_RootCanalShape,
  },
  {
    groupName: MedicalImagesGroups.EndoReportSectionFurcation,
    groupCode:
      AssetContent_Generated_CBCT_Endo_ReportSection.Endo_ReportSection_Furcation,
  },
];

export const ENDODONTIC_PATHOLOGY_GROUP: GroupConfigsType = {
  groupName: MedicalImagesGroups.PeriapicalLesion,
  groupCode:
    AssetContent_Generated_CBCT_ToothPathologySlice_PathologyType.PeriapicalLesion,
};
