import { useIntl } from 'react-intl';

import { ProductModel } from '@/shared/api/protocol-ts/api/billing_new/dto_product_new_pb';
import { Service_CountableKind } from '@/shared/api/protocol-ts/api/billing/dto_services_pb';

export const useTransformTopUpAddonsOptions = (products: ProductModel[]) => {
  const { formatMessage } = useIntl();

  if (products.length === 0) return [];

  const transformedOptions = products.map((product) => ({
    label: formatMessage(
      {
        id: 'billing.topUpLimitsModal.reportLimit',
        defaultMessage: '{amount, plural, one {# report} other {# reports}}',
      },
      {
        amount:
          ((product?.Services?.at(0)?.Kind.case === 'Countable' &&
            Number(
              (product?.Services?.at(0)?.Kind?.value as Service_CountableKind)
                ?.Limit,
            )) ??
            0) ||
          0,
      },
    ),
    value: product?.SKU,
  }));

  return transformedOptions;
};
