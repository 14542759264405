import { RCImageProps } from '../RCImage/RCImage';
import { RCImageBase } from '../RCImage/RCImageBase';
import { repaint } from '../RCImage/repaint';

export type RCCropImageCrop = { x: number, y: number, w: number, h: number }

export interface RCCropImageProps extends RCImageProps {
  crop: RCCropImageCrop
  angle?: number // degrees
}

export const RCCropImage = (props: RCCropImageProps) => {
  const { crop, angle, ...baseProps } = props;

  return (
    <RCImageBase
      {...baseProps}
      repaint={(_context, inCanvas, outCanvas) => {
        repaint(crop, inCanvas, outCanvas, angle)
      }}
    />
  )
};
