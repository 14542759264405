import { useMemo } from 'react';

import { useAppSelector } from '@/shared/hooks';
// TODO: move to logicalCondition entity
// import * as logicalConditionModel from '../model';
import { ConditionCode } from '@/shared/api/protocol-ts/model/dto_report_condition_codes_pb';

// TODO: move to logicalCondition entity
import { logicalConditionModel } from '@/entities/logicalCondition';

import { filterConditionItemsByGroups } from '../lib';
import {
  ConditionInterface,
  TOOTH_ANATOMY_GROUPS,
  TOOTH_GENERAL_GROUPS,
  TOOTH_TYPE_GROUP,
} from '../config';

import { useTransformLogicalConditions } from './useTransformLogicalCondition';

type UseToothConditionsPropsReturnType = {
  conditionIsLoaded: boolean;
  anatomyConditionItems: ConditionInterface[];
  generalConditionItems: ConditionInterface[];
  isMissingTooth: boolean;
};

type UseToothConditionsProps = (props: {
  toothID: string;
  isPreviewMode?: boolean;
}) => UseToothConditionsPropsReturnType;

export const useToothConditions: UseToothConditionsProps = ({
  toothID,
  isPreviewMode = false,
}) => {
  const conditionIsLoading = useAppSelector(
    logicalConditionModel.selectors.selectLoading,
  );

  const conditionIsLoaded = conditionIsLoading === 'succeeded';

  const dataConditionsItems = useTransformLogicalConditions(
    toothID,
    isPreviewMode,
  );

  const anatomyConditionItems = useMemo(
    () =>
      filterConditionItemsByGroups({
        ...TOOTH_TYPE_GROUP,
        ...TOOTH_ANATOMY_GROUPS,
      })(dataConditionsItems),
    [dataConditionsItems],
  );

  const generalConditionItems = useMemo(
    () =>
      filterConditionItemsByGroups(TOOTH_GENERAL_GROUPS)(dataConditionsItems),
    [dataConditionsItems],
  );

  const isMissingTooth = !!dataConditionsItems.find(
    (condition) => condition.code === ConditionCode.Missing,
  );

  return {
    conditionIsLoaded,
    anatomyConditionItems,
    generalConditionItems,
    isMissingTooth,
  };
};
