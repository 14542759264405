// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file api/core/svc_patient_task.proto (package com.diagnocat.core, syntax proto3)
/* eslint-disable */

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { PatientTask } from "../../model/dto_patient_task_pb.js";
import { Patient } from "../../model/dto_patient_pb.js";

/**
 * @generated from message com.diagnocat.core.CreatePatientTaskReq
 */
export const CreatePatientTaskReq = proto3.makeMessageType(
  "com.diagnocat.core.CreatePatientTaskReq",
  () => [
    { no: 1, name: "PatientID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "Description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "DueDate", kind: "message", T: Timestamp },
    { no: 5, name: "AssigneeIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.CreatePatientTaskResp
 */
export const CreatePatientTaskResp = proto3.makeMessageType(
  "com.diagnocat.core.CreatePatientTaskResp",
  () => [
    { no: 1, name: "Task", kind: "message", T: PatientTask },
    { no: 2, name: "Patient", kind: "message", T: Patient },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetPatientTaskAssigneesReq
 */
export const SetPatientTaskAssigneesReq = proto3.makeMessageType(
  "com.diagnocat.core.SetPatientTaskAssigneesReq",
  () => [
    { no: 1, name: "TaskID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "AssigneeIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetPatientTaskAssigneesResp
 */
export const SetPatientTaskAssigneesResp = proto3.makeMessageType(
  "com.diagnocat.core.SetPatientTaskAssigneesResp",
  () => [
    { no: 1, name: "Task", kind: "message", T: PatientTask },
    { no: 2, name: "Patient", kind: "message", T: Patient },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetPatientTaskTitleReq
 */
export const SetPatientTaskTitleReq = proto3.makeMessageType(
  "com.diagnocat.core.SetPatientTaskTitleReq",
  () => [
    { no: 1, name: "TaskID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetPatientTaskTitleResp
 */
export const SetPatientTaskTitleResp = proto3.makeMessageType(
  "com.diagnocat.core.SetPatientTaskTitleResp",
  () => [
    { no: 1, name: "Task", kind: "message", T: PatientTask },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetPatientTaskDescriptionReq
 */
export const SetPatientTaskDescriptionReq = proto3.makeMessageType(
  "com.diagnocat.core.SetPatientTaskDescriptionReq",
  () => [
    { no: 1, name: "TaskID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetPatientTaskDescriptionResp
 */
export const SetPatientTaskDescriptionResp = proto3.makeMessageType(
  "com.diagnocat.core.SetPatientTaskDescriptionResp",
  () => [
    { no: 1, name: "Task", kind: "message", T: PatientTask },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetPatientTaskDueDateReq
 */
export const SetPatientTaskDueDateReq = proto3.makeMessageType(
  "com.diagnocat.core.SetPatientTaskDueDateReq",
  () => [
    { no: 1, name: "TaskID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "DueDate", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetPatientTaskDueDateResp
 */
export const SetPatientTaskDueDateResp = proto3.makeMessageType(
  "com.diagnocat.core.SetPatientTaskDueDateResp",
  () => [
    { no: 1, name: "Task", kind: "message", T: PatientTask },
  ],
);

/**
 * @generated from message com.diagnocat.core.ClosePatientTaskReq
 */
export const ClosePatientTaskReq = proto3.makeMessageType(
  "com.diagnocat.core.ClosePatientTaskReq",
  () => [
    { no: 1, name: "TaskID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.ClosePatientTaskResp
 */
export const ClosePatientTaskResp = proto3.makeMessageType(
  "com.diagnocat.core.ClosePatientTaskResp",
  () => [
    { no: 1, name: "Task", kind: "message", T: PatientTask },
  ],
);

/**
 * @generated from message com.diagnocat.core.ReopenPatientTaskReq
 */
export const ReopenPatientTaskReq = proto3.makeMessageType(
  "com.diagnocat.core.ReopenPatientTaskReq",
  () => [
    { no: 1, name: "TaskID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.ReopenPatientTaskResp
 */
export const ReopenPatientTaskResp = proto3.makeMessageType(
  "com.diagnocat.core.ReopenPatientTaskResp",
  () => [
    { no: 1, name: "Task", kind: "message", T: PatientTask },
  ],
);

/**
 * @generated from message com.diagnocat.core.MarkAsReadPatientTasksReq
 */
export const MarkAsReadPatientTasksReq = proto3.makeMessageType(
  "com.diagnocat.core.MarkAsReadPatientTasksReq",
  () => [
    { no: 1, name: "TaskIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.MarkAsReadPatientTasksResp
 */
export const MarkAsReadPatientTasksResp = proto3.makeMessageType(
  "com.diagnocat.core.MarkAsReadPatientTasksResp",
  () => [
    { no: 1, name: "Tasks", kind: "message", T: PatientTask, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetPatientTaskOrderReq
 */
export const SetPatientTaskOrderReq = proto3.makeMessageType(
  "com.diagnocat.core.SetPatientTaskOrderReq",
  () => [
    { no: 1, name: "TaskID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Order", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetPatientTaskOrderResp
 */
export const SetPatientTaskOrderResp = proto3.makeMessageType(
  "com.diagnocat.core.SetPatientTaskOrderResp",
  () => [
    { no: 1, name: "Tasks", kind: "message", T: PatientTask, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.DeletePatientTaskReq
 */
export const DeletePatientTaskReq = proto3.makeMessageType(
  "com.diagnocat.core.DeletePatientTaskReq",
  () => [
    { no: 1, name: "TaskID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.DeletePatientTaskResp
 */
export const DeletePatientTaskResp = proto3.makeMessageType(
  "com.diagnocat.core.DeletePatientTaskResp",
  () => [
    { no: 1, name: "Tasks", kind: "message", T: PatientTask, repeated: true },
  ],
);

