// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file api/notifications/dto_notification.proto (package com.diagnocat.notifications, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { NotificationMessageDeleted, NotificationMessageEdited, NotificationMessagePosted, NotificationPatientInviteAccepted, NotificationPatientProfileChanged, NotificationPatientStatusChanged, NotificationPatientTreatingDoctorAdded, NotificationPatientTreatingDoctorRemoved, NotificationProjectTaskAddAssignee, NotificationReportCompleted, NotificationReportRequested, NotificationStudyUploaded } from "./dto_notifications_pb.js";

/**
 * @generated from message com.diagnocat.notifications.Notification
 */
export const Notification = proto3.makeMessageType(
  "com.diagnocat.notifications.Notification",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Time", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "Read", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 100, name: "MessagePosted", kind: "message", T: NotificationMessagePosted, oneof: "Payload" },
    { no: 101, name: "MessageEdited", kind: "message", T: NotificationMessageEdited, oneof: "Payload" },
    { no: 102, name: "MessageDeleted", kind: "message", T: NotificationMessageDeleted, oneof: "Payload" },
    { no: 200, name: "StudyUploaded", kind: "message", T: NotificationStudyUploaded, oneof: "Payload" },
    { no: 300, name: "ReportRequested", kind: "message", T: NotificationReportRequested, oneof: "Payload" },
    { no: 301, name: "ReportCompleted", kind: "message", T: NotificationReportCompleted, oneof: "Payload" },
    { no: 400, name: "PatientProfileChanged", kind: "message", T: NotificationPatientProfileChanged, oneof: "Payload" },
    { no: 401, name: "PatientStatusChanged", kind: "message", T: NotificationPatientStatusChanged, oneof: "Payload" },
    { no: 402, name: "PatientTreatingDoctorAdded", kind: "message", T: NotificationPatientTreatingDoctorAdded, oneof: "Payload" },
    { no: 403, name: "PatientTreatingDoctorRemoved", kind: "message", T: NotificationPatientTreatingDoctorRemoved, oneof: "Payload" },
    { no: 404, name: "PatientInviteAccepted", kind: "message", T: NotificationPatientInviteAccepted, oneof: "Payload" },
    { no: 500, name: "ProjectTaskAddAssignee", kind: "message", T: NotificationProjectTaskAddAssignee, oneof: "Payload" },
  ],
);

