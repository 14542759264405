import { ReactNode } from 'react';
import { FieldValues, UseFormSetError } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { ConnectError } from '@bufbuild/connect';

import { PATHS, UserBadRequestError } from '@/shared/config';
import { safeJSONParse } from '@/shared/lib';

export const errorHandler = (
  error: ConnectError,
  setError: UseFormSetError<FieldValues>,
) => {
  const { rawMessage } = error;
  const parsedMessage = safeJSONParse(rawMessage);

  const isInvalidCredsError =
    parsedMessage?.code === UserBadRequestError.RESET_INVALID_CREDS;

  if (isInvalidCredsError) {
    const errorMessage: unknown = (
      <FormattedMessage
        id="resetPassword.errors.invalidCredsError"
        defaultMessage="This email is not registered. You can <a>Sign up</a> instead."
        values={{
          a: (chunks: ReactNode) => (
            <a style={{ color: 'var(--purpleflat)' }} href={PATHS.signUp}>
              {chunks}
            </a>
          ),
        }}
      />
    );

    setError('root', {
      message: errorMessage as string,
    });
  }
};
