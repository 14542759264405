import { ReportType } from '@/shared/api/protocol-ts/model/dto_report_pb';

const CBCT_GP_GENERATION_AVERAGE_TIME = 180_000;

const PANO_AND_IOXRAY_GP_GENERATION_AVERAGE_TIME = 60_000;

export const getReportGenerationInterval = (reportType: ReportType): number => {
  if (reportType === ReportType.ReportType_CBCT_GP) {
    return Math.floor(CBCT_GP_GENERATION_AVERAGE_TIME / 100);
  }
  return Math.floor(PANO_AND_IOXRAY_GP_GENERATION_AVERAGE_TIME / 100);
};
