import { isEmpty } from 'ramda';

import { ToothConditionCollectionByID } from '@/entities/logicalCondition/model/logicalConditionSlice';

import { LogicalCondition } from '../../logicalCondition/config/types';

export const mergeConditions = (
  conditionCollection: ToothConditionCollectionByID,
): LogicalCondition | undefined => {
  if (!conditionCollection || isEmpty(conditionCollection)) {
    return undefined;
  }

  const conditions = Object.values(conditionCollection);

  // @ts-expect-error WARN: Deprecated.
  return conditions.reduce((logicalCondition: LogicalCondition, condition) => {
    // @ts-expect-error WARN: Deprecated.
    const currentLogicalCondition: LogicalCondition = {
      ...condition,
      IDs: [condition.ID],
      Childs: [],
    };

    // If logicalCondition is empty, return the first
    if (isEmpty(logicalCondition)) {
      return currentLogicalCondition;
    }

    const logicalConditionModelScore =
      logicalCondition?.Certainty?.ModelScore ?? 0;
    const conditionModelScore = condition?.Certainty?.ModelScore ?? 0;

    if (logicalConditionModelScore >= conditionModelScore) {
      // WARN: I don't like all this spread shit, because it makes shallow copy of object. It could lead to bugs.
      //       Need to figure out how to use _.merge for this. Try to use _.cloneDeep?
      return {
        ...logicalCondition,
        Localizations: logicalCondition.Localizations.concat(
          condition.Localizations,
        ),
        IDs: [...logicalCondition.IDs, condition.ID],
        Childs: [],
      };
    }

    return {
      ...condition,
      Localizations: logicalCondition.Localizations.concat(
        condition.Localizations,
      ),
      IDs: [...logicalCondition.IDs, condition.ID],
      Childs: [],
    };
  }, {} as LogicalCondition);
};
