import { ConditionCode } from '@/shared/api/protocol-ts/model/dto_report_condition_codes_pb';

import { ConditionInterface } from '../config/types';

import { collectConditionCodesFromGroups } from './collectConditionCodesFromGroups';

export const isConditionItemInGroups =
  (groups: Record<string, ConditionCode[]>) =>
  (conditionItem: ConditionInterface) =>
    collectConditionCodesFromGroups(groups).includes(conditionItem.code);
