import { Mask2DConfigCodes } from '@/shared/config';

import { conditionText } from '@/entities/condition/config/i18n';

import {
  CBCTAnatomyDefaultMessages,
  CBCTToothAnatomyDefaultMessages,
  IOXRayAnatomyDefaultMessages,
  IOXRayToothAnatomyDefaultMessages,
  PanoAnatomyDefaultMessages,
  PanoToothAnatomyDefaultMessages,
} from '../config/i18n';

export const getMaskLabelID = (codes: Mask2DConfigCodes) => {
  if (codes?.CBCTMaxFaxAnatomy) {
    return CBCTAnatomyDefaultMessages[codes.CBCTMaxFaxAnatomy];
  }
  if (codes?.IOXRayMaxFaxAnatomy) {
    return IOXRayAnatomyDefaultMessages[codes.IOXRayMaxFaxAnatomy];
  }
  if (codes?.PanoMaxFaxAnatomy) {
    return PanoAnatomyDefaultMessages[codes.PanoMaxFaxAnatomy];
  }
  if (codes?.CBCTToothAnatomy) {
    return CBCTToothAnatomyDefaultMessages[codes.CBCTToothAnatomy];
  }
  if (codes?.IOXRayToothAnatomy) {
    return IOXRayToothAnatomyDefaultMessages[codes.IOXRayToothAnatomy];
  }
  if (codes?.PanoToothAnatomy) {
    return PanoToothAnatomyDefaultMessages[codes.PanoToothAnatomy];
  }
  if (codes?.conditionCode) {
    return conditionText[codes.conditionCode];
  }
};
