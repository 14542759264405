import { Report } from '@/shared/api/protocol-ts/model/dto_report_pb';

import { SegmentationReportTypes } from '@/entities/reports';

export const filterOutSegmentronReportsIfInactive = (
  reports: Report[],
  isSegmentronActive: boolean,
): Report[] => {
  const isNotSegmentronReport = (report: Report): boolean =>
    !SegmentationReportTypes.includes(report.Type);

  return isSegmentronActive ? reports : reports.filter(isNotSegmentronReport);
};
