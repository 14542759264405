import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import styles from './ListLoadingFailed.module.scss';

type ListLoadingFailedProps = {
  className?: string;
  testID?: string;
};

export const ListLoadingFailed: FC<ListLoadingFailedProps> = (props) => {
  const { className, testID } = props;

  const { formatMessage } = useIntl();

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      <div className={styles.content}>
        <div className={styles.logo} />
        <p className={styles.text}>
          {formatMessage({
            id: 'errorPage.somethingWentWrong',
            defaultMessage: 'Oops. Something went wrong',
          })}
        </p>
      </div>
    </div>
  );
};
