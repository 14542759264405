import { SharedByMeStreamSortBy } from '@/shared/api/protocol-ts/api/core/svc_access_pb';
import { PatientsListColumnName } from '@/shared/config';

export const getSortByOptionsForSharingBy = (
  sortKey: PatientsListColumnName,
  sortAsc: boolean,
): { By?: SharedByMeStreamSortBy; Ascending?: boolean }[] => {
  switch (sortKey) {
    case PatientsListColumnName.PatientName:
      return [
        {
          Ascending: sortAsc,
          By: SharedByMeStreamSortBy.SharedByMeStreamSortByPatientLastName,
        },
        {
          Ascending: sortAsc,
          By: SharedByMeStreamSortBy.SharedByMeStreamSortByPatientFirstName,
        },
      ];
    case PatientsListColumnName.SharedBy:
      return [
        {
          Ascending: sortAsc,
          By: SharedByMeStreamSortBy.SharedByMeStreamSortBySenderLastName,
        },
        {
          Ascending: sortAsc,
          By: SharedByMeStreamSortBy.SharedByMeStreamSortBySenderFirstName,
        },
      ];
    case PatientsListColumnName.SharedWith:
      return [
        {
          Ascending: sortAsc,
          By: SharedByMeStreamSortBy.SharedByMeStreamSortByRecipientEmail,
        },
      ];
    case PatientsListColumnName.SharingDate:
      return [
        {
          Ascending: sortAsc,
          By: SharedByMeStreamSortBy.SharedByMeStreamSortBySharingDate,
        },
      ];
    case PatientsListColumnName.Status:
      return [
        {
          Ascending: sortAsc,
          By: SharedByMeStreamSortBy.SharedByMeStreamSortByStatus,
        },
      ];
    default:
      return [];
  }
};
