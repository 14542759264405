import { defineMessages } from 'react-intl';
import { Color } from 'three';

import { ReportGenerationErrorCode } from '../api/protocol-ts/model/dto_report_generation_error_codes_pb';
import { ApiServices } from '../api/api';
import { ConditionCode } from '../api/protocol-ts/model/dto_report_condition_codes_pb';
import {
  IOXRay_Anatomy,
  IOXRay_Tooth_Anatomy,
  Pano_Anatomy,
  Pano_Tooth_Anatomy,
} from '../api/protocol-ts/model/dto_report_type_2D_common_pb';
import {
  CBCT_Anatomy,
  CBCT_Tooth_Anatomy,
} from '../api/protocol-ts/model/dto_common_anatomy_pb';

import {
  MaskGroup,
  PatientPermissionsKeys,
  ReportPermissionsKeys,
} from './types';

export enum SliceName {
  access = 'access',
  allowedToothConditions = 'allowedToothConditions',
  assets = 'assets',
  auth = 'auth',
  billing = 'billing',
  condition = 'condition',
  marketing = 'marketing',
  messenger = 'messenger',
  modal = 'modal',
  notification = 'notification',
  organization = 'organization',
  patient = 'patient',
  reports = 'reports',
  study = 'study',
  storage = 'storage',
  studyCount = 'studyCount',
  theme = 'theme',
  tooth = 'tooth',
  user = 'user',
  newCondition = 'newCondition',
  logicalCondition = 'logicalCondition',
  toothLandmarks = 'toothLandmarks',
  maskFilters = 'maskFilters',
  hubspot = 'hubspot',
}

export enum ToothStatus {
  unhealthy = 'unhealthy',
  lowProbability = 'lowProbability',
  treated = 'treated',
  healthy = 'healthy',
  missing = 'missing',
  notFound = 'notFound',
}

export enum UserBadRequestError {
  INCORRECT_INVITATION_SECRET = 'incorrect_invitation_secret',
  INVITATION_RECIPIENT_ALREADY_HAS_ORGANIZATION_ROLE = 'invitation_recipient_already_has_organization_role',
  INVITATION_SECRET_INPUT_LIMIT_EXCEEDED = 'invitation_secret_input_limit_exceeded',
  MULTIPLE_PANORAMIC_XRAYS_PROVIDED = 'multiple_panoramic_xrays_provided',
  TOO_MANY_FILES_PROVIDED = 'too_many_files_provided',
  RESOURCE_IS_TOO_LARGE = 'resource_is_too_large',
  FORBIDDEN_VOLUMETRIC_DICOM = 'forbidden_volumetric_dicom',
  FORBIDDEN_BIDIMENSIONAL_DICOM = 'forbidden_bidimensional_dicom',
  FILE_IS_INVALID = 'file_is_invalid',
  LOGIN_INVALID_CREDS = 'login_invalid_creds',
  EMAIL_TAKEN = 'email_taken',
  RESET_INVALID_CREDS = 'reset_invalid_creds',
  ALREADY_REGISTERED = 'already_registered',
  CODE_RETRIES_LIMIT = 'code_retries_limit',
  CODE_IS_EXPIRED = 'code_is_expired',
  WRONG_CODE = 'wrong_code',
  // NOTE: Happens when the user is already signed in and open confirm email link from email letter
  NON_RESENDABLE_AUTH_METHOD = 'non_resendable_auth_method',
}

export enum AlreadyExistError {
  ACCEPTED_PATIENT_SHARING = 'accepted patient sharing',
  INVITATION_TO_THIS_ORGANIZATION_ROLE = 'invitation to this organization role',
  INVITATION_TO_THIS_PATIENT = 'invitation to this patient',
}

export enum NotFoundError {
  TOKEN = 'token',
}

export const REPORT_PERMISSIONS: ReportPermissionsKeys[] = [
  'CanView',
  'CanChangeROI',
  'CanChangeConditions',
  'CanInteractWithImages',
  'CanSign',
  'CanPrint',
  'CanChangeConclusion',
  'CanChangeSettings',
  'CanDelete',
  'CanChangeReportAssets',
  'CanChangeToothComment',
  'CanChangeToothNumeration',
  'CanChangeToothApproved',
  'CanResetTooth',
  'CanChangeToothAssets',
];

export const PATIENT_PERMISSIONS: PatientPermissionsKeys[] = [
  'CanView',
  'CanViewPersonalData',
  'CanEditPersonalData',
  'CanEditPatient',
  'CanViewTeam',
  'CanEditTeam',
  'CanViewSharings',
  'CanShare',
  'CanCancelOthersSharings',
  'CanViewClinicalCaseDescription',
  'CanEditClinicalCaseDescription',
  'CanViewTasks',
  'CanEditTasks',
  'CanRequestReport',
  'CanUploadCBCT',
  'CanUploadIOXRay',
  'CanUploadPanoramicXRay',
  'CanUploadSTL',
  'CanUploadDentalPhoto',
  'CanUploadDocuments',
  'CanViewStudies',
  'CanViewReports',
];

export const ALL_PERMISSIONS = [...REPORT_PERMISSIONS, ...PATIENT_PERMISSIONS];

export const USER_BAD_REQUEST_INLINE_ERROR_CODES = [
  UserBadRequestError.LOGIN_INVALID_CREDS,
  UserBadRequestError.FILE_IS_INVALID,
  UserBadRequestError.INCORRECT_INVITATION_SECRET,
  UserBadRequestError.INVITATION_SECRET_INPUT_LIMIT_EXCEEDED,
  UserBadRequestError.EMAIL_TAKEN,
  UserBadRequestError.INVITATION_RECIPIENT_ALREADY_HAS_ORGANIZATION_ROLE,
  UserBadRequestError.WRONG_CODE,
  UserBadRequestError.RESET_INVALID_CREDS,
];

export const REPORT_GENERATION_INLINE_ERROR_CODES = [
  ReportGenerationErrorCode.ReportGenerationErrorCode_Unspecified,
  ReportGenerationErrorCode.ReportGenerationErrorCode_GeneratedAssetUpload_Failed,
  ReportGenerationErrorCode.ReportGenerationErrorCode_ValidTimeExpired,
  ReportGenerationErrorCode.ReportGenerationErrorCode_Modeling_CbctIosMisalignment,
  ReportGenerationErrorCode.ReportGenerationErrorCode_Modeling_IosMissingJaw,
  ReportGenerationErrorCode.ReportGenerationErrorCode_Modeling_RequestedToothNotFound,
  ReportGenerationErrorCode.ReportGenerationErrorCode_StudyUpload_Failed,
  ReportGenerationErrorCode.ReportGenerationErrorCode_StudyUpload_Canceled,
  ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_IsNot2DImage,
  ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_IsNot3DImage,
  ReportGenerationErrorCode.ReportGenerationErrorCode_FileCannotBeRead,
  ReportGenerationErrorCode.ReportGenerationErrorCode_InputData_UnexpectedStudyQuantity,
  ReportGenerationErrorCode.ReportGenerationErrorCode_InputData_UnexpectedStudyContent,
  ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_DuplicateSlices,
  ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_Metadata_NonDerivableVoxelSize,
  ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_Metadata_NonDerivableRescaleInterceptAttribute,
  ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_Metadata_NonDerivableRescaleSlopeAttribute,
  ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_Metadata_NonDerivableImagePositionPatientAttribute,
  ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_Metadata_UnexpectedModality,
];

export const INLINE_ERROR_CODES = [
  ...USER_BAD_REQUEST_INLINE_ERROR_CODES,
  ...REPORT_GENERATION_INLINE_ERROR_CODES,
];

export enum ToothIconPathByToothStatus {
  unhealthy = 'Unhealthy',
  lowProbability = 'LowProbability',
  treated = 'Treated',
  healthy = 'Healthy',
}

export const REFRESH_TOKEN_COOLDOWN_MS = 240000;

export enum PrintType {
  blackAndWhite = 'black-and-white',
  colored = 'colored',
}

export const MOLAR_TOOTH_NUMBER = [
  18, 17, 16, 28, 27, 26, 38, 37, 36, 48, 47, 46,
];

export const DEFAULT_YEAR_OFFSET: number = 10;

export const DEFAULT_MEDICAL_IMAGE_HEIGHT = 140;

export enum ToastErrors {
  errorHeading = 'errorHeading',
  wrongFormat = 'wrongFormat',
}
export const toastErrors = defineMessages<ToastErrors>({
  errorHeading: {
    id: 'error.heading',
    defaultMessage: 'Error',
  },
  wrongFormat: {
    id: 'error.heading.wrongFormat',
    defaultMessage: 'Wrong format',
  },
});

export const DIAGNOCAT_EMAIL = 'cat@diagnocat.com';

export const UPLOADING_IMAGE_MAX_SIZE = 5242880;
export const UPLOADING_IMAGE_ACCEPTED_FORMATS = ['.png', '.jpg', '.jpeg'];

export const DEFAULT_LOCALE = 'en-US';

// Maybe make separate file for animation constants?
export const MODAL_ANIMATION_DURATION = 0.15;

export const WHITE_SPACE_REGEX = /\s/g;

export const FORWARD_SLASH_REGEX = /\//g;

export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~\\]{8,}$/;

export const BILLING_ZONE_WITHOUT_STRIPE_REGEXP = /cis|israel/gi;

export const JAW = {
  quartile1: [18, 17, 16, 15, 14, 13, 12, 11],
  quartile2: [21, 22, 23, 24, 25, 26, 27, 28],
  quartile3: [31, 32, 33, 34, 35, 36, 37, 38],
  quartile4: [48, 47, 46, 45, 44, 43, 42, 41],
  primaryQuartile1: [55, 54, 53, 52, 51],
  primaryQuartile2: [61, 62, 63, 64, 65],
  primaryQuartile3: [71, 72, 73, 74, 75],
  primaryQuartile4: [85, 84, 83, 82, 81],
};

export const LOWER_JAW_TEETH_ISO_NUMBERS = [
  ...JAW.quartile3,
  ...JAW.quartile4,
  ...JAW.primaryQuartile3,
  ...JAW.primaryQuartile4,
];

export const UPPER_JAW_TEETH_ISO_NUMBERS = [
  ...JAW.quartile1,
  ...JAW.quartile2,
  ...JAW.primaryQuartile1,
  ...JAW.primaryQuartile2,
];

export const NEW_LINE = '\n';

export const IGNORE_INTERNAL_SERVER_ERROR_SERVICES: ApiServices[] = [
  'marketing',
  'hubspot',
];

export const MASK_FILTERS_RESTORATIVE_CODES = [
  ConditionCode.Attrition,
  ConditionCode.Abfraction,
  ConditionCode.CrownFracture,
  ConditionCode.RestorationFracture,
  ConditionCode.PreEruptiveResorption,
  ConditionCode.VoidsInTheFilling,
  ConditionCode.Overhang,
  ConditionCode.OpenMargin,
  ConditionCode.LackOfInterproximalContact,
  ConditionCode.ToothStump,
  ConditionCode.CariesSigns,
  ConditionCode.SecondaryCaries,
  ConditionCode.Child_DefectDepth_Enamel,
  ConditionCode.Child_DefectDepth_Dentin,
  ConditionCode.Child_DefectDepth_WithPulpExposure,
  ConditionCode.Child_DefectDepth_Initial,
  ConditionCode.Child_DefectDepth_NotDefined,
  ConditionCode.RootFragment,
];

export const MASK_FILTERS_ENDO_CODES = [
  ConditionCode.CervicalResorption,
  ConditionCode.Extrusion,
  ConditionCode.Intrusion,
  ConditionCode.LateralLuxation,
  ConditionCode.CervicalResorption,
  ConditionCode.EndodonticAccess,
  ConditionCode.InternalResorption,
  ConditionCode.CanalObliteration,
  ConditionCode.Pulpotomy,
  ConditionCode.Child_EndoTreated_Obturation_ShortFilling,
  ConditionCode.Child_EndoTreated_Obturation_Overfilling,
  ConditionCode.Child_EndoTreated_Obturation_MissedCanal,
  ConditionCode.Child_EndoTreated_Complications_RootPerforation,
  ConditionCode.Child_EndoTreated_Complications_SeparatedInstrumentInTheRootCanal,
  ConditionCode.PeriapicalRadiolucency,
  ConditionCode.LateralRadiolucency,
  ConditionCode.PDLSpaceWidening_AlongTheRoot,
  ConditionCode.PDLSpaceWidening_InPeriapicalRegion,
  ConditionCode.SingsOfCommunicationWithMaxillarySinus,
  ConditionCode.SignsOfInferiorAlveolarCanalInvolvement,
];

export const MASK_FILTERS_PREVIOUS_TREATMENT = [
  ConditionCode.Filling,
  ConditionCode.ArtificialCrown,
  ConditionCode.IndirectRestoration,
  ConditionCode.OrthodonticAppliance,
  ConditionCode.HealingAbutment,
  ConditionCode.EndodonticAccess,
  ConditionCode.EndoTreated,
  ConditionCode.Child_EndoTreated_Obturation_AdequateObturation,
  ConditionCode.Child_EndoTreated_Quality_AdequateDensity,
  ConditionCode.Child_EndoTreated_PostAndCore_CastPostAndCore,
  ConditionCode.Child_EndoTreated_PostAndCore_FiberglassPost,
  ConditionCode.Child_EndoTreated_PostAndCore_MetalPost,
  ConditionCode.Child_EndoTreated_PostAndCore_Post,
];

export const MASK_FILTERS_PERIO_CODES = [
  ConditionCode.PeriodontalBoneLoss,
  ConditionCode.Child_PeriodontalBoneLoss_Severity_Mild,
  ConditionCode.Child_PeriodontalBoneLoss_Severity_Moderate,
  ConditionCode.Child_PeriodontalBoneLoss_Severity_Severe,
  ConditionCode.Child_PeriodontalBoneLoss_Type_Horizontal,
  ConditionCode.Child_PeriodontalBoneLoss_Type_Vertical,
  ConditionCode.Child_PeriodontalBoneLoss_Type_Mixed,
  ConditionCode.FurcationLesion,
  ConditionCode.DentalCalculus,
  ConditionCode.Impaction,
];

export const MASK_FILTERS_IOXRAY_TOOTH_ANATOMY_CODES = [
  IOXRay_Tooth_Anatomy.IOXRay_Tooth_Anatomy_Root,
  IOXRay_Tooth_Anatomy.IOXRay_Tooth_Anatomy_RootCanal,
  IOXRay_Tooth_Anatomy.IOXRay_Tooth_Anatomy_Crown,
  IOXRay_Tooth_Anatomy.IOXRay_Tooth_Anatomy_IncisalEdge,
  IOXRay_Tooth_Anatomy.IOXRay_Tooth_Anatomy_PulpChamber,
  IOXRay_Tooth_Anatomy.IOXRay_Tooth_Anatomy_Enamel,
];

export const MASK_FILTERS_PANO_TOOTH_ANATOMY_CODES = [
  Pano_Tooth_Anatomy.Pano_Tooth_Anatomy_Root,
  Pano_Tooth_Anatomy.Pano_Tooth_Anatomy_RootCanal,
  Pano_Tooth_Anatomy.Pano_Tooth_Anatomy_Crown,
  Pano_Tooth_Anatomy.Pano_Tooth_Anatomy_PulpChamber,
  Pano_Tooth_Anatomy.Pano_Tooth_Anatomy_Enamel,
];

export const MASK_FILTERS_CBCT_TOOTH_ANATOMY_CODES = [
  CBCT_Tooth_Anatomy.CBCT_Tooth_Anatomy_Pulp,
  CBCT_Tooth_Anatomy.CBCT_Tooth_Anatomy_Dentin,
  CBCT_Tooth_Anatomy.CBCT_Tooth_Anatomy_Enamel,
  CBCT_Tooth_Anatomy.CBCT_Tooth_Anatomy_Root,
];

export const MASK_FILTERS_IOXRAY_MAX_FAX_ANATOMY_CODES = [
  IOXRay_Anatomy.IOXRay_Anatomy_AlveolarBone,
  IOXRay_Anatomy.IOXRay_Anatomy_MandibularCanal,
] as number[];

export const MASK_FILTERS_PANO_MAX_FAX_ANATOMY_CODES = [
  Pano_Anatomy.Pano_Anatomy_MandibularCanal,
  Pano_Anatomy.Pano_Anatomy_Maxilla,
  Pano_Anatomy.Pano_Anatomy_Mandible,
];

export const MASK_FILTERS_CBCT_MAX_FAX_ANATOMY_CODES = [
  CBCT_Anatomy.CBCT_Anatomy_MandibularCanal,
  CBCT_Anatomy.CBCT_Anatomy_Maxilla,
  CBCT_Anatomy.CBCT_Anatomy_Mandible,
];

// TODO: need to approve new anatomy codes add new filter

// TODO: temporary config for conditions with masks should be removed when anatomy will be ready
// TODO: need to move at shared mb for correct FSD
export const MASK_CONDITIONS_TO_SHOW = MASK_FILTERS_PERIO_CODES.concat(
  MASK_FILTERS_RESTORATIVE_CODES,
  MASK_FILTERS_ENDO_CODES,
  MASK_FILTERS_PREVIOUS_TREATMENT,
);

export const CONDITIONS_BY_MASK_GROUP: Record<MaskGroup, ConditionCode[]> = {
  perio: MASK_FILTERS_PERIO_CODES,
  restorative: MASK_FILTERS_RESTORATIVE_CODES,
  endo: MASK_FILTERS_ENDO_CODES,
  chart: MASK_FILTERS_PREVIOUS_TREATMENT,
};

export const MASK_COLORS: Record<string, { color: number; opacity: number }> = {
  perio: {
    color: new Color(1, 0.145, 0.306).getHex(),
    opacity: 0.3,
  },
  restorative: {
    color: new Color(0.757, 0.047, 0.427).getHex(),
    opacity: 0.6,
  },
  endo: {
    color: new Color(1, 0.298, 0).getHex(),
    opacity: 0.3,
  },
  unsorted: {
    color: new Color(1, 0, 0).getHex(),
    opacity: 0.3,
  },
  dentin: {
    color: new Color(255 / 255, 220 / 255, 177 / 255).getHex(),
    opacity: 0.3,
  },
  previousTreatment: {
    color: new Color(1, 1, 1).getHex(),
    opacity: 1,
  },
  canal: {
    color: new Color(230 / 255, 185 / 255, 171 / 255).getHex(),
    opacity: 0.3,
  },
  pulp: {
    color: new Color(230 / 255, 185 / 255, 171 / 255).getHex(),
    opacity: 0.8,
  },
  enamel: {
    color: new Color(1, 1, 1).getHex(),
    opacity: 0.3,
  },
  bone: {
    color: new Color(0, 148 / 255, 1).getHex(),
    opacity: 0.15,
  },
};

export const LOW_PROBABILITY_CONDITION_CODES = [
  ConditionCode.CariesSigns,
  ConditionCode.PDLSpaceWidening_AlongTheRoot,
  ConditionCode.PDLSpaceWidening_InPeriapicalRegion,
];
