// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_asset_segmentron_common.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { File } from "./dto_asset_common_pb.js";
import { CBCTSeriesGeometryData } from "./dto_common_geometry_pb.js";

/**
 *
 * - ReportType_CBCT_Segmentation
 * - ReportType_IOS_Segmentation
 * - ReportType_CBCT_IOS_Superimposition
 * - ReportType_StudioImplant
 * - ReportType_StudioOrtho
 *
 * @generated from message com.diagnocat.model.AssetContent_Generated_CBCTTile
 */
export const AssetContent_Generated_CBCTTile = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Generated_CBCTTile",
  () => [
    { no: 1, name: "Tile", kind: "message", T: File },
    { no: 2, name: "Geometry", kind: "message", T: CBCTSeriesGeometryData },
  ],
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Report_Studio_ApplicationStateSnapshot
 */
export const AssetContent_Report_Studio_ApplicationStateSnapshot = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Report_Studio_ApplicationStateSnapshot",
  () => [
    { no: 1, name: "ApplicationStateSnapshot", kind: "message", T: File },
  ],
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Report_Studio_Geometry
 */
export const AssetContent_Report_Studio_Geometry = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Report_Studio_Geometry",
  () => [
    { no: 1, name: "Geometry", kind: "message", T: File },
  ],
);

