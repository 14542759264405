import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import { Organization } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { Description, Icon } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';

import { getImageSrc } from '@/entities/assets';
import { useCountriesOptions } from '@/entities/organization';
import { userModel } from '@/entities/user';

import styles from './PreviewHeader.module.scss';

type PreviewHeaderProps = {
  title: string;
  date: string;
  patientName: string;
  patientDateOfBirth: string;
  organization?: Organization;
  blackAndWhiteMode?: boolean;
};

export const PreviewHeader: FC<PreviewHeaderProps> = (props) => {
  const {
    title,
    date,
    patientName,
    patientDateOfBirth,
    organization,
    blackAndWhiteMode,
  } = props;

  const { formatMessage } = useIntl();

  const locale = useAppSelector(userModel.selectors.selectUserLocale);

  const countryOptions = useCountriesOptions(locale);

  const organizationCountry = organization?.Contacts?.Country;

  const currentCountry = useMemo(
    () =>
      countryOptions?.find(
        (countryOption) => countryOption.value === organizationCountry,
      )?.label ?? '',
    [countryOptions, organizationCountry],
  );

  return (
    <div className={styles.container}>
      <div className={styles.reportInfo}>
        <h1 className="h3">{title}</h1>

        <div className={styles.reportInfoDetails}>
          <div className={styles.patientName}>
            <Icon name="user" size={24} className={styles.patientIcon} />
            <p className="p2">{patientName}</p>
          </div>

          <Description
            fontType="p2"
            className={cn('p2', styles.description)}
            label={formatMessage({
              id: 'patientInfo.DoB',
              defaultMessage: 'DoB',
            })}
          >
            {patientDateOfBirth}
          </Description>

          <Description
            fontType="p2"
            className={styles.description}
            label={formatMessage({
              id: 'patientInfo.studyDate',
              defaultMessage: 'Study Date',
            })}
          >
            {date}
          </Description>
        </div>
      </div>

      <div
        className={cn(
          styles.clinicInfo,
          organization?.LogoAssetID && styles.withClinicLogo,
        )}
      >
        {organization?.LogoAssetID && (
          <img
            className={cn(styles.orgLogo, {
              [styles.blackOrgLogo]: blackAndWhiteMode,
            })}
            src={getImageSrc(organization.LogoAssetID, 'thumbnail')}
            alt={formatMessage({
              id: 'imgAltText.organizationLogo',
              defaultMessage: 'Organization Logo',
            })}
          />
        )}

        <div className={styles.orgContacts}>
          <p className="p2">{organization?.Name}</p>
          <p className="p2">{organization?.Contacts?.Address}</p>
          <p className="p2">{organization?.Contacts?.City}</p>
          <p className="p2">{organization?.Contacts?.Region}</p>
          <p className="p2">{organization?.Contacts?.ZipCode}</p>
          <p className="p2">{currentCountry}</p>
          <p className="p2">{organization?.Contacts?.Phones[0]}</p>
          <p className="p2">{organization?.Contacts?.Emails[0]}</p>
          <p className="p2">{organization?.Contacts?.WebSite}</p>
        </div>
      </div>
    </div>
  );
};
