// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_report_type_ioxray_gp.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { Report_2D_Image_Meta } from "./dto_report_type_2D_common_pb.js";
import { MaxFaxAnatomyLocalization } from "./dto_report_maxfax_anatomy_localization_pb.js";

/**
 * @generated from message com.diagnocat.model.Report_IOXRay_GP
 */
export const Report_IOXRay_GP = proto3.makeMessageType(
  "com.diagnocat.model.Report_IOXRay_GP",
  () => [
    { no: 1, name: "Images_Meta", kind: "message", T: Report_2D_Image_Meta, repeated: true },
    { no: 2, name: "MaxFaxAnatomyLocalizations", kind: "message", T: MaxFaxAnatomyLocalization, repeated: true },
  ],
);

