// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_common_image_annotations.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { FloatPoint2D } from "./dto_common_geometry_pb.js";

/**
 * @generated from message com.diagnocat.model.Annotations
 */
export const Annotations = proto3.makeMessageType(
  "com.diagnocat.model.Annotations",
  () => [
    { no: 1, name: "Layers", kind: "message", T: AnnotationsLayer, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.model.AnnotationsLayer
 */
export const AnnotationsLayer = proto3.makeMessageType(
  "com.diagnocat.model.AnnotationsLayer",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Readonly", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "Annotations", kind: "message", T: Annotation, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.model.Annotation
 */
export const Annotation = proto3.makeMessageType(
  "com.diagnocat.model.Annotation",
  () => [
    { no: 11, name: "Ruler", kind: "message", T: AnnotationType_Ruler, oneof: "Type" },
    { no: 12, name: "Arrow", kind: "message", T: AnnotationType_Arrow, oneof: "Type" },
    { no: 13, name: "PolyLine", kind: "message", T: AnnotationType_PolyLine, oneof: "Type" },
    { no: 14, name: "Angle", kind: "message", T: AnnotationType_Angle, oneof: "Type" },
    { no: 15, name: "Guide", kind: "message", T: AnnotationType_Guide, oneof: "Type" },
  ],
);

/**
 * @generated from message com.diagnocat.model.AnnotationType_Ruler
 */
export const AnnotationType_Ruler = proto3.makeMessageType(
  "com.diagnocat.model.AnnotationType_Ruler",
  () => [
    { no: 1, name: "Start", kind: "message", T: FloatPoint2D },
    { no: 2, name: "End", kind: "message", T: FloatPoint2D },
  ],
);

/**
 * @generated from message com.diagnocat.model.AnnotationType_Arrow
 */
export const AnnotationType_Arrow = proto3.makeMessageType(
  "com.diagnocat.model.AnnotationType_Arrow",
  () => [
    { no: 1, name: "Start", kind: "message", T: FloatPoint2D },
    { no: 2, name: "End", kind: "message", T: FloatPoint2D },
    { no: 3, name: "DoubleSided", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message com.diagnocat.model.AnnotationType_PolyLine
 */
export const AnnotationType_PolyLine = proto3.makeMessageType(
  "com.diagnocat.model.AnnotationType_PolyLine",
  () => [
    { no: 1, name: "Points", kind: "message", T: FloatPoint2D, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.model.AnnotationType_Angle
 */
export const AnnotationType_Angle = proto3.makeMessageType(
  "com.diagnocat.model.AnnotationType_Angle",
  () => [
    { no: 1, name: "Start", kind: "message", T: FloatPoint2D },
    { no: 2, name: "Corner", kind: "message", T: FloatPoint2D },
    { no: 3, name: "End", kind: "message", T: FloatPoint2D },
  ],
);

/**
 * @generated from message com.diagnocat.model.AnnotationType_Guide
 */
export const AnnotationType_Guide = proto3.makeMessageType(
  "com.diagnocat.model.AnnotationType_Guide",
  () => [
    { no: 1, name: "Start", kind: "message", T: FloatPoint2D },
    { no: 2, name: "End", kind: "message", T: FloatPoint2D },
    { no: 3, name: "ReferencedAssetID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

