import {
  createEntityAdapter,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';

import { Notification } from '@/shared/api/protocol-ts/api/notifications/dto_notification_pb';
import { SliceName } from '@/shared/config';

export const notificationAdapter = createEntityAdapter({
  selectId: (notification: Notification) => notification.ID,
});

const initialState = {
  notificationStatus: {
    isOpen: false,
    hasUnread: false,
  },
  notification: notificationAdapter.getInitialState(),
};

const notificationSlice = createSlice({
  name: SliceName.notification,
  initialState,
  reducers: {
    changeHasUnreadTo: (state, action) => {
      state.notificationStatus.hasUnread = action.payload;
    },

    changeNotificationIsOpenTo: (state, action) => {
      state.notificationStatus.isOpen = action.payload;
    },

    clearNotification: (state) => {
      notificationAdapter.removeAll(
        state.notification as EntityState<Notification>,
      );
    },

    addNotification: (state, action) => {
      notificationAdapter.setOne(
        state.notification as EntityState<Notification>,
        action.payload,
      );
    },
  },
});

export const {
  changeHasUnreadTo,
  addNotification,
  changeNotificationIsOpenTo,
  clearNotification,
} = notificationSlice.actions;

export default notificationSlice.reducer;
