// @generated by protoc-gen-connect-web v0.6.0 with parameter "target=ts,ts_nocheck=false"
// @generated from file api/core/svc_patient.proto (package com.diagnocat.core, syntax proto3)
/* eslint-disable */

import { CreatePatientReq, CreatePatientResp, DeletePatientReq, DeletePatientResp, PatientListStreamReq, PatientListStreamResp, PatientProfileStreamReq, PatientProfileStreamResp, SetPatientClinicalCaseDescriptionReq, SetPatientClinicalCaseDescriptionResp, SetPatientExternalIDReq, SetPatientExternalIDResp, SetPatientGenderReq, SetPatientGenderResp, SetPatientPersonalDataReq, SetPatientPersonalDataResp, SetPatientStatusReq, SetPatientStatusResp, SetPatientTreatmentDueDateReq, SetPatientTreatmentDueDateResp } from "./svc_patient_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * Common errors:
 * - type: RequiredArgumentMissingError, Argument: PatientID
 * - type: NotFoundError, Entity: patient (if patient not found by PatientID)
 * - type: BadRequestError, Reason: patient deleted (if remote patient found by PatientID)
 *
 * @generated from service com.diagnocat.core.PatientService
 */
export const PatientService = {
  typeName: "com.diagnocat.core.PatientService",
  methods: {
    /**
     * Get stream with information about patient, his projects, sharing members, invitations, studies and reports
     *
     * Permission: `PatientPermissions.CanView`
     *
     * @generated from rpc com.diagnocat.core.PatientService.PatientProfileStream
     */
    patientProfileStream: {
      name: "PatientProfileStream",
      I: PatientProfileStreamReq,
      O: PatientProfileStreamResp,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * Get list of patients from available organizations
     *
     * Errors:
     * - type: InvalidArgumentValueError, Argument: PatientStatus - (if `ListPatientsReq.Filters.PatientStatuses` contains `PatientStatusInvalidValue`)
     * - type: InvalidArgumentValueError, Argument: StudyType - (if `ListPatientsReq.Filters.StudyTypes` contains `StudyTypeInvalidValue`)
     *
     * @generated from rpc com.diagnocat.core.PatientService.PatientListStream
     */
    patientListStream: {
      name: "PatientListStream",
      I: PatientListStreamReq,
      O: PatientListStreamResp,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * Create new patient
     *
     * Permission:
     * - `OrganizationPermissions.CanCreateTheirOwnPatients`
     * - `OrganizationPermissions.CanCreateOtherDoctorsPatients`
     *
     * Errors:
     * - type: RequiredArgumentMissingError, Argument: OrganizationID/PersonalData/FirstName/LastName/ExternalID/TreatingDoctorID - (if the corresponding argument is empty)
     * - type: BadRequestError, Reason: organization deleted (if remote organization found by `CreatePatientReq.OrganizationID`)
     * - type: InvalidArgumentValueError, Argument: DateOfBirth - (if `CreatePatientReq.PersonalData.DateOfBirth` is not in the correct format ("2006-01-25"))
     * - type: BadRequestError, Reason: treating doctor does not have organization roles
     *
     * @generated from rpc com.diagnocat.core.PatientService.CreatePatient
     */
    createPatient: {
      name: "CreatePatient",
      I: CreatePatientReq,
      O: CreatePatientResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set personal data
     *
     * Permission: `PatientPermissions.CanEditPersonalData`
     *
     * Errors:
     * - type: RequiredArgumentMissingError, Argument: PersonalData/FirstName/LastName - (if the corresponding argument is empty)
     *
     * @generated from rpc com.diagnocat.core.PatientService.SetPatientPersonalData
     */
    setPatientPersonalData: {
      name: "SetPatientPersonalData",
      I: SetPatientPersonalDataReq,
      O: SetPatientPersonalDataResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set externalID
     *
     * Permission: `PatientPermissions.CanEditPatient`
     *
     * Errors:
     * - type: RequiredArgumentMissingError, Argument: ExternalID
     *
     * @generated from rpc com.diagnocat.core.PatientService.SetPatientExternalID
     */
    setPatientExternalID: {
      name: "SetPatientExternalID",
      I: SetPatientExternalIDReq,
      O: SetPatientExternalIDResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set gender
     *
     * Permission: `PatientPermissions.CanEditPatient`
     *
     * @generated from rpc com.diagnocat.core.PatientService.SetPatientGender
     */
    setPatientGender: {
      name: "SetPatientGender",
      I: SetPatientGenderReq,
      O: SetPatientGenderResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set patient status
     *
     * Permission: `PatientPermissions.CanEditPatient`
     *
     * Errors:
     * - type: InvalidArgumentValueError, Argument: Status - (if `SetPatientStatusReq.Status` == `PatientStatusInvalidValue`)
     *
     * @generated from rpc com.diagnocat.core.PatientService.SetPatientStatus
     */
    setPatientStatus: {
      name: "SetPatientStatus",
      I: SetPatientStatusReq,
      O: SetPatientStatusResp,
      kind: MethodKind.Unary,
    },
    /**
     *  Permission: `PatientPermissions.CanEditClinicalCaseDescription`
     *
     * @generated from rpc com.diagnocat.core.PatientService.SetPatientClinicalCaseDescription
     */
    setPatientClinicalCaseDescription: {
      name: "SetPatientClinicalCaseDescription",
      I: SetPatientClinicalCaseDescriptionReq,
      O: SetPatientClinicalCaseDescriptionResp,
      kind: MethodKind.Unary,
    },
    /**
     *  Permission: `PatientPermissions.CanEditClinicalCaseDescription`
     *
     * @generated from rpc com.diagnocat.core.PatientService.SetPatientTreatmentDueDate
     */
    setPatientTreatmentDueDate: {
      name: "SetPatientTreatmentDueDate",
      I: SetPatientTreatmentDueDateReq,
      O: SetPatientTreatmentDueDateResp,
      kind: MethodKind.Unary,
    },
    /**
     * Mark patient as deleted using patient id
     * - this patient mark as deleted
     * - all patient studies and reports mark as deleted
     * - all invitations to this patient are closed
     * - all invitations to the studies/reports of this patient are closed
     *
     * Permission: `PatientPermissions.CanEditPatient`
     *
     * @generated from rpc com.diagnocat.core.PatientService.DeletePatient
     */
    deletePatient: {
      name: "DeletePatient",
      I: DeletePatientReq,
      O: DeletePatientResp,
      kind: MethodKind.Unary,
    },
  }
} as const;

