import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '@/shared/api/api';
import {
  CreateOrganizationReq,
  CreateOrganizationResp,
  DeleteOrganizationReq,
  DeleteOrganizationResp,
  SetOrganizationContactsReq,
  SetOrganizationContactsResp,
  SetOrganizationNameReq,
  SetOrganizationNameResp,
  SetOrganizationSettingsReq,
  SetOrganizationSettingsResp,
  SetOrganizationTypeReq,
  SetOrganizationTypeResp,
  SetOrganizationUserJobPositionsReq,
  SetOrganizationUserJobPositionsResp,
  SetOrganizationUserNameReq,
  SetOrganizationUserNameResp,
} from '@/shared/api/protocol-ts/api/core/svc_organization_pb';
import { OrganizationService } from '@/shared/api/protocol-ts/api/core/svc_organization_connectweb';
import { LocalStorageKey, SliceName } from '@/shared/config';
import { createThunkGenerator } from '@/shared/lib';

type OrganizationRequests = keyof typeof OrganizationService.methods;

const generateOrganizationThunk = createThunkGenerator<OrganizationRequests>(
  SliceName.organization,
);

export const createOrganization = generateOrganizationThunk<
  CreateOrganizationReq,
  CreateOrganizationResp
>('createOrganization', api.organization.createOrganization);

export const setOrganizationName = generateOrganizationThunk<
  SetOrganizationNameReq,
  SetOrganizationNameResp
>('setOrganizationName', api.organization.setOrganizationName);
export const setOrganizationType = generateOrganizationThunk<
  SetOrganizationTypeReq,
  SetOrganizationTypeResp
>('setOrganizationType', api.organization.setOrganizationType);
export const setOrganizationContacts = generateOrganizationThunk<
  SetOrganizationContactsReq,
  SetOrganizationContactsResp
>('setOrganizationContacts', api.organization.setOrganizationContacts);

export const setOrganizationSettings = generateOrganizationThunk<
  SetOrganizationSettingsReq,
  SetOrganizationSettingsResp
>('setOrganizationSettings', api.organization.setOrganizationSettings);

export const deleteOrganization = generateOrganizationThunk<
  DeleteOrganizationReq,
  DeleteOrganizationResp
>('deleteOrganization', api.organization.deleteOrganization);

export const setCurrentOrganizationByID = createAsyncThunk(
  'organization/setCurrentOrganizationID',
  async (organizationID: string) => {
    if (organizationID) {
      localStorage.setItem(LocalStorageKey.organizationID, organizationID);
    }

    return organizationID;
  },
);

export const setOrganizationUserJobPositions = generateOrganizationThunk<
  SetOrganizationUserJobPositionsReq,
  SetOrganizationUserJobPositionsResp
>(
  'setOrganizationUserJobPositions',
  api.organization.setOrganizationUserJobPositions,
);

export const setOrganizationUserName = generateOrganizationThunk<
  SetOrganizationUserNameReq,
  SetOrganizationUserNameResp
>('setOrganizationUserName', api.organization.setOrganizationUserName);
