import { OrganizationUserRole } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { getUserFullName, getUserInitials } from '@/shared/lib';

import { getAvatarSrcByUserID } from '@/entities/assets';

export const useDoctorDetails = (userRole: OrganizationUserRole) => {
  const doctorFullName = getUserFullName(userRole);

  const doctorInitials = getUserInitials(userRole);

  const doctorAvatarSrc = getAvatarSrcByUserID(userRole.UserID, 'thumbnail');

  return { doctorFullName, doctorInitials, doctorAvatarSrc };
};
