import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import styles from './ReportVersions.module.scss';

type ReportVersionsProps = {
  className?: string;
  children: ReactNode;
};

export const ReportsVersions = (props: ReportVersionsProps) => {
  const { className, children } = props;

  const { formatMessage } = useIntl();

  return (
    <section className={cn(styles.container, className)}>
      <header className={styles.header}>
        <h4 className="h4">
          {formatMessage({
            id: 'reportVersions.title',
            defaultMessage: 'Reports Versions',
          })}
        </h4>
      </header>
      <main className={styles.main}>{children}</main>
    </section>
  );
};
