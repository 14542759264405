import { FC, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { ConnectError } from '@bufbuild/connect';

import { ErrorText, Modal, ModalProps } from '@/shared/ui';

import { useRemoveAsset } from '../../hooks/useRemoveAsset';

import styles from './RemoveAssetModal.module.scss';

type RemoveAssetModalProps = {
  assetID?: string;
  className?: string;
  testID?: string;
} & Pick<ModalProps, 'isOpen' | 'onCancel'>;

export const RemoveAssetModal: FC<RemoveAssetModalProps> = (props) => {
  const { className, testID, isOpen, onCancel: onClose, assetID = '' } = props;

  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const { formatMessage } = useIntl();

  const removeAsset = useRemoveAsset();

  const handleClose = () => {
    setErrorMessage(undefined);
    if (onClose) {
      onClose();
    }
  };

  const handleRemoveAsset = async () => {
    setLoading(true);

    try {
      await removeAsset(assetID);
    } catch (error) {
      setErrorMessage((error as ConnectError)?.message);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <Modal
      className={cn(styles.container, className)}
      data-testid={testID}
      title={formatMessage({
        id: 'removeAssetModal.title',
        defaultMessage: 'Remove image',
      })}
      isOpen={isOpen}
      onCancel={handleClose}
      onOk={handleRemoveAsset}
      okButtonText={formatMessage({
        id: 'global.remove',
        defaultMessage: 'Remove',
      })}
      okButtonProps={{
        loading: isLoading,
      }}
    >
      <FormattedMessage
        id="removeAssetModal.message"
        defaultMessage="You're about to delete this image. You can add it later."
      />
      <ErrorText className={styles.errorText} error={errorMessage} />
    </Modal>
  );
};
