import { Empty } from '@bufbuild/protobuf';

import {
  CheckInviteTokenReq,
  CheckInviteTokenResp,
  LogoutReq,
  Recover_ByEmail_1_StartReq,
  Recover_ByEmail_2_SetPasswordReq,
  RefreshResp,
  SignInReq,
  SignUp_ByOneself_1_SendUserInfoReq,
  SignUp_ByOneself_2_ConfirmIdentityReq,
  SignUp_ByOneself_3_SetPasswordReq,
  SignUp_ViaOrganizationInviteReq,
  SignUp_ViaSalesmanInviteReq,
  SignUp_ViaSharedPatientReq,
} from '@/shared/api/protocol-ts/api/auth/svc_authentication_pb';
import { Authentication } from '@/shared/api/protocol-ts/api/auth/svc_authentication_connectweb';
import { createThunkGenerator } from '@/shared/lib';
import { SliceName } from '@/shared/config';
import api from '@/shared/api/api';
import { AuthState } from '@/shared/api/protocol-ts/api/auth/dto_auth_pb';

export type AuthRequests = keyof typeof Authentication.methods;

const generateAuthThunk = createThunkGenerator<AuthRequests>(SliceName.auth);

export const refresh = generateAuthThunk<Empty, RefreshResp>(
  'refresh',
  api.auth.refresh,
);

export const startSignIn = generateAuthThunk<SignInReq, AuthState>(
  'signIn',
  api.auth.signIn,
);

export const logout = generateAuthThunk<LogoutReq, Empty>(
  'logout',
  api.auth.logout,
);

export const signUp = generateAuthThunk<
  SignUp_ByOneself_1_SendUserInfoReq,
  AuthState
>('signUp_ByOneself_1_SendUserInfo', api.auth.signUp_ByOneself_1_SendUserInfo);

export const signUpViaSharedPatient = generateAuthThunk<
  SignUp_ViaSharedPatientReq,
  AuthState
>('signUp_ViaSharedPatient', api.auth.signUp_ViaSharedPatient);

export const signUpViaOrganizationInvite = generateAuthThunk<
  SignUp_ViaOrganizationInviteReq,
  AuthState
>('signUp_ViaOrganizationInvite', api.auth.signUp_ViaOrganizationInvite);

export const signUpViaSalesmanInvite = generateAuthThunk<
  SignUp_ViaSalesmanInviteReq,
  AuthState
>('signUp_ViaSalesmanInvite', api.auth.signUp_ViaSalesmanInvite);

export const confirmEmail = generateAuthThunk<
  SignUp_ByOneself_2_ConfirmIdentityReq,
  AuthState
>(
  'signUp_ByOneself_2_ConfirmIdentity',
  api.auth.signUp_ByOneself_2_ConfirmIdentity,
);

export const confirmPassword = generateAuthThunk<
  SignUp_ByOneself_3_SetPasswordReq,
  AuthState
>('signUp_ByOneself_3_SetPassword', api.auth.signUp_ByOneself_3_SetPassword);

export const checkInviteToken = generateAuthThunk<
  CheckInviteTokenReq,
  CheckInviteTokenResp
>('checkInviteToken', api.auth.checkInviteToken);

export const recoverByEmail = generateAuthThunk<
  Recover_ByEmail_1_StartReq,
  AuthState
>('recover_ByEmail_1_Start', api.auth.recover_ByEmail_1_Start);

export const setPassword = generateAuthThunk<
  Recover_ByEmail_2_SetPasswordReq,
  AuthState
>('recover_ByEmail_2_SetPassword', api.auth.recover_ByEmail_2_SetPassword);
