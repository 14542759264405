import { FC, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, toastCaller } from '@/shared/ui';

import styles from './ScanUidCopy.module.scss';

type ScanUidCopyProps = {
  scanUid: string;
  className?: string;
};

export const ScanUidCopy: FC<ScanUidCopyProps> = (props) => {
  const { scanUid, className } = props;

  const { formatMessage } = useIntl();

  const [copied, setCopied] = useState(false);

  const isScanUid = Boolean(scanUid);

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);

      setCopied(true);

      setTimeout(() => setCopied(false), 2000);

      toastCaller({
        message: formatMessage({
          id: 'global.linkCopied',
          defaultMessage: 'Link copied',
        }),
        type: 'default',
        heading: '',
        position: 'bottom-right',
      });
    } catch (error) {
      setCopied(false);
    }
  };

  return (
    <div className={cn(className, styles.container)}>
      <p className={styles.scanUid}>
        {isScanUid ? (
          ` ${scanUid}`
        ) : (
          <FormattedMessage
            id="global.notAvailable "
            defaultMessage="not available"
          />
        )}
      </p>

      {isScanUid && (
        <Button
          size="tiny"
          variant="tertiary"
          onClick={() => copyToClipBoard(scanUid)}
          className={cn(styles.copyButton, { [styles.copied]: copied })}
          icon={copied ? 'check' : 'copy'}
        />
      )}
    </div>
  );
};
