import { defineMessages } from 'react-intl';

import {
  StudyStatus,
  StudyType,
} from '@/shared/api/protocol-ts/model/dto_study_pb';

export const studyCardTitle = defineMessages<StudyType>({
  [StudyType.StudyType_CBCT]: {
    id: 'studyCardTitle.studyTypeCBCT',
    defaultMessage: 'CBCT',
  },
  [StudyType.StudyType_PanoramicXRay]: {
    id: 'studyCardTitle.studyTypePanoramicXRay',
    defaultMessage: 'PANO',
  },
  [StudyType.StudyType_IntraoralXRay]: {
    id: 'studyCardTitle.studyTypeIntraoralXRay',
    defaultMessage: 'IOXRay',
  },
  [StudyType.StudyType_DentalPhoto]: {
    id: 'studyCardTitle.studyTypeDentalPhoto',
    defaultMessage: 'Dental Photo',
  },
  [StudyType.StudyType_IOS_Meshes]: {
    id: 'studyCardTitle.studyTypeIOSMeshes',
    defaultMessage: 'ISO STL',
  },
  [StudyType.StudyType_InvalidValue]: {
    id: 'studyCardTitle.studyTypeInvalidValue',
    defaultMessage: 'InvalidValue',
  },
});

export const orderReportButtonMessages = defineMessages<StudyType>({
  [StudyType.StudyType_CBCT]: {
    id: 'studyCard.orderReportButton.studyTypeCBCT',
    defaultMessage: 'Order CBCT AI report',
  },
  [StudyType.StudyType_PanoramicXRay]: {
    id: 'studyCard.orderReportButton.studyTypePanoramicXRay',
    defaultMessage: 'Order PANO AI report',
  },
  [StudyType.StudyType_IntraoralXRay]: {
    id: 'studyCard.orderReportButton.studyTypeIntraoralXRay',
    defaultMessage: 'Order IOXRay AI report',
  },
  [StudyType.StudyType_DentalPhoto]: {
    id: 'studyCard.orderReportButton.studyTypeDentalPhoto',
    defaultMessage: 'Order Dental Photo report',
  },
  [StudyType.StudyType_IOS_Meshes]: {
    id: 'studyCard.orderReportButton.studyTypeIOSMeshes',
    defaultMessage: 'Order ISO STL report',
  },
  [StudyType.StudyType_InvalidValue]: {
    id: 'studyCard.orderReportButton.studyTypeInvalidValue',
    defaultMessage: 'InvalidValue',
  },
});

export const studyCardUploadingStatusMessage = defineMessages<StudyStatus>({
  [StudyStatus.StudyStatus_Uploading]: {
    id: 'studyCard.uploadingStatusMessage.studyStatusUploading',
    defaultMessage: 'Uploading',
  },
  [StudyStatus.StudyStatus_Finished]: {
    id: 'studyCard.uploadingStatusMessage.studyStatusFinished',
    defaultMessage: 'Upload successful',
  },
  [StudyStatus.StudyStatus_Failed]: {
    id: 'studyCard.uploadingStatusMessage.studyStatusFailed',
    defaultMessage: 'Upload error',
  },
  [StudyStatus.StudyStatus_InvalidValue]: {
    id: 'studyCard.uploadingStatusMessage.studyStatusInvalidValue',
    defaultMessage: 'Invalid value',
  },
});
