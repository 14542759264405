import { FileWithPath } from 'react-dropzone';

import { AssetType } from '@/shared/api/protocol-ts/model/dto_asset_pb';

import {
  ACCEPTED_FILES_FORMATS_FOR_STUDIES,
  UploadStudyType,
  WITHOUT_FILE_FORMAT_ACCEPTED_STUDIES,
} from '../config/constants';

import { checkFormatValidation } from './checkFormatValidation';

export const areFilesValid = (type: UploadStudyType, files: FileWithPath[]) => {
  if (type === AssetType.AssetType_Study_PanoramicXRay && files.length > 1) {
    return false;
  }

  const acceptWithoutFormat =
    WITHOUT_FILE_FORMAT_ACCEPTED_STUDIES.includes(type);

  return checkFormatValidation(
    files,
    ACCEPTED_FILES_FORMATS_FOR_STUDIES[type],
    acceptWithoutFormat,
  );
};
