// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_patient.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Attribute, Created, Deleted, Gender, PersonalData, Revision } from "./dto_base_pb.js";
import { PatientPermissions } from "./dto_patient_permissions_pb.js";

/**
 * @generated from enum com.diagnocat.model.PatientStatus
 */
export const PatientStatus = proto3.makeEnum(
  "com.diagnocat.model.PatientStatus",
  [
    {no: 0, name: "PatientStatusInvalidValue"},
    {no: 1, name: "PatientStatusUnassigned"},
    {no: 2, name: "PatientStatusUnderTreatment"},
    {no: 3, name: "PatientStatusInReview"},
    {no: 4, name: "PatientStatusCaseFinished"},
  ],
);

/**
 * @generated from message com.diagnocat.model.Patient
 */
export const Patient = proto3.makeMessageType(
  "com.diagnocat.model.Patient",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "ExternalID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "PersonalData", kind: "message", T: PersonalData },
    { no: 5, name: "Age", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 6, name: "Gender", kind: "enum", T: proto3.getEnumType(Gender) },
    { no: 7, name: "Status", kind: "enum", T: proto3.getEnumType(PatientStatus) },
    { no: 8, name: "DisplayAssetID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "Doctors", kind: "message", T: PatientDoctor, repeated: true },
    { no: 24, name: "Attributes", kind: "message", T: Attribute, repeated: true },
    { no: 31, name: "ClinicalCaseDescription", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 32, name: "TreatmentDueDate", kind: "message", T: Timestamp },
    { no: 10020, name: "YourPermissions", kind: "message", T: PatientPermissions },
    { no: 10030, name: "Created", kind: "message", T: Created },
    { no: 10040, name: "Revision", kind: "message", T: Revision },
    { no: 10050, name: "Deleted", kind: "message", T: Deleted },
    { no: 99999, name: "BlackCatID", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message com.diagnocat.model.PatientDoctor
 */
export const PatientDoctor = proto3.makeMessageType(
  "com.diagnocat.model.PatientDoctor",
  () => [
    { no: 1, name: "UserID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "TeamMember", kind: "message", T: PatientDoctor_PatientTeamMember, oneof: "Membership" },
    { no: 12, name: "Sharing", kind: "message", T: PatientDoctor_PatientSharingRole, oneof: "Membership" },
  ],
);

/**
 * only accepted sharings
 *
 * @generated from message com.diagnocat.model.PatientDoctor.PatientSharingRole
 */
export const PatientDoctor_PatientSharingRole = proto3.makeMessageType(
  "com.diagnocat.model.PatientDoctor.PatientSharingRole",
  () => [
    { no: 1, name: "RecipientEmail", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "CreatedAt", kind: "message", T: Timestamp },
    { no: 3, name: "CreatedBy", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "PatientDoctor_PatientSharingRole"},
);

/**
 * @generated from message com.diagnocat.model.PatientDoctor.PatientTeamMember
 */
export const PatientDoctor_PatientTeamMember = proto3.makeMessageType(
  "com.diagnocat.model.PatientDoctor.PatientTeamMember",
  [],
  {localName: "PatientDoctor_PatientTeamMember"},
);

