import { FC, useCallback, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Button, getStudySelectStyles, Modal, Select } from '@/shared/ui';
import { Study } from '@/shared/api/protocol-ts/model/dto_study_pb';
import { RequestReportReq } from '@/shared/api/protocol-ts/api/core/svc_report_pb';

import { reportsModel } from '@/entities/reports';
import { ModalID, modalModel } from '@/entities/modal';
import { useGetOptionsFromStudies } from '@/entities/study';

import { getSegmentsWithTeethAndAnatomies } from '@/features/addNewReport';

import {
  order3DSegmentronFormSchema,
  Order3DSegmentronFormSchemaFormPayload,
} from '../../config/formSchema';

import styles from './Order3DSegmentronModal.module.scss';

export type Order3DSegmentronModalProps = {
  CBCTStudies: Study[];
  className?: string;
};

/**
 * @deprecated
 */
export const Order3DSegmentronModal_DEPRECATED: FC<
  Order3DSegmentronModalProps
> = ({ CBCTStudies, className }) => {
  const dispatch = useAppDispatch();
  const { visible } = useAppSelector(
    (state) => state.modal.Order3DSegmentronModal,
  );

  const { formatMessage } = useIntl();

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.Order3DSegmentronModal));
  }, [dispatch]);

  const CBCTOptions = useGetOptionsFromStudies(CBCTStudies);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<Order3DSegmentronFormSchemaFormPayload>({
    resolver: yupResolver(order3DSegmentronFormSchema),
  });

  const segmentsWithTeethAndAnatomies = getSegmentsWithTeethAndAnatomies();

  const inputCBCTSegmentationRequest = async (CBCTStudyID: string) => {
    await dispatch(
      reportsModel.thunks.requestReport({
        Request: {
          Input: {
            case: 'InputCBCTSegmentation',
            value: {
              CBCTStudyID,
              Segments: segmentsWithTeethAndAnatomies,
            },
          },
        },
      } as RequestReportReq),
    );
  };

  const onSubmit: SubmitHandler<
    Order3DSegmentronFormSchemaFormPayload
  > = async (data) => {
    const { CBCTStudy: CBCTStudyID } = data;

    try {
      await inputCBCTSegmentationRequest(CBCTStudyID);
    } finally {
      handleClose();
    }
  };

  useEffect(() => {
    setValue('CBCTStudy', CBCTOptions[0]?.value);
  }, [setValue, CBCTOptions]);

  return (
    <Modal
      title={formatMessage({
        id: 'Order3dSegmentronModal.Order3DModel',
        defaultMessage: 'Order 3D Model',
      })}
      isOpen={visible}
      onCancel={handleClose}
      className={cn(className, styles.modal)}
      containerClassName={styles.modalContainer}
      borderless
      footer={
        <footer className={cn(styles.footer)}>
          <div className={styles.buttons}>
            <Button variant="secondary" onClick={handleClose}>
              <FormattedMessage id="global.close" defaultMessage="Close" />
            </Button>

            <Button
              variant="primary"
              type="submit"
              loading={isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              <FormattedMessage id="global.order" defaultMessage="Order" />
            </Button>
          </div>
        </footer>
      }
    >
      <form id="orderSegmentronModelForm" onSubmit={handleSubmit(onSubmit)}>
        <p className={cn('h5', styles.title)}>
          <FormattedMessage
            id="orderModel.selectCBCT"
            defaultMessage="Select CBCT"
          />
        </p>

        <Controller
          control={control}
          name="CBCTStudy"
          render={({
            field: { ref, value, name, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Select
              styles={getStudySelectStyles()}
              options={CBCTOptions}
              isRequired
              ref={ref}
              value={value}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              error={error?.message}
            />
          )}
        />
      </form>
    </Modal>
  );
};
