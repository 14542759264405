// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_asset_cbct_gp.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { CBCT_SectionOrientation, DerivedData_Image, File } from "./dto_asset_common_pb.js";
import { ToothNumeration } from "./dto_report_common_pb.js";
import { BBox } from "./dto_common_geometry_pb.js";

/**
 * @generated from message com.diagnocat.model.AssetContent_Generated_CBCT_GP_PanoramaReformatSplit
 */
export const AssetContent_Generated_CBCT_GP_PanoramaReformatSplit = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Generated_CBCT_GP_PanoramaReformatSplit",
  () => [
    { no: 1, name: "PanoramaSplit", kind: "message", T: File },
    { no: 2, name: "Derived", kind: "message", T: DerivedData_Image },
  ],
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Generated_CBCT_GP_PanoramaReformatGeneral
 */
export const AssetContent_Generated_CBCT_GP_PanoramaReformatGeneral = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Generated_CBCT_GP_PanoramaReformatGeneral",
  () => [
    { no: 1, name: "PanoramaSingle", kind: "message", T: File },
    { no: 2, name: "Derived", kind: "message", T: DerivedData_Image },
  ],
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Generated_CBCT_GP_ToothSlice
 */
export const AssetContent_Generated_CBCT_GP_ToothSlice = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Generated_CBCT_GP_ToothSlice",
  () => [
    { no: 1, name: "Slice", kind: "message", T: File },
    { no: 2, name: "Numeration", kind: "message", T: ToothNumeration },
    { no: 3, name: "Orientation", kind: "enum", T: proto3.getEnumType(CBCT_SectionOrientation) },
    { no: 4, name: "Scale", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "WindowWidth", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 6, name: "WindowLevel", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "ProposedToothCardOrder", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "Derived", kind: "message", T: DerivedData_Image },
    { no: 101, name: "Regular", kind: "message", T: CBCTGPToothSliceMetadataRegular, oneof: "Metadata" },
    { no: 102, name: "Guide", kind: "message", T: CBCTGPToothSliceMetadataGuide, oneof: "Metadata" },
    { no: 103, name: "GuideFrontal", kind: "message", T: CBCTGPToothSliceMetadataGuideFrontal, oneof: "Metadata" },
  ],
);

/**
 * @generated from message com.diagnocat.model.CBCTGPToothSliceMetadataRegular
 */
export const CBCTGPToothSliceMetadataRegular = proto3.makeMessageType(
  "com.diagnocat.model.CBCTGPToothSliceMetadataRegular",
  () => [
    { no: 1, name: "Thickness", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "Position", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "TotalImages", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "Stride", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "Order", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * @generated from message com.diagnocat.model.CBCTGPToothSliceMetadataGuide
 */
export const CBCTGPToothSliceMetadataGuide = proto3.makeMessageType(
  "com.diagnocat.model.CBCTGPToothSliceMetadataGuide",
  () => [
    { no: 1, name: "Axis2", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "OrthoAxis", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * @generated from message com.diagnocat.model.CBCTGPToothSliceMetadataGuideFrontal
 */
export const CBCTGPToothSliceMetadataGuideFrontal = proto3.makeMessageType(
  "com.diagnocat.model.CBCTGPToothSliceMetadataGuideFrontal",
  () => [
    { no: 1, name: "Thickness", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "Position", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "Axis2", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * This asset is obsolete and be deleted once backend starts cutting CBCT into tiles.
 *
 * @generated from message com.diagnocat.model.AssetContent_Generated_CBCT_GP_MPR_SubVolume
 */
export const AssetContent_Generated_CBCT_GP_MPR_SubVolume = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Generated_CBCT_GP_MPR_SubVolume",
  () => [
    { no: 1, name: "Volume", kind: "message", T: File },
    { no: 2, name: "Numeration", kind: "message", T: ToothNumeration },
    { no: 3, name: "Position", kind: "message", T: BBox },
    { no: 4, name: "PositionInner", kind: "message", T: BBox },
    { no: 5, name: "WindowWidth", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 6, name: "WindowLevel", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * {"type": "File", "file": {"task_id": "b7ac1063-b446-dc29-2544-24c1de704e47", "key": "14/periodontium-md-image", "filename": "/tmp/tmp1t_p7cp3", "content_type": "image/png", "size": 8376, "meta": {"section": "periodontium", "image_type": "slice-mesiodistal", "image_height": 137, "image_width": 64}, "type": "File"}, "event": "execution-event"}
 *
 * @generated from message com.diagnocat.model.AssetContent_Generated_CBCT_GP_PeriodontiumImage
 */
export const AssetContent_Generated_CBCT_GP_PeriodontiumImage = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Generated_CBCT_GP_PeriodontiumImage",
  () => [
    { no: 1, name: "Periodontium", kind: "message", T: File },
    { no: 2, name: "Numeration", kind: "message", T: ToothNumeration },
    { no: 3, name: "ImageType", kind: "enum", T: proto3.getEnumType(AssetContent_Generated_CBCT_GP_PeriodontiumImage_Type) },
  ],
);

/**
 * @generated from enum com.diagnocat.model.AssetContent_Generated_CBCT_GP_PeriodontiumImage.Type
 */
export const AssetContent_Generated_CBCT_GP_PeriodontiumImage_Type = proto3.makeEnum(
  "com.diagnocat.model.AssetContent_Generated_CBCT_GP_PeriodontiumImage.Type",
  [
    {no: 0, name: "Mesiodistal"},
  ],
);

