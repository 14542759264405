import { MedicalImageViewOptions } from '@/shared/api/protocol-ts/model/dto_common_image_view_options_pb';

import { ViewOptionsInterface } from '../config/reports.type';

export const transformViewOptions = (
  viewOptions: ViewOptionsInterface,
): Partial<MedicalImageViewOptions> => ({
  WindowWidth: viewOptions?.wwwc?.ww,
  WindowLevel: viewOptions?.wwwc?.wc,
  Sharpness: viewOptions?.sharpness,
  Brightness: viewOptions?.brightness,
  Contrast: viewOptions?.contrast,
  Invert: viewOptions?.invert,
});
