import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Button, Input } from '@/shared/ui';
import { User } from '@/shared/api/protocol-ts/model/dto_user_pb';
import { useMedia } from '@/shared/hooks';
import { getUserFullName } from '@/shared/lib';

import styles from './AddSignature.module.scss';

type ReportSignsProps = {
  user: User;
  loading: boolean;
  handleSign: () => void;
  className?: string;
};

export const AddSignature: FC<ReportSignsProps> = (props) => {
  const { user, loading, handleSign, className } = props;

  const userName = getUserFullName(user);

  const { isPhone, isMobile } = useMedia();

  const buttonSize = isMobile ? 'medium' : 'large';

  return (
    <div className={cn(styles.container, className)}>
      <h5 className={cn(styles.signedTitle, 'h5')}>
        <FormattedMessage
          id="report.addSignature"
          defaultMessage="Add signature"
        />
      </h5>

      <div className={styles.addSignature}>
        <Input name="signature" value={userName} />
        <Button
          variant="secondary"
          loading={loading}
          onClick={handleSign}
          size={buttonSize}
        >
          {isPhone ? (
            <FormattedMessage id="report.sign" defaultMessage="Sign" />
          ) : (
            <FormattedMessage
              id="report.signReport"
              defaultMessage="Sign report"
            />
          )}
        </Button>
      </div>
    </div>
  );
};
