import { Asset } from '@/shared/api/protocol-ts/model/dto_asset_pb';
import { MedicalImageInterface } from '@/shared/config';

import { getFileSrc } from './getFileSrc';
import { makeAnnotations } from './makeAnnotations';
import { makeImageViewOptions } from './makeImageViewOptions';

export const getGroupMedicalImages = (
  groupItems: Asset[],
  groupName: string | number,
) =>
  groupItems
    .reduce((medicalImages, asset) => {
      const generatedReportAsset =
        asset.Kind.case === 'GeneratedReport' ? asset.Kind.value : undefined;

      const medicalImage =
        generatedReportAsset?.Content.case === 'CBCTGPToothSlice'
          ? generatedReportAsset.Content.value
          : undefined;

      if (medicalImage?.Metadata.case !== 'Regular') {
        return medicalImages;
      }

      const regularMedicalImage = medicalImage.Metadata.value;

      const annotations = makeAnnotations(
        generatedReportAsset?.MedicalImageFeatures?.Annotations?.Layers,
      );
      const viewOptions = makeImageViewOptions(
        generatedReportAsset?.MedicalImageFeatures?.ViewOptions,
      );

      if (medicalImage?.Slice) {
        medicalImages.push({
          id: medicalImage?.Slice?.ID,
          src: getFileSrc(medicalImage?.Slice.ID),
          image:
            medicalImage?.Slice.Metadata.case === 'Image'
              ? {
                  Height: Number(medicalImage?.Slice.Metadata.value.Height),
                  Width: Number(medicalImage?.Slice.Metadata.value.Width),
                }
              : undefined,
          assetID: asset.ID,
          thicknessMm: regularMedicalImage.Thickness,
          strideMm: regularMedicalImage.Stride,
          annotations,
          viewOptions,
          groupName,
          order: regularMedicalImage.Order,
        });
      }

      return medicalImages;
    }, [] as MedicalImageInterface[])
    .sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0));
