import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Code, ConnectError } from '@bufbuild/connect';

import { Study } from '@/shared/api/protocol-ts/model/dto_study_pb';
import { Report } from '@/shared/api/protocol-ts/model/dto_report_pb';
import api from '@/shared/api/api';
import { PATHS, StreamDataAccumulatorKey } from '@/shared/config';
import { useAppDispatch } from '@/shared/hooks';
import { Tooth } from '@/shared/api/protocol-ts/model/dto_report_tooth_pb';
import { Asset } from '@/shared/api/protocol-ts/model/dto_asset_pb';
import { Invitation } from '@/shared/api/protocol-ts/model/dto_access_pb';

import { studyModel } from '@/entities/study';
import { reportsModel } from '@/entities/reports';
import { patientModel } from '@/entities/patient';
import { toothModel } from '@/entities/tooth';
import { assetsModel } from '@/entities/assets';
import { accessModel } from '@/entities/access';

type PatientProfileStreamDataAccumulators = {
  [StreamDataAccumulatorKey.study]: Study[];
  [StreamDataAccumulatorKey.reports]: Report[];
  [StreamDataAccumulatorKey.teeth]: Tooth[];
  [StreamDataAccumulatorKey.assets]: Asset[];
  [StreamDataAccumulatorKey.patientProfileGuardedAssetsIDs]: string[];
  [StreamDataAccumulatorKey.access]: Invitation[];
};

const dataAccumulators: PatientProfileStreamDataAccumulators = {
  [StreamDataAccumulatorKey.study]: [],
  [StreamDataAccumulatorKey.reports]: [],
  [StreamDataAccumulatorKey.teeth]: [],
  [StreamDataAccumulatorKey.assets]: [],
  [StreamDataAccumulatorKey.patientProfileGuardedAssetsIDs]: [],
  [StreamDataAccumulatorKey.access]: [],
};

let abortController: AbortController;

export const usePatientProfileStream = (id: string) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const openPatientProfileStream = async () => {
    abortController = new AbortController();

    dispatch(patientModel.actions.setLoading('pending'));
    dispatch(studyModel.actions.setLoading('pending'));
    dispatch(reportsModel.actions.setLoading('pending'));
    dispatch(assetsModel.actions.setLoading('pending'));
    dispatch(accessModel.actions.setLoading('pending'));

    try {
      const patientProfileStream = api.patient.patientProfileStream(
        { PatientID: id },
        { signal: abortController.signal },
      );

      for await (const { Update } of patientProfileStream) {
        switch (Update.case) {
          case 'HistoricalPatient': {
            dispatch(patientModel.actions.addOne(Update.value));
            dispatch(patientModel.actions.setLoading('succeeded'));
            break;
          }

          case 'UpdatedPatient': {
            dispatch(patientModel.actions.setNewestOne(Update.value));
            break;
          }

          case 'HistoricalStudy': {
            dataAccumulators[StreamDataAccumulatorKey.study].push(Update.value);
            break;
          }

          case 'EndOfHistoricalStudies': {
            dispatch(
              studyModel.actions.addMany(
                dataAccumulators[StreamDataAccumulatorKey.study],
              ),
            );
            dispatch(studyModel.actions.setLoading('succeeded'));
            dataAccumulators[StreamDataAccumulatorKey.study] = [];
            break;
          }

          case 'UpdatedStudy': {
            dispatch(studyModel.actions.setNewestOne(Update.value));
            break;
          }

          case 'HistoricalReport': {
            dataAccumulators[StreamDataAccumulatorKey.reports].push(
              Update.value,
            );
            break;
          }

          case 'EndOfHistoricalReports': {
            dispatch(
              // For historical entities use only addMany function and setMany for updated entities only
              reportsModel.actions.addMany(
                dataAccumulators[StreamDataAccumulatorKey.reports],
              ),
            );
            dispatch(reportsModel.actions.setLoading('succeeded'));
            dataAccumulators[StreamDataAccumulatorKey.reports] = [];
            break;
          }

          case 'UpdatedReport': {
            dispatch(reportsModel.actions.setNewestOne(Update.value));
            dispatch(reportsModel.actions.setLoading('succeeded'));
            break;
          }

          case 'HistoricalTooth': {
            dataAccumulators[StreamDataAccumulatorKey.teeth].push(Update.value);
            break;
          }

          case 'EndOfHistoricalTeeth': {
            dispatch(
              toothModel.actions.addMany(
                dataAccumulators[StreamDataAccumulatorKey.teeth],
              ),
            );
            dataAccumulators[StreamDataAccumulatorKey.teeth] = [];
            break;
          }

          case 'UpdatedTooth': {
            dispatch(toothModel.actions.setNewestOne(Update.value));
            break;
          }

          case 'HistoricalAsset': {
            dataAccumulators[StreamDataAccumulatorKey.assets].push(
              Update.value,
            );
            dataAccumulators.patientProfileGuardedAssetsIDs.push(
              Update.value.ID,
            );
            break;
          }

          case 'UpdatedAsset': {
            dispatch(assetsModel.actions.setNewestOne(Update.value));
            dispatch(assetsModel.actions.addGuardedIDs([Update.value.ID]));
            break;
          }

          case 'EndOfHistoricalAssets': {
            dispatch(
              assetsModel.actions.setMany(
                dataAccumulators[StreamDataAccumulatorKey.assets],
              ),
            );
            dispatch(
              assetsModel.actions.initGuardedIDs(
                dataAccumulators.patientProfileGuardedAssetsIDs,
              ),
            );
            dispatch(assetsModel.actions.setLoading('succeeded'));
            dataAccumulators.patientProfileGuardedAssetsIDs = [];
            dataAccumulators[StreamDataAccumulatorKey.assets] = [];
            break;
          }

          case 'HistoricalInvitation': {
            dataAccumulators[StreamDataAccumulatorKey.access].push(
              Update.value,
            );
            break;
          }

          case 'EndOfHistoricalInvitations': {
            dispatch(
              accessModel.actions.addMany(
                dataAccumulators[StreamDataAccumulatorKey.access],
              ),
            );

            dispatch(accessModel.actions.setLoading('succeeded'));

            dataAccumulators[StreamDataAccumulatorKey.access] = [];
            break;
          }

          case 'UpdatedInvitation': {
            dispatch(accessModel.actions.setNewestOne(Update.value));
            break;
          }
        }
      }
    } catch (error) {
      const connectError = ConnectError.from(error);

      if (connectError.code === Code.Unauthenticated) {
        navigate(PATHS.signIn, { state: { from: location?.pathname } });
      }

      if (connectError.code === Code.NotFound) {
        navigate(PATHS.patients);
      }
    }
  };

  const closePatientProfileStream = () => {
    if (abortController) {
      abortController.abort();
    }

    dispatch(assetsModel.actions.reset());
    dispatch(reportsModel.actions.reset());
    dispatch(studyModel.actions.removeAll());
    dispatch(toothModel.actions.removeAll());
  };

  useEffect(() => {
    openPatientProfileStream();

    return () => {
      closePatientProfileStream();
    };
  }, []);
};
