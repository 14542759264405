import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { AssetContent_Study_CBCT_CBCT_Metadata } from '@/shared/api/protocol-ts/model/dto_asset_pb';

import {
  i18n,
  ScanDataFields,
  PREVIEW_SCAN_DATA_FIELDS,
} from '@/entities/study';

import styles from './PreviewScanInfo.module.scss';

type PreviewScanInfoProps = {
  className?: string;
  studyMeta: AssetContent_Study_CBCT_CBCT_Metadata;
};

export const PreviewScanInfo: FC<PreviewScanInfoProps> = (props) => {
  const { className, studyMeta } = props;
  const { formatMessage } = useIntl();

  const getValueByDataFieldsKey = (
    key: ScanDataFields,
  ): string | number | number[] | undefined => {
    switch (key) {
      case 'manufacturer':
        return studyMeta?.Manufacturer;
      case 'voxel':
        return studyMeta?.VoxelSize;
      case 'fov':
        return studyMeta?.FOV;
      case 'model':
        return studyMeta?.ManufacturerModelName;
      case 'exposure':
        return studyMeta?.Exposure;
      default:
        return undefined;
    }
  };

  return (
    <div className={cn(styles.container, className)}>
      <h5 className={cn('h5', styles.greyText)}>
        <FormattedMessage
          id="sdutyInfo.scanInfo.title"
          defaultMessage="Scan info"
        />
      </h5>
      <div className={styles.scanElems}>
        {PREVIEW_SCAN_DATA_FIELDS.map((key: ScanDataFields) => (
          <div key={key} className={cn('p3', styles.scanElem, styles[key])}>
            <span className={styles.scanLabel}>
              {formatMessage(i18n.studyScanInfoMessages[key])}
            </span>
            <p className={styles.greyText}>
              {getValueByDataFieldsKey(key) || '-'}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
