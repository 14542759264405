import { ConditionCode } from '@/shared/api/protocol-ts/model/dto_report_condition_codes_pb';

import { ConditionInterface } from '../config/types';

import { isConditionItemInGroups } from './isConditionInGroups';

export const filterConditionItemsByGroups =
  (groups: Record<string, ConditionCode[]>) =>
  (conditionItems: ConditionInterface[]): ConditionInterface[] =>
    conditionItems.filter((item) => isConditionItemInGroups(groups)(item));
