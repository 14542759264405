import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import { isEqual } from 'lodash';
import { prepend } from 'ramda';
import { ConnectError } from '@bufbuild/connect';

import { Gender } from '@/shared/api/protocol-ts/model/dto_base_pb';
import {
  Button,
  DatePicker,
  ErrorText,
  Input,
  InputLabel,
  Modal,
  RadioGroup,
} from '@/shared/ui';
import { gender } from '@/shared/i18n';
import { useAppDispatch, useAppSelector, useMedia } from '@/shared/hooks';
import {
  CreatePatientReq,
  SetPatientPersonalDataReq,
} from '@/shared/api/protocol-ts/api/core/svc_patient_pb';

import { patientModel } from '@/entities/patient';
import { organizationModel } from '@/entities/organization';
import { DoctorsSelect } from '@/entities/doctors';
import { userModel } from '@/entities/user';
import { ModalID, modalModel } from '@/entities/modal';

import { useOrganizationDoctorsOptions } from '@/features/addDoctors';

import {
  DATE_OF_BIRTH_FORMAT,
  EditPatientPayload,
  getPatientValidationSchema,
  NewPatientPayload,
  PATIENT_FORM_ID,
} from '../../config';
import { PatientRemovalConfirmationModal } from '../PatientRemovalConfirmationModal/PatientRemovalConfirmationModal';

import styles from './PatientModalForm.module.scss';

type PatientPayload = NewPatientPayload | EditPatientPayload;

export const PatientModalForm: FC = () => {
  const [globalFormError, setGlobalFormError] = useState<string>();

  const { formatMessage } = useIntl();

  const patientList = useAppSelector(patientModel.selectors.selectAll);

  const {
    visible,
    data: { patientID },
  } = useAppSelector(modalModel.selectors.selectPatientModalForm);

  const dispatch = useAppDispatch();

  const isEditPatient = Boolean(patientID);

  const user = useAppSelector(userModel.selectors.selectCurrentUser);

  const locale = useAppSelector(userModel.selectors.selectUserLocale);

  const patient = useAppSelector(
    patientModel.selectors.selectPatientByID(patientID),
  );

  const currentOrganizationID = useAppSelector(
    organizationModel.selectors.selectCurrentOrganizationID,
  );

  const patientPersonalData = patient?.PersonalData;

  const doctorsOptions = useOrganizationDoctorsOptions();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isSubmitted },
    reset,
    setValue,
  } = useForm<PatientPayload>({
    resolver: yupResolver(getPatientValidationSchema(!isEditPatient)),
    mode: 'all',
  });

  // Set default
  useEffect(() => {
    if (patient) {
      reset({
        firstName: patientPersonalData?.FirstName,
        lastName: patientPersonalData?.LastName,
        email: patientPersonalData?.Emails?.at(0),
        patientExternalID: patient?.ExternalID,
        dateOfBirth: new Date(patientPersonalData?.DateOfBirth ?? ''),
        gender: patient?.Gender,
      });
    }
  }, [
    patientPersonalData,
    patient?.ExternalID,
    patient?.Gender,
    patient,
    reset,
  ]);

  useEffect(() => {
    if (!patientID && !!doctorsOptions.length) {
      const isCurrentUserDoctor = doctorsOptions.some(
        (doctor) => doctor.value === user.ID,
      );

      const treatingDoctorsDefaultValue =
        isCurrentUserDoctor || doctorsOptions.length === 1 ? [user.ID] : [];

      setValue('treatingDoctors', treatingDoctorsDefaultValue);
    }
  }, [doctorsOptions, user]);

  const { isPhone, isMobile } = useMedia();

  const buttonSize = isPhone ? 'medium' : 'large';

  const onClose = () => {
    reset({});
    dispatch(modalModel.actions.closeModal(ModalID.PatientModalForm));
  };

  const onSubmit: SubmitHandler<PatientPayload> = async (data) => {
    try {
      if (isEditPatient) {
        const emailFromData = data.email ? [data.email] : [];

        const shouldUpdatePersonalData =
          data.firstName !== patient?.PersonalData?.FirstName ||
          data.lastName !== patient?.PersonalData?.LastName ||
          !isEqual(emailFromData, patient?.PersonalData?.Emails) ||
          format(data.dateOfBirth, DATE_OF_BIRTH_FORMAT) !==
            patient?.PersonalData?.DateOfBirth;

        const shouldUpdateGender = data.gender !== patient?.Gender;

        const shouldUpdateExternalID =
          data.patientExternalID !== patient?.ExternalID;

        if (shouldUpdatePersonalData) {
          const updatedPatient = await dispatch(
            patientModel.thunks.setPatientPersonalData(
              new SetPatientPersonalDataReq({
                PatientID: patientID,
                PersonalData: {
                  FirstName: data.firstName,
                  LastName: data.lastName,
                  DateOfBirth: format(data.dateOfBirth, DATE_OF_BIRTH_FORMAT),
                  Emails: emailFromData,
                  Phones: [],
                },
              }),
            ),
          ).unwrap();

          if (updatedPatient.Patient) {
            dispatch(patientModel.actions.setNewestOne(updatedPatient.Patient));
          }
        }

        if (shouldUpdateGender) {
          const updatedPatient = await dispatch(
            patientModel.thunks.setPatientGender({
              PatientID: patientID,
              Gender: data.gender,
            }),
          ).unwrap();

          if (updatedPatient.Patient) {
            dispatch(patientModel.actions.setNewestOne(updatedPatient.Patient));
          }
        }

        if (shouldUpdateExternalID) {
          const updatedPatient = await dispatch(
            patientModel.thunks.setPatientExternalID({
              PatientID: patientID,
              ExternalID: data.patientExternalID || '',
            }),
          ).unwrap();

          if (updatedPatient.Patient) {
            dispatch(patientModel.actions.setNewestOne(updatedPatient.Patient));
          }
        }
        // TODO: To add or remove doctors using AddPatientTeamMember and RevokePatientTeamAccess from svc_access
        // Need to make access entity
      } else {
        const newPatient = await dispatch(
          patientModel.thunks.createPatient(
            new CreatePatientReq({
              Attributes: [],
              OrganizationID: currentOrganizationID,
              Gender: data.gender,
              PersonalData: {
                FirstName: data.firstName,
                LastName: data.lastName,
                DateOfBirth: format(data.dateOfBirth, DATE_OF_BIRTH_FORMAT),
                Emails: data.email ? [data.email] : [],
                Phones: [],
              },
              ExternalID: data.patientExternalID || '',
              TreatingDoctorIDs: data.treatingDoctors || [],
            }),
          ),
        ).unwrap();

        if (newPatient.Patient) {
          const newPatientList = prepend(newPatient.Patient, patientList);

          dispatch(patientModel.actions.setMany(newPatientList));
        }
      }

      setGlobalFormError(undefined);
      onClose();
    } catch (error) {
      const connectError = ConnectError.from(error);
      setGlobalFormError(connectError.message);
    }
  };

  const handlePatientRemove = () => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.PatientRemovalConfirmationModal,
        data: {
          patientID,
        },
      }),
    );
  };

  return (
    <Modal
      containerClassName={styles.container}
      bodyClassName={styles.modalBody}
      title={
        isEditPatient ? (
          <FormattedMessage
            id="patientModalForm.editTitle"
            defaultMessage="Edit patient information"
          />
        ) : (
          <FormattedMessage
            id="patientModalForm.title"
            defaultMessage="New patient"
          />
        )
      }
      isOpen={visible}
      onCancel={onClose}
      footer={
        <>
          {isEditPatient && (
            <Button
              className={styles.removeButton}
              size={buttonSize}
              variant="secondary"
              icon="delete"
              danger
              onClick={handlePatientRemove}
            >
              {!isMobile && (
                <FormattedMessage id="global.remove" defaultMessage="Remove" />
              )}
            </Button>
          )}

          <Button size={buttonSize} variant="tertiary" onClick={onClose}>
            <FormattedMessage id="global.cancel" defaultMessage="Cancel" />
          </Button>

          <Button
            size={buttonSize}
            type="submit"
            form={PATIENT_FORM_ID}
            loading={isSubmitting}
          >
            {isEditPatient ? (
              <FormattedMessage
                id="patientModalForm.saveButton"
                defaultMessage="Save"
              />
            ) : (
              <FormattedMessage id="global.add" defaultMessage="Add" />
            )}
          </Button>
        </>
      }
    >
      <form
        id={PATIENT_FORM_ID}
        onSubmit={handleSubmit(onSubmit)}
        className={styles.form}
      >
        <div className={styles.formRow}>
          <Controller
            control={control}
            name="firstName"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <Input
                ref={ref}
                value={value}
                name={name}
                onBlur={() => {
                  if (isSubmitted) {
                    onBlur();
                  }
                }}
                onChange={(event) => {
                  if (!isSubmitted) {
                    setValue(name, event.target.value);
                  } else {
                    onChange(event);
                  }
                }}
                label={
                  <FormattedMessage
                    id="patientModalForm.label.firstName"
                    defaultMessage="First Name"
                  />
                }
                error={error?.message}
                required
              />
            )}
          />

          <Controller
            control={control}
            name="lastName"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <Input
                ref={ref}
                value={value}
                name={name}
                onBlur={() => {
                  if (isSubmitted) {
                    onBlur();
                  }
                }}
                onChange={(event) => {
                  if (!isSubmitted) {
                    setValue(name, event.target.value);
                  } else {
                    onChange(event);
                  }
                }}
                label={
                  <FormattedMessage
                    id="patientModalForm.label.lastName"
                    defaultMessage="Last Name"
                  />
                }
                error={error?.message}
                required
              />
            )}
          />
        </div>

        <Controller
          control={control}
          name="email"
          render={({
            field: { ref, value, name, onBlur, onChange },
            fieldState: { error, isTouched },
          }) => (
            <Input
              ref={ref}
              value={value}
              name={name}
              onBlur={onBlur}
              onChange={(event) => {
                if (!isTouched) {
                  setValue(name, event.target.value);
                } else {
                  onChange(event);
                }
              }}
              label={
                <FormattedMessage
                  id="patientModalForm.label.email"
                  defaultMessage="Email"
                />
              }
              error={error?.message}
            />
          )}
        />

        <div className={styles.formRow}>
          <Controller
            control={control}
            name="dateOfBirth"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <DatePicker
                locale={locale}
                value={value}
                maxDate={new Date()}
                onChange={onChange}
                placeholder={formatMessage({
                  id: 'patientModalForm.placeholder.selectDate',
                  defaultMessage: 'Select date',
                })}
                label={
                  <FormattedMessage
                    id="patientModalForm.label.dateOfBirth"
                    defaultMessage="Date of birth"
                  />
                }
                error={error?.message}
                required
              />
            )}
          />

          <Controller
            control={control}
            name="patientExternalID"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <Input
                ref={ref}
                value={value}
                name={name}
                onBlur={() => {
                  if (isSubmitted) {
                    onBlur();
                  }
                }}
                onChange={(event) => {
                  if (!isSubmitted) {
                    setValue(name, event.target.value);
                  } else {
                    onChange(event);
                  }
                }}
                label={
                  <FormattedMessage
                    id="patientModalForm.label.patientID"
                    defaultMessage="Patient ID"
                  />
                }
                error={error?.message}
              />
            )}
          />
        </div>

        <Controller
          control={control}
          name="gender"
          render={({
            field: { ref, value, name, onChange, onBlur },
            fieldState: { error },
          }) => (
            <div className={styles.radioGroupContainer}>
              <InputLabel
                label={formatMessage({
                  id: 'global.gender',
                  defaultMessage: 'Gender',
                })}
                required
              />
              <RadioGroup
                ref={ref}
                value={value}
                name={name}
                variant="tab"
                onBlur={onBlur}
                onChange={onChange}
                error={error?.message}
                items={[
                  {
                    value: Gender.GenderMale,
                    label: formatMessage(gender[Gender.GenderMale]),
                  },
                  {
                    value: Gender.GenderFemale,
                    label: formatMessage(gender[Gender.GenderFemale]),
                  },
                  {
                    value: Gender.GenderOther,
                    label: formatMessage(gender[Gender.GenderOther]),
                  },
                ]}
              />
            </div>
          )}
        />

        {!isEditPatient && (
          <Controller
            control={control}
            name="treatingDoctors"
            render={({
              field: { value = [], onChange },
              fieldState: { error },
            }) => (
              <DoctorsSelect
                options={doctorsOptions}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
        )}

        <ErrorText error={globalFormError} />
      </form>

      <PatientRemovalConfirmationModal />
    </Modal>
  );
};
