// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_patient_task.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Created, Deleted, Revision } from "./dto_base_pb.js";

/**
 * @generated from enum com.diagnocat.model.PatientTaskTag
 */
export const PatientTaskTag = proto3.makeEnum(
  "com.diagnocat.model.PatientTaskTag",
  [
    {no: 0, name: "PatientTaskTag_Unspecified"},
    {no: 1, name: "PatientTaskTag_New"},
    {no: 2, name: "PatientTaskTag_Updated"},
  ],
);

/**
 * @generated from message com.diagnocat.model.PatientTask
 */
export const PatientTask = proto3.makeMessageType(
  "com.diagnocat.model.PatientTask",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "PatientID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "Title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "Description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "DueDate", kind: "message", T: Timestamp },
    { no: 14, name: "Assignees", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 21, name: "Order", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 22, name: "Tag", kind: "enum", T: proto3.getEnumType(PatientTaskTag) },
    { no: 51, name: "Closed", kind: "message", T: PatientTaskClosed },
    { no: 10030, name: "Created", kind: "message", T: Created },
    { no: 10040, name: "Revision", kind: "message", T: Revision },
    { no: 10050, name: "Deleted", kind: "message", T: Deleted },
  ],
);

/**
 * @generated from message com.diagnocat.model.PatientTaskClosed
 */
export const PatientTaskClosed = proto3.makeMessageType(
  "com.diagnocat.model.PatientTaskClosed",
  () => [
    { no: 1, name: "Closed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "By", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "At", kind: "message", T: Timestamp },
  ],
);

