import { Localization } from '@/shared/api/protocol-ts/model/dto_report_localization_pb';

export const protocolLocalizationToBBox = (localization: Localization) => {
  const { BBox } = localization;
  return {
    xmin: BBox?.X?.Min ?? 0,
    ymin: BBox?.Y?.Min ?? 0,
    xmax: BBox?.X?.Max ?? 0,
    ymax: BBox?.Y?.Max ?? 0,
  };
};
