import api from '@/shared/api/api';
import { createThunkGenerator } from '@/shared/lib';
import { SliceName } from '@/shared/config';
import {
  AcceptInvitationReq,
  AcceptInvitationResp,
  RevokeSharingReq,
  RevokeSharingResp,
  InviteReq,
  InviteResp,
  CancelInvitationReq,
  CancelInvitationResp,
  GetInvitationByTokenReq,
  GetInvitationByTokenResp,
  SetUserOrganizationRolesReq,
  SetUserOrganizationRolesResp,
  SetPatientTeamMembersReq,
  SetPatientTeamMembersResp,
  SetOrganizationInvitationUserInfoReq,
  SetOrganizationInvitationUserInfoResp,
} from '@/shared/api/protocol-ts/api/core/svc_access_pb';
import { AccessService } from '@/shared/api/protocol-ts/api/core/svc_access_connectweb';

type AccessRequests = keyof typeof AccessService.methods;

const generateAccessThunk = createThunkGenerator<AccessRequests>(
  SliceName.access,
);

export const setUserOrganizationRoles = generateAccessThunk<
  SetUserOrganizationRolesReq,
  SetUserOrganizationRolesResp
>('setUserOrganizationRoles', api.access.setUserOrganizationRoles);

export const setPatientTeamMembers = generateAccessThunk<
  SetPatientTeamMembersReq,
  SetPatientTeamMembersResp
>('setPatientTeamMembers', api.access.setPatientTeamMembers);

export const revokeSharing = generateAccessThunk<
  RevokeSharingReq,
  RevokeSharingResp
>('revokeSharing', api.access.revokeSharing);

export const cancelInvitation = generateAccessThunk<
  CancelInvitationReq,
  CancelInvitationResp
>('cancelInvitation', api.access.cancelInvitation);

export const inviteForSharingPatient = generateAccessThunk<
  InviteReq,
  InviteResp
>('invite', api.access.invite);

export const acceptInvite = generateAccessThunk<
  AcceptInvitationReq,
  AcceptInvitationResp
>('invite', api.access.acceptInvitation);

export const getInvitationByToken = generateAccessThunk<
  GetInvitationByTokenReq,
  GetInvitationByTokenResp
>('getInvitationByToken', api.access.getInvitationByToken);

export const setOrganizationInvitationUserInfo = generateAccessThunk<
  SetOrganizationInvitationUserInfoReq,
  SetOrganizationInvitationUserInfoResp
>(
  'setOrganizationInvitationUserInfo',
  api.access.setOrganizationInvitationUserInfo,
);
