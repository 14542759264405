import { useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import { reportsModel } from '@/entities/reports';
import { ModalID, modalModel } from '@/entities/modal';
import { toothModel } from '@/entities/tooth';

export const useSignReport = (allTeethIsApproved = false) => {
  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const reportID = useAppSelector(reportsModel.selectors.selectCurrentReportID);

  const title = allTeethIsApproved
    ? formatMessage({
        id: 'report.signReport',
        defaultMessage: 'Sign report',
      })
    : formatMessage({
        id: 'consfirmSignReportModal.approveAll',
        defaultMessage: 'Approve all teeth',
      });

  return () => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.Confirm,
        data: {
          title,
          description: formatMessage({
            id: 'report.warning.approveAndSign',
            defaultMessage:
              'Are you sure you want to approve all the teeth and include in the report?',
          }),
          okText: formatMessage({
            id: 'report.confirm.approveAndSign',
            defaultMessage: 'Yes, approve all and sign',
          }),
          onConfirm: async () => {
            // TODO: [8|m] Find a way to incapsulate loading status management inside <ConfirmModal />
            dispatch(modalModel.actions.setConfirmModalLoading(true));
            try {
              const { Report, Teeth } = await dispatch(
                reportsModel.thunks.approveAllTeethAndSignReport(
                  reportID as string,
                ),
              ).unwrap();
              if (Report) {
                dispatch(reportsModel.actions.setNewestOne(Report));
              }
              dispatch(toothModel.actions.setMany(Teeth));
            } finally {
              dispatch(modalModel.actions.setConfirmModalLoading(false));
              dispatch(modalModel.actions.closeModal(ModalID.Confirm));
            }
          },
        },
      }),
    );
  };
};
