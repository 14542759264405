// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_report_localization.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { BBox, ClosedPolygon } from "./dto_common_geometry_pb.js";
import { MedicalImageFeatures } from "./dto_common_image_medical_features_pb.js";

/**
 * @generated from enum com.diagnocat.model.LocalizationType
 */
export const LocalizationType = proto3.makeEnum(
  "com.diagnocat.model.LocalizationType",
  [
    {no: 0, name: "LocalizationTypeInvalidValue"},
    {no: 1, name: "LocalizationType2D"},
    {no: 2, name: "LocalizationType3D"},
  ],
);

/**
 * //////////////////////////////////////////////////////////////
 * //  Localization
 * //
 * //  Localization of something on an image;
 * //
 *
 * @generated from message com.diagnocat.model.Localization
 */
export const Localization = proto3.makeMessageType(
  "com.diagnocat.model.Localization",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "TargetAssetID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "Type", kind: "enum", T: proto3.getEnumType(LocalizationType) },
    { no: 12, name: "Mask", kind: "message", T: ClosedPolygon },
    { no: 13, name: "PolygonalMask", kind: "message", T: ClosedPolygon, repeated: true },
    { no: 14, name: "BBox", kind: "message", T: BBox },
    { no: 15, name: "PeriodontalBoneLossLandmarks", kind: "message", T: LocalizationPBLLandmarks },
    { no: 21, name: "CropMedicalImageFeatures", kind: "message", T: MedicalImageFeatures },
  ],
);

/**
 * @generated from message com.diagnocat.model.LocalizationPBLLandmarks
 */
export const LocalizationPBLLandmarks = proto3.makeMessageType(
  "com.diagnocat.model.LocalizationPBLLandmarks",
  () => [
    { no: 1, name: "CementoenamelJunctionLandmarkID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "BoneLevelLandmarkID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

