import { useIntl } from 'react-intl';
import cn from 'classnames';

import { Button, Description } from '@/shared/ui';
import { StudyType } from '@/shared/api/protocol-ts/model/dto_study_pb';

import { i18n } from '@/entities/study';
import { getStudyArchiveURL } from '@/entities/assets';

import { StudyMetaInfo } from '../../config/types';
import { ScanUidCopy } from '../ScanUidCopy/ScanUidCopy';

import styles from './StudyInfo.module.scss';

type StudyInfoProps = StudyMetaInfo & {
  className?: string;
};

export const StudyInfo = (props: StudyInfoProps) => {
  const {
    className,
    id,
    studyType,
    uploadDate,
    studyTime,
    manufacturer,
    model,
    voxel,
    fov,
    exposure,
  } = props;

  const { formatDate, formatMessage } = useIntl();

  const title = formatMessage(i18n.studyTypes[studyType]);
  const isCBCT = studyType === StudyType.StudyType_CBCT;
  const dateLabel = isCBCT
    ? formatMessage(i18n.studyScanInfoMessages.date)
    : formatMessage({
        id: 'studyInfo.uploadDate',
        defaultMessage: 'Upload date',
      });

  return (
    <section className={cn(styles.container, className)}>
      <header className={styles.header}>
        <h4 className="h4">{title}</h4>
      </header>
      <main className={styles.main}>
        <div className={styles.col}>
          {isCBCT && (
            <Description
              label={formatMessage({
                id: 'studyInfo.scanUID',
                defaultMessage: 'Scan UID',
              })}
              className={styles.descriptionContainer}
              contentClassName="p2"
              hideColon
              isVertical
            >
              <ScanUidCopy scanUid={id ?? ''} />
            </Description>
          )}
          <Description
            label={dateLabel}
            className={cn(
              styles.descriptionContainer,
              !isCBCT && styles.alignCenter,
            )}
            labelClassName={cn(!isCBCT && styles.descriptionLabel)}
            contentClassName="p2"
            hideColon
            isVertical={isCBCT}
          >
            {formatDate(isCBCT ? studyTime : uploadDate, {
              dateStyle: 'medium',
              timeStyle: 'short',
            })}
          </Description>
          {isCBCT && (
            <Description
              label={formatMessage(i18n.studyScanInfoMessages.manufacturer)}
              className={styles.descriptionContainer}
              contentClassName="p2"
              hideColon
              isVertical
            >
              {manufacturer || '-'}
            </Description>
          )}
          {isCBCT && (
            <Description
              label={formatMessage(i18n.studyScanInfoMessages.model)}
              className={styles.descriptionContainer}
              contentClassName="p2"
              hideColon
              isVertical
            >
              {model || '-'}
            </Description>
          )}
        </div>
        {isCBCT && (
          <div className={styles.col}>
            <Description
              label={formatMessage(i18n.studyScanInfoMessages.voxel)}
              className={styles.descriptionContainer}
              contentClassName="p2"
              hideColon
              isVertical
            >
              {voxel || '-'}
            </Description>
            <Description
              label={formatMessage(i18n.studyScanInfoMessages.fov)}
              className={styles.descriptionContainer}
              contentClassName="p2"
              hideColon
              isVertical
            >
              {fov || '-'}
            </Description>
            <Description
              label={formatMessage(i18n.studyScanInfoMessages.exposure)}
              className={styles.descriptionContainer}
              contentClassName="p2"
              hideColon
              isVertical
            >
              {exposure || '-'}
            </Description>
          </div>
        )}
      </main>
      <footer className={styles.footer}>
        <Button
          href={getStudyArchiveURL(id)}
          target="_blank"
          size="tiny"
          variant="secondary"
        >
          {formatMessage({ id: 'global.download', defaultMessage: 'Download' })}{' '}
          {title}
        </Button>
      </footer>
    </section>
  );
};
