import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { ConnectError } from '@bufbuild/connect';

import { ErrorText, Modal, ModalProps } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';
import { getUserFullName } from '@/shared/lib';

import { DoctorLabel, useSetDoctors } from '@/entities/doctors';
import { organizationModel } from '@/entities/organization';
import { getAvatarSrcByUserID } from '@/entities/assets';
import { patientModel } from '@/entities/patient';

import { getPatientDoctorsIDsWithoutRemovedDoctor } from '../../lib/getPatientDoctorsIDsWithoutRemovedDoctor';

import styles from './RemoveDoctorModal.module.scss';

type RemoveDoctorModalProps = {
  patientID?: string;
  doctorID?: string;
} & Pick<ModalProps, 'isOpen' | 'onCancel'>;

export const RemoveDoctorModal: FC<RemoveDoctorModalProps> = (props) => {
  const { isOpen, onCancel: onClose, patientID = '', doctorID = '' } = props;

  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const { formatMessage } = useIntl();
  const removeDoctor = useSetDoctors();

  const doctor = useAppSelector(
    organizationModel.selectors.selectUserByID(doctorID),
  );

  const treatingDoctorsIDs = useAppSelector(
    patientModel.selectors.selectTreatingDoctorsIDs(patientID),
  );

  const treatingDoctorsIDsWithoutRemovedDoctor =
    getPatientDoctorsIDsWithoutRemovedDoctor(
      doctorID ?? '',
      treatingDoctorsIDs ?? [],
    );

  const doctorName = getUserFullName(doctor);

  const avatarSrc = getAvatarSrcByUserID(doctor?.UserID ?? '', 'thumbnail');

  const handleClose = () => {
    setErrorMessage(undefined);
    if (onClose) {
      onClose();
    }
  };

  const handleRemoveDoctor = async () => {
    setLoading(true);

    try {
      await removeDoctor({
        patientID,
        doctorIDs: treatingDoctorsIDsWithoutRemovedDoctor,
      });
    } catch (error) {
      setErrorMessage((error as ConnectError)?.message);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <Modal
      containerClassName={styles.container}
      bodyClassName={styles.modalBody}
      title={formatMessage({
        id: 'removeDoctorModal.title',
        defaultMessage: 'Remove treating doctor',
      })}
      isOpen={isOpen}
      onCancel={handleClose}
      onOk={handleRemoveDoctor}
      okButtonText={formatMessage({
        id: 'removeDoctorModal.applyButtonText',
        defaultMessage: 'Remove doctor',
      })}
      okButtonProps={{
        danger: true,
        loading: isLoading,
      }}
    >
      <DoctorLabel fullName={doctorName} avatarSrc={avatarSrc} />
      <ErrorText className={styles.errorText} error={errorMessage} />
    </Modal>
  );
};
