import { FC, MouseEventHandler, ReactNode } from 'react';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import cn from 'classnames';
import { TooltipContentProps } from '@radix-ui/react-tooltip';

import styles from './PrimaryTooltip.module.scss';

type PrimaryTooltipPropsType = {
  className?: string;
  triggerClassName?: string;
  content: ReactNode;
  children?: ReactNode;
  side?: TooltipContentProps['side'];
  sideOffset?: number;
  onTriggerClick?: MouseEventHandler<HTMLDivElement> | undefined;
};

export const PrimaryTooltip: FC<PrimaryTooltipPropsType> = (props) => {
  const {
    className,
    triggerClassName,
    children,
    content,
    side = 'top',
    sideOffset = 4,
    onTriggerClick,
  } = props;

  return (
    <RadixTooltip.Root delayDuration={0}>
      <RadixTooltip.Trigger asChild>
        <div
          className={cn(styles.tooltipTrigger, triggerClassName)}
          onClick={onTriggerClick}
        >
          {children}
        </div>
      </RadixTooltip.Trigger>

      <RadixTooltip.Portal>
        <RadixTooltip.Content
          className={cn(styles.tooltipContent, 'p3', className)}
          side={side}
          sideOffset={sideOffset}
        >
          {content}
          <RadixTooltip.Arrow asChild>
            <div className={cn(styles.tooltipArrow)} />
          </RadixTooltip.Arrow>
        </RadixTooltip.Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  );
};
