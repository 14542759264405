import { OrganizationJobPosition } from '@/shared/api/protocol-ts/model/dto_organization_job_position_pb';
import { FormatMessageFunction } from '@/shared/config';

import { i18n } from '../config';

export const getOrganizationDoctorSpecializations = (
  positions: OrganizationJobPosition[],
  formatMessage: FormatMessageFunction,
) =>
  positions.map((position: OrganizationJobPosition) =>
    formatMessage(i18n.specialityType[position]),
  );
