import { createMPR, MPR } from "graphics";
import { forwardRef, MutableRefObject, useEffect, useRef } from "react";
import { CORS_POLICY } from '@/shared/config';

type MPRBaseProps = {
    nrrdUrl: string;
    initialWw?: number;
    initialWc?: number;
    onMPRReady: (mpr: MPR) => void;
    toothNumber: number
};
// @ts-ignore
export const MPRBase = forwardRef<HTMLCanvasElement, MPRBaseProps>((props, ref: MutableRefObject<HTMLCanvasElement>) => {

    const { nrrdUrl, onMPRReady, initialWw, initialWc, toothNumber } = props

    const mprRef = useRef<MPR>()

    // TODO MPR created and disposed multiple time at leats in storybook for some reason
    useEffect(() => {
        if (!ref.current) return

        let isMounted = true

        const credentials = CORS_POLICY;

        createMPR(ref.current, nrrdUrl, credentials, true, initialWw || 3200, initialWc || 600, toothNumber, "white").then(mpr => {
            // console.log("MPR created")
            if (isMounted) {

                onMPRReady(mpr)
                mprRef.current = mpr
            } else {
                // console.log("MPR disposed post-unmount")
                mpr.dispose()
            }
        })

        return () => {
            isMounted = false

            if (mprRef.current) {
                // console.log("MPR disposed")
                mprRef.current.dispose()
            }
        }

    }, [ref.current, nrrdUrl])


    return (
        <>
            <canvas ref={ref} style={{ width: "100%", height: "100%" }}></canvas>
        </>
    )
})
