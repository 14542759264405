import api from '@/shared/api/api';
import { createThunkGenerator } from '@/shared/lib';
import { SliceName } from '@/shared/config';
import {
  CreateOrderReq,
  CreateOrderResp,
  GetAvailableProductsReq,
  GetAvailableProductsResp,
  PayOrderReq,
  PayOrderResp,
  SetAccountBillingInformationReq,
  SetAccountBillingInformationResp,
} from '@/shared/api/protocol-ts/api/billing_new/svc_billing_new_pb';
import { Billing } from '@/shared/api/protocol-ts/api/billing_new/svc_billing_new_connectweb';

type BillingRequests = keyof typeof Billing.methods;

const generateBillingThunk = createThunkGenerator<BillingRequests>(
  SliceName.billing,
);

export const setAccountBillingInformation = generateBillingThunk<
  SetAccountBillingInformationReq,
  SetAccountBillingInformationResp
>('setAccountBillingInformation', api.billing.setAccountBillingInformation);

export const getAvailableProducts = generateBillingThunk<
  GetAvailableProductsReq,
  GetAvailableProductsResp
>('getAvailableProducts', api.billing.getAvailableProducts);

export const payOrder = generateBillingThunk<PayOrderReq, PayOrderResp>(
  'payOrder',
  api.billing.payOrder,
);

export const createOrder = generateBillingThunk<
  CreateOrderReq,
  CreateOrderResp
>('createOrder', api.billing.createOrder);
