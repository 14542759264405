// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_asset_cbct_endo.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { CBCT_SectionOrientation, CBCT_SliceOrientation, DerivedData_Image, File } from "./dto_asset_common_pb.js";

/**
 * @generated from enum com.diagnocat.model.AssetContent_Generated_CBCT_Endo_ReportSection
 */
export const AssetContent_Generated_CBCT_Endo_ReportSection = proto3.makeEnum(
  "com.diagnocat.model.AssetContent_Generated_CBCT_Endo_ReportSection",
  [
    {no: 0, name: "Endo_ReportSection_RootCanalSpace"},
    {no: 1, name: "Endo_ReportSection_RootCanalSystem"},
    {no: 2, name: "Endo_ReportSection_RootCanalShape"},
    {no: 3, name: "Endo_ReportSection_Furcation"},
  ],
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Generated_CBCT_Endo_PanoramaSplit
 */
export const AssetContent_Generated_CBCT_Endo_PanoramaSplit = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Generated_CBCT_Endo_PanoramaSplit",
  () => [
    { no: 1, name: "PanoramaSplit", kind: "message", T: File },
    { no: 2, name: "Type", kind: "enum", T: proto3.getEnumType(AssetContent_Generated_CBCT_Endo_PanoramaSplit_PanoramaSplit_Type) },
    { no: 3, name: "Thickness", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "WindowWidth", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "WindowLevel", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 101, name: "Derived", kind: "message", T: DerivedData_Image },
  ],
);

/**
 * {"type": "File", "file": {"task_id": "da8561ec-a376-9d8a-c0a9-6da76722dcc4", "key": "split-panorama", "filename": "/tmp/tmp9z4ug6ej", "content_type": "application/dicom", "size": 222976, "meta": {"type": "mandible", "ww": 2890, "wl": 670, "width": 5, "image_height": 185, "image_width": 600, "name": "split-panorama"}, "type": "File"}, "event": "execution-event"}
 *
 * @generated from enum com.diagnocat.model.AssetContent_Generated_CBCT_Endo_PanoramaSplit.PanoramaSplit_Type
 */
export const AssetContent_Generated_CBCT_Endo_PanoramaSplit_PanoramaSplit_Type = proto3.makeEnum(
  "com.diagnocat.model.AssetContent_Generated_CBCT_Endo_PanoramaSplit.PanoramaSplit_Type",
  [
    {no: 0, name: "Mandible"},
    {no: 1, name: "Maxilla"},
  ],
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Generated_CBCT_Endo_CrossSection
 */
export const AssetContent_Generated_CBCT_Endo_CrossSection = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Generated_CBCT_Endo_CrossSection",
  () => [
    { no: 1, name: "CrossSection", kind: "message", T: File },
    { no: 2, name: "Order", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "Thickness", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "RightSideOrient", kind: "enum", T: proto3.getEnumType(CBCT_SliceOrientation) },
    { no: 5, name: "LeftSideOrient", kind: "enum", T: proto3.getEnumType(CBCT_SliceOrientation) },
    { no: 6, name: "Section", kind: "enum", T: proto3.getEnumType(AssetContent_Generated_CBCT_Endo_ReportSection) },
    { no: 7, name: "Orientation", kind: "enum", T: proto3.getEnumType(CBCT_SectionOrientation) },
    { no: 101, name: "Axial", kind: "message", T: CBCTEndoCrossSectionMetadataAxial, oneof: "Metadata" },
    { no: 102, name: "Furcation", kind: "message", T: CBCTEndoCrossSectionMetadataFurcation, oneof: "Metadata" },
    { no: 103, name: "Vertical", kind: "message", T: CBCTEndoCrossSectionMetadataVertical, oneof: "Metadata" },
  ],
);

/**
 * @generated from message com.diagnocat.model.CBCTEndoCrossSectionMetadataFurcation
 */
export const CBCTEndoCrossSectionMetadataFurcation = proto3.makeMessageType(
  "com.diagnocat.model.CBCTEndoCrossSectionMetadataFurcation",
  [],
);

/**
 * @generated from message com.diagnocat.model.CBCTEndoCrossSectionMetadataAxial
 */
export const CBCTEndoCrossSectionMetadataAxial = proto3.makeMessageType(
  "com.diagnocat.model.CBCTEndoCrossSectionMetadataAxial",
  () => [
    { no: 1, name: "Stride", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * @generated from message com.diagnocat.model.CBCTEndoCrossSectionMetadataVertical
 */
export const CBCTEndoCrossSectionMetadataVertical = proto3.makeMessageType(
  "com.diagnocat.model.CBCTEndoCrossSectionMetadataVertical",
  () => [
    { no: 1, name: "CanalLength", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "CanalCurvature", kind: "message", T: CBCTEndoCrossSectionMetadataVertical_CanalCurvatureTuple },
    { no: 3, name: "RootName", kind: "enum", T: proto3.getEnumType(CBCTEndoCrossSectionMetadataVertical_Root) },
  ],
);

/**
 * @generated from enum com.diagnocat.model.CBCTEndoCrossSectionMetadataVertical.Root
 */
export const CBCTEndoCrossSectionMetadataVertical_Root = proto3.makeEnum(
  "com.diagnocat.model.CBCTEndoCrossSectionMetadataVertical.Root",
  [
    {no: 0, name: "MB"},
    {no: 1, name: "DB"},
    {no: 2, name: "P1"},
    {no: 3, name: "P2"},
    {no: 4, name: "P"},
  ],
);

/**
 * @generated from message com.diagnocat.model.CBCTEndoCrossSectionMetadataVertical.CanalCurvatureTuple
 */
export const CBCTEndoCrossSectionMetadataVertical_CanalCurvatureTuple = proto3.makeMessageType(
  "com.diagnocat.model.CBCTEndoCrossSectionMetadataVertical.CanalCurvatureTuple",
  () => [
    { no: 1, name: "V1", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "V2", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "V3", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
  {localName: "CBCTEndoCrossSectionMetadataVertical_CanalCurvatureTuple"},
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Generated_CBCT_Endo_Guide
 */
export const AssetContent_Generated_CBCT_Endo_Guide = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Generated_CBCT_Endo_Guide",
  () => [
    { no: 1, name: "Guide", kind: "message", T: File },
    { no: 2, name: "Section", kind: "enum", T: proto3.getEnumType(AssetContent_Generated_CBCT_Endo_ReportSection) },
    { no: 3, name: "Order", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "Orientation", kind: "enum", T: proto3.getEnumType(CBCT_SectionOrientation) },
  ],
);

