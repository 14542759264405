// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file api/notifications/dto_notifications.proto (package com.diagnocat.notifications, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { Message } from "../messenger/dto_message_pb.js";
import { DoctorInfo, PatientInfo, ProjectInfo, ProjectTaskAssignment, ProjectTaskInfo, ReportInfo, StudyInfo } from "../../backbone/aux_enrichment_models_pb.js";

/**
 * @generated from message com.diagnocat.notifications.NotificationMessagePosted
 */
export const NotificationMessagePosted = proto3.makeMessageType(
  "com.diagnocat.notifications.NotificationMessagePosted",
  () => [
    { no: 1, name: "Message", kind: "message", T: Message },
    { no: 2, name: "Study", kind: "message", T: StudyInfo },
    { no: 3, name: "Patient", kind: "message", T: PatientInfo },
    { no: 4, name: "Sender", kind: "message", T: DoctorInfo },
    { no: 5, name: "TreatingDoctors", kind: "message", T: DoctorInfo, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.notifications.NotificationMessageEdited
 */
export const NotificationMessageEdited = proto3.makeMessageType(
  "com.diagnocat.notifications.NotificationMessageEdited",
  () => [
    { no: 1, name: "Message", kind: "message", T: Message },
  ],
);

/**
 * @generated from message com.diagnocat.notifications.NotificationMessageDeleted
 */
export const NotificationMessageDeleted = proto3.makeMessageType(
  "com.diagnocat.notifications.NotificationMessageDeleted",
  () => [
    { no: 1, name: "Message", kind: "message", T: Message },
  ],
);

/**
 * @generated from message com.diagnocat.notifications.NotificationStudyUploaded
 */
export const NotificationStudyUploaded = proto3.makeMessageType(
  "com.diagnocat.notifications.NotificationStudyUploaded",
  () => [
    { no: 1, name: "Study", kind: "message", T: StudyInfo },
    { no: 2, name: "Patient", kind: "message", T: PatientInfo },
    { no: 3, name: "Project", kind: "message", T: ProjectInfo },
    { no: 4, name: "TreatingDoctors", kind: "message", T: DoctorInfo, repeated: true },
    { no: 5, name: "Doctor", kind: "message", T: DoctorInfo },
  ],
);

/**
 * @generated from message com.diagnocat.notifications.NotificationReportRequested
 */
export const NotificationReportRequested = proto3.makeMessageType(
  "com.diagnocat.notifications.NotificationReportRequested",
  () => [
    { no: 1, name: "Report", kind: "message", T: ReportInfo },
    { no: 2, name: "Study", kind: "message", T: StudyInfo },
    { no: 3, name: "Patient", kind: "message", T: PatientInfo },
    { no: 4, name: "Project", kind: "message", T: ProjectInfo },
    { no: 5, name: "TreatingDoctors", kind: "message", T: DoctorInfo, repeated: true },
    { no: 6, name: "Doctor", kind: "message", T: DoctorInfo },
  ],
);

/**
 * @generated from message com.diagnocat.notifications.NotificationReportCompleted
 */
export const NotificationReportCompleted = proto3.makeMessageType(
  "com.diagnocat.notifications.NotificationReportCompleted",
  () => [
    { no: 1, name: "Report", kind: "message", T: ReportInfo },
    { no: 2, name: "Study", kind: "message", T: StudyInfo },
    { no: 3, name: "Patient", kind: "message", T: PatientInfo },
    { no: 4, name: "Project", kind: "message", T: ProjectInfo },
    { no: 5, name: "TreatingDoctors", kind: "message", T: DoctorInfo, repeated: true },
    { no: 6, name: "Doctor", kind: "message", T: DoctorInfo },
    { no: 100, name: "Success", kind: "message", T: NotificationReportCompletedEventSuccess, oneof: "Result" },
    { no: 101, name: "Error", kind: "message", T: NotificationReportCompletedEventError, oneof: "Result" },
  ],
);

/**
 * @generated from message com.diagnocat.notifications.NotificationReportCompletedEventSuccess
 */
export const NotificationReportCompletedEventSuccess = proto3.makeMessageType(
  "com.diagnocat.notifications.NotificationReportCompletedEventSuccess",
  [],
);

/**
 * @generated from message com.diagnocat.notifications.NotificationReportCompletedEventError
 */
export const NotificationReportCompletedEventError = proto3.makeMessageType(
  "com.diagnocat.notifications.NotificationReportCompletedEventError",
  () => [
    { no: 1, name: "Code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.notifications.NotificationPatientStatusChanged
 */
export const NotificationPatientStatusChanged = proto3.makeMessageType(
  "com.diagnocat.notifications.NotificationPatientStatusChanged",
  () => [
    { no: 1, name: "Patient", kind: "message", T: PatientInfo },
    { no: 2, name: "Doctor", kind: "message", T: DoctorInfo },
    { no: 3, name: "TreatingDoctors", kind: "message", T: DoctorInfo, repeated: true },
    { no: 4, name: "PreviousStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.notifications.NotificationPatientProfileChanged
 */
export const NotificationPatientProfileChanged = proto3.makeMessageType(
  "com.diagnocat.notifications.NotificationPatientProfileChanged",
  () => [
    { no: 1, name: "OriginalPatient", kind: "message", T: PatientInfo },
    { no: 2, name: "ModifiedPatient", kind: "message", T: PatientInfo },
    { no: 3, name: "Doctor", kind: "message", T: DoctorInfo },
    { no: 4, name: "TreatingDoctors", kind: "message", T: DoctorInfo, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.notifications.NotificationPatientInviteAccepted
 */
export const NotificationPatientInviteAccepted = proto3.makeMessageType(
  "com.diagnocat.notifications.NotificationPatientInviteAccepted",
  () => [
    { no: 1, name: "Patient", kind: "message", T: PatientInfo },
    { no: 2, name: "SenderDoctor", kind: "message", T: DoctorInfo },
    { no: 3, name: "RecipientDoctor", kind: "message", T: DoctorInfo },
    { no: 4, name: "TreatingDoctors", kind: "message", T: DoctorInfo, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.notifications.NotificationPatientTreatingDoctorAdded
 */
export const NotificationPatientTreatingDoctorAdded = proto3.makeMessageType(
  "com.diagnocat.notifications.NotificationPatientTreatingDoctorAdded",
  () => [
    { no: 1, name: "Patient", kind: "message", T: PatientInfo },
    { no: 2, name: "Doctor", kind: "message", T: DoctorInfo },
    { no: 3, name: "TreatingDoctors", kind: "message", T: DoctorInfo, repeated: true },
    { no: 4, name: "AddedDoctor", kind: "message", T: DoctorInfo },
  ],
);

/**
 * @generated from message com.diagnocat.notifications.NotificationPatientTreatingDoctorRemoved
 */
export const NotificationPatientTreatingDoctorRemoved = proto3.makeMessageType(
  "com.diagnocat.notifications.NotificationPatientTreatingDoctorRemoved",
  () => [
    { no: 1, name: "Patient", kind: "message", T: PatientInfo },
    { no: 2, name: "Doctor", kind: "message", T: DoctorInfo },
    { no: 3, name: "TreatingDoctors", kind: "message", T: DoctorInfo, repeated: true },
    { no: 4, name: "RemovedDoctor", kind: "message", T: DoctorInfo },
  ],
);

/**
 * @generated from message com.diagnocat.notifications.NotificationProjectTaskAddAssignee
 */
export const NotificationProjectTaskAddAssignee = proto3.makeMessageType(
  "com.diagnocat.notifications.NotificationProjectTaskAddAssignee",
  () => [
    { no: 1, name: "Task", kind: "message", T: ProjectTaskInfo },
    { no: 2, name: "Assignment", kind: "message", T: ProjectTaskAssignment },
    { no: 3, name: "Patient", kind: "message", T: PatientInfo },
    { no: 4, name: "TreatingDoctors", kind: "message", T: DoctorInfo, repeated: true },
  ],
);

