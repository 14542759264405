// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_report_permissions.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";

/**
 * Flags for all actions that can be disabled under User permission level
 *
 * TODO - ReadOnly ???
 *
 * @generated from message com.diagnocat.model.ReportPermissions
 */
export const ReportPermissions = proto3.makeMessageType(
  "com.diagnocat.model.ReportPermissions",
  () => [
    { no: 1, name: "CanView", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "CanChangeROI", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "CanChangeConditions", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "CanInteractWithImages", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "CanSign", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "CanPrint", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "CanChangeConclusion", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "CanChangeSettings", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "CanDelete", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "CanChangeReportAssets", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 21, name: "CanChangeToothComment", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 22, name: "CanChangeToothNumeration", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 23, name: "CanChangeToothApproved", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 24, name: "CanResetTooth", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 25, name: "CanChangeToothAssets", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

