// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_report_type_studio_implant.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { CBCTSeriesGeometryData, StudioColorProfile } from "./dto_common_geometry_pb.js";
import { Report_Studio_OriginatedPosition, Report_Studio_ToothPosition } from "./dto_report_type_studio_common_pb.js";
import { CephalometricLandmark, ToothLandmark } from "./dto_report_landmark_pb.js";
import { Segmentron_PhotoIOSSuperimposition } from "./dto_report_segmentron_common_pb.js";
import { ToothNumeration } from "./dto_report_common_pb.js";

/**
 * @generated from message com.diagnocat.model.Report_Studio_Implant
 */
export const Report_Studio_Implant = proto3.makeMessageType(
  "com.diagnocat.model.Report_Studio_Implant",
  () => [
    { no: 1, name: "CropGeometryData", kind: "message", T: CBCTSeriesGeometryData },
    { no: 2, name: "ColorProfile", kind: "message", T: StudioColorProfile },
    { no: 3, name: "TeethPositions", kind: "message", T: Report_Studio_ToothPosition, repeated: true },
    { no: 5, name: "Plans", kind: "message", T: Report_Studio_Implant_CrownImplantPlan, repeated: true },
    { no: 6, name: "TeethLandmarks", kind: "message", T: ToothLandmark, repeated: true },
    { no: 7, name: "CephalometricLandmarks", kind: "message", T: CephalometricLandmark, repeated: true },
    { no: 8, name: "PhotoIOSSuperimpositions", kind: "message", T: Segmentron_PhotoIOSSuperimposition, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.model.Report_Studio_Implant_CrownImplantPlan
 */
export const Report_Studio_Implant_CrownImplantPlan = proto3.makeMessageType(
  "com.diagnocat.model.Report_Studio_Implant_CrownImplantPlan",
  () => [
    { no: 1, name: "Tooth", kind: "message", T: ToothNumeration },
    { no: 2, name: "CrownPlan", kind: "message", T: Report_Studio_Implant_CrownImplantPlan_CrownPlan },
    { no: 3, name: "ImplantPlan", kind: "message", T: Report_Studio_Implant_CrownImplantPlan_ImplantPlan, opt: true },
  ],
);

/**
 * @generated from message com.diagnocat.model.Report_Studio_Implant_CrownImplantPlan_CrownPlan
 */
export const Report_Studio_Implant_CrownImplantPlan_CrownPlan = proto3.makeMessageType(
  "com.diagnocat.model.Report_Studio_Implant_CrownImplantPlan_CrownPlan",
  () => [
    { no: 1, name: "Position", kind: "message", T: Report_Studio_OriginatedPosition },
    { no: 2, name: "ArtificialCrownAssetID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "Landmarks", kind: "message", T: ToothLandmark, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.model.Report_Studio_Implant_CrownImplantPlan_ImplantPlan
 */
export const Report_Studio_Implant_CrownImplantPlan_ImplantPlan = proto3.makeMessageType(
  "com.diagnocat.model.Report_Studio_Implant_CrownImplantPlan_ImplantPlan",
  () => [
    { no: 1, name: "Position", kind: "message", T: Report_Studio_OriginatedPosition },
    { no: 2, name: "MaximumLength", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "MaximumDiameter", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

