import { StructuredReportsAndStudiesType } from '../config';

export const getSortedStructuredReportsAndStudies = (
  structuredReportsAndStudies: StructuredReportsAndStudiesType[],
) => {
  return [...structuredReportsAndStudies].sort((a, b) => {
    if (b.date === a.date) {
      const latestReportA = a.report?.Created?.At?.toDate?.().getTime() ?? 0;
      const latestReportB = b.report?.Created?.At?.toDate?.().getTime() ?? 0;

      return latestReportB - latestReportA;
    }

    return (b.date ?? 0) - (a.date ?? 0);
  });
};
