import { ReportType } from '@/shared/api/protocol-ts/model/dto_report_pb';
import { ToolNames } from '@/shared/config';

export type SignStatus = 'withoutSign' | 'signed' | 'notSigned';

export enum MaskStyleTypes {
  Default,
  Style2,
  Style3,
  Style4,
  StyleBW,
}

export enum FMXNavigationMode {
  ToothChart = 1,
  MatrixView = 2,
}

export type ViewOptionsInterface = Partial<{
  sharpness: number;
  invert: boolean;
  brightness: number;
  contrast: number;
  wwwc: { ww: number; wc: number };
}>;

export type ActiveControlType = ToolNames | '';

export type CheckReportSignatureModalText = {
  title: string;
  description: string;
  confirm: string;
};

export const SegmentationReportTypes: ReportType[] = [
  ReportType.ReportType_CBCT_Segmentation,
  ReportType.ReportType_CBCT_IOS_Superimposition,
  ReportType.ReportType_IOS_Segmentation,
  ReportType.ReportType_StudioImplant,
  ReportType.ReportType_StudioOrtho,
];
