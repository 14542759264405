// TODO: resolve the type of guide
import { Asset } from '@/shared/api/protocol-ts/model/dto_asset_pb';
import { FormatMessageFunction, MedicalImageInterface } from '@/shared/config';
import { FileMetadata_Image } from '@/shared/api/protocol-ts/model/dto_asset_common_pb';

import {
  PathologyGroup,
  Guide,
  GroupConfigsType,
  GuideType,
} from '../config/medicalImages.types';

import { getFileSrc } from './getFileSrc';
import { getMedicalImageOrient } from './getMedicalImageOrient';
import { makeGuideLines } from './makeGuideLines';
import { makeAnnotations } from './makeAnnotations';
import { makeImageViewOptions } from './makeImageViewOptions';
import { getGuideAxesName } from './getGuideAxesName';

export const makeEndodonticGroup = (
  groupConfigs: GroupConfigsType,
  medicalImagesList: Asset[],
  formatMessage: FormatMessageFunction,
): PathologyGroup => {
  const { groupName, groupCode } = groupConfigs;

  const guides: Guide[] = medicalImagesList.reduce((medicalImages, asset) => {
    const generatedReportAsset =
      asset.Kind.case === 'GeneratedReport' ? asset.Kind.value : undefined;

    const endoGuide =
      generatedReportAsset?.Content.case === 'CBCTEndoGuide'
        ? generatedReportAsset.Content.value
        : undefined;

    if (endoGuide && endoGuide?.Section === groupCode) {
      const Lines = makeGuideLines(
        generatedReportAsset?.MedicalImageFeatures?.Annotations?.Layers,
      );

      // @ts-expect-error WARN: this is wrong type which is not fit the guide field in Guide
      const guide: GuideType = {
        ...endoGuide,
        Lines,
      };

      const endoGuideImage =
        endoGuide?.Guide?.Metadata.case === 'Image'
          ? endoGuide?.Guide?.Metadata.value
          : undefined;

      medicalImages.push({
        id: endoGuide?.Guide?.ID,
        src: getFileSrc(endoGuide?.Guide?.ID ?? ''),
        image:
          endoGuideImage ??
          ({
            Width: 0n,
            Height: 0n,
          } as FileMetadata_Image),
        guideAxesName: getGuideAxesName(endoGuide.Orientation, formatMessage),
        guide,
      });
    }

    return medicalImages;
  }, [] as Guide[]);

  const medicalImages: MedicalImageInterface[] = medicalImagesList
    .reduce((images, item) => {
      const generatedReportAsset =
        item.Kind.case === 'GeneratedReport' ? item.Kind.value : undefined;

      const annotations = makeAnnotations(
        generatedReportAsset?.MedicalImageFeatures?.Annotations?.Layers,
      );

      const viewOptions = makeImageViewOptions(
        generatedReportAsset?.MedicalImageFeatures?.ViewOptions,
      );

      const crossSectionImage =
        generatedReportAsset?.Content.case === 'CBCTEndoCrossSection'
          ? generatedReportAsset.Content.value
          : undefined;

      if (crossSectionImage?.Section === groupCode) {
        images.push({
          id: crossSectionImage.CrossSection?.ID ?? '',
          src: getFileSrc(crossSectionImage.CrossSection?.ID ?? ''),
          path: crossSectionImage.CrossSection?.Path,
          assetID: item.ID,
          rightSideOrient: getMedicalImageOrient(
            crossSectionImage?.RightSideOrient,
          ),
          annotations,
          leftSideOrient: getMedicalImageOrient(
            crossSectionImage?.LeftSideOrient,
          ),
          thicknessMm: crossSectionImage?.Thickness.toFixed(2),
          masks: undefined,
          viewOptions,
          groupName,
        });
      }

      return images;
    }, [] as MedicalImageInterface[])
    .sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0));

  return {
    type: groupName,
    medicalImages,
    guides,
  };
};
