import { useEffect, useRef } from 'react';
import { Subscription } from 'rxjs';
import { generatePath, useLocation, useNavigate } from 'react-router';
import { Code, ConnectError } from '@bufbuild/connect';

import api from '@/shared/api/api';
import { useAppDispatch } from '@/shared/hooks';
import { DEFAULT_LOCALE, PATHS } from '@/shared/config';
import { useHubspotEvents } from '@/shared/hooks/useHubspotEvents';

import { userModel } from '@/entities/user';
import { organizationModel } from '@/entities/organization';
import { authModel } from '@/entities/auth';

import { useSyncOrganizationID } from './useSyncOrganizationID';

let abortController: AbortController;

export const useUserDataStream = (invitationToken: string | undefined) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { setIdentity, trackPage } = useHubspotEvents();

  const userDataStream = useRef<Subscription | null>(null);

  const openUserDataStream = async () => {
    abortController = new AbortController();

    dispatch(organizationModel.actions.setLoading('pending'));
    dispatch(userModel.actions.setLoading('pending'));

    try {
      const userDataStream = api.user.myDataStream(
        {},
        { signal: abortController.signal },
      );

      for await (const { Update } of userDataStream) {
        switch (Update.case) {
          case 'HistoricalMe': {
            // Hubspot events to set identity and track page view
            setIdentity({
              email: Update.value.PersonalData?.Emails[0] ?? '',
              options: {
                id: Update.value.ID,
              },
            });
            trackPage(window.location.href);

            dispatch(
              userModel.actions.setUserLocale(
                Update.value?.Settings?.Language ?? DEFAULT_LOCALE,
              ),
            );

            dispatch(userModel.actions.addUser(Update.value));
            dispatch(userModel.actions.setLoading('succeeded'));
            break;
          }

          case 'UpdatedMe': {
            dispatch(
              userModel.actions.setUserLocale(
                Update.value?.Settings?.Language ?? DEFAULT_LOCALE,
              ),
            );

            dispatch(userModel.actions.addUser(Update.value));
            break;
          }

          case 'HistoricalOrganizations': {
            dispatch(
              organizationModel.actions.setNewestMany(
                Update.value.Organizations,
              ),
            );
            // There is no EndOfHistoricalOrganizations in the API
            dispatch(organizationModel.actions.setLoading('succeeded'));
            break;
          }

          case 'UpdatedOrganization': {
            dispatch(organizationModel.actions.setNewestOne(Update.value));
            break;
          }
        }
      }
    } catch (error) {
      const connectError = ConnectError.from(error);

      // eslint-disable-next-line no-console
      console.error(
        'MyDataStream:: error: ',
        connectError.message,
        connectError.code,
        connectError.rawMessage,
        connectError,
      );

      // TODO: [2/m] think about how to improve navigation
      if (connectError.code === Code.Unauthenticated) {
        if (invitationToken) {
          const { IsAlreadyRegistered } = await dispatch(
            authModel.thunks.checkInviteToken({ Token: invitationToken }),
          ).unwrap();

          if (IsAlreadyRegistered) {
            navigate(PATHS.signIn, {
              state: {
                from: generatePath(PATHS.organization_invite, {
                  token: invitationToken,
                }),
              },
            });
          } else {
            navigate(PATHS.signUp, { state: { invitationToken } });
          }
        } else {
          navigate(PATHS.signIn, { state: { from: location?.pathname } });
        }
      }
    }
  };

  const closeUserDataStream = () => {
    if (userDataStream.current) {
      userDataStream.current.unsubscribe();
    }
  };

  // verify available current organization and sync with localStorage
  useSyncOrganizationID();

  useEffect(() => {
    openUserDataStream();

    return () => {
      closeUserDataStream();
    };
  }, []);
};
