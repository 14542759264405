// @generated by protoc-gen-connect-web v0.6.0 with parameter "target=ts,ts_nocheck=false"
// @generated from file api/notifications/svc_notifications.proto (package com.diagnocat.notifications, syntax proto3)
/* eslint-disable */

import { NotificationsMarkAsReadReq, NotificationsMarkAsReadResp, NotificationsStreamReq, NotificationsStreamResp, RegisterPushSubscriptionReq, RegisterPushSubscriptionResp } from "./svc_notifications_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service com.diagnocat.notifications.Notifications
 */
export const Notifications = {
  typeName: "com.diagnocat.notifications.Notifications",
  methods: {
    /**
     * @generated from rpc com.diagnocat.notifications.Notifications.Stream
     */
    stream: {
      name: "Stream",
      I: NotificationsStreamReq,
      O: NotificationsStreamResp,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc com.diagnocat.notifications.Notifications.MarkAsRead
     */
    markAsRead: {
      name: "MarkAsRead",
      I: NotificationsMarkAsReadReq,
      O: NotificationsMarkAsReadResp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.diagnocat.notifications.Notifications.RegisterPushSubscription
     */
    registerPushSubscription: {
      name: "RegisterPushSubscription",
      I: RegisterPushSubscriptionReq,
      O: RegisterPushSubscriptionResp,
      kind: MethodKind.Unary,
    },
  }
} as const;

