import { FormattedMessage } from 'react-intl';

import { AssetType } from '@/shared/api/protocol-ts/model/dto_asset_pb';

type CreateReportToggleTextProps = {
  studyAssetType: AssetType;
};

export const CreateReportToggleText = (props: CreateReportToggleTextProps) => {
  const { studyAssetType } = props;

  switch (studyAssetType) {
    case AssetType.AssetType_Study_IntraoralXRay:
      return (
        <FormattedMessage
          id="CreateReportToggleText.ioxray"
          defaultMessage="Create IOXray AI report after uploading"
        />
      );

    case AssetType.AssetType_Study_PanoramicXRay:
      return (
        <FormattedMessage
          id="CreateReportToggleText.pano"
          defaultMessage="Create Pano AI report after uploading"
        />
      );

    case AssetType.AssetType_Study_CBCT:
      return (
        <FormattedMessage
          id="CreateReportToggleText.cbct"
          defaultMessage="Create AI CBCT report after loading"
        />
      );

    default:
      return null;
  }
};
