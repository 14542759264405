import { ConditionInterface } from '../config/types';

const getConditionInterfaceID = (condition: ConditionInterface) => condition.id;
const conditionInterfaceHasColor =
  (color: string) => (condition: ConditionInterface) =>
    condition.color === color;

export const getConditionIDsByColor = (
  color: string,
  conditionInterface: ConditionInterface[],
) =>
  conditionInterface
    .filter(conditionInterfaceHasColor(color))
    .map(getConditionInterfaceID);
