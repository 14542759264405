import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { Organization } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { LoadingStateType, SliceName } from '@/shared/config';

import { setCurrentOrganizationByID } from './organizationSlice.thunks';

export const organizationAdapter = createEntityAdapter<Organization>({
  selectId: (organization) => organization.ID,
});

const initialStateFlags = {};

const initialState = () => ({
  currentOrganizationID: '',
  stateFlags: initialStateFlags,
  loading: 'idle' as LoadingStateType,
});

const organizationState = organizationAdapter.getInitialState(initialState());

type OrganizationState = typeof organizationState;

const organizationSlice = createSlice({
  name: SliceName.organization,
  initialState: organizationAdapter.getInitialState(initialState()),
  reducers: {
    addOne: (state, action: PayloadAction<Organization>) => {
      organizationAdapter.addOne(state as OrganizationState, action.payload);
    },
    setOne: (state, action: PayloadAction<Organization>) => {
      organizationAdapter.setOne(state as OrganizationState, action.payload);
    },
    setNewestOne: (state, action: PayloadAction<Organization>) => {
      if (action.payload.Deleted?.Deleted) {
        organizationAdapter.removeOne(
          state as OrganizationState,
          action.payload.ID,
        );
      }

      const currentRevisionNumber =
        state.entities[action.payload.ID]?.Revision?.Number ?? 0;
      const payloadRevisionNumber = action.payload.Revision?.Number ?? 0;

      if (payloadRevisionNumber > currentRevisionNumber) {
        organizationAdapter.setOne(state as OrganizationState, action.payload);
      }
    },
    setNewestMany: (state, action: PayloadAction<Organization[]>) => {
      const items = action.payload.filter((item) => {
        const currentRevisionNumber =
          state.entities[item.ID]?.Revision?.Number ?? 0;
        const payloadRevisionNumber = item.Revision?.Number ?? 0;

        return payloadRevisionNumber > currentRevisionNumber;
      });

      organizationAdapter.setMany(state as OrganizationState, items);
    },
    removeOne: (state, action: PayloadAction<string>) => {
      organizationAdapter.removeOne(state as OrganizationState, action.payload);
    },
    removeAll: (state) => {
      organizationAdapter.removeAll(state as OrganizationState);
    },
    setLoading: (state, action: PayloadAction<LoadingStateType>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setCurrentOrganizationByID.fulfilled, (state, action) => {
      state.currentOrganizationID = action.payload;
    });
  },
});

export const { actions } = organizationSlice;

export default organizationSlice.reducer;
