import { createConnectTransport } from '@bufbuild/connect-web';
import {
  createPromiseClient,
  Interceptor,
  PromiseClient,
  Transport,
} from '@bufbuild/connect';

import { BASE_URI, ENVIRONMENT } from '@/shared/config/config';
import { AssetService } from '@/shared/api/protocol-ts/api/core/svc_asset_connectweb.ts';

import { redirectToSignIn, delay } from '../lib';

import { Authentication } from './protocol-ts/api/auth/svc_authentication_connectweb';
import { UserService } from './protocol-ts/api/core/svc_user_connectweb';
import { AccessService } from './protocol-ts/api/core/svc_access_connectweb.ts';
import { PatientService } from './protocol-ts/api/core/svc_patient_connectweb.ts';
import { PatientTaskService } from './protocol-ts/api/core/svc_patient_task_connectweb.ts';
import { Billing } from './protocol-ts/api/billing_new/svc_billing_new_connectweb';
import { Notifications } from './protocol-ts/api/notifications/svc_notifications_connectweb.ts';
import { ReportService } from './protocol-ts/api/core/svc_report_connectweb.ts';
import { OrganizationService } from './protocol-ts/api/core/svc_organization_connectweb.ts';
import { StorageService } from './protocol-ts/api/core/svc_upload_connectweb.ts';
import { StudyService } from './protocol-ts/api/core/svc_study_connectweb.ts';
import { QuestionnaireService } from './protocol-ts/api/marketing/svc_questionnaire_connectweb.ts';
import { IntegrationService } from './protocol-ts/api/marketing/svc_integration_connectweb.ts';

// const logger: Interceptor = (next) => async (req) => {
//   const res = await next(req);
//   if (res.stream) {
//     // to intercept streaming response messages, we wrap
//     // the AsynchronousIterable with a generator function
//     return {
//       ...res,
//       message: logEach(res.message),
//     };
//   }
//   return res;
// };
// async function* logEach(stream: AsyncIterable<any>) {
//   for await (const m of stream) {
//     console.log('message received', m.Update.case);
//     yield m;
//   }
// }
const debug = ['staging', 'development'].includes(ENVIRONMENT);
const lockTimeout = 20 * 1e3;

class Refresher {
  authAPI: Auth;

  refreshTTL: number;

  accessTTL: number;

  isInited: boolean = false;

  constructor(authAPI: Auth) {
    this.authAPI = authAPI;
    this.refreshTTL = 0;
    this.accessTTL = 0;

    localStorage.setItem('refresh_lock', 'pass');
    // ensures that no dead-lock occurs
    setInterval(() => {
      const lock = localStorage.getItem('refresh_lock');
      if (lock === 'pass') {
        return;
      }

      if (new Date().getTime() - Number(lock) > lockTimeout) {
        localStorage.setItem('refresh_lock', 'pass');
      }
    }, 100);
  }

  async init() {
    if (this.isInited) {
      return;
    }

    const { AccessTokenTTL, RefreshTokenTTL } = await this.authAPI.getTTLs({});
    this.refreshTTL = Number(RefreshTokenTTL);
    this.accessTTL = Number(AccessTokenTTL); // leave 20% for the refresh
    this.isInited = true;
  }

  async refresh() {
    await this.init();

    const ts = Number(localStorage.getItem('refresh_last_at'));
    if (new Date().getTime() - ts > this.accessTTL * 0.8) {
      if (localStorage.getItem('refresh_lock') === 'pass') {
        localStorage.setItem('refresh_lock', new Date().getTime().toString());

        try {
          const { AccessTokenTTL, RefreshTokenTTL } =
            await this.authAPI.refresh({});
          this.refreshTTL = Number(RefreshTokenTTL);
          this.accessTTL = Number(AccessTokenTTL);
        } catch (e) {
          const parsedMessage = JSON.parse((e as APIError)?.rawMessage);

          if (
            parsedMessage.text === 'refresh token timed out' ||
            parsedMessage.text ===
              'no session was found for provided access token'
          ) {
            localStorage.removeItem('user');
            window.dispatchEvent(new Event('storage'));
            redirectToSignIn();
          }

          throw e;
        } finally {
          localStorage.setItem('refresh_lock', 'pass');
        }

        localStorage.setItem(
          'refresh_last_at',
          new Date().getTime().toString(),
        );
      } else {
        await delay(50);
        await this.refresh();
      }
    }
  }
}

const refreshTransport: Transport = createConnectTransport({
  baseUrl: BASE_URI || `https://${window.location.host}/`,
  useBinaryFormat: !(debug || false),
  credentials: 'include', // < Debug
});

type Auth = PromiseClient<typeof Authentication>;
// init auth service for refresh middleware
const auth = createPromiseClient(Authentication, refreshTransport);

const refresher = new Refresher(auth);

interface APIError {
  kind: string;
  rawMessage: string;
}
const refreshInterceptor: Interceptor = (next) => async (req) => {
  await refresher.refresh();
  const resp = await next(req);
  return resp;
};

const transport: Transport = createConnectTransport({
  baseUrl: BASE_URI || `https://${window.location.host}/`,
  useBinaryFormat: !(debug || false),
  credentials: 'include', // < Debug
  interceptors: [refreshInterceptor],
});

const api = {
  user: createPromiseClient(UserService, transport),
  access: createPromiseClient(AccessService, transport),
  patient: createPromiseClient(PatientService, transport),
  patientTask: createPromiseClient(PatientTaskService, transport),
  billing: createPromiseClient(Billing, transport),
  notifications: createPromiseClient(Notifications, transport),
  report: createPromiseClient(ReportService, transport),
  organization: createPromiseClient(OrganizationService, transport),
  // StorageServiceClientImpl contains the study entity
  storage: createPromiseClient(StorageService, transport),
  study: createPromiseClient(StudyService, transport),
  auth,
  assets: createPromiseClient(AssetService, transport),
  marketing: createPromiseClient(QuestionnaireService, transport),
  hubspot: createPromiseClient(IntegrationService, transport),

  refresher,
};

export type ApiServices = keyof typeof api;
export default api;
