import { Asset } from '@/shared/api/protocol-ts/model/dto_asset_pb';
import { FileMetadata_Image } from '@/shared/api/protocol-ts/model/dto_asset_common_pb';

import { Guide, GuideType } from '../config/medicalImages.types';

import { makeGuideLines } from './makeGuideLines';
import { getFileSrc } from './getFileSrc';

export const getGroupGuides = (groupItems: Asset[]) =>
  groupItems.reduce((medicalImages, asset) => {
    const generatedReportAsset =
      asset.Kind.case === 'GeneratedReport' ? asset.Kind.value : undefined;

    const medicalImage =
      generatedReportAsset?.Content.case === 'CBCTGPToothSlice'
        ? generatedReportAsset.Content.value
        : undefined;

    const guideFrontalImage =
      medicalImage?.Metadata.case === 'GuideFrontal'
        ? medicalImage.Metadata.value
        : undefined;

    const guideImage =
      medicalImage?.Metadata.case === 'Guide'
        ? medicalImage.Metadata.value
        : undefined;

    const guideImageFields = guideFrontalImage || guideImage;

    if (!guideImageFields) {
      return medicalImages;
    }

    const lines = makeGuideLines(
      generatedReportAsset?.MedicalImageFeatures?.Annotations?.Layers,
    );

    const guide = {
      ...guideImageFields,
      Lines: lines,
    };

    const medicalImageSize =
      medicalImage?.Slice?.Metadata.case === 'Image'
        ? medicalImage.Slice.Metadata.value
        : undefined;

    if (medicalImage?.Slice) {
      medicalImages.push({
        id: medicalImage?.Slice.ID,
        src: getFileSrc(medicalImage?.Slice.ID),
        image:
          medicalImageSize ?? ({ Width: 0n, Height: 0n } as FileMetadata_Image),
        guide: guide as GuideType,
      });
    }

    return medicalImages;
  }, [] as Guide[]);
