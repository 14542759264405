import { Decision } from '@/shared/api/protocol-ts/model/dto_report_common_pb';
import { ToothLandmark } from '@/shared/api/protocol-ts/model/dto_report_landmark_pb';
import { Condition } from '@/shared/api/protocol-ts/model/dto_report_condition_pb';

import { PBLLandmark, PblConditionWithChildren } from '../config';

import { getPBLColor } from './getPBLColor';

const findPositiveCondition = (condition: PblConditionWithChildren) =>
  condition.children.find(
    (childCond: Condition) =>
      childCond.Certainty?.ModelDecision === Decision.PositiveDecision,
  );

const getPblLowPoint = (
  landmarks: ToothLandmark[],
  condition: PblConditionWithChildren,
) =>
  landmarks.find(
    (landmark) =>
      landmark.ID ===
      condition.Localizations[0].PeriodontalBoneLossLandmarks
        ?.BoneLevelLandmarkID,
  );

const getPblUpPoint = (
  landmarks: ToothLandmark[],
  condition: PblConditionWithChildren,
) =>
  landmarks.find(
    (landmark) =>
      landmark.ID ===
      condition.Localizations[0]?.PeriodontalBoneLossLandmarks
        ?.CementoenamelJunctionLandmarkID,
  );

export const getPblLandmarksFromConditions = (
  pblConditions: PblConditionWithChildren[],
  toothLandmarks: ToothLandmark[],
) => {
  const result = pblConditions.reduce((landmarks, condition) => {
    const imageTargetAssetID = condition.Localizations[0].TargetAssetID; //
    const positiveChildCondition = findPositiveCondition(condition);
    const color = getPBLColor(positiveChildCondition);
    const lowPoint = getPblLowPoint(toothLandmarks, condition);
    const upPoint = getPblUpPoint(toothLandmarks, condition);

    if (lowPoint && upPoint) {
      landmarks.push({
        imageTargetAssetID,
        toothID:
          condition.Attribution.case === 'Tooth'
            ? condition.Attribution.value.ToothID
            : '',
        lowPoint,
        upPoint,
        color,
      });
    }

    return landmarks;
  }, [] as PBLLandmark[]);

  return result;
};
