import { ReportType } from '@/shared/api/protocol-ts/model/dto_report_pb';
import { PATHS } from '@/shared/config';

export const getReportPath = (reportType: ReportType) => {
  const basePath = PATHS.patient + '/' + PATHS.report + '/';

  switch (reportType) {
    case ReportType.ReportType_CBCT_GP:
    case ReportType.ReportType_DentalPhoto_GP: {
      return basePath + PATHS.gp;
    }
    case ReportType.ReportType_IOXRay_GP: {
      return basePath + PATHS.ioxRay;
    }
    case ReportType.ReportType_Pano_GP: {
      return basePath + PATHS.pano;
    }
    case ReportType.ReportType_Pano_Bitewings: {
      return basePath + PATHS.bitewings;
    }
    case ReportType.ReportType_CBCT_Endo: {
      return PATHS.endodonticReport;
    }
    case ReportType.ReportType_CBCT_Implant: {
      return PATHS.implantReport;
    }
    case ReportType.ReportType_CBCT_Molar: {
      return PATHS.molarReport;
    }
    case ReportType.ReportType_IOS_Segmentation:
    case ReportType.ReportType_CBCT_Segmentation: {
      return PATHS.segmentationReport;
    }
    default: {
      return '';
    }
  }
};
