// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_asset_document.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { DerivedData_Image, File } from "./dto_asset_common_pb.js";

/**
 * @generated from message com.diagnocat.model.AssetContent_Document
 */
export const AssetContent_Document = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Document",
  () => [
    { no: 1, name: "Document", kind: "message", T: File },
    { no: 2, name: "Image", kind: "message", T: DerivedData_Image, oneof: "Derived" },
    { no: 3, name: "PDF", kind: "message", T: AssetContent_Document_DerivedData_PDF, oneof: "Derived" },
    { no: 4, name: "Doc", kind: "message", T: AssetContent_Document_DerivedData_DOC, oneof: "Derived" },
    { no: 5, name: "Video", kind: "message", T: AssetContent_Document_DerivedData_Video, oneof: "Derived" },
    { no: 6, name: "Archive", kind: "message", T: AssetContent_Document_DerivedData_Archive, oneof: "Derived" },
    { no: 7, name: "Text", kind: "message", T: AssetContent_Document_DerivedData_Text, oneof: "Derived" },
  ],
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Document.DerivedData_PDF
 */
export const AssetContent_Document_DerivedData_PDF = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Document.DerivedData_PDF",
  [],
  {localName: "AssetContent_Document_DerivedData_PDF"},
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Document.DerivedData_DOC
 */
export const AssetContent_Document_DerivedData_DOC = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Document.DerivedData_DOC",
  [],
  {localName: "AssetContent_Document_DerivedData_DOC"},
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Document.DerivedData_Video
 */
export const AssetContent_Document_DerivedData_Video = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Document.DerivedData_Video",
  [],
  {localName: "AssetContent_Document_DerivedData_Video"},
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Document.DerivedData_Archive
 */
export const AssetContent_Document_DerivedData_Archive = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Document.DerivedData_Archive",
  [],
  {localName: "AssetContent_Document_DerivedData_Archive"},
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Document.DerivedData_Text
 */
export const AssetContent_Document_DerivedData_Text = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Document.DerivedData_Text",
  [],
  {localName: "AssetContent_Document_DerivedData_Text"},
);

