// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file api/marketing/svc_integration.proto (package com.diagnocat.marketing, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message com.diagnocat.marketing.GetChatIdentificationTokenReq
 */
export const GetChatIdentificationTokenReq = proto3.makeMessageType(
  "com.diagnocat.marketing.GetChatIdentificationTokenReq",
  [],
);

/**
 * @generated from message com.diagnocat.marketing.GetChatIdentificationTokenResp
 */
export const GetChatIdentificationTokenResp = proto3.makeMessageType(
  "com.diagnocat.marketing.GetChatIdentificationTokenResp",
  () => [
    { no: 1, name: "Token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

