import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OrganizationOnboardingQuestionnaire_V1 } from '@/shared/api/protocol-ts/api/marketing/dto_questionnaire_pb';
import { SliceName } from '@/shared/config';

import { MarketingInitialState } from '../config/types';

const initialState: MarketingInitialState = {
  questionnaire: new OrganizationOnboardingQuestionnaire_V1(),
  loading: 'idle',
};

const marketingSlice = createSlice({
  name: SliceName.marketing,
  initialState,
  reducers: {
    updateQuestionnaire: (
      state,
      action: PayloadAction<OrganizationOnboardingQuestionnaire_V1>,
    ) => {
      state.questionnaire = action.payload;
    },
  },
});

export const { actions } = marketingSlice;

export default marketingSlice.reducer;
