import { Patient } from '@/shared/api/protocol-ts/model/dto_patient_pb';
import { PatientPermissions } from '@/shared/api/protocol-ts/model/dto_patient_permissions_pb';

export const getPatientYourPermissions = (
  patient?: Patient,
): PatientPermissions => {
  const permissions = patient?.YourPermissions;

  const patientPermissions = new PatientPermissions();
  patientPermissions.CanUploadDentalPhoto =
    permissions?.CanUploadDentalPhoto ?? false;
  patientPermissions.CanViewStudies = permissions?.CanViewStudies ?? false;
  patientPermissions.CanUploadPanoramicXRay =
    permissions?.CanUploadPanoramicXRay ?? false;
  patientPermissions.CanUploadIOXRay = permissions?.CanUploadIOXRay ?? false;
  patientPermissions.CanViewReports = permissions?.CanViewReports ?? false;
  patientPermissions.CanUploadCBCT = permissions?.CanUploadCBCT ?? false;
  patientPermissions.CanUploadDocuments =
    permissions?.CanUploadDocuments ?? false;
  patientPermissions.CanUploadSTL = permissions?.CanUploadSTL ?? false;
  patientPermissions.CanRequestReport = permissions?.CanRequestReport ?? false;
  patientPermissions.CanEditPersonalData =
    permissions?.CanEditPersonalData ?? false;
  patientPermissions.CanViewPersonalData =
    permissions?.CanViewPersonalData ?? false;
  patientPermissions.CanView = permissions?.CanView ?? false;
  patientPermissions.CanEditPatient = permissions?.CanEditPatient ?? false;
  patientPermissions.CanViewTeam = permissions?.CanViewTeam ?? false;
  patientPermissions.CanEditTeam = permissions?.CanEditTeam ?? false;
  patientPermissions.CanViewSharings = permissions?.CanViewSharings ?? false;
  patientPermissions.CanShare = permissions?.CanShare ?? false;
  patientPermissions.CanCancelOthersSharings =
    permissions?.CanCancelOthersSharings ?? false;
  patientPermissions.CanViewClinicalCaseDescription =
    permissions?.CanViewClinicalCaseDescription ?? false;
  patientPermissions.CanEditClinicalCaseDescription =
    permissions?.CanEditClinicalCaseDescription ?? false;
  patientPermissions.CanViewTasks = permissions?.CanViewTasks ?? false;
  patientPermissions.CanEditTasks = permissions?.CanEditTasks ?? false;

  return patientPermissions;
};
