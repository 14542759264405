import api from '@/shared/api/api';
import {
  OrganizationOnboardingQuestionnaireReq_V1,
  OrganizationOnboardingQuestionnaireResp_V1,
  PutOrganizationOnboardingQuestionnaireReq_V1,
  PutOrganizationOnboardingQuestionnaireResp_V1,
} from '@/shared/api/protocol-ts/api/marketing/svc_questionnaire_pb';
import { QuestionnaireService } from '@/shared/api/protocol-ts/api/marketing/svc_questionnaire_connectweb';
import { SliceName } from '@/shared/config';
import { createThunkGenerator } from '@/shared/lib';

type MarketingRequests = keyof typeof QuestionnaireService.methods;

const generateMarketingThunk = createThunkGenerator<MarketingRequests>(
  SliceName.marketing,
);

export const getOrganizationOnboardingQuestionnaire = generateMarketingThunk<
  OrganizationOnboardingQuestionnaireReq_V1,
  OrganizationOnboardingQuestionnaireResp_V1
>(
  'getOrganizationOnboardingQuestionnaire_V1',
  api.marketing.getOrganizationOnboardingQuestionnaire_V1,
);

export const putOrganizationOnboardingQuestionnaire = generateMarketingThunk<
  PutOrganizationOnboardingQuestionnaireReq_V1,
  PutOrganizationOnboardingQuestionnaireResp_V1
>(
  'putOrganizationOnboardingQuestionnaire_V1',
  api.marketing.putOrganizationOnboardingQuestionnaire_V1,
);
