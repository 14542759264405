// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_asset_common.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { Empty, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum com.diagnocat.model.CBCT_SectionOrientation
 */
export const CBCT_SectionOrientation = proto3.makeEnum(
  "com.diagnocat.model.CBCT_SectionOrientation",
  [
    {no: 0, name: "SectionOrientationInvalidValue"},
    {no: 1, name: "SectionOrientationAxial"},
    {no: 2, name: "SectionOrientationVestibuloOral"},
    {no: 3, name: "SectionOrientationMesioDistal"},
  ],
);

/**
 * @generated from enum com.diagnocat.model.CBCT_SliceOrientation
 */
export const CBCT_SliceOrientation = proto3.makeEnum(
  "com.diagnocat.model.CBCT_SliceOrientation",
  [
    {no: 0, name: "SliceOrientationInvalidValue"},
    {no: 1, name: "SliceOrientationMesial"},
    {no: 2, name: "SliceOrientationDistal"},
    {no: 3, name: "SliceOrientationLingual"},
    {no: 4, name: "SliceOrientationBuccal"},
  ],
);

/**
 * @generated from message com.diagnocat.model.File
 */
export const File = proto3.makeMessageType(
  "com.diagnocat.model.File",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "Size", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "MIME", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "CompressSize", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 101, name: "NoMetadata", kind: "message", T: Empty, oneof: "Metadata" },
    { no: 102, name: "Image", kind: "message", T: FileMetadata_Image, oneof: "Metadata" },
  ],
);

/**
 * @generated from message com.diagnocat.model.FileMetadata_Image
 */
export const FileMetadata_Image = proto3.makeMessageType(
  "com.diagnocat.model.FileMetadata_Image",
  () => [
    { no: 1, name: "Width", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "Height", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * generated by backend if not emitted by pipelines, otherwise by pipelines
 * specification: https://diagnocat.atlassian.net/l/cp/c09mQHCk
 *
 * @generated from message com.diagnocat.model.DerivedData_Image
 */
export const DerivedData_Image = proto3.makeMessageType(
  "com.diagnocat.model.DerivedData_Image",
  () => [
    { no: 1, name: "Thumbnail", kind: "message", T: File },
    { no: 2, name: "Preview", kind: "message", T: File },
  ],
);

/**
 * generated by backend if not emitted by pipelines, otherwise by pipelines
 * specification: https://diagnocat.atlassian.net/l/cp/c09mQHCk
 *
 * @generated from message com.diagnocat.model.DerivedData_3DModel
 */
export const DerivedData_3DModel = proto3.makeMessageType(
  "com.diagnocat.model.DerivedData_3DModel",
  () => [
    { no: 1, name: "FrontalPreview", kind: "message", T: File },
    { no: 2, name: "LeftPreview", kind: "message", T: File },
    { no: 3, name: "RightPreview", kind: "message", T: File },
    { no: 4, name: "FrontalThumbnail", kind: "message", T: File },
    { no: 5, name: "LeftThumbnail", kind: "message", T: File },
    { no: 6, name: "RightThumbnail", kind: "message", T: File },
    { no: 7, name: "RotatingPreview", kind: "message", T: File },
  ],
);

