import { GeneratedReportAsset } from '@/shared/api/protocol-ts/model/dto_asset_pb';

export const getGeneratedReportOrder = (
  generatedReportAsset: GeneratedReportAsset | undefined,
) => {
  if (
    generatedReportAsset?.Content.case === 'CBCTGPToothSlice' &&
    generatedReportAsset.Content.value.Metadata.case === 'Regular' &&
    generatedReportAsset.Content.value.Metadata.value.Order
  ) {
    return generatedReportAsset.Content.value.Metadata.value.Order;
  }
};
