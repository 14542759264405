import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';

import { ToothLandmark } from '@/shared/api/protocol-ts/model/dto_report_landmark_pb';
import {
  DefaultInitialState,
  LoadingStateType,
  SliceName,
} from '@/shared/config';

export const toothLandmarksAdapter = createEntityAdapter<ToothLandmark>({
  selectId: (toothLandmark: ToothLandmark) => toothLandmark.ID,
});

const initialStateFlags: DefaultInitialState = {
  loading: 'idle',
};

const toothLandmarksState =
  toothLandmarksAdapter.getInitialState(initialStateFlags);

type ToothLandmarksState = typeof toothLandmarksState;

const toothLandmarksSlice = createSlice({
  name: SliceName.toothLandmarks,
  initialState: toothLandmarksState,
  reducers: {
    addOne: (state, action: PayloadAction<ToothLandmark>) => {
      toothLandmarksAdapter.addOne(
        state as ToothLandmarksState,
        action.payload,
      );
    },
    addMany: (state, action: PayloadAction<ToothLandmark[]>) => {
      toothLandmarksAdapter.addMany(
        state as ToothLandmarksState,
        action.payload,
      );
    },
    setOne: (state, action: PayloadAction<ToothLandmark>) => {
      toothLandmarksAdapter.setOne(
        state as ToothLandmarksState,
        action.payload,
      );
    },
    setNewestOne: (state, action: PayloadAction<ToothLandmark>) => {
      const currentRevisionNumber =
        state.entities[action.payload.ID]?.Revision?.Number ?? 0;
      const payloadRevisionNumber = action.payload.Revision?.Number ?? 0;

      if (payloadRevisionNumber > currentRevisionNumber) {
        toothLandmarksAdapter.setOne(
          state as ToothLandmarksState,
          action.payload,
        );
      }
    },
    setMany: (state, action: PayloadAction<ToothLandmark[]>) => {
      toothLandmarksAdapter.setMany(
        state as ToothLandmarksState,
        action.payload,
      );
    },
    removeOne: (state, action: PayloadAction<string>) => {
      toothLandmarksAdapter.removeOne(
        state as ToothLandmarksState,
        action.payload,
      );
    },
    removeAll: (state) => {
      toothLandmarksAdapter.removeAll(state as ToothLandmarksState);
    },
    setLoading: (state, action: PayloadAction<LoadingStateType>) => {
      state.loading = action.payload;
    },
  },
});

export const { actions } = toothLandmarksSlice;

export default toothLandmarksSlice.reducer;
