import { User } from '@/shared/api/protocol-ts/model/dto_user_pb';

import { RootState } from '@/app/model/store';

export const selectCurrentUser = (state: RootState): User => state.user.user;

export const selectCurrentUserID = (state: RootState): string =>
  state.user.user.ID;

export const selectUserOrganizationLegacy = (state: RootState) =>
  state.user.userOrganizationLegacy;

export const selectSettings = (state: RootState) => state.user.settings;

export const selectUserLocale = (state: RootState) => state.user.locale;

export const selectLoading = (state: RootState) => state.user.loading;
