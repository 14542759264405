import { FC, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { Condition } from '@/shared/api/protocol-ts/model/dto_report_condition_pb';
import { AbsoluteBlock } from '@/shared/graphics/RenderComponents/AbsoluteBlock';
import { RCImage } from '@/shared/graphics/RenderComponents/RCImage/RCImage';
import { RCLocalizations } from '@/shared/graphics/RenderComponents/RCLocalizations/RCLocalizations';
import { RCContainer } from '@/shared/graphics/RenderComponents/RCContainer/RCContainer';

import { conditionText } from '@/entities/condition/config/i18n';
import { getImageSrc } from '@/entities/assets';

import { ContextRequest } from 'graphics';

import styles from '../NonDentalFindings.module.scss';

type NonDentalFindingsProps = {
  conditions: Condition[];
  className?: string;
};

export const RarePathologies: FC<NonDentalFindingsProps> = (props) => {
  const { className, conditions } = props;

  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [hoveredConditionID, setHoveredConditionID] = useState('');

  const containerRef = useRef<HTMLDivElement>(null);

  const { formatMessage } = useIntl();

  const localizations = conditions.flatMap((condition) =>
    condition.Localizations.map(({ BBox }) => ({
      conditionID: condition.ID,
      xmin: BBox?.X?.Min ?? 0,
      ymin: BBox?.Y?.Min ?? 0,
      xmax: BBox?.X?.Max ?? 0,
      ymax: BBox?.Y?.Max ?? 0,
    })),
  );

  const handleConditionHover = (id: string) => {
    setHoveredConditionID(id);
  };

  const imageSrc = {
    url: getImageSrc(conditions[0]?.Localizations[0].TargetAssetID, 'original'),
    kind: 'dicom',
  };

  const hoveredLocalizations = localizations.filter(
    (localization) =>
      hoveredConditionID && localization.conditionID === hoveredConditionID,
  );

  useEffect(() => {
    if (containerRef.current) {
      const aspect = containerRef.current.clientWidth / imageSize.width;
      const height = imageSize.height * aspect;

      setContainerHeight(height);
    }
  }, [containerRef.current, imageSize]);

  return (
    <div className={className}>
      <RCContainer
        ref={containerRef}
        className={styles.pano}
        style={{
          width: '100%',
          height: containerHeight,
          borderRadius: '16px',
          overflow: 'hidden',
          marginBlock: '16px',
        }}
      >
        <AbsoluteBlock>
          <RCImage
            src={imageSrc as ContextRequest}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
            onLoaded={(context) => {
              setImageSize({
                width: context.width,
                height: context.height,
              });
            }}
          />
        </AbsoluteBlock>
        <AbsoluteBlock>
          <RCLocalizations
            detections={
              hoveredConditionID ? hoveredLocalizations : localizations
            }
            imageSize={imageSize}
            isNonDentalFindings
          />
        </AbsoluteBlock>
      </RCContainer>

      {conditions.map((condition) => (
        <p
          key={condition.ID}
          className={styles.condition}
          onMouseEnter={() => handleConditionHover(condition.ID)}
          onMouseLeave={() => handleConditionHover('')}
        >
          {formatMessage(conditionText[condition.Code])}
        </p>
      ))}
    </div>
  );
};
