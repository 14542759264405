import axios from 'axios';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { replace } from 'ramda';
import { generatePath } from 'react-router';

import {
  BASE_URI,
  FORWARD_SLASH_REGEX,
  PATHS,
  WHITE_SPACE_REGEX,
} from '@/shared/config';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { ReportType } from '@/shared/api/protocol-ts/model/dto_report_pb';

import { i18n, reportsModel } from '@/entities/reports';
import { patientModel } from '@/entities/patient';
import { authModel } from '@/entities/auth';

const DEBUG_TYPEWRITER_LOCALLY = false;

// NOTE: [2/l] needs refactoring, patientID and reportID should be obtained inside the hook
export const useDownloadPDF_DEPRECATED = (
  patientID: string,
  reportID: string,
): {
  downloadPDF: () => Promise<void>;
  isLoadingPDF: boolean;
} => {
  const dispatch = useAppDispatch();

  const { formatMessage, formatDate } = useIntl();

  const [isLoadingPDF, setIsLoadingPDF] = useState<boolean>(false);

  const currentPatient = useAppSelector(
    patientModel.selectors.selectPatientByID(patientID),
  );

  const currentReport = useAppSelector(
    reportsModel.selectors.selectByID(reportID),
  );

  const patientFullName = `${currentPatient?.PersonalData?.LastName}_${currentPatient?.PersonalData?.FirstName}`;

  const reportType = replace(
    WHITE_SPACE_REGEX,
    '_',
    formatMessage(i18n[currentReport?.Type ?? ReportType.ReportType_CBCT_GP]),
  );

  const reportCreationDate = replace(
    FORWARD_SLASH_REGEX,
    '',
    formatDate(currentReport?.Created?.At?.toDate() ?? 0, {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    }),
  );

  const pathname = generatePath(PATHS.previewReport, { patientID, reportID });

  const fileName = `${patientFullName}_${reportType}_${reportCreationDate}.pdf`;

  const getTypewriterUrl = async () => {
    if (DEBUG_TYPEWRITER_LOCALLY) {
      const localHost = 'http://localhost:3001';
      const typeWriter = 'http://localhost:3003';
      const encode = encodeURIComponent(localHost + pathname);
      return `${typeWriter}/typewriter?url=${encode}`;
    } else {
      await dispatch(authModel.thunks.refresh({}));
      const encode = encodeURIComponent(BASE_URI + pathname);
      return `${BASE_URI}/typewriter?url=${encode}`;
    }
  };

  const getAxiosConfig = () => ({
    withCredentials: !DEBUG_TYPEWRITER_LOCALLY,
    responseType: 'blob' as const,
    headers: {
      Accept: 'application/pdf',
    },
  });

  const downloadPDF = async () => {
    setIsLoadingPDF(true);

    try {
      const url = await getTypewriterUrl();
      const config = getAxiosConfig();

      const { data } = await axios.get(url, config);

      const blob = new Blob([data], { type: 'application/pdf' });

      const downloadLink = Object.assign(document.createElement('a'), {
        href: window.URL.createObjectURL(blob),
        download: fileName,
      });

      downloadLink.click();
    } finally {
      setIsLoadingPDF(false);
    }
  };

  return {
    downloadPDF,
    isLoadingPDF,
  };
};
