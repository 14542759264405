// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_report_tooth.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { ToothNumeration } from "./dto_report_common_pb.js";
import { Localization } from "./dto_report_localization_pb.js";
import { Created, Deleted, Revision } from "./dto_base_pb.js";
import { ToothPeriodontalStatus } from "./dto_report_periodontal_status_pb.js";
import { ToothAnatomyLocalization } from "./dto_report_tooth_anatomy_localization_pb.js";
import { MedicalImageFeatures } from "./dto_common_image_medical_features_pb.js";

/**
 *  These teeth are "real" teeth in a patient's mouth. They have ISO numbers, and everyone knows they're real.
 *
 * @generated from message com.diagnocat.model.Tooth
 */
export const Tooth = proto3.makeMessageType(
  "com.diagnocat.model.Tooth",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ReportID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "PatientID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "Numeration", kind: "message", T: ToothNumeration },
    { no: 11, name: "Comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "IsInROI", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 21, name: "IsApproved", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 22, name: "IsControl", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 23, name: "IsLocalized", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 30, name: "Localizations", kind: "message", T: Localization, repeated: true },
    { no: 31, name: "DisplaySlices", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 32, name: "Detections", kind: "message", T: ToothDetection, repeated: true },
    { no: 101, name: "Completed", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "Status" },
    { no: 102, name: "InProgress", kind: "message", T: ToothProgress, oneof: "Status" },
    { no: 10030, name: "Created", kind: "message", T: Created },
    { no: 10040, name: "Revision", kind: "message", T: Revision },
    { no: 10050, name: "Deleted", kind: "message", T: Deleted },
  ],
);

/**
 * tooth detection in one image in different modalities
 *
 * @generated from message com.diagnocat.model.ToothDetection
 */
export const ToothDetection = proto3.makeMessageType(
  "com.diagnocat.model.ToothDetection",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Localizations", kind: "message", T: Localization },
    { no: 3, name: "PeriodontalStatuses", kind: "message", T: ToothPeriodontalStatus, repeated: true },
    { no: 4, name: "Anatomies", kind: "message", T: ToothAnatomyLocalization, repeated: true },
    { no: 21, name: "CropMedicalImageFeatures", kind: "message", T: MedicalImageFeatures },
  ],
);

/**
 * @generated from message com.diagnocat.model.ToothProgress
 */
export const ToothProgress = proto3.makeMessageType(
  "com.diagnocat.model.ToothProgress",
  () => [
    { no: 1, name: "ConditionsCompleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "SlicesUploaded", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

