import { Asset } from '@/shared/api/protocol-ts/model/dto_asset_pb';

import { Group, GroupConfigsType } from '../config/medicalImages.types';

import { getGroupGuides } from './getGroupGuides';
import { getGroupMedicalImages } from './getGroupMedicalImages';

export const makeGroupByOrientation = (
  groupConfigs: GroupConfigsType,
  sliceList: Asset[],
): Group => {
  const { groupName, groupCode } = groupConfigs;
  const groupItems = sliceList?.filter((item) => {
    if (item.Kind.case === 'GeneratedReport') {
      if (item.Kind.value.Content.case === 'CBCTGPToothSlice') {
        return item.Kind.value.Content.value.Orientation === groupCode;
      }
    }
  });

  const guides = getGroupGuides(groupItems);
  const medicalImages = getGroupMedicalImages(groupItems, groupName);

  return {
    type: groupName,
    medicalImages,
    guides,
  };
};
