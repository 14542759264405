import { compose, filter, propEq, sortBy } from 'ramda';

import {
  Report,
  ReportType,
} from '@/shared/api/protocol-ts/model/dto_report_pb';

const filterReportsByPatientID = (patientID: string) => (reports: Report[]) =>
  filter<Report>(propEq('PatientID', patientID))(reports);

const filterReportsByEnabledReportTypes = (
  enabledAsRecentTypes: ReportType[],
) => filter<Report>((report) => enabledAsRecentTypes.includes(report.Type));

const sortByRevisionUpdatedAt = sortBy<Report>(
  (a) => Number(a?.Revision?.UpdatedAt ?? 0) * -1, // desc sorting
);

export const getPatientRecentReports = (
  reports: Report[],
  patientID: string,
  enabledAsRecentTypes: ReportType[],
) =>
  compose(
    sortByRevisionUpdatedAt,
    filterReportsByEnabledReportTypes(enabledAsRecentTypes),
    filterReportsByPatientID(patientID),
  )(reports);
