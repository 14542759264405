// @generated by protoc-gen-connect-web v0.6.0 with parameter "target=ts,ts_nocheck=false"
// @generated from file api/marketing/svc_integration.proto (package com.diagnocat.marketing, syntax proto3)
/* eslint-disable */

import { GetChatIdentificationTokenReq, GetChatIdentificationTokenResp } from "./svc_integration_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service com.diagnocat.marketing.IntegrationService
 */
export const IntegrationService = {
  typeName: "com.diagnocat.marketing.IntegrationService",
  methods: {
    /**
     * @generated from rpc com.diagnocat.marketing.IntegrationService.GetChatIdentificationToken
     */
    getChatIdentificationToken: {
      name: "GetChatIdentificationToken",
      I: GetChatIdentificationTokenReq,
      O: GetChatIdentificationTokenResp,
      kind: MethodKind.Unary,
    },
  }
} as const;

