import api from '@/shared/api/api';
import {
  CreatePatientReq,
  CreatePatientResp,
  DeletePatientReq,
  DeletePatientResp,
  SetPatientExternalIDReq,
  SetPatientExternalIDResp,
  SetPatientGenderReq,
  SetPatientGenderResp,
  SetPatientPersonalDataReq,
  SetPatientPersonalDataResp,
  SetPatientStatusReq,
  SetPatientStatusResp,
} from '@/shared/api/protocol-ts/api/core/svc_patient_pb';
import { createThunkGenerator } from '@/shared/lib';
import { SliceName } from '@/shared/config';
import { PatientService } from '@/shared/api/protocol-ts/api/core/svc_patient_connectweb';

type PatientRequests = keyof typeof PatientService.methods;

const generatePatientThunk = createThunkGenerator<PatientRequests>(
  SliceName.patient,
);

export const createPatient = generatePatientThunk<
  CreatePatientReq,
  CreatePatientResp
>('createPatient', (request) => {
  // Ensure TreatingDoctorIDs is an array of strings
  if (request.TreatingDoctorIDs) {
    request.TreatingDoctorIDs = request.TreatingDoctorIDs.filter(
      (id): id is string => id !== undefined,
    ) as string[];
  }
  return api.patient.createPatient(request as Partial<CreatePatientReq>);
});

export const deletePatient = generatePatientThunk<
  DeletePatientReq,
  DeletePatientResp
>('deletePatient', api.patient.deletePatient);

export const setPatientPersonalData = generatePatientThunk<
  SetPatientPersonalDataReq,
  SetPatientPersonalDataResp
>('setPatientPersonalData', api.patient.setPatientPersonalData);

export const setPatientExternalID = generatePatientThunk<
  SetPatientExternalIDReq,
  SetPatientExternalIDResp
>('setPatientExternalID', api.patient.setPatientExternalID);

export const setPatientGender = generatePatientThunk<
  SetPatientGenderReq,
  SetPatientGenderResp
>('setPatientGender', api.patient.setPatientGender);

export const setPatientStatus = generatePatientThunk<
  SetPatientStatusReq,
  SetPatientStatusResp
>('setPatientStatus', api.patient.setPatientStatus);
