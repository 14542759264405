// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_report_maxfax_anatomy_localization.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { Localization } from "./dto_report_localization_pb.js";
import { IOXRay_Anatomy, Pano_Anatomy } from "./dto_report_type_2D_common_pb.js";
import { CBCT_Anatomy } from "./dto_common_anatomy_pb.js";

/**
 * @generated from message com.diagnocat.model.MaxFaxAnatomyLocalization
 */
export const MaxFaxAnatomyLocalization = proto3.makeMessageType(
  "com.diagnocat.model.MaxFaxAnatomyLocalization",
  () => [
    { no: 1, name: "Localization", kind: "message", T: Localization },
    { no: 2, name: "IOXRay", kind: "enum", T: proto3.getEnumType(IOXRay_Anatomy), oneof: "Type" },
    { no: 3, name: "Pano", kind: "enum", T: proto3.getEnumType(Pano_Anatomy), oneof: "Type" },
    { no: 4, name: "CBCT", kind: "enum", T: proto3.getEnumType(CBCT_Anatomy), oneof: "Type" },
  ],
);

