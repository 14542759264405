import React, { ChangeEvent } from 'react';
import RcSelect, { Props } from 'react-select';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { ErrorText } from '../ErrorText/ErrorText';
import { InputLabel } from '../InputLabel/InputLabel';

import { selectComponents } from './Select.components';
import { OptionsType, OptionType, ValueType } from './Select.types';
import { selectStyles } from './Select.styles';
import styles from './Select.module.scss';

export type SelectProps<CustomOptionType = OptionType> = Omit<
  Props,
  'onChange' | 'placeholder' | 'options'
> & {
  className?: string;
  testID?: string;
  options: CustomOptionType[];
  onChange: (newValue: ChangeEvent | ValueType) => void;
  onBlur?: () => void;
  value?: ValueType;
  placeholder?: string;
  name?: string;
  label?: string | JSX.Element;
  error?: string;
  noOptionsMessage?: string | JSX.Element;
  isDisabled?: boolean;
  isRequired?: boolean;
  defaultValue?: string;
  isClearable?: boolean;
};

export { getStudySelectStyles } from './utils/getStudySelectStyles';
// TODO [8/l] Fix types
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const InnerSelect: React.ForwardRefRenderFunction<
  HTMLInputElement,
  SelectProps
> = <CustomOptionType extends OptionType = OptionType>(
  props: SelectProps<CustomOptionType>,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  // eslint-disable-next-line
  ref: React.RefObject<any>,
) => {
  const {
    className,
    testID,
    name,
    value,
    styles: customSelectStyles,
    error = '',
    label = '',
    options,
    placeholder = (
      <FormattedMessage id="global.select" defaultMessage="Select..." />
    ),
    onChange,
    isSearchable = true,
    noOptionsMessage = (
      <FormattedMessage id="global.noData" defaultMessage="No data..." />
    ),
    isRtl,
    isDisabled,
    isMulti,
    defaultValue,
    onBlur,
    isRequired,
    components,
    isClearable = false,
    ...restSelectProps
  } = props;

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      <InputLabel label={label} required={isRequired} />
      <RcSelect
        ref={ref}
        name={name}
        isSearchable={isSearchable}
        menuPlacement="auto"
        aria-invalid={!isDisabled && Boolean(error)}
        defaultValue={options.find((option) => option.value === defaultValue)}
        styles={{ ...selectStyles, ...customSelectStyles }}
        value={
          isMulti
            ? options.filter((option) =>
                (value as ValueType[])?.includes(option.value),
              )
            : options.find((option) => option.value === value)
        }
        isRtl={isRtl}
        onChange={(newValue, actionMeta) => {
          if (actionMeta.action === 'clear') {
            onChange(null);
            return;
          }
          if (isMulti) {
            onChange((newValue as OptionsType).map((option) => option.value));
          } else {
            onChange((newValue as OptionType).value);
          }
        }}
        onBlur={onBlur}
        placeholder={placeholder}
        options={options}
        isDisabled={isDisabled}
        isMulti={isMulti}
        noOptionsMessage={() => noOptionsMessage}
        isClearable={isClearable}
        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        components={{
          ...selectComponents,
          ...components,
        }}
        {...restSelectProps}
      />
      <ErrorText error={error} withInput />
    </div>
  );
};

export const Select = React.forwardRef(InnerSelect);
