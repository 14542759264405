import { Condition } from '@/shared/api/protocol-ts/model/dto_report_condition_pb';

const isPblCondition = (condition: Condition) =>
  condition.Localizations.length &&
  condition.Localizations.some(
    (localization) => localization.PeriodontalBoneLossLandmarks,
  );

export const filterPblConditions = (conditions: Condition[]) =>
  conditions.filter(isPblCondition);
