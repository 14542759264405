// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file api/messenger/dto_message.proto (package com.diagnocat.messenger, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message com.diagnocat.messenger.Message
 */
export const Message = proto3.makeMessageType(
  "com.diagnocat.messenger.Message",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Time", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "Sender", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "Links", kind: "message", T: Link, repeated: true },
    { no: 5, name: "Content", kind: "message", T: Content, repeated: true },
    { no: 6, name: "Service", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "Edited", kind: "message", T: MessageEdited },
  ],
);

/**
 * @generated from message com.diagnocat.messenger.MessageEdited
 */
export const MessageEdited = proto3.makeMessageType(
  "com.diagnocat.messenger.MessageEdited",
  () => [
    { no: 1, name: "Edited", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "Editor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "Time", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.messenger.Link
 */
export const Link = proto3.makeMessageType(
  "com.diagnocat.messenger.Link",
  () => [
    { no: 1, name: "EntityType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "EntityID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "LinkType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.messenger.Content
 */
export const Content = proto3.makeMessageType(
  "com.diagnocat.messenger.Content",
  () => [
    { no: 1, name: "Service", kind: "message", T: ContentService, oneof: "Part" },
    { no: 2, name: "Text", kind: "message", T: ContentText, oneof: "Part" },
    { no: 3, name: "Mention", kind: "message", T: ContentMention, oneof: "Part" },
  ],
);

/**
 * @generated from message com.diagnocat.messenger.ContentService
 */
export const ContentService = proto3.makeMessageType(
  "com.diagnocat.messenger.ContentService",
  [],
);

/**
 * @generated from message com.diagnocat.messenger.ContentText
 */
export const ContentText = proto3.makeMessageType(
  "com.diagnocat.messenger.ContentText",
  () => [
    { no: 1, name: "Text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.messenger.ContentMention
 */
export const ContentMention = proto3.makeMessageType(
  "com.diagnocat.messenger.ContentMention",
  () => [
    { no: 1, name: "Kind", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "Name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

