// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_report_type_cbct_ios_superimposition.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { Report_CBCT_IOS_Segment } from "./dto_report_common_pb.js";
import { Segmentron_PhotoIOSSuperimposition } from "./dto_report_segmentron_common_pb.js";
import { ToothLandmark } from "./dto_report_landmark_pb.js";
import { Report_Studio_ToothPosition } from "./dto_report_type_studio_common_pb.js";
import { ToothPeriodontalStatus } from "./dto_report_periodontal_status_pb.js";

/**
 * @generated from enum com.diagnocat.model.Report_CBCT_IOS_Superimposition_Orientation
 */
export const Report_CBCT_IOS_Superimposition_Orientation = proto3.makeEnum(
  "com.diagnocat.model.Report_CBCT_IOS_Superimposition_Orientation",
  [
    {no: 0, name: "CBCT_IOS_Superimposition_Orientation_CBCT"},
    {no: 1, name: "CBCT_IOS_Superimposition_Orientation_IOS"},
    {no: 2, name: "CBCT_IOS_Superimposition_Orientation_Zero_index"},
  ],
);

/**
 * @generated from message com.diagnocat.model.Report_CBCT_IOS_Superimposition
 */
export const Report_CBCT_IOS_Superimposition = proto3.makeMessageType(
  "com.diagnocat.model.Report_CBCT_IOS_Superimposition",
  () => [
    { no: 1, name: "Orientation", kind: "enum", T: proto3.getEnumType(Report_CBCT_IOS_Superimposition_Orientation) },
    { no: 2, name: "AlignmentSuccessful", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "Segments", kind: "message", T: Report_CBCT_IOS_Segment, repeated: true },
    { no: 4, name: "PhotoIOSSuperimpositions", kind: "message", T: Segmentron_PhotoIOSSuperimposition, repeated: true },
    { no: 5, name: "TeethLandmarks", kind: "message", T: ToothLandmark, repeated: true },
    { no: 6, name: "TeethPositions", kind: "message", T: Report_Studio_ToothPosition, repeated: true },
    { no: 7, name: "TeethPeriodontalStatuses", kind: "message", T: ToothPeriodontalStatus, repeated: true },
  ],
);

