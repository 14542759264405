// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_common_image_view_options.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { BoolValue, DoubleValue, proto3 } from "@bufbuild/protobuf";

/**
 * LEGACY, DO NOT USE
 *
 * @generated from message com.diagnocat.model.MedicalImageViewOptions
 */
export const MedicalImageViewOptions = proto3.makeMessageType(
  "com.diagnocat.model.MedicalImageViewOptions",
  () => [
    { no: 1, name: "WindowWidth", kind: "message", T: DoubleValue },
    { no: 2, name: "WindowLevel", kind: "message", T: DoubleValue },
    { no: 3, name: "Sharpness", kind: "message", T: DoubleValue },
    { no: 4, name: "Invert", kind: "message", T: BoolValue },
    { no: 5, name: "ColorMap", kind: "message", T: MedicalImageColorMap },
    { no: 6, name: "ViewportCoords", kind: "message", T: MedicalImageViewportCoords },
    { no: 11, name: "Brightness", kind: "message", T: DoubleValue },
    { no: 12, name: "Contrast", kind: "message", T: DoubleValue },
  ],
);

/**
 * SHALL REPLACE MedicalImageViewOptions after full transition
 *
 * @generated from message com.diagnocat.model.MedicalImageViewOptions_Upcoming
 */
export const MedicalImageViewOptions_Upcoming = proto3.makeMessageType(
  "com.diagnocat.model.MedicalImageViewOptions_Upcoming",
  () => [
    { no: 1, name: "WindowWidth", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 2, name: "WindowLevel", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 3, name: "Sharpness", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 4, name: "Invert", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 5, name: "ColorMap", kind: "message", T: MedicalImageColorMap },
    { no: 6, name: "ViewportCoords", kind: "message", T: MedicalImageViewportCoords_Upcoming },
    { no: 11, name: "Brightness", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 12, name: "Contrast", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
  ],
);

/**
 * TODO: @kirill
 *
 * @generated from message com.diagnocat.model.MedicalImageColorMap
 */
export const MedicalImageColorMap = proto3.makeMessageType(
  "com.diagnocat.model.MedicalImageColorMap",
  [],
);

/**
 * LEGACY, DO NOT USE
 *
 * @generated from message com.diagnocat.model.MedicalImageViewportCoords
 */
export const MedicalImageViewportCoords = proto3.makeMessageType(
  "com.diagnocat.model.MedicalImageViewportCoords",
  () => [
    { no: 1, name: "Left", kind: "message", T: DoubleValue },
    { no: 2, name: "Top", kind: "message", T: DoubleValue },
    { no: 3, name: "Right", kind: "message", T: DoubleValue },
    { no: 4, name: "Bottom", kind: "message", T: DoubleValue },
  ],
);

/**
 * SHALL REPLACE MedicalImageViewportCoords after full transition
 *
 * @generated from message com.diagnocat.model.MedicalImageViewportCoords_Upcoming
 */
export const MedicalImageViewportCoords_Upcoming = proto3.makeMessageType(
  "com.diagnocat.model.MedicalImageViewportCoords_Upcoming",
  () => [
    { no: 1, name: "Left", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 2, name: "Top", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 3, name: "Right", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 4, name: "Bottom", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
  ],
);

