import { ToothDetection } from '@/shared/api/protocol-ts/model/dto_report_tooth_pb';

import { NonNullableLocalization } from '../config/types';

export const makeDetectionsFromDeletedToothLocalizations = (
  toothDetection: ToothDetection[],
) => {
  if (!toothDetection.length) {
    return [];
  }

  return toothDetection
    .filter(
      (
        detection,
      ): detection is ToothDetection & {
        Localizations: NonNullableLocalization;
      } => detection.Localizations !== undefined,
    )
    .map((detection) => {
      const { Localizations } = detection;
      const BBox = Localizations.BBox;

      return {
        tooth: {
          toothID: detection.ID,
          ISONumber: 404,
          Localization: Localizations,
        },
        xmin: BBox?.X?.Min ?? 0,
        ymin: BBox?.Y?.Min ?? 0,
        xmax: BBox?.X?.Max ?? 0,
        ymax: BBox?.Y?.Max ?? 0,
      };
    });
};
