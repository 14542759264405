import { generatePath, useLocation, useNavigate } from 'react-router';

import { ReportType } from '@/shared/api/protocol-ts/model/dto_report_pb';
import { LocationStateType } from '@/shared/config';
import { getSegmentronReportLink } from '@/shared/lib';

import { SegmentationReportTypes } from '../config';
import { getReportPath } from '../lib';

export const useNavigateToReport = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // NOTE: type casting is necessary for typification location.state
  const locationState = location?.state as LocationStateType;

  return ({
    reportID,
    reportType,
    patientID,
  }: {
    reportID: string;
    reportType: ReportType;
    patientID: string;
  }) => {
    const isSegmentronReport = SegmentationReportTypes.includes(reportType);
    if (isSegmentronReport) {
      window.open(
        getSegmentronReportLink(reportID, reportType),
        '_blank',
        'noopener,noreferrer',
      );
    } else if (patientID && reportID) {
      navigate(
        generatePath(getReportPath(reportType), {
          patientID,
          reportID,
        }),
        {
          state: {
            ...locationState,
            lastPositionPatientProfile: window.pageYOffset,
          },
        },
      );
    }
  };
};
