import { FileWithPath } from 'react-dropzone';
import { compose, map, prop, sum } from 'ramda';

import { AssetType } from '@/shared/api/protocol-ts/model/dto_asset_pb';

import { MAX_BYTES_FOR_CBCT, MAX_BYTES_FOR_STUDIES } from '../config/constants';

export const isExceedTheSize = (
  files: FileWithPath[],
  assetType: AssetType,
) => {
  const fileSize =
    files.length === 1
      ? files[0]?.size
      : compose(
          sum,
          map<FileWithPath, number>(prop<keyof FileWithPath>('size')),
        )(files);

  if (assetType === AssetType.AssetType_Study_CBCT) {
    return fileSize > MAX_BYTES_FOR_CBCT;
  }

  return fileSize > MAX_BYTES_FOR_STUDIES;
};
