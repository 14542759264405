import { createSelector } from '@reduxjs/toolkit';

import {
  Asset,
  AssetType,
  GeneratedReportAsset,
} from '@/shared/api/protocol-ts/model/dto_asset_pb';

import { RootState } from '@/app/model/store';

import { assetsAdapter } from './assetsSlice';

const assetsSelectors = assetsAdapter.getSelectors(
  (state: RootState) => state.assets,
);

export const { selectAll, selectById, selectEntities } = assetsSelectors;

export const selectMedicalImages = createSelector(
  [assetsSelectors.selectAll],
  (assets) =>
    assets.reduce((accumulator, asset) => {
      if (asset.Kind.case === 'GeneratedReport') {
        return [...accumulator, asset.Kind.value];
      }

      return accumulator;
    }, [] as GeneratedReportAsset[]),
);

export const selectCBCTPanoReformatSplitAsset = (reportID: string) =>
  createSelector([assetsSelectors.selectAll], (assets) => {
    const splitAsset = assets.find((asset) => {
      if (asset.Kind.case === 'GeneratedReport') {
        const generatedReportAsset = asset.Kind.value;

        if (
          generatedReportAsset.Belonging?.ReportID === reportID &&
          generatedReportAsset.Content.case === 'CBCTGPPanoramaReformatSplit'
        ) {
          return generatedReportAsset.Content.value;
        }
      }
    });

    return splitAsset;
  });

export const selectMedicalImagesByToothID = (toothID: string) =>
  createSelector(selectMedicalImages, (medicalImages) =>
    medicalImages.filter(
      (medicalImage) => medicalImage?.Belonging?.ToothID === toothID,
    ),
  );

export const selectByToothID = (toothID: string) =>
  createSelector(assetsSelectors.selectAll, (medicalImages) =>
    medicalImages.filter((medicalImage) => {
      const generatedReportAsset =
        medicalImage.Kind.case === 'GeneratedReport'
          ? medicalImage.Kind.value
          : undefined;

      const reportAsset =
        medicalImage.Kind.case === 'Report'
          ? medicalImage.Kind.value
          : undefined;

      return (
        generatedReportAsset?.Belonging?.ToothID === toothID ||
        reportAsset?.Belonging?.ToothID === toothID
      );
    }),
  );

export const selectByReportID = (reportID: string) =>
  createSelector(assetsSelectors.selectAll, (medicalImages) =>
    medicalImages.filter(
      (medicalImage) =>
        medicalImage.Kind.case === 'GeneratedReport' &&
        medicalImage.Kind.value.Belonging?.ReportID === reportID,
    ),
  );

export const selectCBCTGPPanoImageByReportID = (reportID: string) =>
  createSelector(selectMedicalImages, (medicalImages) =>
    medicalImages.find(
      (medicalImage) =>
        medicalImage?.Belonging?.ReportID === reportID &&
        medicalImage.Content.case === 'CBCTGPPanoramaReformatGeneral',
    ),
  );

export const selectEndodonticPanoImageByReportID = (reportID: string) =>
  createSelector(selectMedicalImages, (medicalImages) =>
    medicalImages.find(
      (medicalImage) =>
        medicalImage?.Belonging?.ReportID === reportID &&
        medicalImage.Content.case === 'CBCTEndoPanoramaSplit',
    ),
  );

export const selectCBCTGPPanoImageSplit_DEPRICATED = (reportID: string) =>
  createSelector(selectMedicalImages, (medicalImages) => {
    const split = medicalImages.find(
      (medicalImage) =>
        medicalImage?.Belonging?.ReportID === reportID &&
        medicalImage?.Content.case === 'CBCTGPPanoramaReformatSplit',
    );

    return split;
  });

export const selectGPAssetByReportID = (reportID: string) =>
  createSelector([assetsSelectors.selectAll], (assets) =>
    assets.find(
      (asset) =>
        asset.Kind.case === 'GeneratedReport' &&
        asset.Kind.value.Belonging?.ReportID === reportID &&
        asset.Kind.value.Content.case === 'CBCTGPPanoramaReformatGeneral',
    ),
  );

export const selectEndodonticAssetByReportID = (reportID: string) =>
  createSelector([assetsSelectors.selectAll], (assets) =>
    assets.find(
      (asset) =>
        asset.Kind.case === 'GeneratedReport' &&
        asset.Kind.value.Belonging?.ReportID === reportID &&
        asset.Kind.value.Content.case === 'CBCTEndoPanoramaSplit',
    ),
  );

export const selectCBCTMedicalImagesByToothID = (toothID: string) =>
  createSelector(selectMedicalImagesByToothID(toothID), (medicalImages) =>
    medicalImages
      .filter(
        (medicalImage) => medicalImage.Content.case === 'CBCTGPToothSlice',
      )
      .map((medicalImage) => medicalImage.Content.value),
  );

export const selectCBCTToothPathologyMedicalImage = (toothID: string) =>
  createSelector(selectMedicalImagesByToothID(toothID), (medicalImages) =>
    medicalImages
      .filter(
        (medicalImage) =>
          medicalImage.Content.case === 'CBCTToothPathologySlice',
      )
      .map((medicalImage) => medicalImage.Content.value),
  );

export const selectIOXRayImagesByStudyID = (studyID: string) =>
  createSelector(assetsSelectors.selectAll, (assets) =>
    assets.filter(
      (asset) =>
        asset.Kind.case === 'Study' && asset.Kind.value.StudyID === studyID,
    ),
  );

// TODO: There are 1500+ assets in CBCT report, so find is not the best way.
// Study has asset id, so is this the same asset that we find here by study id?
export const selectCBCTMetaDataByStudyID = (studyID: string) =>
  createSelector([assetsSelectors.selectAll], (assets) => {
    const targetAsset = assets.find(
      (asset) =>
        asset.Kind.case === 'Study' && asset.Kind.value.StudyID === studyID,
    );

    const studyAsset =
      targetAsset?.Kind.case === 'Study' ? targetAsset.Kind.value : undefined;

    const CBCTMetaData =
      studyAsset?.Content.case === 'CBCT'
        ? studyAsset.Content.value.Metadata
        : undefined;

    return CBCTMetaData ? CBCTMetaData : undefined;
  });

export const selectMPRByToothID = (toothID: string) =>
  createSelector([assetsSelectors.selectAll], (assets) => {
    const targetAsset = assets.find(
      (asset) =>
        asset.Kind.case === 'GeneratedReport' &&
        asset.Kind.value.Belonging?.ToothID === toothID &&
        asset.Kind.value.Content.case === 'CBCTGPMPRSubVolume',
    );

    if (
      targetAsset &&
      targetAsset.Kind.case === 'GeneratedReport' &&
      targetAsset.Kind.value.Content.case === 'CBCTGPMPRSubVolume'
    ) {
      return targetAsset.Kind.value.Content.value;
    }

    return undefined;
  });

export const selectAssetByStudyID = (studyID: string) =>
  createSelector([assetsSelectors.selectAll], (assets) =>
    assets.find(
      (asset) =>
        asset.Kind.case === 'Study' && asset.Kind.value.StudyID === studyID,
    ),
  );

export const selectAssetByID = (assetID: string) => (state: RootState) =>
  selectById(state, assetID);

export const selectAssetsByIDs = (assetIDs: string[]) =>
  createSelector(assetsSelectors.selectEntities, (assetsEntities) =>
    assetIDs.reduce((assets, assetID) => {
      if (assetsEntities[assetID]) {
        return assets.concat(assetsEntities[assetID] as Asset);
      }

      return assets;
    }, [] as Asset[]),
  );

export const selectLoading = (state: RootState) => state.assets.loading;

export const makeSelectAssetsByIDs = (assetIDs: string[]) =>
  createSelector(assetsSelectors.selectEntities, (assets) =>
    assetIDs.map((assetID) => assets[assetID]),
  );

export const makeSelectorUserUploadedAssetsByToothID = (toothID: string) =>
  createSelector(assetsSelectors.selectAll, (assets) =>
    assets
      .filter(
        (asset) =>
          !asset.Deleted?.Deleted &&
          (asset.Type === AssetType.AssetType_Report_Uploaded_ToothSlice ||
            asset.Type === AssetType.AssetType_Report_Uploaded_MPRPlane) &&
          asset.Kind.case === 'Report' &&
          asset.Kind.value.Belonging?.ToothID === toothID,
      )
      .sort((a, b) => {
        const dateA = a?.Created?.At?.toDate().getUTCDate() ?? 0;
        const dateB = b?.Created?.At?.toDate().getUTCDate() ?? 0;

        return dateA - dateB;
      }),
  );

export const selectIsAssetsExistsByToothID = (toothID: string) =>
  createSelector(selectByToothID(toothID), (assets) => assets.length > 0);
