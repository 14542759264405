// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_common_image_medical_features.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { Annotations } from "./dto_common_image_annotations_pb.js";
import { MedicalImageViewOptions } from "./dto_common_image_view_options_pb.js";

/**
 * @generated from message com.diagnocat.model.MedicalImageFeatures
 */
export const MedicalImageFeatures = proto3.makeMessageType(
  "com.diagnocat.model.MedicalImageFeatures",
  () => [
    { no: 1, name: "Annotations", kind: "message", T: Annotations },
    { no: 2, name: "ViewOptions", kind: "message", T: MedicalImageViewOptions },
  ],
);

