import { useCallback, useMemo, useState } from 'react';

import { ReportSettings } from '@/shared/api/protocol-ts/model/dto_report_pb';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import { conditionModel } from '@/entities/condition';

import * as reportsModel from '../model';

type UseReportSettings = {
  isLoading: boolean;
  settings: ReportSettings;
  updateSettings: (newSettings: Partial<ReportSettings>) => void;
};

export const useReportSettings = (): UseReportSettings => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const report = useAppSelector(reportsModel.selectors.selectCurrentReport);

  const settings = useMemo(
    () =>
      report?.Settings ??
      ({
        LowProbabilityMode: false,
        ShowProblemAreas: false,
        ShowPanoramaSplitInsteadOfSingle: false,
        ShowConditionDetails: false,
      } as ReportSettings),
    [report?.Settings],
  );

  const updateSettings = useCallback(
    async (newSettings: Partial<ReportSettings>) => {
      setIsLoading(true);

      try {
        const { Report, TeethConditions } = await dispatch(
          reportsModel.thunks.setReportSettings({
            ReportID: report?.ID as string,
            Settings: { ...settings, ...newSettings } as ReportSettings,
          }),
        ).unwrap();

        if (TeethConditions) {
          dispatch(conditionModel.actions.setNewestMany(TeethConditions));
        }

        if (Report) {
          dispatch(reportsModel.actions.setNewestOne(Report));
        }
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, report?.ID, settings],
  );

  return {
    isLoading,
    settings,
    updateSettings,
  };
};
