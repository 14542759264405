import {
  createEntityAdapter,
  createSlice,
  EntityId,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  LoadingStateType,
  DefaultInitialState,
  SliceName,
} from '@/shared/config';
import { Invitation } from '@/shared/api/protocol-ts/model/dto_access_pb';

export const accessAdapter = createEntityAdapter<Invitation>({
  selectId: (invitation) => invitation.ID,
});

type AccessSliceInitialState = DefaultInitialState & {
  accessSharedWithMeCount: number;
  accessSharedByMeCount: number;
  accessTotalOrganizationPatientCount: number;
};

const initialState: AccessSliceInitialState = {
  accessSharedWithMeCount: 0,
  accessSharedByMeCount: 0,
  accessTotalOrganizationPatientCount: 0,
  loading: 'idle',
};

const accessState = accessAdapter.getInitialState(initialState);

type AccessState = typeof accessState;

const accessSlice = createSlice({
  name: SliceName.access,
  initialState: accessState,
  reducers: {
    addOne: (state, action: PayloadAction<Invitation>) =>
      accessAdapter.addOne(state as AccessState, action.payload),
    addMany: (state, action: PayloadAction<Invitation[]>) =>
      accessAdapter.addMany(state as AccessState, action.payload),
    removeOne: (state, action: PayloadAction<EntityId>) =>
      accessAdapter.removeOne(state as AccessState, action.payload),
    removeAll: (state) => accessAdapter.removeAll(state as AccessState),
    setOne: (state, action: PayloadAction<Invitation>) =>
      accessAdapter.setOne(state as AccessState, action.payload),
    setMany: (state, action: PayloadAction<Invitation[]>) =>
      accessAdapter.setMany(state as AccessState, action.payload),
    setNewestOne: (state, action: PayloadAction<Invitation>) => {
      const currentRevisionNumber =
        state.entities[action.payload.ID]?.Revision?.Number ?? 0;
      const payloadRevisionNumber = action.payload.Revision?.Number ?? 0;

      if (payloadRevisionNumber > currentRevisionNumber) {
        accessAdapter.setOne(state as AccessState, action.payload);
      }
    },

    setSharedWithMeCount: (state, action: PayloadAction<number>) => {
      state.accessSharedWithMeCount = action.payload;
    },
    setSharedByMeCount: (state, action: PayloadAction<number>) => {
      state.accessSharedByMeCount = action.payload;
    },
    setTotalOrganizationPatientCount: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.accessTotalOrganizationPatientCount = action.payload;
    },
    setLoading: (state, action: PayloadAction<LoadingStateType>) => {
      state.loading = action.payload;
    },
  },
});

export const { actions } = accessSlice;

export default accessSlice.reducer;
