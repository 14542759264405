import api from '@/shared/api/api';
import {
  DeleteAssetReq,
  DeleteAssetResp,
  SetMedicalImageViewOptionsReq,
  SetMedicalImageViewOptionsResp,
  SetTopLayerAnnotationsReq,
  SetTopLayerAnnotationsResp,
} from '@/shared/api/protocol-ts/api/core/svc_asset_pb';
import { AssetService } from '@/shared/api/protocol-ts/api/core/svc_asset_connectweb';
import { createThunkGenerator } from '@/shared/lib';
import { SliceName } from '@/shared/config';

type AssetsRequests = keyof typeof AssetService.methods;

const generateAssetThunk = createThunkGenerator<AssetsRequests>(
  SliceName.assets,
);

export const setTopLayerAnnotations = generateAssetThunk<
  SetTopLayerAnnotationsReq,
  SetTopLayerAnnotationsResp
>('setTopLayerAnnotations', api.assets.setTopLayerAnnotations);

export const setMedicalImageViewOptions = generateAssetThunk<
  SetMedicalImageViewOptionsReq,
  SetMedicalImageViewOptionsResp
>('setMedicalImageViewOptions', api.assets.setMedicalImageViewOptions);

export const deleteAsset = generateAssetThunk<DeleteAssetReq, DeleteAssetResp>(
  'deleteAsset',
  api.assets.deleteAsset,
);
