// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_report_type_cbct_segmentation.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { CBCTSeriesGeometryData } from "./dto_common_geometry_pb.js";
import { CephalometricLandmark, ToothLandmark } from "./dto_report_landmark_pb.js";
import { Report_CBCT_IOS_Segment } from "./dto_report_common_pb.js";
import { Report_Studio_ToothPosition } from "./dto_report_type_studio_common_pb.js";
import { ToothPeriodontalStatus } from "./dto_report_periodontal_status_pb.js";

/**
 * @generated from message com.diagnocat.model.Report_CBCT_Segmentation
 */
export const Report_CBCT_Segmentation = proto3.makeMessageType(
  "com.diagnocat.model.Report_CBCT_Segmentation",
  () => [
    { no: 1, name: "CropGeometryData", kind: "message", T: CBCTSeriesGeometryData },
    { no: 2, name: "CephalometricLandmarks", kind: "message", T: CephalometricLandmark, repeated: true },
    { no: 3, name: "Segments", kind: "message", T: Report_CBCT_IOS_Segment, repeated: true },
    { no: 4, name: "TeethLandmarks", kind: "message", T: ToothLandmark, repeated: true },
    { no: 5, name: "TeethPositions", kind: "message", T: Report_Studio_ToothPosition, repeated: true },
    { no: 6, name: "TeethPeriodontalStatuses", kind: "message", T: ToothPeriodontalStatus, repeated: true },
  ],
);

