import { FC, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { Modal, ModalProps } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { ReportPrintSettings } from '@/shared/api/protocol-ts/model/dto_report_pb';

import { reportsModel } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';

import styles from './ConfirmSignReportModal.module.scss';

type ConfirmSignReportProps = {
  className?: string;
  reportID: string;
  isAllTeethApproved: boolean;
} & Pick<ModalProps, 'isOpen' | 'onCancel' | 'onOk'>;

export const ConfirmSignReportModal: FC<ConfirmSignReportProps> = (props) => {
  const {
    isOpen,
    reportID,
    onCancel: onClose,
    className,
    isAllTeethApproved,
  } = props;

  const [loading, setLoading] = useState(false);

  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();

  const report = useAppSelector(reportsModel.selectors.selectByID(reportID));

  const newPrintSettings: ReportPrintSettings = {
    ...report?.PrintSettings,
    ShowDiagnosesProbabilities: false,
  } as ReportPrintSettings;

  const title = isAllTeethApproved
    ? formatMessage({
        id: 'report.signReport',
        defaultMessage: 'Sign report',
      })
    : formatMessage({
        id: 'consfirmSignReportModal.approveAll',
        defaultMessage: 'Approve all teeth',
      });

  const handleApproveAndSign = async () => {
    setLoading(true);
    try {
      await dispatch(
        reportsModel.thunks.setReportPrintSettings({
          ReportID: reportID,
          PrintSettings: newPrintSettings,
        }),
      );

      const currentReport = await dispatch(
        reportsModel.thunks.approveAllTeethAndSignReport(reportID),
      ).unwrap();

      if (currentReport.Report) {
        dispatch(reportsModel.actions.setNewestOne(currentReport.Report));
      }

      dispatch(toothModel.actions.setMany(currentReport.Teeth));
    } finally {
      setLoading(false);
      if (onClose) {
        onClose();
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      onOk={handleApproveAndSign}
      onCancel={onClose}
      className={cn(styles.container, className)}
      okButtonText={
        <FormattedMessage
          id="report.confirm.approveAndSign"
          defaultMessage="Yes, approve all and sign"
        />
      }
      okButtonProps={{ loading }}
    >
      <FormattedMessage
        id="report.warning.approveAndSign"
        defaultMessage="Are you sure you want to approve all the teeth and include in the report?"
      />
    </Modal>
  );
};
