import { FC, useMemo } from 'react';
import cn from 'classnames';

import { useAppSelector } from '@/shared/hooks';
import { StudyType } from '@/shared/api/protocol-ts/model/dto_study_pb';

import { studyCountModel } from '@/entities/studyCount';
import { StudyCounter } from '@/entities/patient/ui/StudyCounter/StudyCounter';

import { CountsIconNames } from '../../config/constants';

import styles from './StudiesCounters.module.scss';

type StudiesCountersVariants = 'row' | 'card';

type StudiesCountersProps = {
  patientID: string;
  variant?: StudiesCountersVariants;
  className?: string;
};

export const StudiesCounters: FC<StudiesCountersProps> = (props) => {
  const { patientID, className, variant = 'row' } = props;

  const patientStudies = useAppSelector(
    studyCountModel.selectors.selectStudyCountsByPatientID(patientID),
  );

  const studies = useMemo(() => {
    // TODO: move this into reduce in patient list stream
    const defaultStudies: Record<
      Exclude<
        StudyType,
        | StudyType.StudyType_InvalidValue
        | StudyType.StudyType_DentalPhoto
        | StudyType.StudyType_IOS_Meshes
      >,
      { iconName: CountsIconNames; count: number }
    > = {
      [StudyType.StudyType_CBCT]: {
        iconName: 'cbctNew',
        count: 0,
      },

      [StudyType.StudyType_IntraoralXRay]: {
        iconName: 'ioxray',
        count: 0,
      },

      [StudyType.StudyType_PanoramicXRay]: {
        iconName: 'pano',
        count: 0,
      },
    };

    if (!patientStudies) return defaultStudies;

    patientStudies?.counts?.forEach(({ Count, Modality }) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (defaultStudies[Modality]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        defaultStudies[Modality].count = Count;
      }
    });

    return defaultStudies;
  }, [patientStudies]);

  return (
    <div
      className={cn(
        styles.container,
        variant === 'card' && styles.cardContainer,
        className,
      )}
    >
      {Object.values(studies).map(({ iconName, count }) => (
        <StudyCounter name={iconName} count={count} key={iconName} />
      ))}
    </div>
  );
};
