import { OrganizationOnboardingQuestionnaire_V1 } from '@/shared/api/protocol-ts/api/marketing/dto_questionnaire_pb';
import { DefaultInitialState } from '@/shared/config';

export enum QuestionnaireSteps {
  CompanyName = 1,
  OwnerSpecialization = 2,
  CompanyProfile = 3,
  CBCTMachineType = 4,
  CBCTImagingSoftware = 5,
  XRayImagingSoftware = 6,
  FocusOfInterest = 7,
}

export type MarketingInitialState = DefaultInitialState & {
  questionnaire: OrganizationOnboardingQuestionnaire_V1;
};
