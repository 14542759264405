import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEmpty } from 'ramda';

import {
  Study,
  StudyStatus,
  StudyType,
} from '@/shared/api/protocol-ts/model/dto_study_pb';
import { Button, Icon, Skeleton, Tabs, WidgetCard } from '@/shared/ui';
import {
  useAppSelector,
  useLocationState,
  useMedia,
  useTab,
} from '@/shared/hooks';

import { userModel } from '@/entities/user';
import {
  billingModel,
  STUDY_TO_SERVICES_MAP,
  StudyToServiceMap,
} from '@/entities/billing';

import { useNewReport } from '@/features/addNewReport';

import { ReportAccordion } from '../ReportAccordion/ReportAccordion';
import { orderReportButtonMessages, studyCardTitle } from '../../config';
import { StudyPreview } from '../StudyPreview/StudyPreview';

import styles from './StudyCard.module.scss';

type StudyCardProps = {
  className?: string;
  studies: Study[];
  // Get study type from internal data 'studies'
  onViewDetails: (studyID: string) => void;
};

// TODO: rewrite tabs logic and preview images logic
export const StudyCard: FC<StudyCardProps> = (props) => {
  const { className, studies, onViewDetails } = props;

  const { formatMessage, formatDate } = useIntl();

  const { isPhone } = useMedia();

  const studyLoading = useAppSelector((state) => state.study.loading);

  const user = useAppSelector(userModel.selectors.selectCurrentUser);

  const services = useAppSelector(
    billingModel.selectors.selectActiveServicesList,
  );

  const { lastOpenTabStudyID } = useLocationState();

  const { CanEditInfo, CanEditPersonalData } = user?.YourPermissions ?? {};

  const isLastOpenTabInThisStudyCard = studies.some(
    (study) => study.ID === lastOpenTabStudyID,
  );

  const isUserCanEdit = CanEditInfo && CanEditPersonalData;

  const showSkeleton = studyLoading === 'idle' || studyLoading === 'pending';

  const studyType = studies[0]?.Type;

  const { tabValue: currentStudyID, handleTabChange } = useTab({
    name: `studyCard-${studyType}`,
  });

  const { requestIOXRayGPReport, requestPanoGPReport, requestCBCTGPReport } =
    useNewReport();

  const notDeletedStudies = studies.filter((study) => !study.Deleted?.Deleted);

  const hasStudyIDInFilteredStudies = notDeletedStudies.some(
    (study) => study.ID === currentStudyID,
  );

  const studyID =
    (hasStudyIDInFilteredStudies && currentStudyID) ||
    (isLastOpenTabInThisStudyCard && lastOpenTabStudyID) ||
    notDeletedStudies[0]?.ID;

  const isDisabledReportButton = (currentID: string) => {
    const currentStudy = studies.find((study) => study.ID === currentID);

    return currentStudy?.Status !== StudyStatus?.StudyStatus_Finished;
  };

  if (showSkeleton) {
    return <Skeleton width="100%" borderRadius="16px" height="390px" />;
  }

  if (isEmpty(notDeletedStudies)) {
    return null;
  }

  const requestReport = () => {
    switch (studyType) {
      case StudyType.StudyType_CBCT:
        requestCBCTGPReport(studyID);
        break;
      case StudyType.StudyType_IntraoralXRay:
        requestIOXRayGPReport(studyID);
        break;
      case StudyType.StudyType_PanoramicXRay:
        requestPanoGPReport(studyID);
        break;
      default: {
        break;
      }
    }
  };

  return (
    <>
      <WidgetCard className={cn(styles.container, className)}>
        <Tabs.Root value={studyID} onValueChange={handleTabChange}>
          <Tabs.List size="small">
            <h4 className={cn(styles.title, 'h4')}>
              {formatMessage(studyCardTitle[studyType])}
            </h4>

            {notDeletedStudies.map(
              (study) =>
                study?.Created?.At && (
                  <Tabs.Trigger
                    className={cn({
                      [styles.noClickable]: studies.length === 1,
                    })}
                    key={study?.ID}
                    value={study?.ID}
                    size="small"
                  >
                    {formatDate(study.Created.At.toDate(), {
                      dateStyle: 'medium',
                      timeStyle: 'short',
                    })}
                  </Tabs.Trigger>
                ),
            )}

            <Button
              className={styles.detailsButton}
              variant="tertiary"
              size="medium"
              onClick={() => {
                onViewDetails(studyID);
              }}
            >
              {isPhone ? (
                <Icon name="info" size={32} />
              ) : (
                <FormattedMessage
                  id="studyCard.viewDetailsLink"
                  defaultMessage="View details"
                />
              )}
            </Button>
          </Tabs.List>

          {studies.map((study) => (
            <Tabs.Content key={study.ID} value={study.ID}>
              <div className={styles.content}>
                <StudyPreview study={study} />
                <ReportAccordion studyID={study.ID} />
              </div>
            </Tabs.Content>
          ))}
        </Tabs.Root>

        {isUserCanEdit && (
          <footer>
            <Button
              variant="secondary"
              size="medium"
              block
              icon="plus"
              disabled={
                !services[
                  STUDY_TO_SERVICES_MAP[studyType as keyof StudyToServiceMap]
                ] || isDisabledReportButton(studyID)
              }
              onClick={requestReport}
            >
              {formatMessage(orderReportButtonMessages[studyType])}
            </Button>
          </footer>
        )}
      </WidgetCard>
    </>
  );
};
