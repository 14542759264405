// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file api/notifications/svc_notifications.proto (package com.diagnocat.notifications, syntax proto3)
/* eslint-disable */

import { Empty, proto3 } from "@bufbuild/protobuf";
import { Notification } from "./dto_notification_pb.js";

/**
 * @generated from message com.diagnocat.notifications.NotificationsStreamReq
 */
export const NotificationsStreamReq = proto3.makeMessageType(
  "com.diagnocat.notifications.NotificationsStreamReq",
  [],
);

/**
 * @generated from message com.diagnocat.notifications.NotificationsStreamResp
 */
export const NotificationsStreamResp = proto3.makeMessageType(
  "com.diagnocat.notifications.NotificationsStreamResp",
  () => [
    { no: 1, name: "Historical", kind: "message", T: Notification, oneof: "Notification" },
    { no: 2, name: "Live", kind: "message", T: Notification, oneof: "Notification" },
    { no: 101, name: "Ping", kind: "message", T: Empty, oneof: "Notification" },
  ],
);

/**
 * @generated from message com.diagnocat.notifications.NotificationsMarkAsReadReq
 */
export const NotificationsMarkAsReadReq = proto3.makeMessageType(
  "com.diagnocat.notifications.NotificationsMarkAsReadReq",
  () => [
    { no: 1, name: "IDs", kind: "message", T: NotificationsMarkAsReadReq_SelectedIDs, oneof: "Target" },
    { no: 2, name: "All", kind: "message", T: Empty, oneof: "Target" },
  ],
);

/**
 * @generated from message com.diagnocat.notifications.NotificationsMarkAsReadReq.SelectedIDs
 */
export const NotificationsMarkAsReadReq_SelectedIDs = proto3.makeMessageType(
  "com.diagnocat.notifications.NotificationsMarkAsReadReq.SelectedIDs",
  () => [
    { no: 1, name: "IDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
  {localName: "NotificationsMarkAsReadReq_SelectedIDs"},
);

/**
 * @generated from message com.diagnocat.notifications.NotificationsMarkAsReadResp
 */
export const NotificationsMarkAsReadResp = proto3.makeMessageType(
  "com.diagnocat.notifications.NotificationsMarkAsReadResp",
  [],
);

/**
 * @generated from message com.diagnocat.notifications.RegisterPushSubscriptionReq
 */
export const RegisterPushSubscriptionReq = proto3.makeMessageType(
  "com.diagnocat.notifications.RegisterPushSubscriptionReq",
  () => [
    { no: 1, name: "UserID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "DeviceID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "WebPushSubscription", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "Subscription" },
    { no: 9, name: "APNS2DeviceToken", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "Subscription" },
  ],
);

/**
 * @generated from message com.diagnocat.notifications.RegisterPushSubscriptionResp
 */
export const RegisterPushSubscriptionResp = proto3.makeMessageType(
  "com.diagnocat.notifications.RegisterPushSubscriptionResp",
  [],
);

