import { FC, useCallback } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, WidgetCard } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
// import { ServiceType } from '@/shared/api/protocol-ts/api/billing/dto_services_pb';
import { NEW_LINE } from '@/shared/config';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';

import { ModalID, modalModel } from '@/entities/modal';
import { organizationModel } from '@/entities/organization';

import styles from './OrderImplantOrOrthoModel.module.scss';

type OrderImplantOrOrthoModelProps = {
  className?: string;
};

/**
 * @deprecated
 */
export const OrderImplantOrOrthoModel_DEPRECATED: FC<
  OrderImplantOrOrthoModelProps
> = (props) => {
  const { className } = props;
  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const displayImplantPlanningButton = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Show_ImplantPlanningButton,
    ),
  );

  const displayOrthoPlanningButton = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Show_OrthoPlanningButton,
    ),
  );

  const openOrderModelModal = useCallback(
    (modalTitle: string, type: 'InputStudioImplant' | 'InputStudioOrtho') => {
      dispatch(
        modalModel.actions.openModal({
          modalID: ModalID.OrderOrthoORImplantModal,
          data: { title: modalTitle, type },
        }),
      );
    },
    [dispatch],
  );

  const openOrder3DSegmentronModal = useCallback(() => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.Order3DSegmentronModal,
      }),
    );
  }, [dispatch]);

  return (
    <WidgetCard className={cn(styles.container, className)}>
      <h4 className="h4">
        <FormattedMessage
          id="PatientProfile.order3DModels"
          defaultMessage="Order 3D Models"
        />
      </h4>

      <div className={styles.ordersWrapper}>
        <Button
          onClick={openOrder3DSegmentronModal}
          variant="secondary"
          icon="cbct3d"
          className={styles.orderButton}
        >
          <FormattedMessage
            id="PatientProfile.new3DModel"
            defaultMessage="New 3D{newLine}model"
            values={{ newLine: NEW_LINE }}
          />
        </Button>

        {displayImplantPlanningButton && (
          <Button
            onClick={() =>
              openOrderModelModal(
                formatMessage({
                  id: 'OrderModel.OrderImplantStudio ',
                  defaultMessage: 'Order Implant Studio ',
                }),
                'InputStudioImplant',
              )
            }
            variant="secondary"
            icon="ios3d"
            className={styles.orderButton}
          >
            <FormattedMessage
              id="PatientProfile.NewImplantPlanning"
              defaultMessage="New implant{newLine}planning"
              values={{ newLine: NEW_LINE }}
            />
          </Button>
        )}

        {displayOrthoPlanningButton && (
          <Button
            onClick={() =>
              openOrderModelModal(
                formatMessage({
                  id: 'OrderModel.OrderOrthoStudio',
                  defaultMessage: 'Order Ortho Studio',
                }),
                'InputStudioOrtho',
              )
            }
            variant="secondary"
            icon="model3d"
            className={styles.orderButton}
          >
            <FormattedMessage
              id="PatientProfile.NewOrthoPlanning"
              defaultMessage="New ortho{newLine}planning"
              values={{ newLine: NEW_LINE }}
            />
          </Button>
        )}
      </div>
    </WidgetCard>
  );
};
