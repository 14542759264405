import { defineMessages } from 'react-intl';

import { Gender } from '@/shared/api/protocol-ts/model/dto_base_pb';

export const gender = defineMessages<Gender>({
  [Gender.GenderMale]: {
    id: 'gender.male',
    defaultMessage: 'Male',
  },
  [Gender.GenderFemale]: {
    id: 'gender.female',
    defaultMessage: 'Female',
  },
  [Gender.GenderOther]: {
    id: 'gender.other',
    defaultMessage: 'Other',
  },
  [Gender.GenderUnspecified]: {
    id: 'gender.unspecified',
    defaultMessage: 'Unspecified',
  },
});
