import { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import { toothModel, ToothType, ToothTypesSet } from '@/entities/tooth';
import { useCheckReportSignature } from '@/entities/reports';

import { ToothChartTag } from '../ToothChartTag/ToothChartTag';

import styles from './ToothChartTags.module.scss';

type ToothChartTagsProps = {
  toothTypesSet: ToothTypesSet;
  reportID: string;
};

type ToothChartTagItem = {
  toothType: ToothType;
  active: boolean;
  amount: number;
  toothIDs: string[];
};

// Maybe should be standalone feature
export const ToothChartTags = (props: ToothChartTagsProps) => {
  const { toothTypesSet, reportID } = props;

  const dispatch = useAppDispatch();

  const allTeethIDs = useAppSelector(
    toothModel.selectors.selectReportAllIds(reportID),
  );
  const localROITeethIDs = useAppSelector(
    toothModel.selectors.selectLocalROITeethIDs,
  );

  const { checkReportSignature } = useCheckReportSignature();

  const toothTags = useMemo(
    () =>
      Object.entries(toothTypesSet).reduce(
        (acc, [toothId, toothType]) => {
          if (toothType in acc) {
            acc[toothType].push(toothId);
          } else {
            acc[toothType] = [toothId];
          }

          return acc;
        },
        { all: allTeethIDs } as Record<ToothType, string[]>,
      ),
    [allTeethIDs, toothTypesSet],
  );

  const toothTagItems = useMemo(
    () =>
      (Object.entries(toothTags) as [ToothType, string[]][])
        .map(
          ([toothType, toothIDs]): ToothChartTagItem => ({
            toothType,
            active: toothIDs.every((toothID) =>
              localROITeethIDs.includes(toothID),
            ),
            amount: toothIDs.length,
            toothIDs,
          }),
        )
        .sort((a, b) => {
          const tagOrder: ToothType[] = [
            'all',
            'healthy',
            'treated',
            'missing',
            'lowProbability',
            'unhealthy',
          ];

          return tagOrder.indexOf(a.toothType) - tagOrder.indexOf(b.toothType);
        }),
    [toothTags, localROITeethIDs],
  );

  const handleTagClick = useCallback(
    (toothType: ToothType) => {
      checkReportSignature({
        onSignatureChecked: async () => {
          dispatch(
            toothModel.actions.toggleLocalROITeethIDs(toothTags[toothType]),
          );
          dispatch(toothModel.thunks.updateCurrentReportROI());
        },
      });
    },
    [dispatch, toothTags, checkReportSignature],
  );

  return (
    <div className={styles.container}>
      {toothTagItems.map((item) => (
        <ToothChartTag
          key={item.toothType}
          toothType={item.toothType}
          amount={item.amount}
          active={item.active}
          onClick={handleTagClick}
        />
      ))}
    </div>
  );
};
