import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
  EntityState,
} from '@reduxjs/toolkit';

import {
  DefaultInitialState,
  LoadingStateType,
  SliceName,
} from '@/shared/config';
import { Condition } from '@/shared/api/protocol-ts/model/dto_report_condition_pb';

import { sortConditionsByGroupOrder } from '../lib/sortConditionsByGroupOrder';

export const conditionAdapter = createEntityAdapter<Condition>({
  selectId: (condition: Condition) => condition.ID,
  sortComparer: sortConditionsByGroupOrder,
});

type ConditionInitialState = DefaultInitialState;

const initialState: ConditionInitialState = {
  loading: 'idle',
};

type ConditionState = EntityState<Condition> & DefaultInitialState;

const conditionSlice = createSlice({
  name: SliceName.condition,
  initialState: conditionAdapter.getInitialState(initialState),
  reducers: {
    addOne: (state, action: PayloadAction<Condition>) =>
      conditionAdapter.addOne(state as ConditionState, action.payload),
    addMany: (state, action: PayloadAction<Condition[]>) =>
      conditionAdapter.addMany(state as ConditionState, action.payload),
    setNewestOne: (state, action: PayloadAction<Condition>) => {
      if (action.payload.Deleted?.Deleted) {
        conditionAdapter.removeOne(state as ConditionState, action.payload.ID);
      }

      const currentRevisionNumber =
        state.entities[action.payload.ID]?.Revision?.Number ?? 0;
      const payloadRevisionNumber = action.payload.Revision?.Number ?? 0;

      if (payloadRevisionNumber > currentRevisionNumber) {
        conditionAdapter.setOne(state as ConditionState, action.payload);
      }
    },
    setNewestMany: (state, action: PayloadAction<Condition[]>) => {
      const items = action.payload.filter((item) => {
        const currentRevisionNumber =
          state.entities[item.ID]?.Revision?.Number ?? 0;
        const payloadRevisionNumber = item.Revision?.Number ?? 0;

        return payloadRevisionNumber > currentRevisionNumber;
      });

      conditionAdapter.setMany(state as ConditionState, items);
    },
    setMany: (state, action: PayloadAction<Condition[]>) =>
      conditionAdapter.setMany(state as ConditionState, action.payload),
    removeOne: (state, action: PayloadAction<string>) =>
      conditionAdapter.removeOne(state as ConditionState, action.payload),
    removeAll: (state) => conditionAdapter.removeAll(state as ConditionState),
    setLoading: (state, action: PayloadAction<LoadingStateType>) => {
      state.loading = action.payload;
    },
    reset: () => conditionAdapter.getInitialState(initialState),
  },
});

export const { actions } = conditionSlice;

export default conditionSlice.reducer;
