// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_common_anatomy.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum com.diagnocat.model.CBCT_Anatomy
 */
export const CBCT_Anatomy = proto3.makeEnum(
  "com.diagnocat.model.CBCT_Anatomy",
  [
    {no: 0, name: "CBCT_Anatomy_InvalidValue"},
    {no: 1, name: "CBCT_Anatomy_Airways"},
    {no: 2, name: "CBCT_Anatomy_Cranium"},
    {no: 3, name: "CBCT_Anatomy_SoftTissue"},
    {no: 4, name: "CBCT_Anatomy_IncisiveCanal"},
    {no: 5, name: "CBCT_Anatomy_Mandible"},
    {no: 6, name: "CBCT_Anatomy_Maxilla"},
    {no: 7, name: "CBCT_Anatomy_MandibularCanal"},
    {no: 8, name: "CBCT_Anatomy_Sinuses"},
  ],
);

/**
 * @generated from enum com.diagnocat.model.CBCT_Tooth_Anatomy
 */
export const CBCT_Tooth_Anatomy = proto3.makeEnum(
  "com.diagnocat.model.CBCT_Tooth_Anatomy",
  [
    {no: 0, name: "CBCT_Tooth_Anatomy_InvalidValue"},
    {no: 1, name: "CBCT_Tooth_Anatomy_Pulp"},
    {no: 2, name: "CBCT_Tooth_Anatomy_Dentin"},
    {no: 3, name: "CBCT_Tooth_Anatomy_Enamel"},
    {no: 4, name: "CBCT_Tooth_Anatomy_Root"},
  ],
);

