import {
  components,
  MultiValueRemoveProps,
  MultiValueGenericProps,
} from 'react-select';

import { Icon } from '@/shared/ui';

export const AdditionalInfoMultiValueLabel = (
  props: MultiValueGenericProps<{ label: string }>,
) => {
  const {
    data: { label },
  } = props;

  return (
    <components.MultiValueLabel {...props}>
      <p className="p2">{label}</p>
    </components.MultiValueLabel>
  );
};

export const AdditionalInfoMultiValueRemove = (
  props: MultiValueRemoveProps,
) => (
  <components.MultiValueRemove {...props}>
    <Icon name="close" size={24} />
  </components.MultiValueRemove>
);
