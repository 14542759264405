// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_asset_report_uploaded.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { File } from "./dto_asset_common_pb.js";

/**
 * @generated from message com.diagnocat.model.AssetContent_Report_UserUploaded_ToothSlice
 */
export const AssetContent_Report_UserUploaded_ToothSlice = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Report_UserUploaded_ToothSlice",
  () => [
    { no: 1, name: "Slice", kind: "message", T: File },
  ],
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Report_UserUploaded_MPRPlane
 */
export const AssetContent_Report_UserUploaded_MPRPlane = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Report_UserUploaded_MPRPlane",
  () => [
    { no: 1, name: "MPRPlane", kind: "message", T: File },
  ],
);

