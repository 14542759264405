import { Report } from '@/shared/api/protocol-ts/model/dto_report_pb';
import { Study, StudyType } from '@/shared/api/protocol-ts/model/dto_study_pb';
import { getDateTimestamp } from '@/shared/lib';

export const getLatestDate = (
  report: Report | undefined,
  studies: Study[],
): number | undefined => {
  const cbctStudy = studies.find(
    (study) => study.Type === StudyType.StudyType_CBCT,
  );

  if (cbctStudy && cbctStudy.Created?.At) {
    return getDateTimestamp(cbctStudy.Created.At.toDate());
  }

  const studiesDate = studies.filter((study) => study.Created?.At);

  if (studiesDate.length > 0) {
    return Math.max(
      ...studiesDate.map((study) =>
        getDateTimestamp(study.Created?.At?.toDate() ?? new Date()),
      ),
      0,
    );
  }

  const reportsDate = report && report.Created?.At;

  if (reportsDate) {
    return getDateTimestamp(reportsDate.toDate());
  }

  return undefined;
};
