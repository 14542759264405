import { useIntl } from 'react-intl';

import { useAppDispatch } from '@/shared/hooks';

import { reportsModel } from '@/entities/reports';
import { ModalID, modalModel } from '@/entities/modal';

export const useRemoveReport_DEPRECATED = () => {
  const dispatch = useAppDispatch();

  return async (reportID: string) => {
    const { DeletedReportID } = await dispatch(
      reportsModel.thunks.deleteReport_DEPRECATED({ ReportID: reportID }),
    ).unwrap();

    dispatch(reportsModel.actions.removeOne(DeletedReportID));
  };
};

export const useRemoveReport = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  return async (reportID: string) => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.Confirm,
        data: {
          title: formatMessage({
            id: 'removeReportModal.title',
            defaultMessage: 'Remove report',
          }),
          okText: formatMessage({
            id: 'global.applyRemove',
            defaultMessage: 'Yes, remove',
          }),
          description: formatMessage({
            id: 'removeReportModal.message',
            defaultMessage:
              'After removing, all report data will be lost. Are you sure?',
          }),
          danger: true,
          onConfirm: async () => {
            dispatch(modalModel.actions.setConfirmModalLoading(true));

            await dispatch(reportsModel.thunks.deleteReport(reportID));

            dispatch(modalModel.actions.closeModal(ModalID.Confirm));
            dispatch(modalModel.actions.setConfirmModalLoading(false));
          },
        },
      }),
    );
  };
};
