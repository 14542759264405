import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { cloneDeep, isEmpty, merge } from 'lodash';

import { ConditionCode } from '@/shared/api/protocol-ts/model/dto_report_condition_codes_pb';
import { useAppSelector } from '@/shared/hooks';
import { Condition } from '@/shared/api/protocol-ts/model/dto_report_condition_pb';
import { Decision } from '@/shared/api/protocol-ts/model/dto_report_common_pb';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import {
  isConditionUncertain,
  shouldConditionItemBeShown,
} from '@/shared/embeddedLibs/conditionsAndMasks';

import {
  ToothCondition,
  ToothConditionCollectionByID,
} from '@/entities/logicalCondition/model/logicalConditionSlice';
import { logicalConditionModel } from '@/entities/logicalCondition';
import { reportsModel } from '@/entities/reports';
import { organizationModel } from '@/entities/organization';

import {
  getConditionGroupByCode,
  getConditionCodeColor,
  getConditionModelScorePercentage,
} from '../lib';
import {
  ChildConditionGroups,
  FLAT_CONDITION_GROUPS,
  CHILD_CONDITION_GROUPS,
  ONE_OF_CONDITION_GROUPS,
} from '../config/const';
import { ConditionInterface, ChildConditionInterface } from '../config/types';
import { conditionText } from '../config/i18n';
import { mergeConditions } from '../lib/mergeConditions';

// Return all possible condition items
export const useTransformLogicalConditionsAll = (
  toothConditions: ToothCondition,
): ConditionInterface[] => {
  const { formatMessage } = useIntl();
  const showLowProbability = useAppSelector(
    reportsModel.selectors.selectLowProbabilityMode,
  );
  const hideProbability = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Hide_Probabilities,
    ),
  );

  const { conditions, childConditions } =
    toothConditions ??
    ({ conditions: {}, childConditions: {} } as ToothCondition);

  const conditionItems = useMemo((): ConditionInterface[] => {
    return FLAT_CONDITION_GROUPS.map((code) => {
      const condition = mergeConditions(conditions[code]);

      const conditionColor = getConditionCodeColor(
        code,
        isConditionUncertain(condition),
        showLowProbability,
      );

      const isEndo = code === ConditionCode.EndoTreated;

      const conditionGroup = getConditionGroupByCode(code);

      const childConditionInterfaces: ChildConditionInterface[] =
        code in CHILD_CONDITION_GROUPS
          ? (
              Object.entries(
                // @ts-expect-error WARN: TS and Object.entries are pain in the ass
                CHILD_CONDITION_GROUPS[code],
              ) as unknown as [ChildConditionGroups, ConditionCode[]]
            ).flatMap(
              // @ts-expect-error WARN: TS flatMap, and Object.entries are pain in the ass
              ([group, codes]: [ChildConditionGroups, ConditionCode[]]) =>
                codes.map((childCode): ChildConditionInterface => {
                  const children = condition?.IDs?.reduce(
                    (childCollection, conditionID) => {
                      const childConditionCollection =
                        childConditions[conditionID ?? '']?.[childCode];

                      if (isEmpty(childCollection)) {
                        return childConditionCollection;
                      } else {
                        const childConditionCollectionClone = cloneDeep(
                          childConditionCollection,
                        );
                        return merge(
                          childConditionCollectionClone,
                          childCollection,
                        );
                      }
                    },
                    {} as ToothConditionCollectionByID,
                  );

                  const childCondition = children
                    ? mergeConditions(children)
                    : undefined;
                  const childConditionColor = getConditionCodeColor(
                    childCode,
                    isConditionUncertain(childCondition),
                    showLowProbability,
                  );

                  return {
                    id: childCondition?.ID ?? '',
                    ids: childCondition?.IDs ?? [],
                    toothID:
                      condition?.Attribution.case === 'Tooth'
                        ? condition?.Attribution.value.ToothID
                        : '',
                    parentID: childCondition?.ParentID as string,
                    code: childCode,
                    parentConditionCode: code,
                    parentConditionId: condition?.ID,
                    group,
                    text: conditionText[childCode]
                      ? formatMessage(conditionText[childCode])
                      : '',
                    color: isEndo ? childConditionColor : conditionColor,
                    probability: childCondition?.Certainty?.ModelScore,
                    probabilityText: hideProbability
                      ? ''
                      : getConditionModelScorePercentage(childCondition),
                    isChecked:
                      shouldConditionItemBeShown(showLowProbability)(
                        childCondition,
                      ),
                    isOneOf: false,
                    hasLocalizations: !!childCondition?.Localizations?.length,
                    modelPositive:
                      childCondition?.Certainty?.ModelDecision ===
                      Decision.PositiveDecision,
                  };
                }),
            )
          : [];

      return {
        id: condition?.ID ?? '',
        ids: condition?.IDs ?? [],
        toothID:
          condition?.Attribution.case === 'Tooth'
            ? condition?.Attribution.value.ToothID
            : '',
        code,
        group: conditionGroup,
        text: conditionText[code] && formatMessage(conditionText[code]),
        color: conditionColor,
        probability: condition?.Certainty?.ModelScore,
        probabilityText: hideProbability
          ? ''
          : getConditionModelScorePercentage(condition),
        isChecked: shouldConditionItemBeShown(showLowProbability)(condition),
        isOneOf: ONE_OF_CONDITION_GROUPS.includes(conditionGroup),
        hasLocalizations: !!condition?.Localizations?.length,
        modelPositive:
          condition?.Certainty?.ModelDecision === Decision.PositiveDecision,
        childConditionInterfaces,
      };
    });
  }, [
    conditions,
    showLowProbability,
    formatMessage,
    hideProbability,
    childConditions,
  ]);

  return conditionItems;
};

export const useTransformLogicalConditions = (
  toothID: string,
  isPreviewMode = false,
): ConditionInterface[] => {
  const { formatMessage } = useIntl();
  const lowProbabilityMode = useAppSelector(
    reportsModel.selectors.selectLowProbabilityMode,
  );
  const hideProbability = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Hide_Probabilities,
    ),
  );

  const conditions = useAppSelector(
    logicalConditionModel.selectors.selectToothActiveLogicalConditions(toothID),
  );

  const showLowProbability = lowProbabilityMode && !isPreviewMode;

  return useMemo(() => {
    const filteredConditions = isPreviewMode
      ? conditions.filter(
          (condition) =>
            !isConditionUncertain(condition as unknown as Condition),
        )
      : conditions;

    return filteredConditions.map((condition): ConditionInterface => {
      const code = condition.Code as ConditionCode;
      const isEndo = code === ConditionCode.EndoTreated;
      const conditionColor = getConditionCodeColor(
        code,
        isConditionUncertain(condition as unknown as Condition),
        showLowProbability,
      );
      const conditionGroup = getConditionGroupByCode(code);

      const childConditionInterfaces = condition.Childs.map(
        (childCondition): ChildConditionInterface => {
          const code = childCondition.Code as ConditionCode;
          const childConditionGroup = getConditionGroupByCode(code);

          return {
            id: childCondition?.ID as string,
            ids: childCondition?.IDs,
            toothID:
              childCondition?.Attribution.case === 'Tooth'
                ? childCondition?.Attribution.value.ToothID
                : '',
            code: childCondition.Code,
            parentConditionCode: code,
            parentID: condition.ID as string,
            text: conditionText[code] && formatMessage(conditionText[code]),
            color: isEndo
              ? getConditionCodeColor(
                  code,
                  isConditionUncertain(childCondition as Condition),
                  showLowProbability,
                )
              : conditionColor,
            probability: childCondition?.Certainty?.ModelScore,
            probabilityText: hideProbability
              ? ''
              : getConditionModelScorePercentage(childCondition as Condition),
            isChecked: shouldConditionItemBeShown(showLowProbability)(
              childCondition as Condition,
            ),
            isOneOf: ONE_OF_CONDITION_GROUPS.includes(childConditionGroup),
            hasLocalizations: !!childCondition?.Localizations?.length,
            modelPositive:
              childCondition?.Certainty?.ModelDecision ===
              Decision.PositiveDecision,
          };
        },
      );

      return {
        id: condition?.ID as string,
        ids: condition?.IDs as string[],
        toothID:
          condition?.Attribution.case === 'Tooth'
            ? condition?.Attribution.value.ToothID
            : '',
        code,
        group: conditionGroup,
        text: conditionText[code] && formatMessage(conditionText[code]),
        color: conditionColor,
        probability: condition?.Certainty?.ModelScore,
        probabilityText: hideProbability
          ? ''
          : getConditionModelScorePercentage(condition as unknown as Condition),
        isChecked: shouldConditionItemBeShown(showLowProbability)(
          condition as unknown as Condition,
        ),
        isOneOf: ONE_OF_CONDITION_GROUPS.includes(conditionGroup),
        hasLocalizations: !!condition?.Localizations?.length,
        modelPositive:
          condition?.Certainty?.ModelDecision === Decision.PositiveDecision,
        childConditionInterfaces,
      };
    });
  }, [conditions, formatMessage, hideProbability, showLowProbability]);
};
