import {
  AnnotationsLayer,
  AnnotationType_Guide,
} from '@/shared/api/protocol-ts/model/dto_common_image_annotations_pb';

export const makeGuideLines = (
  annotations: AnnotationsLayer[] = [],
): AnnotationType_Guide[] =>
  annotations.reduce((guideLines, item) => {
    item.Annotations.forEach((annotation) => {
      if (annotation.Type.case === 'Guide') {
        guideLines.push(annotation.Type.value);
      }
    });

    return guideLines;
  }, [] as AnnotationType_Guide[]);
