import { FormattedMessage } from 'react-intl';
import { ConnectError } from '@bufbuild/connect';

import { toastCaller } from '@/shared/ui';

import { getErrorMessage } from './getErrorMessage';

export const errorToastCaller = (error: ConnectError) => {
  const errorMessage = getErrorMessage(error);

  toastCaller({
    type: 'error',
    heading: <FormattedMessage id="error.heading" defaultMessage="Error" />,
    message: errorMessage,
    autoClose: 10000,
  });
};
