import { generatePath, useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { head, pipe, split } from 'ramda';

import { useAppSelector, useMedia } from '@/shared/hooks';
import { BreadcrumbElementType, PATHS } from '@/shared/config';
import { Patient } from '@/shared/api/protocol-ts/model/dto_patient_pb';

import { patientModel } from '@/entities/patient';
import { getDisplayToothNumber, toothModel } from '@/entities/tooth';
import { organizationModel } from '@/entities/organization';

import { getPatientLabel } from '../utils/getPatientLabel';

import { useGetReportConfigs } from './useGetReportConfigs';

export const useBreadcrumbs = (): Record<string, BreadcrumbElementType> => {
  const params = useParams();
  const { pathname } = useLocation();

  const { formatMessage } = useIntl();

  const { isLarge, isWide } = useMedia();

  const patientID = params.patientID ?? '';
  const reportID = params.reportID ?? '';
  const toothID = params.toothID ?? '';
  const isPrintPage = pathname.includes('preview');

  const currentPatient = useAppSelector(
    patientModel.selectors.selectPatientByID(patientID),
  );

  const currentTooth = useAppSelector(
    toothModel.selectors.selectToothByID(toothID),
  );

  const dentalNotationFormat = useAppSelector(
    organizationModel.selectors.selectDentalNotationFormat,
  );

  const { reportLabel, reportPath } = useGetReportConfigs({
    patientID,
    reportID,
    isWide,
  });

  const patientLabel = getPatientLabel({
    patient: currentPatient as Patient,
    isWide,
    isLarge,
  });

  const patientProfilePath = generatePath(PATHS.patient, {
    patientID,
  });

  const toothNumber = getDisplayToothNumber(
    currentTooth?.Numeration?.ISO ?? 0,
    dentalNotationFormat,
  );

  const toothLabel = toothNumber
    ? `${formatMessage({
        id: 'global.tooth',
        defaultMessage: 'Tooth',
      })} ${toothNumber}`
    : '';

  const printLabel = isPrintPage
    ? formatMessage({
        id: 'global.printReport',
        defaultMessage: 'Print Report',
      })
    : '';

  const shortPrintLabel = printLabel
    ? `${pipe(split(' '), head)(printLabel)}...`
    : '';

  return {
    patient: {
      label: patientLabel,
      path: reportID ? patientProfilePath : '',
    },
    report: {
      label: reportLabel,
      path: toothID || isPrintPage ? reportPath || '' : '',
    },
    tooth: {
      label: toothLabel,
      path: '',
    },
    print: {
      label: isWide ? printLabel : shortPrintLabel,
      path: '',
    },
  };
};
