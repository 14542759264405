// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_report_type_photo_gp.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { Certainty } from "./dto_report_certainty_pb.js";

/**
 * @generated from message com.diagnocat.model.Report_DentalPhoto_GP
 */
export const Report_DentalPhoto_GP = proto3.makeMessageType(
  "com.diagnocat.model.Report_DentalPhoto_GP",
  () => [
    { no: 1, name: "TypePredictions", kind: "message", T: Photo_GP_TypePrediction, repeated: true },
  ],
);

/**
 * Image Type inference for Photos
 * Finding the positive values should be implemented by looking over each com.diagnocat.backbone.pipelines.com.diagnocat.model.ConditionCertainty to find a POSITIVE variant
 *
 * @generated from message com.diagnocat.model.Photo_GP_TypePrediction
 */
export const Photo_GP_TypePrediction = proto3.makeMessageType(
  "com.diagnocat.model.Photo_GP_TypePrediction",
  () => [
    { no: 1, name: "AssetID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "ImageType", kind: "message", T: Photo_GP_TypePrediction_Photo_GP_TypePrediction_ImageType },
    { no: 12, name: "IntraoralSubtype", kind: "message", T: Photo_GP_TypePrediction_Photo_GP_TypePrediction_IntraoralSubtype },
    { no: 13, name: "PortraitSubtype", kind: "message", T: Photo_GP_TypePrediction_Photo_GP_TypePrediction_PortraitSubtype },
    { no: 14, name: "PortraitSide", kind: "message", T: Photo_GP_TypePrediction_Photo_GP_TypePrediction_PortraitSide },
    { no: 15, name: "SmileType", kind: "message", T: Photo_GP_TypePrediction_Photo_GP_TypePrediction_SmileType },
    { no: 16, name: "Adult", kind: "message", T: Certainty },
    { no: 17, name: "Mirror", kind: "message", T: Certainty },
  ],
);

/**
 * @generated from message com.diagnocat.model.Photo_GP_TypePrediction.Photo_GP_TypePrediction_ImageType
 */
export const Photo_GP_TypePrediction_Photo_GP_TypePrediction_ImageType = proto3.makeMessageType(
  "com.diagnocat.model.Photo_GP_TypePrediction.Photo_GP_TypePrediction_ImageType",
  () => [
    { no: 1, name: "Intraoral", kind: "message", T: Certainty },
    { no: 2, name: "Portrait", kind: "message", T: Certainty },
    { no: 3, name: "Other", kind: "message", T: Certainty },
    { no: 4, name: "Lab", kind: "message", T: Certainty },
  ],
  {localName: "Photo_GP_TypePrediction_Photo_GP_TypePrediction_ImageType"},
);

/**
 * @generated from message com.diagnocat.model.Photo_GP_TypePrediction.Photo_GP_TypePrediction_IntraoralSubtype
 */
export const Photo_GP_TypePrediction_Photo_GP_TypePrediction_IntraoralSubtype = proto3.makeMessageType(
  "com.diagnocat.model.Photo_GP_TypePrediction.Photo_GP_TypePrediction_IntraoralSubtype",
  () => [
    { no: 1, name: "OcclusalLower", kind: "message", T: Certainty },
    { no: 2, name: "OcclusalUpper", kind: "message", T: Certainty },
    { no: 3, name: "OcclusalRight", kind: "message", T: Certainty },
    { no: 4, name: "OcclusalLeft", kind: "message", T: Certainty },
    { no: 5, name: "Frontal", kind: "message", T: Certainty },
  ],
  {localName: "Photo_GP_TypePrediction_Photo_GP_TypePrediction_IntraoralSubtype"},
);

/**
 * @generated from message com.diagnocat.model.Photo_GP_TypePrediction.Photo_GP_TypePrediction_PortraitSubtype
 */
export const Photo_GP_TypePrediction_Photo_GP_TypePrediction_PortraitSubtype = proto3.makeMessageType(
  "com.diagnocat.model.Photo_GP_TypePrediction.Photo_GP_TypePrediction_PortraitSubtype",
  () => [
    { no: 1, name: "Frontal", kind: "message", T: Certainty },
    { no: 2, name: "Oblique", kind: "message", T: Certainty },
    { no: 3, name: "Profile", kind: "message", T: Certainty },
  ],
  {localName: "Photo_GP_TypePrediction_Photo_GP_TypePrediction_PortraitSubtype"},
);

/**
 * @generated from message com.diagnocat.model.Photo_GP_TypePrediction.Photo_GP_TypePrediction_PortraitSide
 */
export const Photo_GP_TypePrediction_Photo_GP_TypePrediction_PortraitSide = proto3.makeMessageType(
  "com.diagnocat.model.Photo_GP_TypePrediction.Photo_GP_TypePrediction_PortraitSide",
  () => [
    { no: 1, name: "Left", kind: "message", T: Certainty },
    { no: 2, name: "Right", kind: "message", T: Certainty },
  ],
  {localName: "Photo_GP_TypePrediction_Photo_GP_TypePrediction_PortraitSide"},
);

/**
 * @generated from message com.diagnocat.model.Photo_GP_TypePrediction.Photo_GP_TypePrediction_SmileType
 */
export const Photo_GP_TypePrediction_Photo_GP_TypePrediction_SmileType = proto3.makeMessageType(
  "com.diagnocat.model.Photo_GP_TypePrediction.Photo_GP_TypePrediction_SmileType",
  () => [
    { no: 1, name: "NoSmile", kind: "message", T: Certainty },
    { no: 2, name: "Smile", kind: "message", T: Certainty },
    { no: 3, name: "Grin", kind: "message", T: Certainty },
  ],
  {localName: "Photo_GP_TypePrediction_Photo_GP_TypePrediction_SmileType"},
);

