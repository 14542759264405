import { compose } from 'ramda';

import { Tooth } from '@/shared/api/protocol-ts/model/dto_report_tooth_pb';

import { filterTeethFromRemovedOne } from './filterTeethFromRemovedOne';
import { mapTeethToIds } from './mapTeethToIds';

export const getROITeethIDsAfterRemoveOne = (
  ROITeeth: Tooth[],
  removedToothID: string,
) => {
  if (ROITeeth.length > 1) {
    return compose(mapTeethToIds, filterTeethFromRemovedOne)(
      removedToothID,
      ROITeeth,
    );
  }

  if (ROITeeth.length === 1) {
    return [''];
  }

  return undefined;
};
