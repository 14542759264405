// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_report_generation_error_codes.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";

/**
 * https://diagnocat.atlassian.net/wiki/spaces/PW/pages/246087681/New+Design+Errors
 *
 * @generated from enum com.diagnocat.model.ReportGenerationErrorCode
 */
export const ReportGenerationErrorCode = proto3.makeEnum(
  "com.diagnocat.model.ReportGenerationErrorCode",
  [
    {no: 0, name: "ReportGenerationErrorCode_Unspecified"},
    {no: 1, name: "ReportGenerationErrorCode_StudyUpload_Failed"},
    {no: 2, name: "ReportGenerationErrorCode_StudyUpload_Canceled"},
    {no: 3, name: "ReportGenerationErrorCode_GeneratedAssetUpload_Failed"},
    {no: 11, name: "ReportGenerationErrorCode_FileCannotBeRead"},
    {no: 20, name: "ReportGenerationErrorCode_Dicom_IsNot2DImage"},
    {no: 21, name: "ReportGenerationErrorCode_Dicom_IsNot3DImage"},
    {no: 22, name: "ReportGenerationErrorCode_Dicom_MultipleStudies"},
    {no: 40, name: "ReportGenerationErrorCode_Dicom_InsufficientVolume"},
    {no: 41, name: "ReportGenerationErrorCode_Dicom_DuplicateSlices"},
    {no: 42, name: "ReportGenerationErrorCode_Dicom_Metadata_NonDerivableVoxelSize"},
    {no: 43, name: "ReportGenerationErrorCode_Dicom_Metadata_NonDerivableRescaleInterceptAttribute"},
    {no: 44, name: "ReportGenerationErrorCode_Dicom_Metadata_NonDerivableRescaleSlopeAttribute"},
    {no: 45, name: "ReportGenerationErrorCode_Dicom_Metadata_NonDerivableImagePositionPatientAttribute"},
    {no: 46, name: "ReportGenerationErrorCode_Dicom_Metadata_UnexpectedModality"},
    {no: 47, name: "ReportGenerationErrorCode_Dicom_Metadata_NonDerivableSlicePosition"},
    {no: 48, name: "ReportGenerationErrorCode_Dicom_Metadata_NoPixelData"},
    {no: 49, name: "ReportGenerationErrorCode_Dicom_Metadata_InvalidVoxelSize"},
    {no: 60, name: "ReportGenerationErrorCode_InputData_UnexpectedStudyQuantity"},
    {no: 61, name: "ReportGenerationErrorCode_InputData_UnexpectedStudyContent"},
    {no: 62, name: "ReportGenerationErrorCode_InputData_BadTaskParameters"},
    {no: 80, name: "ReportGenerationErrorCode_Modeling_CbctIosMisalignment"},
    {no: 81, name: "ReportGenerationErrorCode_Modeling_IosWrongOcclusion"},
    {no: 82, name: "ReportGenerationErrorCode_Modeling_IosMissingJaw"},
    {no: 83, name: "ReportGenerationErrorCode_Modeling_RequestedToothNotFound"},
    {no: 84, name: "ReportGenerationErrorCode_Modeling_CbctIosImpossibleSuperimposition"},
    {no: 85, name: "ReportGenerationErrorCode_Modeling_CBCT_Endo_ToothRootsNotFound"},
    {no: 101, name: "ReportGenerationErrorCode_ValidTimeExpired"},
  ],
);

