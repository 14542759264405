import { FC, useRef, useCallback } from 'react';
import cn from 'classnames';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import {
  ErrorCode,
  FileRejection,
  FileWithPath,
  useDropzone,
} from 'react-dropzone';
import * as Accordion from '@radix-ui/react-accordion';
import { Control, Controller } from 'react-hook-form';

import { StudyType } from '@/shared/api/protocol-ts/model/dto_study_pb';
import gladCat from '@/shared/assets/images/cats/glad.png';
import { useAppSelector } from '@/shared/hooks';
import { Icon, InlineFormError } from '@/shared/ui';
import { orderReportName } from '@/shared/i18n';
import { OrderReportType } from '@/shared/config/types';
import { AssetType } from '@/shared/api/protocol-ts/model/dto_asset_pb';

// import { useBillingAccess } from '@/entities/billing';

import { ensureRefreshImageSrc } from '@/entities/assets';

import { selectUploadStudyTypes } from '../../config/i18n';
import { OrderReportDropzone } from '../OrderReportDropzone/OrderReportDropzone';
import { UploadStudyFile } from '../UploadStudyFile/UploadStudyFile';
import { selectStudyList } from '../../model/orderReportModal.selectors';
import {
  ACCEPTED_FILE_TYPES_FOR_DROPZONE_BY_ASSET_TYPE,
  MAX_BYTES_FOR_CBCT,
  MAX_BYTES_FOR_STUDIES,
  OrderReportModalStep,
  SELECT_INPUT_NAME,
  UploadStudyPayload,
  UploadStudyType,
} from '../../config';

import styles from './OrderReportStep.module.scss';

type OrderReportStepProps = {
  control: Control<UploadStudyPayload, unknown>;
  step?: OrderReportModalStep;
  orderReportType: OrderReportType;
  studyType: StudyType;
  assetType: UploadStudyType;
  files: FileWithPath[];
  currentStudyID?: string;
  errorMessage?: string;
  className?: string;
  testID?: string;
  onAddFiles: (files: FileWithPath[]) => void;
  onDeleteFiles: () => void;
  onError: (errorMessage: string) => void;
};

export const OrderReportStep: FC<OrderReportStepProps> = (props) => {
  const {
    className,
    testID,
    step,
    orderReportType,
    studyType,
    assetType,
    files,
    control,
    currentStudyID,
    errorMessage,
    onAddFiles,
    onDeleteFiles,
    onError,
  } = props;

  const { formatMessage } = useIntl();
  const accordionTriggerRef = useRef<HTMLButtonElement>(null);
  const studies = useAppSelector(selectStudyList(studyType));
  // const services = useBillingAccess();

  const handleRejectFiles = useCallback(
    (fileRejections: FileRejection[]) => {
      let currentErrorMessage: string;

      if (fileRejections.length > 0) {
        const errorCode = fileRejections?.at(0)?.errors?.at(0)?.code;

        switch (errorCode) {
          case ErrorCode.FileInvalidType: {
            currentErrorMessage = formatMessage({
              id: 'orderReportModal.wrongFile',
              defaultMessage: 'Wrong File(s) format',
            });
            break;
          }
          case ErrorCode.FileTooLarge: {
            const maxSize =
              assetType === AssetType.AssetType_Study_CBCT ? '10GB' : '250MB';

            currentErrorMessage = formatMessage(
              {
                id: 'orderReportModal.sizeError',
                defaultMessage: 'File(s) size must be less than {maxSize}',
              },
              { maxSize },
            );
            break;
          }
          default: {
            currentErrorMessage = formatMessage({
              id: 'uploadImage.wrongFile',
              defaultMessage: 'Some files are too large or incorrect format',
            });
            break;
          }
        }

        onError(currentErrorMessage);
      }
    },
    [assetType, formatMessage, onError],
  );

  const dropzoneState = useDropzone({
    onDropAccepted: onAddFiles,
    onDropRejected: handleRejectFiles,
    maxSize:
      studyType === StudyType.StudyType_CBCT
        ? MAX_BYTES_FOR_CBCT
        : MAX_BYTES_FOR_STUDIES,
    accept: ACCEPTED_FILE_TYPES_FOR_DROPZONE_BY_ASSET_TYPE[assetType],
    // disabled: !services[ServiceType.ServiceType_Upload_STL], // TODO: Make dynamic rule
  });

  const currentStudy = studies.find((study) => study.id === currentStudyID);

  const showStudySelect =
    studies.length > 0 && files.length === 0 && step !== 3;
  const showDropzone = studies.length === 0 && files.length === 0;
  const showUploadStudyFile = files.length > 0;

  return (
    <div data-testid={testID} className={cn(styles.container, className)}>
      <h4 className={cn(styles.title, 'h4')}>
        {step !== undefined && (
          <FormattedMessage
            id="orderReportModal.step"
            defaultMessage="Step {step}: "
            values={{ step }}
          />
        )}
        {studies.length === 0 ? (
          <FormattedMessage
            id="orderReportModal.uploadSubtitle"
            defaultMessage="Upload"
          />
        ) : (
          <FormattedMessage
            id="orderReportModal.selectSubtitle"
            defaultMessage="Select"
          />
        )}{' '}
        {step === 3
          ? formatMessage(selectUploadStudyTypes[assetType]).toLowerCase()
          : formatMessage(selectUploadStudyTypes[assetType])}
        {step === 3 && ' ('}
        {step === 3 &&
          formatMessage({
            id: 'global.optional',
            defaultMessage: 'Optional',
          }).toLowerCase()}
        {step === 3 && ')'}
      </h4>

      {showStudySelect && (
        <Accordion.Root
          className={styles.accordionRoot}
          type="single"
          collapsible
        >
          <Accordion.Item
            className={styles.accordionItem}
            value="study-accordion"
          >
            <Accordion.Trigger ref={accordionTriggerRef}>
              <div className={styles.accordionTrigger}>
                <div className={styles.accordionTriggerStudy}>
                  <div
                    className={cn(
                      styles.accordionTriggerImgWrapper,
                      !currentStudy?.previewSrc && styles.placeholder,
                    )}
                  >
                    <img
                      src={currentStudy?.previewSrc || gladCat}
                      className={cn(
                        currentStudy?.previewSrc
                          ? styles.accordionTriggerImg
                          : styles.accordionTriggerImgFallback,
                      )}
                      onError={ensureRefreshImageSrc}
                    />
                  </div>
                  <p className="p2">
                    <FormattedDate
                      value={currentStudy?.createdAt}
                      dateStyle="medium"
                    />
                  </p>
                </div>

                <div className={styles.accordionChevron}>
                  <Icon name="arrowDown" size={24} />
                </div>
              </div>
            </Accordion.Trigger>

            <Accordion.Content className={styles.accordionContent}>
              <div className={styles.accordionStudies}>
                {studies.map((study) => (
                  <Controller
                    key={study.id}
                    control={control}
                    name={SELECT_INPUT_NAME[step ?? 1]}
                    render={({ field: { value, name, onChange } }) => (
                      <label className={styles.studyLabel}>
                        <input
                          type="radio"
                          name={name}
                          value={value}
                          checked={value === study.id}
                          onClick={() => {
                            if (
                              accordionTriggerRef.current !== null &&
                              accordionTriggerRef.current.dataset.state ===
                                'open' // WARN: this hack needs to prevent reopen accordion on rappidly clicking on study
                            ) {
                              accordionTriggerRef.current.click();
                            }
                          }}
                          onChange={() => {
                            onChange(study.id);
                          }}
                        />
                        <div className={styles.study}>
                          <div
                            className={cn(
                              styles.studyImgWrapper,
                              !study.previewSrc && styles.placeholder,
                            )}
                          >
                            <img
                              className={cn(
                                study.previewSrc
                                  ? styles.studyImg
                                  : styles.studyImgFallback,
                                studyType ===
                                  StudyType.StudyType_IntraoralXRay &&
                                  styles.ioxRay,
                              )}
                              onError={ensureRefreshImageSrc}
                              src={study.previewSrc || gladCat}
                            />
                          </div>
                          <div className={styles.studyInfo}>
                            <h6 className="h6">
                              {formatMessage(orderReportName[orderReportType])}{' '}
                              <FormattedDate value={study.createdAt} />
                            </h6>
                            <p className="p2">
                              <FormattedMessage
                                id="orderReportModal.createdAt"
                                defaultMessage="Created at {date}"
                                values={{
                                  date: (
                                    <FormattedDate
                                      value={study.createdAt}
                                      dateStyle="medium"
                                    />
                                  ),
                                }}
                              />
                            </p>
                          </div>
                        </div>
                      </label>
                    )}
                  />
                ))}
              </div>

              <OrderReportDropzone dropzoneState={dropzoneState} />
            </Accordion.Content>
          </Accordion.Item>
        </Accordion.Root>
      )}

      {showDropzone && (
        <OrderReportDropzone
          dropzoneState={dropzoneState}
          canUploadFolder={step !== 3}
        />
      )}

      {showUploadStudyFile && (
        <UploadStudyFile
          files={files}
          onDelete={onDeleteFiles}
          errorText={errorMessage}
        />
      )}

      {!!errorMessage && <InlineFormError errorMessage={errorMessage} />}
    </div>
  );
};
