import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { take } from 'ramda';

import { PATHS } from '@/shared/config';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { getUserFullName, getUserInitials } from '@/shared/lib';
import { resetState } from '@/shared/model';
import { Organization } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { LogoutReq_LogoutOption } from '@/shared/api/protocol-ts/api/auth/svc_authentication_pb';

import { authModel } from '@/entities/auth';
import { ModalID, modalModel } from '@/entities/modal';
import {
  getOrganizationInitials,
  organizationModel,
} from '@/entities/organization';
import { userModel } from '@/entities/user';
import { getImageSrc } from '@/entities/assets';

export const useControlPanel = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector(userModel.selectors.selectCurrentUser);

  const currentOrganization = useAppSelector(
    organizationModel.selectors.selectCurrentOrganization,
  );

  const allOrganizations = useAppSelector(
    organizationModel.selectors.organizationSelectors.selectAll,
  );

  const isSingleOrganizationsUser = allOrganizations?.length === 1;

  const currentOrganizationInitials = getOrganizationInitials(
    currentOrganization as Organization,
  );

  const currentOrganizationName = currentOrganization?.Name;

  const currentOrganizationAsset = currentOrganization?.LogoAssetID;

  const userInitials = getUserInitials(user);

  const userFullName = getUserFullName(user);

  const userAvatarAsset = getImageSrc(user?.AvatarAssetID, 'thumbnail');

  const profileFullInitials = currentOrganization
    ? currentOrganizationInitials
    : userInitials;

  const profileInitials = take(2, profileFullInitials);

  const profileName = currentOrganization
    ? currentOrganizationName
    : userFullName;

  const profileAssetID = currentOrganization
    ? getImageSrc(currentOrganizationAsset ?? '', 'thumbnail')
    : getImageSrc(userAvatarAsset, 'thumbnail');
  const profileAvatarSize = isSingleOrganizationsUser ? 44 : 40;

  // TODO: [1/m] remove navigate(PATHS.patients) after updating the ProtectedRoutes
  const organizationChangeHandle = (organizationID: string) => {
    dispatch(
      organizationModel.thunks.setCurrentOrganizationByID(organizationID),
    );

    navigate(PATHS.patients);
  };

  const logoutHandle = async () => {
    await dispatch(authModel.thunks.refresh({}));

    await dispatch(
      authModel.thunks.logout({ Option: LogoutReq_LogoutOption.None }),
    ).unwrap();

    // TODO: REMOVE dispatch and localstorage cleaner when auth will work on new gRPC lib.
    // Think about to encapsulate this into thunk
    localStorage.removeItem('user');
    window.dispatchEvent(new Event('storage'));
    dispatch(organizationModel.actions.removeAll());
    dispatch(resetState());

    // NOTE: clear hubspot chat after logout
    window.HubSpotConversations?.clear({ resetWidget: true });

    navigate(PATHS.signIn);
  };

  const openGetFullAccessModalHandle = useCallback(() => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.GetFullAccessModal,
      }),
    );
  }, [dispatch]);

  const navigateToCompanySettingsHandle = () =>
    navigate({
      pathname: PATHS.companySettings,
      search: 'tab=CompanyInfo',
    });

  return {
    allOrganizations,
    profileAssetID,
    profileInitials,
    profileName,
    profileAvatarSize,
    currentOrganization,
    currentOrganizationAsset,
    currentOrganizationInitials,
    currentOrganizationName,
    isSingleOrganizationsUser,
    userAvatarAsset,
    userFullName,
    userInitials,
    openGetFullAccessModalHandle,
    logoutHandle,
    navigateToCompanySettingsHandle,
    organizationChangeHandle,
  };
};
