import { includes } from 'ramda';

import { Condition } from '@/shared/api/protocol-ts/model/dto_report_condition_pb';
import { ToothStatus } from '@/shared/config';
import { isConditionUncertain } from '@/shared/embeddedLibs/conditionsAndMasks';

import { PURPLE_CODES, RED_CODES, YELLOW_CODES } from '../config';

// We have condition status which eqauls to tooth status
// Also we have condition color. We have different functions for this.
// I think it's better to choose one of them and use it everywhere.
export const getCondtionStatus = (condition: Condition) => {
  const { Code } = condition;

  const isYellow = includes(Code, YELLOW_CODES);
  const isRed = includes(Code, RED_CODES);
  const isPurple = includes(Code, PURPLE_CODES);

  // TODO: [h,4] rewrite isConditionUncertain and add filter for uncertain conditions
  if (isYellow && isConditionUncertain(condition)) {
    return ToothStatus.lowProbability;
  }

  if (isRed) {
    return ToothStatus.unhealthy;
  }

  if (isPurple) {
    return ToothStatus.treated;
  }

  return ToothStatus.healthy;
};
