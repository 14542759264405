import { ProductState } from '@/shared/api/protocol-ts/api/billing_new/dto_product_new_pb';
import { Service } from '@/shared/api/protocol-ts/api/billing_new/dto_services_new_pb';

export const getServicesFromSubscriptionList = (products: ProductState[]) => {
  const services = products.reduce(
    (result, product) => result.concat(product?.Services),
    [] as Service[],
  );

  return services;
};
