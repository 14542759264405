import { Decision } from '@/shared/api/protocol-ts/model/dto_report_common_pb';
import { ConditionCode } from '@/shared/api/protocol-ts/model/dto_report_condition_codes_pb';
import { Condition } from '@/shared/api/protocol-ts/model/dto_report_condition_pb';
import { LOW_PROBABILITY_CONDITION_CODES, ToothStatus } from '@/shared/config';
import {
  isConditionUncertain,
  shouldConditionItemBeShown,
} from '@/shared/embeddedLibs/conditionsAndMasks';

import { getCondtionStatus } from '../condition';

import { ToothCondition, ToothConditions } from './model/logicalConditionSlice';

const conditionEngineDecisionIsPositive = (condition: Condition) =>
  condition.Certainty?.EngineDecision === Decision.PositiveDecision;
// Use this in the selector to iterate over the conditions of each tooth
/**
 * @deprecated
 * */
export const getToothStatus = (
  toothConditions: ToothCondition,
  showLowProbability = false,
  isFDA = false,
  isFDANonAided = false,
) => {
  // TODO [4|h] Find out why there are no conditions for supernumerary teeth
  if (!toothConditions) {
    return ToothStatus.healthy;
  }

  const missingConditions = toothConditions.conditions[ConditionCode.Missing];
  const missingConditionsList = Object.values(missingConditions || {});

  if (missingConditionsList.some(conditionEngineDecisionIsPositive)) {
    return ToothStatus.missing;
  }

  if (isFDA) {
    if (!isFDANonAided) {
      const periapicalRadiolucencyConditions =
        toothConditions.conditions[ConditionCode.PeriapicalRadiolucency];

      const hasUnhealthyCondition =
        periapicalRadiolucencyConditions &&
        Object.values(periapicalRadiolucencyConditions).some(
          conditionEngineDecisionIsPositive,
        );

      return hasUnhealthyCondition
        ? ToothStatus.unhealthy
        : ToothStatus.healthy;
    }
    return ToothStatus.healthy;
  }

  const conditionStatuses = new Map<ToothStatus, boolean>();
  const childConditions = Object.values(
    toothConditions.childConditions,
  ).flatMap((item) => Object.values(item));

  const allConditions = Object.values(toothConditions.conditions).concat(
    childConditions,
  );

  allConditions.forEach((conditions) => {
    const conditionsWithoutPBL = Object.values(conditions).filter(
      (condition) => condition.Code !== ConditionCode.PeriodontalBoneLoss,
    );

    const condition = conditionsWithoutPBL.sort(
      (a, b) => (b.Certainty?.ModelScore ?? 0) - (a.Certainty?.ModelScore ?? 0),
    )?.[0];

    if (shouldConditionItemBeShown(showLowProbability)(condition)) {
      const conditionStatus = getCondtionStatus(condition);

      conditionStatuses.set(conditionStatus, true);
    }
  });

  if (showLowProbability && conditionStatuses.get(ToothStatus.lowProbability)) {
    return ToothStatus.lowProbability;
  }

  if (conditionStatuses.get(ToothStatus.unhealthy)) {
    return ToothStatus.unhealthy;
  }

  if (conditionStatuses.get(ToothStatus.treated)) {
    return ToothStatus.treated;
  }

  return ToothStatus.healthy;
};

export const findLowProbabilityConditionIDs = (
  toothConditions: ToothConditions,
) => {
  const toothConditionList = Object.values(toothConditions);

  return toothConditionList.reduce((conditionIDs, toothCondition) => {
    LOW_PROBABILITY_CONDITION_CODES.forEach((lowProbabilityCode) => {
      const conditions = toothCondition.conditions[lowProbabilityCode];

      if (conditions) {
        const condition = Object.values(conditions).sort(
          (a, b) =>
            (b.Certainty?.ModelScore ?? 0) - (a.Certainty?.ModelScore ?? 0),
        )?.[0];

        if (isConditionUncertain(condition)) {
          conditionIDs.push(condition.ID);
        }
      }
    });
    return conditionIDs;
  }, [] as string[]);
};
