import { Report } from '@/shared/api/protocol-ts/model/dto_report_pb';

import { SignStatus } from '../config/reports.type';

export const getReportSignStatus = (report?: Report) => {
  const signStatus: SignStatus = report?.Signature?.Signed
    ? 'signed'
    : 'notSigned';

  const isReportWithoutSignature = Boolean(
    report?.Specific.case === 'DataCBCTOrtho',
  );

  return isReportWithoutSignature ? 'withoutSign' : signStatus;
};
