import { Report_2D_Image_Meta } from '@/shared/api/protocol-ts/model/dto_report_type_2D_common_pb';

// NOTE: In some cases the assetID will be matched with GeneratedAssetID.
// In other cases GeneratedAssetID will be empty and assetID will be matched with StudyAssetID.
export const getImageMetaIDByAssetID = (
  imagesMeta: Report_2D_Image_Meta[],
  assetID: string,
) =>
  imagesMeta.find(
    (imageMeta) =>
      imageMeta.GeneratedAssetID === assetID ||
      imageMeta.StudyAssetID === assetID,
  )?.ID || ''; // TODO: find more elegant solution
