// @generated by protoc-gen-connect-web v0.6.0 with parameter "target=ts,ts_nocheck=false"
// @generated from file api/core/svc_asset.proto (package com.diagnocat.core, syntax proto3)
/* eslint-disable */

import { DeleteAssetByFileIDReq, DeleteAssetReq, DeleteAssetResp, GetReportUploadedAssetsReq, GetReportUploadedAssetsResp, SetMedicalImageViewOptionsReq, SetMedicalImageViewOptionsResp, SetTopLayerAnnotationsReq, SetTopLayerAnnotationsResp } from "./svc_asset_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service com.diagnocat.core.AssetService
 */
export const AssetService = {
  typeName: "com.diagnocat.core.AssetService",
  methods: {
    /**
     * @generated from rpc com.diagnocat.core.AssetService.GetReportUploadedAssets
     */
    getReportUploadedAssets: {
      name: "GetReportUploadedAssets",
      I: GetReportUploadedAssetsReq,
      O: GetReportUploadedAssetsResp,
      kind: MethodKind.Unary,
    },
    /**
     * Update annotations in Layer with ReadOnly == false. If this layer not exists -> create new Layer
     *
     * Permission: `ReportPermissions.CanChangeReportAssets`
     *
     * @generated from rpc com.diagnocat.core.AssetService.SetTopLayerAnnotations
     */
    setTopLayerAnnotations: {
      name: "SetTopLayerAnnotations",
      I: SetTopLayerAnnotationsReq,
      O: SetTopLayerAnnotationsResp,
      kind: MethodKind.Unary,
    },
    /**
     * Update viewOptions in report assets (AssetType_Report_Generated || AssetType_Report_Uploaded)
     *
     * Permission: `ReportPermissions.CanChangeReportAssets`
     *
     * @generated from rpc com.diagnocat.core.AssetService.SetMedicalImageViewOptions
     */
    setMedicalImageViewOptions: {
      name: "SetMedicalImageViewOptions",
      I: SetMedicalImageViewOptionsReq,
      O: SetMedicalImageViewOptionsResp,
      kind: MethodKind.Unary,
    },
    /**
     * Only for User, Organization or Patient assets
     *
     * Permission: `OrganizationPermissions.CanEditInfo` - organization assets
     * Permission: `UserPermissions.CanEditInfo` - user assets
     * Permission: `PatientPermissions.CanEditPatient` - patient documents
     * Permission: `ReportPermissions.CanChangeReportAssets` - report assets
     * Permission: `ReportPermissions.CanChangeToothAssets` - teeth assets
     *
     * @generated from rpc com.diagnocat.core.AssetService.DeleteAsset
     */
    deleteAsset: {
      name: "DeleteAsset",
      I: DeleteAssetReq,
      O: DeleteAssetResp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.diagnocat.core.AssetService.DeleteAssetByFileID
     */
    deleteAssetByFileID: {
      name: "DeleteAssetByFileID",
      I: DeleteAssetByFileIDReq,
      O: DeleteAssetResp,
      kind: MethodKind.Unary,
    },
  }
} as const;

