// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file api/marketing/dto_questionnaire.proto (package com.diagnocat.marketing, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message com.diagnocat.marketing.OrganizationOnboardingQuestionnaire_V1
 */
export const OrganizationOnboardingQuestionnaire_V1 = proto3.makeMessageType(
  "com.diagnocat.marketing.OrganizationOnboardingQuestionnaire_V1",
  () => [
    { no: 1, name: "OwnerSpecialization", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "CompanyProfile", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "CBCTMachineType", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "CBCTImagingSoftware", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "XRayImagingSoftware", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "FocusOfInterest", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

