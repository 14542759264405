// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_upload_session.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { AssetType } from "./dto_asset_pb.js";
import { Created } from "./dto_base_pb.js";
import { File } from "./dto_asset_common_pb.js";

/**
 * @generated from message com.diagnocat.model.UploadSession
 */
export const UploadSession = proto3.makeMessageType(
  "com.diagnocat.model.UploadSession",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "AssetType", kind: "enum", T: proto3.getEnumType(AssetType) },
    { no: 3, name: "Files", kind: "message", T: UploadSessionFile, repeated: true },
    { no: 10, name: "Target", kind: "message", T: UploadSessionTarget },
    { no: 21, name: "Closed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10030, name: "Created", kind: "message", T: Created },
  ],
);

/**
 * @generated from message com.diagnocat.model.UploadSessionTarget
 */
export const UploadSessionTarget = proto3.makeMessageType(
  "com.diagnocat.model.UploadSessionTarget",
  () => [
    { no: 10, name: "UserID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "PatientID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "StudyID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "GeneratedFromStudyIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 15, name: "ReportID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.model.UploadSessionFile
 */
export const UploadSessionFile = proto3.makeMessageType(
  "com.diagnocat.model.UploadSessionFile",
  () => [
    { no: 1, name: "File", kind: "message", T: File },
    { no: 2, name: "InProcess", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "Uploaded", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "StartedAt", kind: "message", T: Timestamp },
    { no: 5, name: "LastInProcessAt", kind: "message", T: Timestamp },
    { no: 6, name: "UploadedAt", kind: "message", T: Timestamp },
  ],
);

/**
 * FileID or Path used as identifier
 *
 * @generated from message com.diagnocat.model.UploadTarget
 */
export const UploadTarget = proto3.makeMessageType(
  "com.diagnocat.model.UploadTarget",
  () => [
    { no: 1, name: "FileID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "UploadURL", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

