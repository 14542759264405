import { defineMessages } from 'react-intl';

import {
  ServiceGroupName,
  ServiceType,
} from '../api/protocol-ts/api/billing_new/dto_services_new_pb';

export const serviceNamesDefaultMessage = defineMessages<ServiceType>({
  [ServiceType.ServiceTypeInvalidValue]: {
    id: 'subscriptions.service.0',
    defaultMessage: 'Invalid Value',
  },
  [ServiceType.ServiceType_Upload_CBCT]: {
    id: 'subscriptions.service.1',
    defaultMessage: 'Upload CBCT',
  },
  [ServiceType.ServiceType_Upload_IOXRay]: {
    id: 'subscriptions.service.2',
    defaultMessage: 'Upload Intraoral X-Ray',
  },
  [ServiceType.ServiceType_Upload_Panorama]: {
    id: 'subscriptions.service.3',
    defaultMessage: 'Upload Panoramic',
  },
  [ServiceType.ServiceType_Upload_STL]: {
    id: 'subscriptions.service.4',
    defaultMessage: 'STL Uploads',
  },
  [ServiceType.ServiceType_Upload_DentalPhoto]: {
    id: 'subscriptions.service.5',
    defaultMessage: 'Dental Photo Uploads',
  },
  [ServiceType.ServiceType_Upload_Documents]: {
    id: 'subscriptions.service.6',
    defaultMessage: 'Documents upload',
  },
  // Both countable and enabler
  [ServiceType.ServiceType_Order_CBCT_GP_Analysis]: {
    id: 'subscriptions.service.100',
    defaultMessage:
      '{amount, plural, =0 {Radiology Report} one {{amount} Radiology Report} other {{amount} Radiology Reports}}',
  },
  // Both countable and enabler
  [ServiceType.ServiceType_Order_CBCT_Endodontic_Analysis]: {
    id: 'subscriptions.service.101',
    defaultMessage:
      '{amount, plural, =0 {Endo Report} one {{amount} Endo Report} other {{amount} Endo Reports}}',
  },
  // Both countable and enabler
  [ServiceType.ServiceType_Order_CBCT_Implantology_Analysis]: {
    id: 'subscriptions.service.102',
    defaultMessage:
      '{amount, plural, =0 {Implant Report} one {{amount} Implant Report} other {{amount} Implant Reports}}',
  },
  // Both countable and enabler
  [ServiceType.ServiceType_Order_CBCT_ThirdMolar_Analysis]: {
    id: 'subscriptions.service.103',
    defaultMessage:
      '{amount, plural, =0 {3rd Molar Report} one {{amount} 3rd Molar Report} other {{amount} 3rd Molar Reports}}',
  },
  // Both countable and enabler
  [ServiceType.ServiceType_Order_CBCT_STL_Analysis]: {
    id: 'subscriptions.service.104',
    defaultMessage:
      '{amount, plural, =0 {DICOM to STL conversion} one {{amount} DICOM to STL conversion} other {{amount} DICOM to STL conversions}}',
  },
  // Both countable and enabler
  [ServiceType.ServiceType_Order_CBCT_Orthodontic_Analysis]: {
    id: 'subscriptions.service.105',
    defaultMessage:
      '{amount, plural, =0 {Ortho Report} one {{amount} Ortho Report} other {{amount} Ortho Reports}}',
  },
  // Both countable and enabler
  [ServiceType.ServiceType_Order_CBCT_IOS_Superimposition_Analysis]: {
    id: 'subscriptions.service.106',
    defaultMessage:
      '{amount, plural, =0 {CBCT IOS Superimposition} other {{amount} CBCT IOS Superimposition}}',
  },
  // Both countable and enabler
  [ServiceType.ServiceType_Order_IOS_Segmentation_Analysis]: {
    id: 'subscriptions.service.107',
    defaultMessage:
      '{amount, plural, =0 {IOS Segmentation} one {{amount} IOS Segmentation} other {{amount} IOS Segmentations}}',
  },
  // Both countable and enabler
  [ServiceType.ServiceType_Order_IOXRay_GP_Analysis]: {
    id: 'subscriptions.service.108',
    defaultMessage:
      '{amount, plural, =0 {FMX AI Report} one {{amount} FMX AI Report} other {{amount} FMX AI Reports}}',
  },
  // Both countable and enabler
  [ServiceType.ServiceType_Order_Panoramic_GP_Analysis]: {
    id: 'subscriptions.service.109',
    defaultMessage:
      '{amount, plural, =0 {Pano AI Report} one {{amount} Pano AI Report} other {{amount} Pano AI Reports}}',
  },
  // Both countable and enabler
  [ServiceType.ServiceType_Order_DentalPhoto_GP_Analysis]: {
    id: 'subscriptions.service.110',
    defaultMessage:
      '{amount, plural, =0 {Dental Photo Classification} one {{amount} Dental Photo Classification} other {{amount} Dental Photo Classification}}',
  },
  [ServiceType.ServiceType_Order_ImplantStudio_Analysis]: {
    id: 'subscriptions.service.111',
    defaultMessage: 'Implant studio analysis',
  },
  [ServiceType.ServiceType_Order_OrthoStudio_Analysis]: {
    id: 'subscriptions.service.112',
    defaultMessage: 'Ortho studio analysis',
  },
  // Only enabler
  [ServiceType.ServiceType_Enable_CollaborationTool]: {
    id: 'subscriptions.service.200',
    defaultMessage: 'Collaboration Tool',
  },
  // Only enabler
  [ServiceType.ServiceType_Enable_STLViewer]: {
    id: 'subscriptions.service.201',
    defaultMessage: 'STL Viewer',
  },
  [ServiceType.ServiceType_Enable_DesktopApp]: {
    id: 'subscriptions.service.202',
    defaultMessage: 'Desktop application',
  },
});

export const serviceGroupNamesDefaultMessage = defineMessages<ServiceGroupName>(
  {
    [ServiceGroupName.ServiceGroupNameInvalidValue]: {
      id: 'subscriptions.serviceGroupNames.0',
      defaultMessage: 'Invalid value',
    },
    [ServiceGroupName.ServiceGroupNameEmpty]: {
      id: 'subscriptions.serviceGroupNames.1',
      defaultMessage: 'Empty group name',
    },
    [ServiceGroupName.ServiceGroupNameAnalysis3D]: {
      id: 'subscriptions.serviceGroupNames.3',
      defaultMessage: '3D analysis',
    },
    [ServiceGroupName.ServiceGroupNameAnalysis2D]: {
      id: 'subscriptions.serviceGroupNames.4',
      defaultMessage: '2D analysis',
    },
    [ServiceGroupName.ServiceGroupNameUpload3D]: {
      id: 'subscriptions.serviceGroupNames.5',
      defaultMessage: '3D uploads',
    },
    [ServiceGroupName.ServiceGroupNameUpload2D]: {
      id: 'subscriptions.serviceGroupNames.6',
      defaultMessage: '2D uploads',
    },
  },
);
