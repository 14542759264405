// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file api/core/svc_access.proto (package com.diagnocat.core, syntax proto3)
/* eslint-disable */

import { Empty, proto3 } from "@bufbuild/protobuf";
import { Invitation, InvitationTarget, InvitationTarget_OrganizationMembership } from "../../model/dto_access_pb.js";
import { Organization, OrganizationUserRoleType } from "../../model/dto_organization_pb.js";
import { Patient } from "../../model/dto_patient_pb.js";
import { PatientTask } from "../../model/dto_patient_task_pb.js";

/**
 * @generated from enum com.diagnocat.core.SharedByMeStreamSortBy
 */
export const SharedByMeStreamSortBy = proto3.makeEnum(
  "com.diagnocat.core.SharedByMeStreamSortBy",
  [
    {no: 0, name: "SharedByMeStreamSortByPatientFirstName"},
    {no: 1, name: "SharedByMeStreamSortByPatientLastName"},
    {no: 2, name: "SharedByMeStreamSortBySenderFirstName"},
    {no: 3, name: "SharedByMeStreamSortBySenderLastName"},
    {no: 4, name: "SharedByMeStreamSortByRecipientEmail"},
    {no: 5, name: "SharedByMeStreamSortBySharingDate"},
    {no: 6, name: "SharedByMeStreamSortByStatus"},
  ],
);

/**
 * @generated from enum com.diagnocat.core.SharedWithMeStreamSortBy
 */
export const SharedWithMeStreamSortBy = proto3.makeEnum(
  "com.diagnocat.core.SharedWithMeStreamSortBy",
  [
    {no: 0, name: "SharedWithMeStreamSortByClinicName"},
    {no: 1, name: "SharedWithMeStreamSortByPatientFirstName"},
    {no: 2, name: "SharedWithMeStreamSortByPatientLastName"},
    {no: 3, name: "SharedWithMeStreamSortBySenderFirstName"},
    {no: 4, name: "SharedWithMeStreamSortBySenderLastName"},
    {no: 5, name: "SharedWithMeStreamSortBySenderEmail"},
    {no: 6, name: "SharedWithMeStreamSortBySharingDate"},
    {no: 7, name: "SharedWithMeStreamSortByStatus"},
  ],
);

/**
 * @generated from message com.diagnocat.core.SharedByMeStreamReq
 */
export const SharedByMeStreamReq = proto3.makeMessageType(
  "com.diagnocat.core.SharedByMeStreamReq",
  () => [
    { no: 1, name: "ResumeToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "SearchString", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "Sort", kind: "message", T: SharedByMeStreamSort, repeated: true },
    { no: 100, name: "StartFromInvitationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 101, name: "Limit", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.SharedByMeStreamSort
 */
export const SharedByMeStreamSort = proto3.makeMessageType(
  "com.diagnocat.core.SharedByMeStreamSort",
  () => [
    { no: 1, name: "By", kind: "enum", T: proto3.getEnumType(SharedByMeStreamSortBy) },
    { no: 2, name: "Ascending", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.SharedWithMeStreamReq
 */
export const SharedWithMeStreamReq = proto3.makeMessageType(
  "com.diagnocat.core.SharedWithMeStreamReq",
  () => [
    { no: 1, name: "ResumeToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "SearchString", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "Sort", kind: "message", T: SharedWithMeStreamSort, repeated: true },
    { no: 100, name: "StartFromInvitationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 101, name: "Limit", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.SharedWithMeStreamSort
 */
export const SharedWithMeStreamSort = proto3.makeMessageType(
  "com.diagnocat.core.SharedWithMeStreamSort",
  () => [
    { no: 1, name: "By", kind: "enum", T: proto3.getEnumType(SharedWithMeStreamSortBy) },
    { no: 2, name: "Ascending", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.InvitationListStreamResp
 */
export const InvitationListStreamResp = proto3.makeMessageType(
  "com.diagnocat.core.InvitationListStreamResp",
  () => [
    { no: 1, name: "ResumeToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "InitialInvitationsList", kind: "message", T: InvitationListStreamResp_InitialInvitations, oneof: "Update" },
    { no: 11, name: "InvitationUpdated", kind: "message", T: Invitation, oneof: "Update" },
    { no: 21, name: "TotalOrganizationPatientCount", kind: "scalar", T: 13 /* ScalarType.UINT32 */, oneof: "Update" },
    { no: 22, name: "SharedWithMeCount", kind: "scalar", T: 13 /* ScalarType.UINT32 */, oneof: "Update" },
    { no: 23, name: "SharedByMeCount", kind: "scalar", T: 13 /* ScalarType.UINT32 */, oneof: "Update" },
    { no: 101, name: "Ping", kind: "message", T: Empty, oneof: "Update" },
  ],
);

/**
 * @generated from message com.diagnocat.core.InvitationListStreamResp.InitialInvitations
 */
export const InvitationListStreamResp_InitialInvitations = proto3.makeMessageType(
  "com.diagnocat.core.InvitationListStreamResp.InitialInvitations",
  () => [
    { no: 1, name: "Invitations", kind: "message", T: Invitation, repeated: true },
  ],
  {localName: "InvitationListStreamResp_InitialInvitations"},
);

/**
 * @generated from message com.diagnocat.core.GetInvitationByTokenReq
 */
export const GetInvitationByTokenReq = proto3.makeMessageType(
  "com.diagnocat.core.GetInvitationByTokenReq",
  () => [
    { no: 1, name: "Token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.GetInvitationByTokenResp
 */
export const GetInvitationByTokenResp = proto3.makeMessageType(
  "com.diagnocat.core.GetInvitationByTokenResp",
  () => [
    { no: 1, name: "Invitation", kind: "message", T: Invitation },
  ],
);

/**
 * @generated from message com.diagnocat.core.InviteReq
 */
export const InviteReq = proto3.makeMessageType(
  "com.diagnocat.core.InviteReq",
  () => [
    { no: 1, name: "Recipient", kind: "message", T: InviteReq_RecipientContacts },
    { no: 2, name: "Target", kind: "message", T: InvitationTarget },
    { no: 3, name: "Comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.InviteReq.RecipientContacts
 */
export const InviteReq_RecipientContacts = proto3.makeMessageType(
  "com.diagnocat.core.InviteReq.RecipientContacts",
  () => [
    { no: 1, name: "Phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "InviteReq_RecipientContacts"},
);

/**
 * @generated from message com.diagnocat.core.InviteResp
 */
export const InviteResp = proto3.makeMessageType(
  "com.diagnocat.core.InviteResp",
  () => [
    { no: 1, name: "CreatedInvitation", kind: "message", T: Invitation },
    { no: 11, name: "AutoAcceptedOrganizationInvitation", kind: "message", T: InviteResp_AutoAcceptedOrganizationInvitationDetails },
  ],
);

/**
 * These details are provided if an invitation to organization is automatically accepted.
 * In this case just modified organization's data is provided [1]
 * Also all sharing invitations (from this organization) previously given to the invitee could become cancelled [2]
 * and the invitee will be added to these (previously shared) patients [3] as a treatment team member
 *
 * @generated from message com.diagnocat.core.InviteResp.AutoAcceptedOrganizationInvitationDetails
 */
export const InviteResp_AutoAcceptedOrganizationInvitationDetails = proto3.makeMessageType(
  "com.diagnocat.core.InviteResp.AutoAcceptedOrganizationInvitationDetails",
  () => [
    { no: 1, name: "Organization", kind: "message", T: Organization },
    { no: 2, name: "CancelledInvitations", kind: "message", T: Invitation, repeated: true },
    { no: 3, name: "Patients", kind: "message", T: Patient, repeated: true },
  ],
  {localName: "InviteResp_AutoAcceptedOrganizationInvitationDetails"},
);

/**
 * @generated from message com.diagnocat.core.CancelInvitationReq
 */
export const CancelInvitationReq = proto3.makeMessageType(
  "com.diagnocat.core.CancelInvitationReq",
  () => [
    { no: 1, name: "InvitationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.CancelInvitationResp
 */
export const CancelInvitationResp = proto3.makeMessageType(
  "com.diagnocat.core.CancelInvitationResp",
  () => [
    { no: 1, name: "Invitation", kind: "message", T: Invitation },
  ],
);

/**
 * @generated from message com.diagnocat.core.AcceptInvitationReq
 */
export const AcceptInvitationReq = proto3.makeMessageType(
  "com.diagnocat.core.AcceptInvitationReq",
  () => [
    { no: 1, name: "InvitationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Secret", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.AcceptInvitationResp
 */
export const AcceptInvitationResp = proto3.makeMessageType(
  "com.diagnocat.core.AcceptInvitationResp",
  () => [
    { no: 1, name: "Invitations", kind: "message", T: Invitation, repeated: true },
    { no: 2, name: "Organization", kind: "message", T: Organization },
    { no: 3, name: "Patients", kind: "message", T: Patient, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetOrganizationInvitationUserInfoReq
 */
export const SetOrganizationInvitationUserInfoReq = proto3.makeMessageType(
  "com.diagnocat.core.SetOrganizationInvitationUserInfoReq",
  () => [
    { no: 1, name: "InvitationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Membership", kind: "message", T: InvitationTarget_OrganizationMembership },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetOrganizationInvitationUserInfoResp
 */
export const SetOrganizationInvitationUserInfoResp = proto3.makeMessageType(
  "com.diagnocat.core.SetOrganizationInvitationUserInfoResp",
  () => [
    { no: 1, name: "Invitation", kind: "message", T: Invitation },
  ],
);

/**
 * @generated from message com.diagnocat.core.RevokeSharingReq
 */
export const RevokeSharingReq = proto3.makeMessageType(
  "com.diagnocat.core.RevokeSharingReq",
  () => [
    { no: 1, name: "UserID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "PatientID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.RevokeSharingResp
 */
export const RevokeSharingResp = proto3.makeMessageType(
  "com.diagnocat.core.RevokeSharingResp",
  () => [
    { no: 1, name: "Patient", kind: "message", T: Patient },
    { no: 2, name: "Invitation", kind: "message", T: Invitation },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetPatientTeamMembersReq
 */
export const SetPatientTeamMembersReq = proto3.makeMessageType(
  "com.diagnocat.core.SetPatientTeamMembersReq",
  () => [
    { no: 1, name: "PatientID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "UserIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetPatientTeamMembersResp
 */
export const SetPatientTeamMembersResp = proto3.makeMessageType(
  "com.diagnocat.core.SetPatientTeamMembersResp",
  () => [
    { no: 1, name: "Patient", kind: "message", T: Patient },
    { no: 2, name: "Tasks", kind: "message", T: PatientTask, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetUserOrganizationRolesReq
 */
export const SetUserOrganizationRolesReq = proto3.makeMessageType(
  "com.diagnocat.core.SetUserOrganizationRolesReq",
  () => [
    { no: 1, name: "UserID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "Roles", kind: "enum", T: proto3.getEnumType(OrganizationUserRoleType), repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetUserOrganizationRolesResp
 */
export const SetUserOrganizationRolesResp = proto3.makeMessageType(
  "com.diagnocat.core.SetUserOrganizationRolesResp",
  () => [
    { no: 1, name: "Organization", kind: "message", T: Organization },
    { no: 2, name: "Patients", kind: "message", T: Patient, repeated: true },
  ],
);

