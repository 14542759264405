// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_report_common.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { CBCT_Anatomy } from "./dto_common_anatomy_pb.js";

/**
 * @generated from enum com.diagnocat.model.Decision
 */
export const Decision = proto3.makeEnum(
  "com.diagnocat.model.Decision",
  [
    {no: 0, name: "NoDecision"},
    {no: 1, name: "NegativeDecision"},
    {no: 2, name: "PositiveDecision"},
  ],
);

/**
 * @generated from enum com.diagnocat.model.JawType
 */
export const JawType = proto3.makeEnum(
  "com.diagnocat.model.JawType",
  [
    {no: 0, name: "JawType_InvalidVariant"},
    {no: 1, name: "JawType_Maxilla"},
    {no: 2, name: "JawType_Mandible"},
  ],
);

/**
 * @generated from enum com.diagnocat.model.IOS_Anatomy
 */
export const IOS_Anatomy = proto3.makeEnum(
  "com.diagnocat.model.IOS_Anatomy",
  [
    {no: 0, name: "IOS_Anatomy_InvalidValue"},
    {no: 1, name: "IOS_Anatomy_Original_Mandible"},
    {no: 2, name: "IOS_Anatomy_Original_Maxilla"},
    {no: 3, name: "IOS_Anatomy_GingivaUpper"},
    {no: 4, name: "IOS_Anatomy_GingivaLower"},
  ],
);

/**
 * @generated from enum com.diagnocat.model.CBCT_Tooth_RootType
 */
export const CBCT_Tooth_RootType = proto3.makeEnum(
  "com.diagnocat.model.CBCT_Tooth_RootType",
  [
    {no: 0, name: "RootType_InvalidValue"},
    {no: 1, name: "RootType_Unspecified"},
    {no: 2, name: "RootType_SingleRoot"},
    {no: 3, name: "RootType_Buccal"},
    {no: 4, name: "RootType_Lingual"},
    {no: 5, name: "RootType_Palatal"},
    {no: 6, name: "RootType_Mesial"},
    {no: 7, name: "RootType_Mesial1"},
    {no: 8, name: "RootType_Mesial2"},
    {no: 9, name: "RootType_MesialBuccal"},
    {no: 10, name: "RootType_MesialLingual"},
    {no: 11, name: "RootType_Distal"},
    {no: 12, name: "RootType_DistalBuccal"},
    {no: 13, name: "RootType_DistalLingual"},
    {no: 14, name: "RootType_Average"},
  ],
);

/**
 * @generated from message com.diagnocat.model.ToothNumeration
 */
export const ToothNumeration = proto3.makeMessageType(
  "com.diagnocat.model.ToothNumeration",
  () => [
    { no: 1, name: "ISO", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "SupernumeraryIndex", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * @generated from message com.diagnocat.model.Report_CBCT_IOS_Segment
 */
export const Report_CBCT_IOS_Segment = proto3.makeMessageType(
  "com.diagnocat.model.Report_CBCT_IOS_Segment",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "CBCTToothISO", kind: "scalar", T: 13 /* ScalarType.UINT32 */, oneof: "Structure" },
    { no: 3, name: "IOSToothISO", kind: "scalar", T: 13 /* ScalarType.UINT32 */, oneof: "Structure" },
    { no: 4, name: "FusedToothISO", kind: "scalar", T: 13 /* ScalarType.UINT32 */, oneof: "Structure" },
    { no: 5, name: "IOSAnatomy", kind: "enum", T: proto3.getEnumType(IOS_Anatomy), oneof: "Structure" },
    { no: 6, name: "CBCTAnatomy", kind: "enum", T: proto3.getEnumType(CBCT_Anatomy), oneof: "Structure" },
  ],
);

