// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_legacy.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message com.diagnocat.model.EmptyPayload
 */
export const EmptyPayload = proto3.makeMessageType(
  "com.diagnocat.model.EmptyPayload",
  [],
);

/**
 * @generated from message com.diagnocat.model.ReportLegacyPayload
 */
export const ReportLegacyPayload = proto3.makeMessageType(
  "com.diagnocat.model.ReportLegacyPayload",
  () => [
    { no: 1, name: "Key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "JSON", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

