// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_report_periodontal_status.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { CBCT_Tooth_RootType, ToothNumeration } from "./dto_report_common_pb.js";

/**
 * @generated from enum com.diagnocat.model.ToothPeriodontalStatusMeasurement_Site
 */
export const ToothPeriodontalStatusMeasurement_Site = proto3.makeEnum(
  "com.diagnocat.model.ToothPeriodontalStatusMeasurement_Site",
  [
    {no: 0, name: "PeriodontalMeasurement_Site_InvalidValue"},
    {no: 1, name: "PeriodontalMeasurement_Site_Mesial"},
    {no: 2, name: "PeriodontalMeasurement_Site_MesialBuccal"},
    {no: 3, name: "PeriodontalMeasurement_Site_MesialLingual"},
    {no: 4, name: "PeriodontalMeasurement_Site_Distal"},
    {no: 5, name: "PeriodontalMeasurement_Site_DistalBuccal"},
    {no: 6, name: "PeriodontalMeasurement_Site_DistalLingual"},
    {no: 7, name: "PeriodontalMeasurement_Site_Vestibular"},
    {no: 8, name: "PeriodontalMeasurement_Site_Oral"},
  ],
);

/**
 * @generated from message com.diagnocat.model.ToothPeriodontalStatus
 */
export const ToothPeriodontalStatus = proto3.makeMessageType(
  "com.diagnocat.model.ToothPeriodontalStatus",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ToothNumeration", kind: "message", T: ToothNumeration },
    { no: 3, name: "ToothLocalizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "Measurements", kind: "message", T: ToothPeriodontalStatusMeasurement, repeated: true },
    { no: 5, name: "Roots", kind: "message", T: ToothPeriodontalStatusRoot, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.model.ToothPeriodontalStatusMeasurement
 */
export const ToothPeriodontalStatusMeasurement = proto3.makeMessageType(
  "com.diagnocat.model.ToothPeriodontalStatusMeasurement",
  () => [
    { no: 1, name: "Site", kind: "enum", T: proto3.getEnumType(ToothPeriodontalStatusMeasurement_Site) },
    { no: 2, name: "BoneLoss", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 3, name: "Recession", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 4, name: "Probing", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
  ],
);

/**
 * @generated from message com.diagnocat.model.ToothPeriodontalStatusRoot
 */
export const ToothPeriodontalStatusRoot = proto3.makeMessageType(
  "com.diagnocat.model.ToothPeriodontalStatusRoot",
  () => [
    { no: 1, name: "RootType", kind: "enum", T: proto3.getEnumType(CBCT_Tooth_RootType) },
    { no: 2, name: "Length", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

