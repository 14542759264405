// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_report_type_studio_ortho.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { Empty, proto3 } from "@bufbuild/protobuf";
import { CBCTSeriesGeometryData, PlanePosition, ProjectionTransform, StudioColorProfile } from "./dto_common_geometry_pb.js";
import { Report_Studio_ToothPosition } from "./dto_report_type_studio_common_pb.js";
import { CephalometricLandmark, ToothLandmark } from "./dto_report_landmark_pb.js";
import { Segmentron_PhotoIOSSuperimposition } from "./dto_report_segmentron_common_pb.js";
import { ToothNumeration } from "./dto_report_common_pb.js";
import { CephalometricMeasure_Code } from "./dto_report_cephalometric_measurement_codes_pb.js";

/**
 * @generated from message com.diagnocat.model.Report_Studio_Ortho
 */
export const Report_Studio_Ortho = proto3.makeMessageType(
  "com.diagnocat.model.Report_Studio_Ortho",
  () => [
    { no: 1, name: "CropGeometryData", kind: "message", T: CBCTSeriesGeometryData },
    { no: 2, name: "ColorProfile", kind: "message", T: StudioColorProfile },
    { no: 3, name: "TeethPositions", kind: "message", T: Report_Studio_ToothPosition, repeated: true },
    { no: 5, name: "NaturalHeadPositionTransform", kind: "message", T: ProjectionTransform },
    { no: 6, name: "OrthoPlanes", kind: "message", T: Report_Studio_Ortho_OrthoPlanes },
    { no: 7, name: "TeethMovementPlans", kind: "message", T: Report_Studio_Ortho_TeethMovementPlan, repeated: true },
    { no: 8, name: "CephalometricMeasurements", kind: "message", T: Report_Studio_Ortho_CephalometricMeasurements, repeated: true },
    { no: 9, name: "TeethLandmarks", kind: "message", T: ToothLandmark, repeated: true },
    { no: 10, name: "CephalometricLandmarks", kind: "message", T: CephalometricLandmark, repeated: true },
    { no: 11, name: "PhotoIOSSuperimpositions", kind: "message", T: Segmentron_PhotoIOSSuperimposition, repeated: true },
    { no: 12, name: "TeethAnalysis", kind: "message", T: Report_Studio_Ortho_TeethAnalysis },
  ],
);

/**
 * @generated from message com.diagnocat.model.Report_Studio_Ortho_OrthoPlanes
 */
export const Report_Studio_Ortho_OrthoPlanes = proto3.makeMessageType(
  "com.diagnocat.model.Report_Studio_Ortho_OrthoPlanes",
  () => [
    { no: 1, name: "Midline", kind: "message", T: PlanePosition },
    { no: 2, name: "OcclusionalUpper", kind: "message", T: PlanePosition },
    { no: 3, name: "OcclusionalLower", kind: "message", T: PlanePosition },
  ],
);

/**
 * @generated from message com.diagnocat.model.Report_Studio_Ortho_TeethMovementPlan
 */
export const Report_Studio_Ortho_TeethMovementPlan = proto3.makeMessageType(
  "com.diagnocat.model.Report_Studio_Ortho_TeethMovementPlan",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Order", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "Steps", kind: "message", T: Report_Studio_Ortho_TeethMovementPlan_Report_Studio_Ortho_TeethMovementPlan_Step, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.model.Report_Studio_Ortho_TeethMovementPlan.Report_Studio_Ortho_TeethMovementPlan_Step
 */
export const Report_Studio_Ortho_TeethMovementPlan_Report_Studio_Ortho_TeethMovementPlan_Step = proto3.makeMessageType(
  "com.diagnocat.model.Report_Studio_Ortho_TeethMovementPlan.Report_Studio_Ortho_TeethMovementPlan_Step",
  () => [
    { no: 1, name: "Tooth", kind: "message", T: ToothNumeration },
    { no: 2, name: "Transform", kind: "message", T: ProjectionTransform },
  ],
  {localName: "Report_Studio_Ortho_TeethMovementPlan_Report_Studio_Ortho_TeethMovementPlan_Step"},
);

/**
 * @generated from message com.diagnocat.model.Report_Studio_Ortho_CephalometricMeasurements
 */
export const Report_Studio_Ortho_CephalometricMeasurements = proto3.makeMessageType(
  "com.diagnocat.model.Report_Studio_Ortho_CephalometricMeasurements",
  () => [
    { no: 1, name: "Measurements", kind: "message", T: Report_Studio_Ortho_CephalometricMeasurements_Measurement, repeated: true },
    { no: 2, name: "Initial", kind: "message", T: Empty, oneof: "State" },
    { no: 3, name: "TeethMovementPlanID", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "State" },
  ],
);

/**
 * @generated from message com.diagnocat.model.Report_Studio_Ortho_CephalometricMeasurements.Measurement
 */
export const Report_Studio_Ortho_CephalometricMeasurements_Measurement = proto3.makeMessageType(
  "com.diagnocat.model.Report_Studio_Ortho_CephalometricMeasurements.Measurement",
  () => [
    { no: 1, name: "Type", kind: "enum", T: proto3.getEnumType(CephalometricMeasure_Code) },
    { no: 2, name: "Values", kind: "message", T: Report_Studio_Ortho_CephalometricMeasurements_MeasurementValues },
  ],
  {localName: "Report_Studio_Ortho_CephalometricMeasurements_Measurement"},
);

/**
 *
 * Notes:
 * - if `IsPerSide` is True the measurement is defined and calculated for each patient's side.
 * - if `IsPerSide` is False `Left` and `Right` are guaranteed to be equal.
 * - if `Left` and `Right` are default valued, an error occured during calculation.
 *
 * @generated from message com.diagnocat.model.Report_Studio_Ortho_CephalometricMeasurements_MeasurementValues
 */
export const Report_Studio_Ortho_CephalometricMeasurements_MeasurementValues = proto3.makeMessageType(
  "com.diagnocat.model.Report_Studio_Ortho_CephalometricMeasurements_MeasurementValues",
  () => [
    { no: 1, name: "Left", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "Right", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "IsPerSide", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "Norm", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "NormDeviation", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 *
 * STL tooth analysis.
 *
 * @generated from message com.diagnocat.model.Report_Studio_Ortho_TeethAnalysis
 */
export const Report_Studio_Ortho_TeethAnalysis = proto3.makeMessageType(
  "com.diagnocat.model.Report_Studio_Ortho_TeethAnalysis",
  () => [
    { no: 1, name: "Ponts", kind: "message", T: Report_Studio_Ortho_TeethAnalysis_Ponts },
    { no: 2, name: "TonnBolton", kind: "message", T: Report_Studio_Ortho_TeethAnalysis_TonnBolton },
    { no: 3, name: "SpaceCrowding", kind: "message", T: Report_Studio_Ortho_TeethAnalysis_LeftRightMeasure },
    { no: 4, name: "CurveOfSpee", kind: "message", T: Report_Studio_Ortho_TeethAnalysis_LeftRightMeasure },
  ],
);

/**
 * @generated from message com.diagnocat.model.Report_Studio_Ortho_TeethAnalysis_Ponts
 */
export const Report_Studio_Ortho_TeethAnalysis_Ponts = proto3.makeMessageType(
  "com.diagnocat.model.Report_Studio_Ortho_TeethAnalysis_Ponts",
  () => [
    { no: 1, name: "LowerMolars", kind: "message", T: Report_Studio_Ortho_TeethAnalysis_Ponts_Measure },
    { no: 2, name: "UpperMolars", kind: "message", T: Report_Studio_Ortho_TeethAnalysis_Ponts_Measure },
    { no: 3, name: "LowerPreMolars", kind: "message", T: Report_Studio_Ortho_TeethAnalysis_Ponts_Measure },
    { no: 4, name: "UpperPreMolars", kind: "message", T: Report_Studio_Ortho_TeethAnalysis_Ponts_Measure },
  ],
);

/**
 * @generated from message com.diagnocat.model.Report_Studio_Ortho_TeethAnalysis_Ponts.Measure
 */
export const Report_Studio_Ortho_TeethAnalysis_Ponts_Measure = proto3.makeMessageType(
  "com.diagnocat.model.Report_Studio_Ortho_TeethAnalysis_Ponts.Measure",
  () => [
    { no: 1, name: "Norm", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "Fact", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "Diff", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
  {localName: "Report_Studio_Ortho_TeethAnalysis_Ponts_Measure"},
);

/**
 * @generated from message com.diagnocat.model.Report_Studio_Ortho_TeethAnalysis_TonnBolton
 */
export const Report_Studio_Ortho_TeethAnalysis_TonnBolton = proto3.makeMessageType(
  "com.diagnocat.model.Report_Studio_Ortho_TeethAnalysis_TonnBolton",
  () => [
    { no: 1, name: "Tonn", kind: "message", T: Report_Studio_Ortho_TeethAnalysis_TonnBolton_Measure },
    { no: 2, name: "Bolton", kind: "message", T: Report_Studio_Ortho_TeethAnalysis_TonnBolton_Measure },
  ],
);

/**
 * @generated from message com.diagnocat.model.Report_Studio_Ortho_TeethAnalysis_TonnBolton.Measure
 */
export const Report_Studio_Ortho_TeethAnalysis_TonnBolton_Measure = proto3.makeMessageType(
  "com.diagnocat.model.Report_Studio_Ortho_TeethAnalysis_TonnBolton.Measure",
  () => [
    { no: 1, name: "IndexNorm", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "IndexFact", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "DiffUpper", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "DiffLower", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
  {localName: "Report_Studio_Ortho_TeethAnalysis_TonnBolton_Measure"},
);

/**
 * @generated from message com.diagnocat.model.Report_Studio_Ortho_TeethAnalysis_LeftRightMeasure
 */
export const Report_Studio_Ortho_TeethAnalysis_LeftRightMeasure = proto3.makeMessageType(
  "com.diagnocat.model.Report_Studio_Ortho_TeethAnalysis_LeftRightMeasure",
  () => [
    { no: 2, name: "Lower", kind: "message", T: Report_Studio_Ortho_TeethAnalysis_LeftRightMeasure_Measure },
    { no: 1, name: "Upper", kind: "message", T: Report_Studio_Ortho_TeethAnalysis_LeftRightMeasure_Measure },
  ],
);

/**
 * @generated from message com.diagnocat.model.Report_Studio_Ortho_TeethAnalysis_LeftRightMeasure.Measure
 */
export const Report_Studio_Ortho_TeethAnalysis_LeftRightMeasure_Measure = proto3.makeMessageType(
  "com.diagnocat.model.Report_Studio_Ortho_TeethAnalysis_LeftRightMeasure.Measure",
  () => [
    { no: 1, name: "Left", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "Right", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "Total", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
  {localName: "Report_Studio_Ortho_TeethAnalysis_LeftRightMeasure_Measure"},
);

