import { SharedWithMeStreamSortBy } from '@/shared/api/protocol-ts/api/core/svc_access_pb';
import { PatientsListColumnName } from '@/shared/config';

export const getSortByOptionsForSharingWith = (
  sortKey: PatientsListColumnName,
  sortAsc: boolean,
): { By?: SharedWithMeStreamSortBy; Ascending?: boolean }[] => {
  switch (sortKey) {
    case PatientsListColumnName.PatientName:
      return [
        {
          Ascending: sortAsc,
          By: SharedWithMeStreamSortBy.SharedWithMeStreamSortByPatientLastName,
        },
        {
          Ascending: sortAsc,
          By: SharedWithMeStreamSortBy.SharedWithMeStreamSortByPatientFirstName,
        },
      ];
    case PatientsListColumnName.ClinicName:
      return [
        {
          Ascending: sortAsc,
          By: SharedWithMeStreamSortBy.SharedWithMeStreamSortByClinicName,
        },
      ];
    case PatientsListColumnName.SharedBy:
      return [
        {
          Ascending: sortAsc,
          By: SharedWithMeStreamSortBy.SharedWithMeStreamSortBySenderLastName,
        },
        {
          Ascending: sortAsc,
          By: SharedWithMeStreamSortBy.SharedWithMeStreamSortBySenderFirstName,
        },
      ];
    case PatientsListColumnName.SenderEmail:
      return [
        {
          Ascending: sortAsc,
          By: SharedWithMeStreamSortBy.SharedWithMeStreamSortBySenderEmail,
        },
      ];
    case PatientsListColumnName.SharingDate:
      return [
        {
          Ascending: sortAsc,
          By: SharedWithMeStreamSortBy.SharedWithMeStreamSortBySharingDate,
        },
      ];
    default:
      return [];
  }
};
