import type { AnyAction, Middleware } from '@reduxjs/toolkit';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import { ConnectError } from '@bufbuild/connect';

import { INLINE_ERROR_CODES } from '@/shared/config';

import { errorToastCaller } from '@/features/errorToastCaller';

export const errorMiddleware: Middleware =
  () => (next) => (action: AnyAction) => {
    if (isRejectedWithValue(action)) {
      const { rawMessage } = action.payload as ConnectError;
      const { type, code } = JSON.parse(rawMessage);
      // NOTE: Do not show toast for internal server errors of specific services.
      // For example for all hubspot unexpected errors.
      const ignoreInternalServerError = type === 'InternalServerError';

      // eslint-disable-next-line no-console
      console.warn(`Receive api error: ${type}`);

      if (
        action.payload instanceof ConnectError &&
        !INLINE_ERROR_CODES.includes(code) &&
        !ignoreInternalServerError
      ) {
        errorToastCaller(action.payload as ConnectError);
      }
    }

    return next(action);
  };
