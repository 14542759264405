// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file backbone/aux_enrichment_models.proto (package com.diagnocat.backbone, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message com.diagnocat.backbone.UserInfo
 */
export const UserInfo = proto3.makeMessageType(
  "com.diagnocat.backbone.UserInfo",
  () => [
    { no: 1, name: "UserID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "FirstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "LastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "Email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "Country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "Language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "BillingZone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.backbone.DoctorInfo
 */
export const DoctorInfo = proto3.makeMessageType(
  "com.diagnocat.backbone.DoctorInfo",
  () => [
    { no: 1, name: "OrganizationParticipantID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "UserID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "FirstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "LastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "SpecializationLegacy", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "Email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "CreatedAt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "UpdatedAt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.backbone.PatientInfo
 */
export const PatientInfo = proto3.makeMessageType(
  "com.diagnocat.backbone.PatientInfo",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ManualID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "FirstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "LastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "BirthDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "Gender", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "Status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "CreatedAt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "UpdatedAt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.backbone.ReportInfo
 */
export const ReportInfo = proto3.makeMessageType(
  "com.diagnocat.backbone.ReportInfo",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "CreatedAt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "UpdatedAt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 100, name: "LegacyReportID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.backbone.StudyInfo
 */
export const StudyInfo = proto3.makeMessageType(
  "com.diagnocat.backbone.StudyInfo",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "DateOfStudy", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "CreatedAt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "UpdatedAt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.backbone.ProjectInfo
 */
export const ProjectInfo = proto3.makeMessageType(
  "com.diagnocat.backbone.ProjectInfo",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "CaseDescription", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "DueDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "CreatedAt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "ClosedAt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.backbone.ProjectTaskInfo
 */
export const ProjectTaskInfo = proto3.makeMessageType(
  "com.diagnocat.backbone.ProjectTaskInfo",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Creator", kind: "message", T: DoctorInfo },
    { no: 3, name: "Project", kind: "message", T: ProjectInfo },
    { no: 4, name: "Title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "Description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "Status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "DueDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "Assignments", kind: "message", T: ProjectTaskAssignment, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.backbone.ProjectTaskAssignment
 */
export const ProjectTaskAssignment = proto3.makeMessageType(
  "com.diagnocat.backbone.ProjectTaskAssignment",
  () => [
    { no: 1, name: "Assigner", kind: "message", T: DoctorInfo },
    { no: 2, name: "Assignee", kind: "message", T: DoctorInfo },
  ],
);

