import { createSelector } from '@reduxjs/toolkit';

import { convertConditionColorToHEX } from '@/shared/lib';
import { Decision } from '@/shared/api/protocol-ts/model/dto_report_common_pb';
import { isConditionUncertain } from '@/shared/embeddedLibs/conditionsAndMasks';

import { logicalConditionModel } from '@/entities/logicalCondition';
import { getImageMetaIDByAssetID, reportsModel } from '@/entities/reports';
import { getConditionCodeColor } from '@/entities/condition';

type RenderConditionBbox = {
  min: { x: number; y: number };
  max: { x: number; y: number };
  color: string;
  width: number;
};

export const selectToothChartItems = createSelector(
  logicalConditionModel.selectors.selectTeethConditions,
  logicalConditionModel.selectors.selectHoveredConditionLink,
  reportsModel.selectors.selectLowProbabilityMode,
  reportsModel.selectors.selectCurrentReportImagesMeta,
  (toothConditions, hoveredConditionLink, showLowProbability, imagesMeta) => {
    if (
      !toothConditions ||
      !hoveredConditionLink ||
      !hoveredConditionLink.toothID ||
      !hoveredConditionLink.code
    ) {
      return undefined;
    }

    const { toothID, code } = hoveredConditionLink;

    const conditions = toothConditions[toothID].conditions[code];
    const localizations = Object.values(conditions)
      .filter(
        (condition) =>
          condition.Certainty?.EngineDecision === Decision.PositiveDecision,
      )
      .flatMap((condition) => condition.Localizations);

    const conditionByCertainity = Object.values(conditions).sort(
      (a, b) => (b.Certainty?.ModelScore ?? 0) - (a.Certainty?.ModelScore ?? 0),
    )?.[0];

    const isUncertain = isConditionUncertain(conditionByCertainity);

    const BBoxColor = getConditionCodeColor(
      code,
      isUncertain,
      showLowProbability,
    );

    const hoveredConditionBBoxes = localizations.reduce(
      (accum, localization) => {
        const assetID = localization.TargetAssetID;
        const BBox = localization.BBox;

        const transformedBbox = {
          max: {
            x: BBox?.X?.Max,
            y: BBox?.Y?.Max,
          },
          min: {
            x: BBox?.X?.Min,
            y: BBox?.Y?.Min,
          },
          color: convertConditionColorToHEX(BBoxColor),
          width: 2,
        } as RenderConditionBbox;

        return {
          ...accum,
          [localization.TargetAssetID]: {
            imageID: getImageMetaIDByAssetID(imagesMeta, assetID),
            boxes: accum[assetID]
              ? [...accum[assetID].boxes, transformedBbox]
              : [transformedBbox],
          },
        };
      },
      {} as Record<string, { imageID: string; boxes: RenderConditionBbox[] }>,
    );

    const hoveredConditionBBoxesList = Object.values(hoveredConditionBBoxes);

    return hoveredConditionBBoxesList;
  },
);
