// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_report_certainty.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { Decision } from "./dto_report_common_pb.js";

/**
 * TMP: Think of better naming (?)
 *
 * @generated from message com.diagnocat.model.Certainty
 */
export const Certainty = proto3.makeMessageType(
  "com.diagnocat.model.Certainty",
  () => [
    { no: 1, name: "ModelDecision", kind: "enum", T: proto3.getEnumType(Decision) },
    { no: 2, name: "ModelScore", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "IsModelScoreSet", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "UserDecision", kind: "enum", T: proto3.getEnumType(Decision) },
    { no: 6, name: "UserScore", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 5, name: "EngineDecision", kind: "enum", T: proto3.getEnumType(Decision) },
  ],
);

