import { ToothStatus } from '@/shared/config';
import { ConditionCode } from '@/shared/api/protocol-ts/model/dto_report_condition_codes_pb';

import { ToothItem } from './types';

export const DEFAULT_TOOTH_CHART_ITEMS: Record<number, ToothItem> = {
  18: {
    id: '18',
    status: ToothStatus.notFound,
    ISONumber: 18,
    isLowerJaw: false,
    active: false,
    childItems: [],
    gridArea: 18,
    isApproved: false,
  },
  17: {
    id: '17',
    status: ToothStatus.notFound,
    ISONumber: 17,
    isLowerJaw: false,
    active: false,
    childItems: [],
    gridArea: 17,
    isApproved: false,
  },
  16: {
    id: '16',
    status: ToothStatus.notFound,
    ISONumber: 16,
    isLowerJaw: false,
    active: false,
    childItems: [],
    gridArea: 16,
    isApproved: false,
  },
  15: {
    id: '15',
    status: ToothStatus.notFound,
    ISONumber: 15,
    active: false,
    isLowerJaw: false,
    childItems: [],
    gridArea: 15,
    isApproved: false,
  },
  14: {
    id: '14',
    status: ToothStatus.notFound,
    ISONumber: 14,
    active: false,
    isLowerJaw: false,
    childItems: [],
    gridArea: 14,
    isApproved: false,
  },
  13: {
    id: '13',
    status: ToothStatus.notFound,
    ISONumber: 13,
    active: false,
    isLowerJaw: false,
    childItems: [],
    gridArea: 13,
    isApproved: false,
  },
  12: {
    id: '12',
    status: ToothStatus.notFound,
    ISONumber: 12,
    active: false,
    isLowerJaw: false,
    childItems: [],
    gridArea: 12,
    isApproved: false,
  },
  11: {
    id: '11',
    status: ToothStatus.notFound,
    ISONumber: 11,
    active: false,
    isLowerJaw: false,
    childItems: [],
    gridArea: 11,
    isApproved: false,
  },
  21: {
    id: '21',
    status: ToothStatus.notFound,
    ISONumber: 21,
    active: false,
    isLowerJaw: false,
    childItems: [],
    gridArea: 21,
    isApproved: false,
  },
  22: {
    id: '22',
    status: ToothStatus.notFound,
    ISONumber: 22,
    active: false,
    isLowerJaw: false,
    childItems: [],
    gridArea: 22,
    isApproved: false,
  },
  23: {
    id: '23',
    status: ToothStatus.notFound,
    ISONumber: 23,
    active: false,
    isLowerJaw: false,
    childItems: [],
    gridArea: 23,
    isApproved: false,
  },
  24: {
    id: '24',
    status: ToothStatus.notFound,
    ISONumber: 24,
    active: false,
    isLowerJaw: false,
    childItems: [],
    gridArea: 24,
    isApproved: false,
  },
  25: {
    id: '25',
    status: ToothStatus.notFound,
    ISONumber: 25,
    active: false,
    isLowerJaw: false,
    childItems: [],
    gridArea: 25,
    isApproved: false,
  },
  26: {
    id: '26',
    status: ToothStatus.notFound,
    ISONumber: 26,
    active: false,
    isLowerJaw: false,
    childItems: [],
    gridArea: 26,
    isApproved: false,
  },
  27: {
    id: '27',
    status: ToothStatus.notFound,
    ISONumber: 27,
    active: false,
    isLowerJaw: false,
    childItems: [],
    gridArea: 27,
    isApproved: false,
  },
  28: {
    id: '28',
    status: ToothStatus.notFound,
    ISONumber: 28,
    active: false,
    isLowerJaw: false,
    childItems: [],
    gridArea: 28,
    isApproved: false,
  },
  48: {
    id: '48',
    status: ToothStatus.notFound,
    ISONumber: 48,
    active: false,
    isLowerJaw: true,
    childItems: [],
    gridArea: 48,
    isApproved: false,
  },
  47: {
    id: '47',
    status: ToothStatus.notFound,
    ISONumber: 47,
    active: false,
    isLowerJaw: true,
    childItems: [],
    gridArea: 47,
    isApproved: false,
  },
  46: {
    id: '46',
    status: ToothStatus.notFound,
    ISONumber: 46,
    active: false,
    isLowerJaw: true,
    childItems: [],
    gridArea: 46,
    isApproved: false,
  },
  45: {
    id: '45',
    status: ToothStatus.notFound,
    ISONumber: 45,
    active: false,
    isLowerJaw: true,
    childItems: [],
    gridArea: 45,
    isApproved: false,
  },
  44: {
    id: '44',
    status: ToothStatus.notFound,
    ISONumber: 44,
    active: false,
    isLowerJaw: true,
    childItems: [],
    gridArea: 44,
    isApproved: false,
  },
  43: {
    id: '43',
    status: ToothStatus.notFound,
    ISONumber: 43,
    active: false,
    isLowerJaw: true,
    childItems: [],
    gridArea: 43,
    isApproved: false,
  },
  42: {
    id: '42',
    status: ToothStatus.notFound,
    ISONumber: 42,
    active: false,
    isLowerJaw: true,
    childItems: [],
    gridArea: 42,
    isApproved: false,
  },
  41: {
    id: '41',
    status: ToothStatus.notFound,
    ISONumber: 41,
    active: false,
    isLowerJaw: true,
    childItems: [],
    gridArea: 41,
    isApproved: false,
  },
  31: {
    id: '31',
    status: ToothStatus.notFound,
    ISONumber: 31,
    active: false,
    isLowerJaw: true,
    childItems: [],
    gridArea: 31,
    isApproved: false,
  },
  32: {
    id: '32',
    status: ToothStatus.notFound,
    ISONumber: 32,
    active: false,
    isLowerJaw: true,
    childItems: [],
    gridArea: 32,
    isApproved: false,
  },
  33: {
    id: '33',
    status: ToothStatus.notFound,
    ISONumber: 33,
    active: false,
    isLowerJaw: true,
    childItems: [],
    gridArea: 33,
    isApproved: false,
  },
  34: {
    id: '34',
    status: ToothStatus.notFound,
    ISONumber: 34,
    active: false,
    isLowerJaw: true,
    childItems: [],
    gridArea: 34,
    isApproved: false,
  },
  35: {
    id: '35',
    status: ToothStatus.notFound,
    ISONumber: 35,
    active: false,
    isLowerJaw: true,
    childItems: [],
    gridArea: 35,
    isApproved: false,
  },
  36: {
    id: '36',
    status: ToothStatus.notFound,
    ISONumber: 36,
    active: false,
    isLowerJaw: true,
    childItems: [],
    gridArea: 36,
    isApproved: false,
  },
  37: {
    id: '37',
    status: ToothStatus.notFound,
    ISONumber: 37,
    active: false,
    isLowerJaw: true,
    childItems: [],
    gridArea: 37,
    isApproved: false,
  },
  38: {
    id: '38',
    status: ToothStatus.notFound,
    ISONumber: 38,
    active: false,
    isLowerJaw: true,
    childItems: [],
    gridArea: 38,
    isApproved: false,
  },
};

export const PBL_SEVERITIES = [
  ConditionCode.Child_PeriodontalBoneLoss_Severity_Severe,
  ConditionCode.Child_PeriodontalBoneLoss_Severity_Moderate,
  ConditionCode.Child_PeriodontalBoneLoss_Severity_Mild,
];
