import { FC } from 'react';
import cn from 'classnames';
import { head } from 'ramda';

import { DEFAULT_MEDICAL_IMAGE_HEIGHT } from '@/shared/config';

import { PathologyGroup, Group, Guide } from '@/entities/assets';

import MedicalImageGuide from '../MedicalImageGuide/MedicalImageGuide';
import { sortGuides } from '../../helpers/sortGuides';
import styles from '../MedicalImageGroupSection/MedicalImageGroupSection.module.scss';

type GuidesListProps = {
  group: Group | PathologyGroup;
  hoveredGuide: string;
  handleZoomImg: (assetID: string) => void;
  setHoveredGuide: (assetID: string) => void;
  className?: string;
};

const GuidesList: FC<GuidesListProps> = (props) => {
  const { group, hoveredGuide, handleZoomImg, setHoveredGuide, className } =
    props;
  const firstGuide = group.guides && head(group.guides);

  const guides = group.guides?.sort(sortGuides) ?? [];
  const guideHeight = DEFAULT_MEDICAL_IMAGE_HEIGHT;
  const aspect = guideHeight / (Number(firstGuide?.image?.Height) ?? 1);
  const firstGuideWidth = aspect * (Number(firstGuide?.image?.Width) ?? 1);

  return (
    <div className={cn(className, styles.container)}>
      {group.guides?.length &&
        guides.map((guide: Guide, guideIndex) => (
          <MedicalImageGuide
            key={guide.id}
            group={group}
            guide={guide}
            hoveredGuide={hoveredGuide}
            handleZoomImg={handleZoomImg}
            setHoveredGuide={setHoveredGuide}
            guideIndex={guideIndex}
            firstGuideWidth={firstGuideWidth}
          />
        ))}
    </div>
  );
};

export default GuidesList;
