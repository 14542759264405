// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file api/marketing/svc_questionnaire.proto (package com.diagnocat.marketing, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { OrganizationOnboardingQuestionnaire_V1 } from "./dto_questionnaire_pb.js";

/**
 * @generated from message com.diagnocat.marketing.OrganizationOnboardingQuestionnaireReq_V1
 */
export const OrganizationOnboardingQuestionnaireReq_V1 = proto3.makeMessageType(
  "com.diagnocat.marketing.OrganizationOnboardingQuestionnaireReq_V1",
  () => [
    { no: 1, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.marketing.OrganizationOnboardingQuestionnaireResp_V1
 */
export const OrganizationOnboardingQuestionnaireResp_V1 = proto3.makeMessageType(
  "com.diagnocat.marketing.OrganizationOnboardingQuestionnaireResp_V1",
  () => [
    { no: 1, name: "Questionnaire", kind: "message", T: OrganizationOnboardingQuestionnaire_V1 },
  ],
);

/**
 * @generated from message com.diagnocat.marketing.PutOrganizationOnboardingQuestionnaireReq_V1
 */
export const PutOrganizationOnboardingQuestionnaireReq_V1 = proto3.makeMessageType(
  "com.diagnocat.marketing.PutOrganizationOnboardingQuestionnaireReq_V1",
  () => [
    { no: 1, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Questionnaire", kind: "message", T: OrganizationOnboardingQuestionnaire_V1 },
  ],
);

/**
 * @generated from message com.diagnocat.marketing.PutOrganizationOnboardingQuestionnaireResp_V1
 */
export const PutOrganizationOnboardingQuestionnaireResp_V1 = proto3.makeMessageType(
  "com.diagnocat.marketing.PutOrganizationOnboardingQuestionnaireResp_V1",
  () => [
    { no: 1, name: "Questionnaire", kind: "message", T: OrganizationOnboardingQuestionnaire_V1 },
  ],
);

