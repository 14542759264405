import { PatientsListColumnName } from '@/shared/config';
import { PatientListStreamSortBy } from '@/shared/api/protocol-ts/api/core/svc_patient_pb';

export const getSortByOptions = (
  sortKey: PatientsListColumnName,
  sortAsc: boolean,
): { By?: PatientListStreamSortBy; Ascending?: boolean }[] => {
  switch (sortKey) {
    case PatientsListColumnName.PatientName:
      return [
        {
          Ascending: sortAsc,
          By: PatientListStreamSortBy.PatientListStreamSortByLastName,
        },
        {
          Ascending: sortAsc,
          By: PatientListStreamSortBy.PatientListStreamSortByFirstName,
        },
      ];
    case PatientsListColumnName.DateOfBirth:
      return [
        {
          Ascending: sortAsc,
          By: PatientListStreamSortBy.PatientListStreamSortByDateOfBirth,
        },
      ];
    case PatientsListColumnName.ExternalID:
      return [
        {
          Ascending: sortAsc,
          By: PatientListStreamSortBy.PatientListStreamSortByExternalID,
        },
      ];
    default:
      return [];
  }
};
