import { components, OptionProps } from 'react-select';

import { Components, OptionType } from '@/shared/ui/Select/Select.types';
import { Checkbox } from '@/shared/ui/Checkbox/Checkbox';
import { Radio } from '@/shared/ui/RadioGroup/components/Radio';

import styles from './DropdownControls.module.scss';

const Option = (props: OptionProps<OptionType>) => {
  const { isMulti, isFocused, isSelected, data, children } = props;

  return (
    <components.Option {...props}>
      {isMulti ? (
        <Checkbox
          checked={isSelected}
          onChange={() => {}}
          className={styles.option}
        >
          {children}
        </Checkbox>
      ) : (
        <Radio
          value={data.value}
          checked={isFocused}
          onChange={() => {}}
          className={styles.option}
        >
          {children}
        </Radio>
      )}
    </components.Option>
  );
};

export const dropdownControls: Components = {
  // TODO: fix types
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Option,
  DropdownIndicator: null,
  IndicatorSeparator: null,
};
