import {
  AxisSlice,
  BBox,
} from '@/shared/api/protocol-ts/model/dto_common_geometry_pb';

const defaultBBox = new BBox({
  X: new AxisSlice({
    Min: 0,
    Max: 0,
    Size: 0,
  }),
  Y: new AxisSlice({
    Min: 0,
    Max: 0,
    Size: 0,
  }),
  Z: new AxisSlice({
    Min: 0,
    Max: 0,
    Size: 0,
  }),
});

export const transformBBoxToCrop = (bBox: BBox = defaultBBox) => {
  const xMin = bBox.X?.Min ?? 0;
  const yMin = bBox.Y?.Min ?? 0;
  const xMax = bBox.X?.Max ?? 0;
  const yMax = bBox.Y?.Max ?? 0;

  return {
    x: xMin,
    y: yMin,
    w: xMax - xMin,
    h: yMax - yMin,
  };
};
