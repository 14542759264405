// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_asset_mesh_face_segmentation.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { File } from "./dto_asset_common_pb.js";
import { IOS_Anatomy, ToothNumeration } from "./dto_report_common_pb.js";
import { ConditionCode } from "./dto_report_condition_codes_pb.js";

/**
 * @generated from enum com.diagnocat.model.Mesh_FaceSegmentation_Instance_PackStrategy
 */
export const Mesh_FaceSegmentation_Instance_PackStrategy = proto3.makeEnum(
  "com.diagnocat.model.Mesh_FaceSegmentation_Instance_PackStrategy",
  [
    {no: 0, name: "Mesh_FaceSegmentation_Instance_PackStrategy_InvalidValue"},
    {no: 1, name: "Mesh_FaceSegmentation_Instance_PackStrategy_UInt32"},
    {no: 2, name: "Mesh_FaceSegmentation_Instance_PackStrategy_RangeUInt32"},
  ],
);

/**
 * Asset with per-face mesh segmentation.
 *
 * @generated from message com.diagnocat.model.AssetContent_Generated_Mesh_FaceSegmentation
 */
export const AssetContent_Generated_Mesh_FaceSegmentation = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Generated_Mesh_FaceSegmentation",
  () => [
    { no: 1, name: "TargetFileID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "FaceIndices", kind: "message", T: File },
    { no: 3, name: "Instances", kind: "message", T: Mesh_FaceSegmentation_Instance, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.model.Mesh_FaceSegmentation_Instance
 */
export const Mesh_FaceSegmentation_Instance = proto3.makeMessageType(
  "com.diagnocat.model.Mesh_FaceSegmentation_Instance",
  () => [
    { no: 1, name: "FaceLocations", kind: "message", T: Mesh_FaceSegmentation_Instance_FaceLocation, repeated: true },
    { no: 2, name: "Tooth", kind: "message", T: ToothNumeration, oneof: "Type" },
    { no: 3, name: "ToothCondition", kind: "message", T: Mesh_FaceSegmentation_ToothConditionInstance, oneof: "Type" },
    { no: 4, name: "MaxFaxAnatomy", kind: "enum", T: proto3.getEnumType(IOS_Anatomy), oneof: "Type" },
  ],
);

/**
 * @generated from message com.diagnocat.model.Mesh_FaceSegmentation_ToothConditionInstance
 */
export const Mesh_FaceSegmentation_ToothConditionInstance = proto3.makeMessageType(
  "com.diagnocat.model.Mesh_FaceSegmentation_ToothConditionInstance",
  () => [
    { no: 1, name: "Tooth", kind: "message", T: ToothNumeration },
    { no: 2, name: "Code", kind: "enum", T: proto3.getEnumType(ConditionCode) },
  ],
);

/**
 * @generated from message com.diagnocat.model.Mesh_FaceSegmentation_Instance_FaceLocation
 */
export const Mesh_FaceSegmentation_Instance_FaceLocation = proto3.makeMessageType(
  "com.diagnocat.model.Mesh_FaceSegmentation_Instance_FaceLocation",
  () => [
    { no: 1, name: "TargetGeometryIndex", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "PackStrategy", kind: "enum", T: proto3.getEnumType(Mesh_FaceSegmentation_Instance_PackStrategy) },
    { no: 3, name: "StartByte", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "ContentSize", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

