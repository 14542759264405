// @generated by protoc-gen-connect-web v0.6.0 with parameter "target=ts,ts_nocheck=false"
// @generated from file api/core/svc_patient_task.proto (package com.diagnocat.core, syntax proto3)
/* eslint-disable */

import { ClosePatientTaskReq, ClosePatientTaskResp, CreatePatientTaskReq, CreatePatientTaskResp, DeletePatientTaskReq, DeletePatientTaskResp, MarkAsReadPatientTasksReq, MarkAsReadPatientTasksResp, ReopenPatientTaskReq, ReopenPatientTaskResp, SetPatientTaskAssigneesReq, SetPatientTaskAssigneesResp, SetPatientTaskDescriptionReq, SetPatientTaskDescriptionResp, SetPatientTaskDueDateReq, SetPatientTaskDueDateResp, SetPatientTaskOrderReq, SetPatientTaskOrderResp, SetPatientTaskTitleReq, SetPatientTaskTitleResp } from "./svc_patient_task_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * Common errors:
 * - type: RequiredArgumentMissingError, Argument: TaskID/Title
 * - type: NotFoundError, Entity: patient task (if task not fount)
 * - type: BadRequestError, Reason: patient deleted (if remote patient found by PatientID)
 *
 * @generated from service com.diagnocat.core.PatientTaskService
 */
export const PatientTaskService = {
  typeName: "com.diagnocat.core.PatientTaskService",
  methods: {
    /**
     * Create new PatientTask
     *
     * Permission: `PatientPermissions.CanEditTasks`
     *
     * Errors:
     * - type: BadRequestError, Reason: assignee does not have organization role
     * - type: BadRequestError, Reason: dueDate cannot be less than current
     *
     * @generated from rpc com.diagnocat.core.PatientTaskService.CreatePatientTask
     */
    createPatientTask: {
      name: "CreatePatientTask",
      I: CreatePatientTaskReq,
      O: CreatePatientTaskResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set assignees list for the task
     *
     * Permission: `PatientPermissions.CanEditTasks`
     *
     * Errors:
     * - type: BadRequestError, Reason: assignee does not have organization role
     *
     * @generated from rpc com.diagnocat.core.PatientTaskService.SetPatientTaskAssignees
     */
    setPatientTaskAssignees: {
      name: "SetPatientTaskAssignees",
      I: SetPatientTaskAssigneesReq,
      O: SetPatientTaskAssigneesResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set patient task title
     *
     * Permission: `PatientPermissions.CanEditTasks`
     *
     * @generated from rpc com.diagnocat.core.PatientTaskService.SetPatientTaskTitle
     */
    setPatientTaskTitle: {
      name: "SetPatientTaskTitle",
      I: SetPatientTaskTitleReq,
      O: SetPatientTaskTitleResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set patient task description
     *
     * Permission: `PatientPermissions.CanEditTasks`
     *
     * @generated from rpc com.diagnocat.core.PatientTaskService.SetPatientTaskDescription
     */
    setPatientTaskDescription: {
      name: "SetPatientTaskDescription",
      I: SetPatientTaskDescriptionReq,
      O: SetPatientTaskDescriptionResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set patient task due date
     *
     * Permission: `PatientPermissions.CanEditTasks`
     *
     * Errors:
     * - type: BadRequestError, Reason: dueDate cannot be less than current
     *
     * @generated from rpc com.diagnocat.core.PatientTaskService.SetPatientTaskDueDate
     */
    setPatientTaskDueDate: {
      name: "SetPatientTaskDueDate",
      I: SetPatientTaskDueDateReq,
      O: SetPatientTaskDueDateResp,
      kind: MethodKind.Unary,
    },
    /**
     * Close patient task
     *
     * Permission: `PatientPermissions.CanEditTasks`
     *
     * @generated from rpc com.diagnocat.core.PatientTaskService.ClosePatientTask
     */
    closePatientTask: {
      name: "ClosePatientTask",
      I: ClosePatientTaskReq,
      O: ClosePatientTaskResp,
      kind: MethodKind.Unary,
    },
    /**
     * Reopen patient task
     *
     * Permission: `PatientPermissions.CanEditTasks`
     *
     * @generated from rpc com.diagnocat.core.PatientTaskService.ReopenPatientTask
     */
    reopenPatientTask: {
      name: "ReopenPatientTask",
      I: ReopenPatientTaskReq,
      O: ReopenPatientTaskResp,
      kind: MethodKind.Unary,
    },
    /**
     * Mark as read patient tasks
     *
     * Permission: without permissions
     *
     * @generated from rpc com.diagnocat.core.PatientTaskService.MarkAsReadPatientTasks
     */
    markAsReadPatientTasks: {
      name: "MarkAsReadPatientTasks",
      I: MarkAsReadPatientTasksReq,
      O: MarkAsReadPatientTasksResp,
      kind: MethodKind.Unary,
    },
    /**
     * Set new order to patient task
     *
     * Permission: `PatientPermissions.CanEditTasks`
     *
     * @generated from rpc com.diagnocat.core.PatientTaskService.SetPatientTaskOrder
     */
    setPatientTaskOrder: {
      name: "SetPatientTaskOrder",
      I: SetPatientTaskOrderReq,
      O: SetPatientTaskOrderResp,
      kind: MethodKind.Unary,
    },
    /**
     * Delete patient task
     *
     * Permission: `PatientPermissions.CanEditTasks`
     *
     * @generated from rpc com.diagnocat.core.PatientTaskService.DeletePatientTask
     */
    deletePatientTask: {
      name: "DeletePatientTask",
      I: DeletePatientTaskReq,
      O: DeletePatientTaskResp,
      kind: MethodKind.Unary,
    },
  }
} as const;

