// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_report_type_specific.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { CBCTSeriesGeometryData } from "./dto_common_geometry_pb.js";

/**
 * @generated from message com.diagnocat.model.Report_CBCT_Endo
 */
export const Report_CBCT_Endo = proto3.makeMessageType(
  "com.diagnocat.model.Report_CBCT_Endo",
  () => [
    { no: 1, name: "CropGeometryData", kind: "message", T: CBCTSeriesGeometryData },
    { no: 2, name: "PeriapicalLesionLocalization", kind: "message", T: Report_CBCT_Endo_PeriapicalLesionLocalization },
  ],
);

/**
 * @generated from message com.diagnocat.model.Report_CBCT_Endo_PeriapicalLesionLocalization
 */
export const Report_CBCT_Endo_PeriapicalLesionLocalization = proto3.makeMessageType(
  "com.diagnocat.model.Report_CBCT_Endo_PeriapicalLesionLocalization",
  () => [
    { no: 1, name: "ToothNumberISO", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "PeriapicalLesionDetected", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "HypodensityVolume", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * @generated from message com.diagnocat.model.Report_CBCT_Implant
 */
export const Report_CBCT_Implant = proto3.makeMessageType(
  "com.diagnocat.model.Report_CBCT_Implant",
  () => [
    { no: 1, name: "CropGeometryData", kind: "message", T: CBCTSeriesGeometryData },
    { no: 2, name: "SliceStep", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "SliceThickness", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "MeasurementScale", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * @generated from message com.diagnocat.model.Report_CBCT_Molar
 */
export const Report_CBCT_Molar = proto3.makeMessageType(
  "com.diagnocat.model.Report_CBCT_Molar",
  () => [
    { no: 1, name: "CropGeometryData", kind: "message", T: CBCTSeriesGeometryData },
  ],
);

/**
 * @generated from message com.diagnocat.model.Report_CBCT_Ortho
 */
export const Report_CBCT_Ortho = proto3.makeMessageType(
  "com.diagnocat.model.Report_CBCT_Ortho",
  [],
);

