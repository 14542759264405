import { createSelector } from '@reduxjs/toolkit';
import { cloneDeep, isEmpty, isNull, merge } from 'lodash';

import { ConditionCode } from '@/shared/api/protocol-ts/model/dto_report_condition_codes_pb';
import { Decision } from '@/shared/api/protocol-ts/model/dto_report_common_pb';
import { Condition } from '@/shared/api/protocol-ts/model/dto_report_condition_pb';

import { mergeConditions } from '@/entities/condition/lib/mergeConditions';

import { RootState } from '@/app/model/store';

import { findLowProbabilityConditionIDs } from '../utils';
import { LogicalCondition } from '../config';

import {
  ToothCondition,
  ToothConditionCollectionByCode,
} from './logicalConditionSlice';

export const selectTeethConditions = (state: RootState) =>
  state.logicalCondition.toothConditions;

export const selectToothConditions = (toothID: string) => (state: RootState) =>
  state.logicalCondition.toothConditions[toothID] ||
  ({ conditions: {}, childConditions: {} } as ToothCondition);

// return merged conditions by code
export const selectCondition =
  ({
    toothID,
    code,
    parentID,
  }: {
    toothID: string | null;
    code: ConditionCode | null;
    parentID: string | null;
  }) =>
  (state: RootState) => {
    if (isNull(toothID) || isNull(code)) {
      return undefined;
    }

    if (code < 5000) {
      return mergeConditions(
        state.logicalCondition.toothConditions[toothID]?.conditions?.[code],
      );
    }

    if (isNull(parentID)) {
      return undefined;
    }

    return mergeConditions(
      state.logicalCondition.toothConditions[toothID]?.childConditions?.[
        parentID
      ]?.[code],
    );
  };

export const selectChildCondition =
  ({
    toothID,
    parentID,
    code,
  }: {
    toothID: string;
    parentID: string;
    code: ConditionCode;
  }) =>
  (state: RootState) =>
    state.logicalCondition.toothConditions[toothID]?.childConditions[parentID][
      code
    ];

export const selectHoveredConditionLink = (state: RootState) =>
  state.logicalCondition.hoveredConditionLink;

export const selectLoading = (state: RootState) =>
  state.logicalCondition.loading;

export const selectMappedConditions = (toothID: string) =>
  createSelector(selectToothConditions(toothID), (toothConditions) => {
    const conditions = Object.values(toothConditions.conditions).flatMap(
      (item) => Object.values(item),
    );
    const childConditions = Object.values(
      toothConditions.childConditions,
    ).flatMap((item) =>
      Object.values(item).flatMap((childItem) => Object.values(childItem)),
    );

    return conditions.concat(childConditions);
  });

export const selectLowProbabilityConditionIDs = createSelector(
  (state: RootState) => state.logicalCondition.toothConditions,
  findLowProbabilityConditionIDs,
);

const isLogicalConditionActive = (
  logicalCondition?: LogicalCondition,
): logicalCondition is LogicalCondition =>
  !isEmpty(logicalCondition) &&
  logicalCondition.Certainty?.EngineDecision === Decision.PositiveDecision;

export const selectToothActiveLogicalConditions = (toothID: string) =>
  createSelector(selectToothConditions(toothID), (toothConditions) => {
    const conditions = Object.values(toothConditions.conditions)
      .flatMap(mergeConditions)
      .filter(isLogicalConditionActive)
      .map((logicalCondition) => {
        return logicalCondition?.IDs.reduce(
          (logicalConditionsWithChilds, parentID) => {
            const childConditions = toothConditions.childConditions[parentID];

            logicalConditionsWithChilds.Childs.push(childConditions);
            return logicalConditionsWithChilds;
          },
          {
            ...logicalCondition,

            Childs: [] as ToothConditionCollectionByCode[],
          },
        );
      });

    return conditions.map((condition) => {
      const childs = condition?.Childs.reduce((acc, curr) => {
        if (isEmpty(acc)) {
          return curr;
        } else {
          const currClone = cloneDeep(curr);
          return merge(currClone, acc);
        }
      }, {} as ToothConditionCollectionByCode);

      if (childs === undefined) {
        return {
          ...condition,
          Childs: [],
        };
      }

      const childsCondition = Object.values(childs)
        .flatMap(mergeConditions)
        .filter(isLogicalConditionActive);

      return {
        ...condition,
        Childs: childsCondition,
      };
    });
  });

export const selectAllSlicesMasks = (toothID: string) =>
  createSelector(selectToothConditions(toothID), (toothConditions) => {
    return mergeConditions(
      toothConditions.conditions[ConditionCode.PeriapicalRadiolucency],
    )?.Localizations;
  });

export const selectPeriapicalConditionsByReportID = (reportID: string) =>
  createSelector(
    (state: RootState) => state.logicalCondition.toothConditions,
    (toothConditions) => {
      return Object.entries(toothConditions).reduce(
        (acc, [toothID, conditions]) => {
          const periapicalCondition =
            conditions.conditions[ConditionCode.PeriapicalRadiolucency];

          if (periapicalCondition) {
            const firstCondition = Object.values(periapicalCondition).at(0);

            if (firstCondition && firstCondition.ReportID === reportID) {
              acc[toothID] = firstCondition;
            }
          }
          return acc;
        },
        {} as Record<string, Condition>,
      );
    },
  );
