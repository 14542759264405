import {
    ContextManager as ContextManagerClass,
    ContextRequest,
    IContext,
    ProcessingUniforms,
} from 'graphics';
import {
    CSSProperties,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { CORS_POLICY } from '@/shared/config';

export const ContextManager = new ContextManagerClass();

export interface RCImageProps {
    src: ContextRequest;
    onLoaded?: (context: IContext) => void;
    onRendered?: (getPngBlob: () => Promise<Blob | null>) => void;
    uniforms?: ProcessingUniforms;
    style?: CSSProperties;
    onClick?: () => void;
}

export type Repaint = (
    context: IContext,
    inCanvas: HTMLCanvasElement,
    outCanvas: HTMLCanvasElement,
) => void;

export interface RCImageBaseProps extends RCImageProps {
    repaint: Repaint;
}

/**
 * Base component for rendering medical images. Features extends by providing a `repaint` method.
 */
export const RCImageBase = (props: RCImageBaseProps) => {
    const { src, uniforms, onRendered, onLoaded, style, onClick, repaint } = props;

    const ref = useRef<HTMLCanvasElement | null>(null);

    const srcWithCors = {
        ...src,
        credentials: CORS_POLICY,
    }

    const [isLoaded, setIsLoaded] = useState(false);

    useLayoutEffect(() => {
        // A variable to closure the context for the cleanup function
        let context2: IContext;

        const ownAndRenderAsync = async () => {
            const context = await ContextManager.ownContext(srcWithCors);
            context2 = context;

            // FIX for PROD-2779
            if (!isLoaded) {
                onLoaded?.(context);
                setIsLoaded(true);
            } else {
                // Don't render if the canvas is not mounted
                if (!ref.current) return;

                context.render(uniforms || {}, (canvas) => {
                    repaint(context, canvas, ref.current!);
                    onRendered?.(getPngBlob);
                });
            }
        };

        ownAndRenderAsync();

        return () => {
            if (context2) ContextManager.disownContext(context2);
        };
    }, [
        ref.current,
        src.url,
        src.kind,
        uniforms?.ww,
        uniforms?.wc,
        uniforms?.sharpness,
        uniforms?.invert,
        repaint,
        isLoaded,
    ]);

    // To make it possible for outside actors to download an image from a DicomImage's canvas
    const getPngBlob = useMemo(() => {
        return () =>
            new Promise<Blob | null>((resolve) => {
                if (ref.current) {
                    ref.current.toBlob(resolve);
                } else {
                    resolve(null)
                }
            });
    }, [ref]);

    return (
        <canvas
            data-src={JSON.stringify(src)}
            ref={ref}
            style={style}
            onClick={onClick}
        />
    );
};
