// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_report_condition_codes.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";

/**
 * A lot of Attributes were added erroneously and will be phased out.
 *
 * @generated from enum com.diagnocat.model.ConditionCode
 */
export const ConditionCode = proto3.makeEnum(
  "com.diagnocat.model.ConditionCode",
  [
    {no: 0, name: "ConditionCodeInvalidValue"},
    {no: 1000, name: "CodeTooth"},
    {no: 1001, name: "Missing"},
    {no: 1003, name: "Implant"},
    {no: 1004, name: "Pontic"},
    {no: 1057, name: "RootFragment"},
    {no: 1002, name: "PartiallyVisible"},
    {no: 1007, name: "Filling"},
    {no: 1008, name: "Hypercementosis"},
    {no: 1009, name: "Impaction"},
    {no: 1010, name: "Roots1"},
    {no: 1011, name: "Roots2"},
    {no: 1012, name: "Roots3"},
    {no: 1013, name: "Roots4"},
    {no: 1014, name: "Canals1"},
    {no: 1015, name: "Canals2"},
    {no: 1016, name: "Canals3"},
    {no: 1017, name: "Canals4"},
    {no: 1018, name: "Canals5"},
    {no: 1019, name: "ArtificialCrown"},
    {no: 1021, name: "CariesSigns"},
    {no: 1023, name: "EctopicEruption"},
    {no: 1024, name: "EndoTreated"},
    {no: 1026, name: "VerticalRootFracture"},
    {no: 1033, name: "CanalObliteration"},
    {no: 1036, name: "InternalResorption"},
    {no: 1037, name: "OrthodonticAppliance"},
    {no: 1038, name: "PeriapicalRadiopacity"},
    {no: 1202, name: "PeriapicalRadiolucency"},
    {no: 1039, name: "PDLSpaceWidening_InPeriapicalRegion"},
    {no: 1042, name: "PDLSpaceWidening_AlongTheRoot"},
    {no: 1043, name: "FurcationLesion"},
    {no: 1044, name: "PulpStone"},
    {no: 1046, name: "ExternalResorption"},
    {no: 1051, name: "HorizontalRootFracture"},
    {no: 1052, name: "CrownFracture"},
    {no: 1053, name: "Abfraction"},
    {no: 1060, name: "Attrition"},
    {no: 1065, name: "PeriImplantitis"},
    {no: 1068, name: "DentalCalculus"},
    {no: 1075, name: "HorizontalDisplacement"},
    {no: 1077, name: "Overeruption"},
    {no: 1083, name: "OpenMargin"},
    {no: 1084, name: "Overhang"},
    {no: 1086, name: "LackOfInterproximalContact"},
    {no: 1087, name: "SecondaryCaries"},
    {no: 1022, name: "CrownDefectOver50pct"},
    {no: 1092, name: "PeriodontalBoneLoss"},
    {no: 1148, name: "EndodonticAccess"},
    {no: 1149, name: "Pulpotomy"},
    {no: 1150, name: "Clamp"},
    {no: 1151, name: "ForeignBody"},
    {no: 1152, name: "EndodonticInstrument"},
    {no: 1181, name: "IndirectRestoration"},
    {no: 1182, name: "RestorationFracture"},
    {no: 1185, name: "LateralRadiolucency"},
    {no: 1186, name: "SingsOfCommunicationWithMaxillarySinus"},
    {no: 1187, name: "SignsOfInferiorAlveolarCanalInvolvement"},
    {no: 1189, name: "Apicoectomy"},
    {no: 1197, name: "ToothGerm"},
    {no: 1200, name: "MucosalThickening"},
    {no: 1201, name: "BoneStructureDisorder"},
    {no: 1204, name: "ToothStump"},
    {no: 1205, name: "VoidsInTheFilling"},
    {no: 1206, name: "Extrusion"},
    {no: 1207, name: "Intrusion"},
    {no: 1208, name: "LateralLuxation"},
    {no: 1218, name: "HealingAbutment"},
    {no: 1223, name: "CervicalResorption"},
    {no: 1224, name: "PreEruptiveResorption"},
    {no: 1240, name: "MaxFaxArtifact"},
    {no: 1241, name: "MaxFaxBoneDisruption"},
    {no: 1242, name: "MaxFaxMetalConstruction"},
    {no: 1243, name: "MaxFaxBoneAugmentation"},
    {no: 1244, name: "MaxFaxSoftTissueThickening"},
    {no: 1245, name: "EndoAntralSyndrome"},
    {no: 1246, name: "OroantralCommunication"},
    {no: 1247, name: "OstialObstruction"},
    {no: 1248, name: "MarginalDiscrepancy"},
    {no: 5000, name: "Child_DefectDepth_NotDefined"},
    {no: 5001, name: "Child_DefectDepth_Initial"},
    {no: 5002, name: "Child_DefectDepth_Enamel"},
    {no: 5003, name: "Child_DefectDepth_Dentin"},
    {no: 5004, name: "Child_DefectDepth_WithPulpExposure"},
    {no: 5005, name: "Child_DefectDepth_Root"},
    {no: 5006, name: "Child_DefectDepth_CrownRoot"},
    {no: 5050, name: "Child_DefectSurface_NotDefined"},
    {no: 5051, name: "Child_DefectSurface_Mesial"},
    {no: 5052, name: "Child_DefectSurface_Distal"},
    {no: 5053, name: "Child_DefectSurface_Lingual"},
    {no: 5054, name: "Child_DefectSurface_Buccal"},
    {no: 5055, name: "Child_DefectSurface_Occlusial"},
    {no: 5056, name: "Child_DefectSurface_Vestibular"},
    {no: 5057, name: "Child_DefectSurface_IncisalEdge"},
    {no: 5200, name: "Child_PeriodontalBoneLoss_Severity_Mild"},
    {no: 5201, name: "Child_PeriodontalBoneLoss_Severity_Moderate"},
    {no: 5202, name: "Child_PeriodontalBoneLoss_Severity_Severe"},
    {no: 5210, name: "Child_PeriodontalBoneLoss_Type_Vertical"},
    {no: 5211, name: "Child_PeriodontalBoneLoss_Type_Horizontal"},
    {no: 5212, name: "Child_PeriodontalBoneLoss_Type_Mixed"},
    {no: 5250, name: "Child_Filling_BuildUp"},
    {no: 5300, name: "Child_MaxFaxBoneStructureDisorder_Type_Hyperdense"},
    {no: 5301, name: "Child_MaxFaxBoneStructureDisorder_Type_Hypodense"},
    {no: 5302, name: "Child_MaxFaxBoneStructureDisorder_Type_Mix"},
    {no: 5350, name: "Child_Impaction_Subtype_CompleteBony"},
    {no: 5351, name: "Child_Impaction_Subtype_PartiallyBony"},
    {no: 5400, name: "Child_EndoTreated_Obturation_AdequateObturation"},
    {no: 5401, name: "Child_EndoTreated_Obturation_ShortFilling"},
    {no: 5402, name: "Child_EndoTreated_Obturation_Overfilling"},
    {no: 5403, name: "Child_EndoTreated_Obturation_MissedCanal"},
    {no: 5410, name: "Child_EndoTreated_Quality_AdequateDensity"},
    {no: 5411, name: "Child_EndoTreated_Quality_VoidsPresentInTheRootFilling"},
    {no: 5412, name: "Child_EndoTreated_Quality_VoidsInThePostArea"},
    {no: 5420, name: "Child_EndoTreated_PostAndCore_CastPostAndCore"},
    {no: 5421, name: "Child_EndoTreated_PostAndCore_FiberglassPost"},
    {no: 5422, name: "Child_EndoTreated_PostAndCore_MetalPost"},
    {no: 5423, name: "Child_EndoTreated_PostAndCore_Post"},
    {no: 5430, name: "Child_EndoTreated_Complications_RootPerforation"},
    {no: 5431, name: "Child_EndoTreated_Complications_SeparatedInstrumentInTheRootCanal"},
    {no: 5450, name: "Child_MucosalThickening_Type_Focal"},
    {no: 5451, name: "Child_MucosalThickening_Type_Diffused"},
    {no: 5452, name: "Child_MucosalThickening_Type_CompleteOrPartialObstruction"},
    {no: 5470, name: "Child_RootType_SingleRoot"},
    {no: 5471, name: "Child_RootType_Buccal"},
    {no: 5472, name: "Child_RootType_Lingual"},
    {no: 5473, name: "Child_RootType_Palatal"},
    {no: 5474, name: "Child_RootType_Mesial"},
    {no: 5475, name: "Child_RootType_Mesial1"},
    {no: 5476, name: "Child_RootType_Mesial2"},
    {no: 5477, name: "Child_RootType_MesialBuccal"},
    {no: 5478, name: "Child_RootType_MesialLingual"},
    {no: 5479, name: "Child_RootType_Distal"},
    {no: 5480, name: "Child_RootType_DistalBuccal"},
    {no: 5481, name: "Child_RootType_DistalLingual"},
  ],
);

