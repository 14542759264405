import { FC } from 'react';

import { Avatar } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';

import { DoctorsAvatars } from '@/entities/doctors';

import { patientDoctorsModel } from '@/features/patientDoctors';

import styles from './PatientDoctorsCell.module.scss';

type PatientDoctorsCellProps = {
  patientID: string;
  className?: string;
};

export const PatientDoctorsCell: FC<PatientDoctorsCellProps> = (props) => {
  const { className, patientID } = props;

  const allPatientDoctors = useAppSelector(
    patientDoctorsModel.selectors.selectAllPatientDoctors(patientID),
  );

  const isOnlyOneDoctor = allPatientDoctors?.length === 1;

  const moreThanFourDoctors =
    allPatientDoctors && allPatientDoctors?.length > 4;

  const visibleDoctors = moreThanFourDoctors
    ? allPatientDoctors.slice(0, 3)
    : allPatientDoctors;

  return (
    <div className={className}>
      <Avatar.Group>
        {visibleDoctors &&
          visibleDoctors.map((patientDoctor) => (
            <DoctorsAvatars
              doctor={patientDoctor}
              isOnlyOneDoctor={isOnlyOneDoctor}
              key={patientDoctor?.UserID}
            />
          ))}

        {allPatientDoctors && visibleDoctors && moreThanFourDoctors && (
          <Avatar
            className={styles.avatarCount}
            count={allPatientDoctors.length - visibleDoctors.length}
          />
        )}
      </Avatar.Group>
    </div>
  );
};
