import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import PinInput from 'react-pin-input';
import { DeepPartial } from 'react-hook-form';
import { ConnectError } from '@bufbuild/connect';

import { Icon, InlineFormError, Spinner } from '@/shared/ui';
import { PATHS } from '@/shared/config';
import smileCat from '@/shared/assets/images/cats/smile.png';
import { useAppDispatch } from '@/shared/hooks';
import { AuthState_AuthStatus } from '@/shared/api/protocol-ts/api/auth/dto_auth_pb';
import { SignUp_ByOneself_2_ConfirmIdentityReq } from '@/shared/api/protocol-ts/api/auth/svc_authentication_pb';
import { trackPageView } from '@/shared/lib';

import { authModel } from '@/entities/auth';

import { useConfirmEmailError } from './hooks/useConfirmEmailError';
import styles from './ConfirmEmail.module.scss';

type ConfirmEmailProps = {
  className?: string;
  testID?: string;
};

export const ConfirmEmail: FC<ConfirmEmailProps> = (props) => {
  const { className, testID } = props;

  const location = useLocation();

  const email = (location?.state as { email: string })?.email;

  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isVerifiedSuccessfully, setIsVerifiedSuccessfully] = useState(false);

  const { callConfirmEmailError, inlineError } = useConfirmEmailError();

  const confirmEmail = async (confirmationCode: string) => {
    setIsLoading(true);

    try {
      const confirmEmailResponse = await dispatch(
        authModel.thunks.confirmEmail({
          ConfirmationCode: confirmationCode,
        } as DeepPartial<SignUp_ByOneself_2_ConfirmIdentityReq>),
      ).unwrap();

      if (confirmEmailResponse.Status === AuthState_AuthStatus.IN_PROGRESS) {
        setIsVerifiedSuccessfully(true);

        setTimeout(() => navigate(PATHS.finishRegistration), 2000);
      }
    } catch (error) {
      const connectError = ConnectError.from(error);
      callConfirmEmailError(connectError);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const confirmationCode = searchParams.get('code');

    if (confirmationCode) {
      confirmEmail(confirmationCode);
    }

    trackPageView('ConfirmEmail Page Viewed');
  }, []);

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      <div className={styles.wrapper}>
        <h3 className={cn(styles.heading, 'h3')}>
          <FormattedMessage
            id="confirmEmail.verification"
            defaultMessage="Verification"
          />
        </h3>

        <div className={styles.content}>
          <img
            src={smileCat}
            width={130}
            height={130}
            alt={formatMessage({
              id: 'imgAltText.smileCat',
              defaultMessage: 'Smile cat',
            })}
          />

          <h5 className={cn(styles.centeredText, 'h5')}>
            {isVerifiedSuccessfully ? (
              <FormattedMessage
                id="confirmEmail.verifiedSuccessfully"
                defaultMessage="Congrats! Your Diagnocat account is verified successfully."
              />
            ) : (
              <FormattedMessage
                id="confirmEmail.congrats"
                defaultMessage="Congrats! Your Diagnocat account is almost ready."
              />
            )}
          </h5>

          {isVerifiedSuccessfully ? (
            <Icon
              name="check"
              size={48}
              className={styles.verifiedSuccessfullyIcon}
            />
          ) : (
            <>
              <p className={cn(styles.centeredText, 'p2')}>
                <FormattedMessage
                  id="confirmEmail.we_sent_code"
                  defaultMessage="We have sent a verification code to {email}. Please check your email to confirm your account. Don't forget to also check your spam folder in case the email got sent there"
                  values={{
                    email: email || 'your@mail.com',
                  }}
                />
              </p>

              {!isLoading ? (
                <PinInput
                  length={6}
                  type="numeric"
                  inputMode="number"
                  inputStyle={{
                    width: '38px',
                    height: '48px',
                    borderColor: 'rgba(var(--focused-rgb), .26)',
                    borderRadius: '8px',
                    borderWidth: '2px',
                  }}
                  inputFocusStyle={{ borderColor: 'var(--focused)' }}
                  onComplete={(value) => confirmEmail(value)}
                  autoSelect
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
              ) : (
                <Spinner size={48} className={styles.spinner} />
              )}
            </>
          )}

          {inlineError && (
            <InlineFormError
              className={styles.inlineFormError}
              errorMessage={inlineError}
            />
          )}
        </div>
      </div>
    </div>
  );
};
