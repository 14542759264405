// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_report_landmark_codes.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";

/**
 * https://docs.google.com/document/d/1MzKZP-kaXVrOxkYGDmf-phEfvLnTr7dw1jJO4DSX-R4/edit
 *
 * @generated from enum com.diagnocat.model.ToothLandmark_Image_Code
 */
export const ToothLandmark_Image_Code = proto3.makeEnum(
  "com.diagnocat.model.ToothLandmark_Image_Code",
  [
    {no: 0, name: "ToothLandmark_Image_Code_InvalidValue"},
    {no: 1, name: "ToothLandmark_Image_Code_CementoenamelJunctionMesial"},
    {no: 2, name: "ToothLandmark_Image_Code_CementoenamelJunctionDistal"},
    {no: 3, name: "ToothLandmark_Image_Code_BoneLevelMesial"},
    {no: 4, name: "ToothLandmark_Image_Code_BoneLevelDistal"},
    {no: 5, name: "ToothLandmark_Image_Code_Apex"},
  ],
);

/**
 * https://diagnocat.atlassian.net/wiki/spaces/DATA/pages/89882743/Teeth+s+specification
 *
 * @generated from enum com.diagnocat.model.ToothLandmark_CBCT_Code
 */
export const ToothLandmark_CBCT_Code = proto3.makeEnum(
  "com.diagnocat.model.ToothLandmark_CBCT_Code",
  [
    {no: 0, name: "ToothLandmark_CBCT_Code_InvalidValue"},
    {no: 1, name: "ToothLandmark_CBCT_Code_UpperToothMedian"},
    {no: 2, name: "ToothLandmark_CBCT_Code_LowerToothMedian"},
    {no: 3, name: "ToothLandmark_CBCT_Code_EnamelJointMesial"},
    {no: 4, name: "ToothLandmark_CBCT_Code_EnamelJointDistal"},
    {no: 5, name: "ToothLandmark_CBCT_Code_EnamelJointVestibular"},
    {no: 6, name: "ToothLandmark_CBCT_Code_EnamelJointOral"},
    {no: 7, name: "ToothLandmark_CBCT_Code_BonePeakMesial"},
    {no: 8, name: "ToothLandmark_CBCT_Code_BonePeakDistal"},
    {no: 9, name: "ToothLandmark_CBCT_Code_BonePeakVestibular"},
    {no: 10, name: "ToothLandmark_CBCT_Code_BonePeakOral"},
    {no: 11, name: "ToothLandmark_CBCT_Code_AttachmentMesial"},
    {no: 12, name: "ToothLandmark_CBCT_Code_AttachmentDistal"},
    {no: 13, name: "ToothLandmark_CBCT_Code_AttachmentVestibular"},
    {no: 14, name: "ToothLandmark_CBCT_Code_AttachmentOral"},
    {no: 15, name: "ToothLandmark_CBCT_Code_Furcation"},
    {no: 16, name: "ToothLandmark_CBCT_Code_Buccal"},
    {no: 17, name: "ToothLandmark_CBCT_Code_Lingual"},
    {no: 18, name: "ToothLandmark_CBCT_Code_Mesial1"},
    {no: 19, name: "ToothLandmark_CBCT_Code_Mesial2"},
    {no: 20, name: "ToothLandmark_CBCT_Code_Distal"},
    {no: 21, name: "ToothLandmark_CBCT_Code_Palatal"},
    {no: 22, name: "ToothLandmark_CBCT_Code_A1"},
    {no: 23, name: "ToothLandmark_CBCT_Code_Mesial"},
  ],
);

/**
 * https://diagnocat.atlassian.net/wiki/spaces/DATA/pages/127238145/IOS+Surfaces+Landmarks
 *
 * @generated from enum com.diagnocat.model.ToothLandmark_IOS_Code
 */
export const ToothLandmark_IOS_Code = proto3.makeEnum(
  "com.diagnocat.model.ToothLandmark_IOS_Code",
  [
    {no: 0, name: "ToothLandmark_IOS_Code_InvalidValue"},
    {no: 1, name: "ToothLandmark_IOS_Code_CenterOralFrontal"},
    {no: 2, name: "ToothLandmark_IOS_Code_CenterOralPosterior"},
    {no: 3, name: "ToothLandmark_IOS_Code_Mesial"},
    {no: 4, name: "ToothLandmark_IOS_Code_Distal"},
    {no: 5, name: "ToothLandmark_IOS_Code_Cuspidal"},
    {no: 6, name: "ToothLandmark_IOS_Code_CuspidalOral"},
    {no: 7, name: "ToothLandmark_IOS_Code_CuspidalBuccal"},
    {no: 8, name: "ToothLandmark_IOS_Code_CuspidalBuccalMesial"},
    {no: 9, name: "ToothLandmark_IOS_Code_CuspidalBuccalDistal"},
    {no: 10, name: "ToothLandmark_IOS_Code_CuspidalOralMesial"},
    {no: 11, name: "ToothLandmark_IOS_Code_CuspidalOralDistal"},
    {no: 12, name: "ToothLandmark_IOS_Code_CuspidalOralAccesorium"},
    {no: 13, name: "ToothLandmark_IOS_Code_CuspidalDistalAccesorium"},
    {no: 14, name: "ToothLandmark_IOS_Code_CuspidalDistal"},
  ],
);

/**
 * https://diagnocat.atlassian.net/wiki/spaces/DATA/pages/157777942/Ceph+landmarks+specification
 *
 * @generated from enum com.diagnocat.model.CephalometricLandmark_CBCT_Code
 */
export const CephalometricLandmark_CBCT_Code = proto3.makeEnum(
  "com.diagnocat.model.CephalometricLandmark_CBCT_Code",
  [
    {no: 0, name: "CephalometricLandmark_CBCT_Code_InvalidValue"},
    {no: 1, name: "CephalometricLandmark_CBCT_Code_Nasion"},
    {no: 2, name: "CephalometricLandmark_CBCT_Code_Sella"},
    {no: 3, name: "CephalometricLandmark_CBCT_Code_Basion"},
    {no: 4, name: "CephalometricLandmark_CBCT_Code_AnteriorNasalSpine"},
    {no: 5, name: "CephalometricLandmark_CBCT_Code_PosteriorNasalSpine"},
    {no: 6, name: "CephalometricLandmark_CBCT_Code_APointSubspinale"},
    {no: 7, name: "CephalometricLandmark_CBCT_Code_ProsthionSupradentaleSuperiorProsthion"},
    {no: 8, name: "CephalometricLandmark_CBCT_Code_Interdentale"},
    {no: 9, name: "CephalometricLandmark_CBCT_Code_BPointSupramentale"},
    {no: 10, name: "CephalometricLandmark_CBCT_Code_ProtuberantiaMenti"},
    {no: 11, name: "CephalometricLandmark_CBCT_Code_Pogonion"},
    {no: 12, name: "CephalometricLandmark_CBCT_Code_Gnathion"},
    {no: 13, name: "CephalometricLandmark_CBCT_Code_Menton"},
    {no: 14, name: "CephalometricLandmark_CBCT_Code_OrbitaleRight"},
    {no: 15, name: "CephalometricLandmark_CBCT_Code_OrbitaleLeft"},
    {no: 16, name: "CephalometricLandmark_CBCT_Code_PorionRight"},
    {no: 17, name: "CephalometricLandmark_CBCT_Code_PorionLeft"},
    {no: 18, name: "CephalometricLandmark_CBCT_Code_CondylionRight"},
    {no: 19, name: "CephalometricLandmark_CBCT_Code_CondylionLeft"},
    {no: 20, name: "CephalometricLandmark_CBCT_Code_CondyleLateralPointRight"},
    {no: 21, name: "CephalometricLandmark_CBCT_Code_CondyleLateralPointLeft"},
    {no: 22, name: "CephalometricLandmark_CBCT_Code_CondyleMedialPointRight"},
    {no: 23, name: "CephalometricLandmark_CBCT_Code_CondyleMedialPointLeft"},
    {no: 24, name: "CephalometricLandmark_CBCT_Code_ArticulareRight"},
    {no: 25, name: "CephalometricLandmark_CBCT_Code_ArticulareLeft"},
    {no: 26, name: "CephalometricLandmark_CBCT_Code_UpperRamalPointRight"},
    {no: 27, name: "CephalometricLandmark_CBCT_Code_UpperRamalPointLeft"},
    {no: 28, name: "CephalometricLandmark_CBCT_Code_AnteriorRamalPointRight"},
    {no: 29, name: "CephalometricLandmark_CBCT_Code_GonionRight"},
    {no: 30, name: "CephalometricLandmark_CBCT_Code_GonionLeft"},
    {no: 31, name: "CephalometricLandmark_CBCT_Code_AntegonionRight"},
    {no: 32, name: "CephalometricLandmark_CBCT_Code_AntegonionLeft"},
    {no: 33, name: "CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalRootPointRight"},
    {no: 34, name: "CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalRootPointLeft"},
    {no: 35, name: "CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalCuspPointRight"},
    {no: 36, name: "CephalometricLandmark_CBCT_Code_UpperMolarMesiobuccalCuspPointLeft"},
    {no: 37, name: "CephalometricLandmark_CBCT_Code_LowerMolarMesiobuccalCuspPointRight"},
    {no: 38, name: "CephalometricLandmark_CBCT_Code_LowerMolarMesiobuccalCuspPointLeft"},
    {no: 39, name: "CephalometricLandmark_CBCT_Code_LowerMolarMesialRootPointRight"},
    {no: 40, name: "CephalometricLandmark_CBCT_Code_LowerMolarMesialRootPointLeft"},
    {no: 41, name: "CephalometricLandmark_CBCT_Code_UpperIncisorRootPointRight"},
    {no: 42, name: "CephalometricLandmark_CBCT_Code_UpperIncisorRootPointLeft"},
    {no: 43, name: "CephalometricLandmark_CBCT_Code_UpperIncisorCrownPointRight"},
    {no: 44, name: "CephalometricLandmark_CBCT_Code_UpperIncisorCrownPointLeft"},
    {no: 45, name: "CephalometricLandmark_CBCT_Code_LowerIncisorRootPointRight"},
    {no: 46, name: "CephalometricLandmark_CBCT_Code_LowerIncisorRootPointLeft"},
    {no: 47, name: "CephalometricLandmark_CBCT_Code_LowerIncisorCrownPointRight"},
    {no: 48, name: "CephalometricLandmark_CBCT_Code_LowerIncisorCrownPointLeft"},
    {no: 49, name: "CephalometricLandmark_CBCT_Code_Glabella"},
    {no: 50, name: "CephalometricLandmark_CBCT_Code_SoftTissueNasion"},
    {no: 51, name: "CephalometricLandmark_CBCT_Code_Pronasale"},
    {no: 52, name: "CephalometricLandmark_CBCT_Code_Cartilage"},
    {no: 53, name: "CephalometricLandmark_CBCT_Code_SubnasaleSubnasalSubnasion"},
    {no: 54, name: "CephalometricLandmark_CBCT_Code_LabialisSuperior"},
    {no: 55, name: "CephalometricLandmark_CBCT_Code_StomionSuperius"},
    {no: 56, name: "CephalometricLandmark_CBCT_Code_StomionInferius"},
    {no: 57, name: "CephalometricLandmark_CBCT_Code_LabialInferior"},
    {no: 58, name: "CephalometricLandmark_CBCT_Code_MentolabialSulcus"},
    {no: 59, name: "CephalometricLandmark_CBCT_Code_SoftTissuePogonion"},
    {no: 60, name: "CephalometricLandmark_CBCT_Code_SoftTissueMenton"},
    {no: 61, name: "CephalometricLandmark_CBCT_Code_LateralLeft"},
    {no: 62, name: "CephalometricLandmark_CBCT_Code_LateralRight"},
    {no: 63, name: "CephalometricLandmark_CBCT_Code_CristaGalli"},
    {no: 64, name: "CephalometricLandmark_CBCT_Code_MaxillareLeft"},
    {no: 65, name: "CephalometricLandmark_CBCT_Code_MaxillareRight"},
    {no: 66, name: "CephalometricLandmark_CBCT_Code_WillAndrewsAndLarryAndrewsLeft"},
    {no: 67, name: "CephalometricLandmark_CBCT_Code_WillAndrewsAndLarryAndrewsRight"},
    {no: 68, name: "CephalometricLandmark_CBCT_Code_ZygionLeft"},
    {no: 69, name: "CephalometricLandmark_CBCT_Code_ZygionRight"},
    {no: 70, name: "CephalometricLandmark_CBCT_Code_CenterOfCondyleLeft"},
    {no: 71, name: "CephalometricLandmark_CBCT_Code_CenterOfCondyleRight"},
    {no: 72, name: "CephalometricLandmark_CBCT_Code_EndocanthionLeft"},
    {no: 73, name: "CephalometricLandmark_CBCT_Code_EndocanthionRight"},
    {no: 74, name: "CephalometricLandmark_CBCT_Code_PterygoidLeft"},
    {no: 75, name: "CephalometricLandmark_CBCT_Code_PterygoidRight"},
  ],
);

