import { defineMessages } from 'react-intl';

import {
  CBCT_Anatomy,
  CBCT_Tooth_Anatomy,
} from '@/shared/api/protocol-ts/model/dto_common_anatomy_pb';
import {
  IOXRay_Anatomy,
  IOXRay_Tooth_Anatomy,
  Pano_Anatomy,
  Pano_Tooth_Anatomy,
} from '@/shared/api/protocol-ts/model/dto_report_type_2D_common_pb';

export const maskFiltersDefaultMessages = defineMessages({
  perio: {
    id: 'mask.filter.perio',
    defaultMessage: 'Perio',
  },
  restorative: {
    id: 'mask.filter.restorative',
    defaultMessage: 'Restorative',
  },
  endo: {
    id: 'mask.filter.endo',
    defaultMessage: 'Endo',
  },
  chart: {
    id: 'mask.filter.chart',
    defaultMessage: 'Chart',
  },
});

export const CBCTAnatomyDefaultMessages = defineMessages({
  [CBCT_Anatomy.CBCT_Anatomy_Airways]: {
    id: 'general.airways',
    defaultMessage: 'Airways',
  },
  [CBCT_Anatomy.CBCT_Anatomy_Cranium]: {
    id: 'general.cranium',
    defaultMessage: 'Cranium',
  },
  [CBCT_Anatomy.CBCT_Anatomy_SoftTissue]: {
    id: 'mask.anatomy.softTissue',
    defaultMessage: 'Soft Tissue',
  },
  [CBCT_Anatomy.CBCT_Anatomy_IncisiveCanal]: {
    id: 'mask.anatomy.incisiveCanal',
    defaultMessage: 'Incisive Canal',
  },
  [CBCT_Anatomy.CBCT_Anatomy_Mandible]: {
    id: 'general.mandible',
    defaultMessage: 'Mandible',
  },
  [CBCT_Anatomy.CBCT_Anatomy_Maxilla]: {
    id: 'general.maxilla',
    defaultMessage: 'Maxilla',
  },
  [CBCT_Anatomy.CBCT_Anatomy_MandibularCanal]: {
    id: 'mask.anatomy.mandibularCanal',
    defaultMessage: 'Mandibular Canal',
  },
  [CBCT_Anatomy.CBCT_Anatomy_Sinuses]: {
    id: 'general.sinuses',
    defaultMessage: 'Sinuses',
  },
  [CBCT_Anatomy.CBCT_Anatomy_InvalidValue]: {
    id: 'mask.anatomy.invalidValue',
    defaultMessage: 'Invalid Value',
  },
});

export const IOXRayAnatomyDefaultMessages = defineMessages({
  [IOXRay_Anatomy.IOXRay_Anatomy_InvalidValue]: {
    id: 'mask.anatomy.invalidValue',
    defaultMessage: 'Invalid Value',
  },
  [IOXRay_Anatomy.IOXRay_Anatomy_AlveolarBone]: {
    id: 'mask.anatomy.alveolarBone',
    defaultMessage: 'Alveolar Bone',
  },
  [IOXRay_Anatomy.IOXRay_Anatomy_MaxillarySinus]: {
    id: 'mask.anatomy.maxillarySinus',
    defaultMessage: 'Maxillary Sinus',
  },
  [IOXRay_Anatomy.IOXRay_Anatomy_MandibularCanal]: {
    id: 'mask.anatomy.mandibularCanal',
    defaultMessage: 'Mandibular Canal',
  },
  [IOXRay_Anatomy.IOXRay_Anatomy_NasalCavity]: {
    id: 'mask.anatomy.nasalCavity',
    defaultMessage: 'Nasal Cavity',
  },
});

export const PanoAnatomyDefaultMessages = defineMessages({
  [Pano_Anatomy.Pano_Anatomy_InvalidValue]: {
    id: 'mask.anatomy.invalidValue',
    defaultMessage: 'Invalid Value',
  },
  [Pano_Anatomy.Pano_Anatomy_Maxilla]: {
    id: 'general.maxilla',
    defaultMessage: 'Maxilla',
  },
  [Pano_Anatomy.Pano_Anatomy_Mandible]: {
    id: 'general.mandible',
    defaultMessage: 'Mandible',
  },
  [Pano_Anatomy.Pano_Anatomy_MaxillarySinus]: {
    id: 'mask.anatomy.maxillarySinus',
    defaultMessage: 'Maxillary Sinus',
  },
  [Pano_Anatomy.Pano_Anatomy_MandibularCanal]: {
    id: 'mask.anatomy.mandibularCanal',
    defaultMessage: 'Mandibular Canal',
  },
});

export const CBCTToothAnatomyDefaultMessages = defineMessages({
  [CBCT_Tooth_Anatomy.CBCT_Tooth_Anatomy_InvalidValue]: {
    id: 'mask.anatomy.invalidValue',
    defaultMessage: 'Invalid Value',
  },
  [CBCT_Tooth_Anatomy.CBCT_Tooth_Anatomy_Pulp]: {
    id: 'general.pulp',
    defaultMessage: 'Pulp',
  },
  [CBCT_Tooth_Anatomy.CBCT_Tooth_Anatomy_Dentin]: {
    id: 'general.dentin',
    defaultMessage: 'Dentin',
  },
  [CBCT_Tooth_Anatomy.CBCT_Tooth_Anatomy_Enamel]: {
    id: 'general.enamel',
    defaultMessage: 'Enamel',
  },
  [CBCT_Tooth_Anatomy.CBCT_Tooth_Anatomy_Root]: {
    id: 'general.root',
    defaultMessage: 'Root',
  },
});

export const IOXRayToothAnatomyDefaultMessages = defineMessages({
  [IOXRay_Tooth_Anatomy.IOXRay_Tooth_Anatomy_InvalidValue]: {
    id: 'mask.anatomy.invalidValue',
    defaultMessage: 'Invalid Value',
  },
  [IOXRay_Tooth_Anatomy.IOXRay_Tooth_Anatomy_Root]: {
    id: 'general.root',
    defaultMessage: 'Root',
  },
  [IOXRay_Tooth_Anatomy.IOXRay_Tooth_Anatomy_RootCanal]: {
    id: 'mask.anatomy.rootCanal',
    defaultMessage: 'Root Canal',
  },
  [IOXRay_Tooth_Anatomy.IOXRay_Tooth_Anatomy_Crown]: {
    id: 'general.crown',
    defaultMessage: 'Crown',
  },
  [IOXRay_Tooth_Anatomy.IOXRay_Tooth_Anatomy_IncisalEdge]: {
    id: 'mask.anatomy.incisalEdge',
    defaultMessage: 'Incisal Edge',
  },
  [IOXRay_Tooth_Anatomy.IOXRay_Tooth_Anatomy_PulpChamber]: {
    id: 'mask.anatomy.pulpChamber',
    defaultMessage: 'Pulp Chamber',
  },
  [IOXRay_Tooth_Anatomy.IOXRay_Tooth_Anatomy_Enamel]: {
    id: 'general.enamel',
    defaultMessage: 'Enamel',
  },
});

export const PanoToothAnatomyDefaultMessages = defineMessages({
  [Pano_Tooth_Anatomy.Pano_Tooth_Anatomy_InvalidValue]: {
    id: 'mask.anatomy.invalidValue',
    defaultMessage: 'Invalid Value',
  },
  [Pano_Tooth_Anatomy.Pano_Tooth_Anatomy_Root]: {
    id: 'general.root',
    defaultMessage: 'Root',
  },
  [Pano_Tooth_Anatomy.Pano_Tooth_Anatomy_RootCanal]: {
    id: 'mask.anatomy.rootCanal',
    defaultMessage: 'Root Canal',
  },
  [Pano_Tooth_Anatomy.Pano_Tooth_Anatomy_Crown]: {
    id: 'general.crown',
    defaultMessage: 'Crown',
  },
  [Pano_Tooth_Anatomy.Pano_Tooth_Anatomy_PulpChamber]: {
    id: 'mask.anatomy.pulpChamber',
    defaultMessage: 'Pulp Chamber',
  },
  [Pano_Tooth_Anatomy.Pano_Tooth_Anatomy_Enamel]: {
    id: 'general.enamel',
    defaultMessage: 'Enamel',
  },
});
