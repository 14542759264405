import { createSelector } from '@reduxjs/toolkit';

import {
  DentalNotationFormat,
  FeatureFlag,
  OrganizationUserRoleType,
} from '@/shared/api/protocol-ts/model/dto_organization_pb';

import { RootState } from '@/app/model/store';

import { TREATING_DOCTORS_AVAILABLE_ROLES } from '../config';

import { organizationAdapter } from './organizationSlice';

export const organizationSelectors = organizationAdapter.getSelectors(
  (state: RootState) => state.organization,
);

export const { selectIds } = organizationSelectors;

export const selectCurrentOrganizationID = (state: RootState) =>
  state.organization.currentOrganizationID;

export const selectCurrentOrganization = createSelector(
  [organizationSelectors.selectEntities, selectCurrentOrganizationID],
  (organizationEntities, organizationID) =>
    organizationEntities[organizationID],
);

export const selectAllUsers = createSelector(
  selectCurrentOrganization,
  (organization) => organization?.UserRoles ?? [],
);

export const selectUserByID = (userID: string) =>
  createSelector(selectAllUsers, (allUsers) =>
    allUsers.find((user) => user.UserID === userID),
  );

export const selectAllDoctors = createSelector(
  selectCurrentOrganization,
  (organization) =>
    organization?.UserRoles.filter((userRole) =>
      userRole.Roles.some(
        (role) =>
          TREATING_DOCTORS_AVAILABLE_ROLES.includes(role) ||
          role ===
            OrganizationUserRoleType.OrganizationUserRoleTypeNonClinicalMember,
      ),
    ),
);

export const selectTreatingDoctors = createSelector(
  selectCurrentOrganization,
  (organization) =>
    organization?.UserRoles.filter((userRole) =>
      userRole.Roles.some((role) =>
        TREATING_DOCTORS_AVAILABLE_ROLES.includes(role),
      ),
    ),
);

export const selectSharedDoctors = createSelector(
  selectCurrentOrganization,
  (organization) =>
    organization?.UserRoles.filter((userRole) =>
      userRole.Roles.includes(
        OrganizationUserRoleType.OrganizationUserRoleTypeNonClinicalMember,
      ),
    ),
);

export const selectDoctorByID = (doctorId: string) =>
  createSelector(selectAllDoctors, (allDoctors) =>
    allDoctors?.find((doctor) => doctor.UserID === doctorId),
  );

export const selectUsersByRoles = createSelector(
  [selectAllUsers, (_, userRoles: OrganizationUserRoleType[]) => userRoles],
  (organizationUsers, userRoles) =>
    organizationUsers?.filter((user) =>
      user?.Roles?.some((role) => userRoles.includes(role)),
    ),
);

export const selectDentalNotationFormat = createSelector(
  selectCurrentOrganization,
  (organization) =>
    organization?.Settings?.DentalNotationFormat ||
    DentalNotationFormat.DentalNotationFormatISO,
);

export const selectFeatureList = createSelector(
  selectCurrentOrganization,
  (organization) =>
    organization?.FeatureFlags?.reduce(
      (features, { Flag, Value }) => ({
        ...features,
        [Flag]: Value,
      }),
      {} as Record<FeatureFlag, boolean>,
    ),
);

export const selectIsFeatureActiveByName = (featureName: FeatureFlag) =>
  createSelector(selectFeatureList, (features) =>
    features ? features[featureName] : false,
  );

export const selectLoading = (state: RootState) => state.organization.loading;
