import { ReportType } from '@/shared/api/protocol-ts/model/dto_report_pb';

export const getModelTypeForUrl = (reportType: ReportType) => {
  switch (reportType) {
    case ReportType.ReportType_StudioImplant:
      return 'implant';
    case ReportType.ReportType_StudioOrtho:
      return 'ortho';
    default:
      return 'viewer';
  }
};
