import { Asset } from '@/shared/api/protocol-ts/model/dto_asset_pb';
import { MedicalImageInterface } from '@/shared/config';

import {
  getFileSrc,
  makeAnnotations,
  makeImageViewOptions,
} from '@/entities/assets';

export const transformUserAssetsToMedicalImages = (
  assets: Asset[],
): MedicalImageInterface[] =>
  assets.map((asset, index) => {
    const reportAsset =
      asset.Kind.case === 'Report' ? asset.Kind.value : undefined;

    const userUploadedToothSlice =
      reportAsset?.Content.case === 'UserUploadedToothSlice'
        ? reportAsset.Content.value
        : undefined;

    const userUploadedMPRPlane =
      reportAsset?.Content.case === 'UserUploadedMPRPlane'
        ? reportAsset.Content.value
        : undefined;

    const medicalImageID =
      userUploadedToothSlice?.Slice?.ID ?? userUploadedMPRPlane?.MPRPlane?.ID;
    const medicalImagePath = userUploadedToothSlice?.Slice?.Path;
    const annotations = makeAnnotations(
      reportAsset?.MedicalImageFeatures?.Annotations?.Layers,
    );
    const viewOptions = makeImageViewOptions(
      reportAsset?.MedicalImageFeatures?.ViewOptions,
    );

    return {
      src: getFileSrc(medicalImageID as string),
      kind: 'raster',
      id: medicalImageID as string,
      path: medicalImagePath,
      assetID: asset?.ID,
      order: index,
      annotations,
      viewOptions,
    };
  });
