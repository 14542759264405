import { FC, memo, useMemo } from 'react';
import cn from 'classnames';

import { MedicalImageInterface } from '@/shared/config';
import { useAppSelector } from '@/shared/hooks';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';

import { Group, MedicalImagesGroups, PathologyGroup } from '@/entities/assets';
import { organizationModel } from '@/entities/organization';

import { MedicalImageGroupSection } from '../MedicalImageGroupSection/MedicalImageGroupSection';

import styles from './MedicalImagesGroup.module.scss';

type MedicalImagesGroupProps = {
  selectedMedicalImages?: MedicalImageInterface[];
  groups: (Group | PathologyGroup)[];
  onDragMedicalImage?: (medicalImage: MedicalImageInterface) => void;
  toggleSelected?: (medicalImage: MedicalImageInterface) => void;
  className?: string;
};

export const MedicalImagesGroup: FC<MedicalImagesGroupProps> = memo((props) => {
  const {
    className,
    selectedMedicalImages,
    toggleSelected,
    onDragMedicalImage,
    groups,
  } = props;

  const isFDA = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_FDA_SubmissionView,
    ),
  );
  const isFDANonAided = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_FDA_NonAidedVersion,
    ),
  );

  const hidePerioMasksAndSlicesFF = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Hide_PerioMasksAndSlices,
    ),
  );

  const showPerioMasksAndSlices = useMemo(() => {
    if (isFDA) {
      return !isFDANonAided;
    }

    return !hidePerioMasksAndSlicesFF;
  }, [hidePerioMasksAndSlicesFF, isFDA, isFDANonAided]);

  const hideCariesSigns = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Hide_CariesSigns,
    ),
  );

  const filteredGroups = groups.filter((group) => {
    if (group.type === MedicalImagesGroups.Caries) {
      return !hideCariesSigns && !isFDA;
    }

    if (group.type === MedicalImagesGroups.PeriapicalLesion) {
      return showPerioMasksAndSlices;
    }

    return true;
  });

  return (
    <div className={cn(styles.container, className)}>
      {filteredGroups.map((group) => (
        <MedicalImageGroupSection
          key={group.type}
          group={group}
          selectedMedicalImages={selectedMedicalImages}
          onDragMedicalImage={onDragMedicalImage}
          toggleSelected={toggleSelected}
        />
      ))}
    </div>
  );
});
