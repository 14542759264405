import { FC, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router';
import { generatePath } from 'react-router-dom';

import {
  Button,
  Column,
  Icon,
  Layout,
  Skeleton,
  WidgetCard,
  WidgetLayout,
} from '@/shared/ui';
import { LocationStateType, PATHS } from '@/shared/config';
import { useAppDispatch, useAppSelector, useMedia } from '@/shared/hooks';
import { Report } from '@/shared/api/protocol-ts/model/dto_report_pb';

import { getReportSignStatus, reportsModel } from '@/entities/reports';
import {
  assetsModel,
  getFileSrc,
  makeImageViewOptions,
  useGetEndodonticMedicalImages,
} from '@/entities/assets';
import { getDisplayToothNumber, toothModel } from '@/entities/tooth';
import { organizationModel } from '@/entities/organization';

import {
  useReport_DEPRECATED,
  useReportDataStream,
} from '@/features/reportStream';

import { Header } from '@/widgets/Header';
import { PanoImage } from '@/widgets/PanoImage';
import { Conclusion } from '@/widgets/Conclusion';
import { MedicalImagesGroup } from '@/widgets/MedicalImageGroup';
import { ReportHeading } from '@/widgets/ReportHeading';
import { ZoomedMedicalImageModal } from '@/widgets/ZoomedMedicalImageModal';
import { GetFullAccessModal } from '@/widgets/GetFullAccessModal';
import { ReportSigns } from '@/widgets/Conclusion';

import { SCELETON_DEFAULT_HEIGHT, PANO_TOOLS } from './config';
import styles from './EndodonticReport.module.scss';

type ReportProps = {
  className?: string;
};

export const EndodonticReport: FC<ReportProps> = (props) => {
  const { className } = props;

  const dentalNotationFormat = useAppSelector(
    organizationModel.selectors.selectDentalNotationFormat,
  );

  const { isMobile, isTablet, isSmallDesktop, isDesktop, isLarge } = useMedia();

  const navigate = useNavigate();

  const { reportID = '', patientID = '' } = useParams();

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const { report, isReportLoading, isReportSigned } = useReport_DEPRECATED(
    reportID,
    patientID,
  );

  const columnRef = useRef<HTMLDivElement>(null);

  const [panoContainerWidth, setPanoContainerWidth] = useState(0);

  const [panoImageToggled, setPanoImageToggled] = useState(false);

  const location = useLocation();

  // type casting is necessary for typification location.state
  const locationState = location?.state as LocationStateType;

  const lastPositionReport = locationState?.lastPositionReport;

  // The "columnSecond" element is needed to determine the position when returning
  // to the page. It is not extracted into a separate variable because then it
  // would need to be added as a dependency in the useEffect, which leads to
  // unnecessary re-rendering.
  useEffect(() => {
    if (lastPositionReport) {
      document
        .querySelector(`[class*=columnSecond]`)
        ?.scrollTo(0, lastPositionReport);
    }
  }, []);

  const dataCBCTEndo =
    report?.Specific.case === 'DataCBCTEndo' ? report?.Specific.value : null;

  const displayToothNumber = getDisplayToothNumber(
    dataCBCTEndo?.PeriapicalLesionLocalization?.ToothNumberISO ?? 0,
    dentalNotationFormat,
  );

  const handleSign = async () => {
    setLoading(true);
    try {
      const currentReport = await dispatch(
        reportsModel.thunks.approveAllTeethAndSignReport(reportID),
      ).unwrap();

      if (currentReport.Report) {
        dispatch(reportsModel.actions.setNewestOne(currentReport.Report));
      }

      dispatch(toothModel.actions.setMany(currentReport.Teeth));
    } finally {
      setLoading(false);
    }
  };

  const handlePrintButton = () => {
    navigate(generatePath(PATHS.previewReport, { patientID, reportID }), {
      state: {
        ...locationState,
        lastPositionReport: document.querySelector(`[class*=columnSecond]`)
          ?.scrollTop,
        toothID: undefined,
      },
    });
  };

  const reportSignStatus = getReportSignStatus(report);

  const endodonticGroups = useGetEndodonticMedicalImages(reportID);

  const panoImage = useAppSelector(
    assetsModel.selectors.selectEndodonticPanoImageByReportID(reportID),
  );

  const endoPanoAsset = useAppSelector(
    assetsModel.selectors.selectEndodonticAssetByReportID(reportID),
  );

  const endoPanoAssetID = endoPanoAsset?.ID;

  const viewOptionsEndo = makeImageViewOptions(
    panoImage?.MedicalImageFeatures?.ViewOptions,
  );

  const panoSplitImage =
    panoImage?.Content.case === 'CBCTEndoPanoramaSplit'
      ? panoImage?.Content.value.PanoramaSplit
      : null;

  const panoImageUrl = useMemo(
    () => getFileSrc(panoSplitImage?.ID ?? ''),
    [panoSplitImage?.ID],
  );

  const handleTogglePanoImage = () => {
    setPanoImageToggled(!panoImageToggled);
  };

  const onTransitionEnd = () => {
    setPanoContainerWidth(columnRef!.current?.clientWidth ?? 0);
  };

  useEffect(() => {
    if (isSmallDesktop || isTablet || isMobile) {
      setPanoImageToggled(false);
    }
  }, [isSmallDesktop, isTablet, isMobile]);

  useReportDataStream(reportID);

  return (
    <>
      <Layout footerWithSettings fullWidth>
        <Layout.Header>
          <Header fullWidth />
        </Layout.Header>

        <Layout.Content>
          <Layout.Main>
            <Layout.Content className={cn(styles.container, className)}>
              <WidgetLayout className={styles.columnWrapper}>
                <Column
                  className={cn(panoImageToggled && styles.leftColumnToggled)}
                >
                  <div
                    className={cn(
                      styles.leftFixedColumn,
                      panoImageToggled && styles.leftInnerColumnToggled,
                    )}
                    ref={columnRef}
                    onTransitionEnd={onTransitionEnd}
                  >
                    <ReportHeading
                      report={report as Report}
                      isReportLoading={isReportLoading}
                      smallHeader={panoImageToggled}
                    />

                    <WidgetCard className={styles.panoImageWidget}>
                      {panoImage ? (
                        <PanoImage
                          src={panoImageUrl}
                          controls={PANO_TOOLS}
                          style={{
                            width: '100%',
                          }}
                          toggled={panoImageToggled}
                          containerWidth={panoContainerWidth}
                          toolbarPosition="inside"
                          assetID={endoPanoAssetID}
                          viewOptions={viewOptionsEndo}
                          // ruler
                        />
                      ) : (
                        <Skeleton
                          width="100%"
                          borderRadius="8px"
                          height={`${SCELETON_DEFAULT_HEIGHT}px`}
                        />
                      )}

                      {(isLarge || isDesktop) && (
                        <Icon
                          name="playFilled"
                          onClick={handleTogglePanoImage}
                          size={24}
                          className={cn(
                            styles.panoToggleIcon,
                            panoImageToggled && styles.iconToggled,
                          )}
                        />
                      )}
                    </WidgetCard>

                    {report?.LegacyConclusion && <Conclusion />}
                  </div>
                </Column>

                <Column
                  className={cn(
                    styles.rightColumn,
                    panoImageToggled && styles.rightColumnToggled,
                  )}
                >
                  <WidgetCard className={styles.endodonticGroupsWrapper}>
                    {displayToothNumber ? (
                      <h3 className={isMobile ? 'h4' : 'h3'}>
                        <FormattedMessage
                          id="report.toothNumber"
                          defaultMessage="Tooth {number}"
                          values={{
                            number: displayToothNumber,
                          }}
                        />
                      </h3>
                    ) : (
                      <Skeleton height={44} width={140} />
                    )}
                    <MedicalImagesGroup groups={endodonticGroups} />
                  </WidgetCard>

                  {report?.Signature?.UserSignatures.length ? (
                    <WidgetCard>
                      <ReportSigns
                        signedUsers={report?.Signature.UserSignatures}
                      />
                    </WidgetCard>
                  ) : null}
                </Column>
              </WidgetLayout>
            </Layout.Content>
          </Layout.Main>
        </Layout.Content>

        <Layout.Footer settingsFooter>
          <div className={styles.footerWrapper}>
            <Button
              variant={isReportSigned ? 'primary' : 'tertiary'}
              onClick={handlePrintButton}
              icon={isReportSigned ? 'check' : undefined}
              size={isMobile ? 'medium' : 'large'}
            >
              {isReportSigned ? (
                <FormattedMessage
                  id="report.printReport"
                  defaultMessage="Print report"
                />
              ) : (
                <FormattedMessage
                  id="report.printReportWithoutSignature"
                  defaultMessage="Print report without signature"
                />
              )}
            </Button>

            {!isReportSigned && (
              <Button
                icon="pen"
                disabled={
                  reportSignStatus === 'withoutSign' ||
                  !report?.YourPermissions?.CanChangeToothApproved
                }
                loading={loading}
                onClick={handleSign}
                size={isMobile ? 'medium' : 'large'}
              >
                <FormattedMessage id="report.sign" defaultMessage="Sign" />
              </Button>
            )}
          </div>
        </Layout.Footer>
      </Layout>

      <ZoomedMedicalImageModal />

      <GetFullAccessModal />
    </>
  );
};
