import { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { isEqual } from 'lodash';
import { useIntl } from 'react-intl/lib';

import { Checkbox, Description, Modal, Switch } from '@/shared/ui';
import { UltimateMedicalImage } from '@/shared/graphics/RenderComponents/Presets/UltimateMedicalImage';
import { RCCropImageWithPTools } from '@/shared/graphics/RenderComponents/RCCropImage/RCCropImageWithTools';
import { Annotation } from '@/shared/graphics/RenderComponents/AnnotationsLayer/Annotation';
import { RCCropImageCrop } from '@/shared/graphics/RenderComponents/RCCropImage/RCCropImage';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { MedicalImageViewOptions } from '@/shared/api/protocol-ts/model/dto_common_image_view_options_pb';

import { ModalID, modalModel } from '@/entities/modal';
import {
  getImageSrc,
  isMedicalImagesTitle,
  medicalImageTitles,
  useGetSelectedMedicalImages,
} from '@/entities/assets';
import {
  shouldGetWwwcFromImageContext,
  useMedicalImageControls,
} from '@/entities/reports';

import { ImageToolbar } from '@/features/imageToolbar';
import { useAddAndRemoveMedicalImage } from '@/features/useAddAndRemoveMedicalImage';

import { IContext } from 'graphics';

import {
  CONTROLS,
  POPUP_DEFAULT_SIZE,
  RASTER_CONTROLS,
} from '../config/constants';
import { getMedicalImageSize } from '../utils/getMedicalImageSize';
import { MedicalImageSizeType } from '../config/types';

import styles from './ZoomedMedicalImageModal.module.scss';

export const ZoomedMedicalImageModal: FC = () => {
  const [size, setSize] = useState<MedicalImageSizeType>({
    width: 0,
    height: 0,
  });

  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const {
    visible,
    data: { toothID, medicalImage, medicalImageType, isSelectionEnabled },
  } = useAppSelector(modalModel.selectors.selectMedicalImage);

  const selectedMedicalImages = useGetSelectedMedicalImages(toothID);

  const { toggleMedicalImageSelection } = useAddAndRemoveMedicalImage();

  const isTheMedicalImageSelected = useMemo(
    () => selectedMedicalImages?.some((item) => item.src === medicalImage?.src),
    [selectedMedicalImages, medicalImage],
  );

  const {
    activeControl,
    handleSetActiveControl,
    mode,
    handleSetMode,
    annotations,
    viewAnnotations,
    sharpness,
    handleSetSharpness,
    currentAnnotation,
    wwwc: currentWwwc,
    handleSetWWWC,
    handleSetAnnotations,
    toggleAnnotationsVisibility,
    handleSetCroppedImageAnnotations,
    handleSetCroppedMedicalImageViewOptions,
    handleSetMedicalImageViewOptions,
  } = useMedicalImageControls(
    medicalImage?.src,
    medicalImage?.assetID,
    medicalImage?.viewOptions,
    medicalImage?.annotations,
    toothID,
  );

  const crop = medicalImage?.TargetAssetID
    ? {
        x: medicalImage.BBox?.X?.Min ?? 0,
        y: medicalImage.BBox?.Y?.Min ?? 0,
        w: (medicalImage.BBox?.X?.Max ?? 0) - (medicalImage.BBox?.X?.Min ?? 0),
        h: (medicalImage.BBox?.Y?.Max ?? 0) - (medicalImage.BBox?.Y?.Min ?? 0),
      }
    : ({} as RCCropImageCrop);

  const onImageLoaded = (newDicom: IContext) => {
    const imageWidth = medicalImage?.TargetAssetID ? crop?.w : newDicom.width;
    const imageHeight = medicalImage?.TargetAssetID ? crop?.h : newDicom.height;

    const imageSize = getMedicalImageSize(imageWidth, imageHeight);

    if (
      medicalImage.viewOptions &&
      shouldGetWwwcFromImageContext(medicalImage.viewOptions)
    ) {
      handleSetWWWC({
        ww: newDicom.sourceWindowWidth,
        wc: newDicom.sourceWindowCenter,
      });
    }

    setSize(imageSize);
  };

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.ZoomedMedicalImage));
  }, [dispatch]);

  const handleClosePopup = () => {
    if (currentWwwc) {
      const updatedViewOptions = {
        wwwc: {
          ww: currentWwwc.ww,
          wc: currentWwwc.wc,
        },
        sharpness,
        invert: false,
      };

      if (
        !isEqual(updatedViewOptions, medicalImage?.viewOptions) &&
        medicalImageType === 'cropped'
      ) {
        handleSetCroppedMedicalImageViewOptions(
          medicalImage?.localizationID ?? '',
          {
            WindowWidth: currentWwwc?.ww,
            WindowLevel: currentWwwc?.wc,
            Sharpness: sharpness,
            Invert: false,
            ColorMap: undefined,
            ViewportCoords: undefined,
            Brightness: 0,
            Contrast: 0,
          } as MedicalImageViewOptions,
        );

        return;
      }
      if (!isEqual(updatedViewOptions, medicalImage?.viewOptions)) {
        handleSetMedicalImageViewOptions({
          WindowWidth: currentWwwc?.ww,
          WindowLevel: currentWwwc?.wc,
          Sharpness: sharpness,
        });
      }
    }

    handleSetActiveControl('');
    handleSetMode('view');
    setSize({ width: POPUP_DEFAULT_SIZE, height: POPUP_DEFAULT_SIZE });
    handleClose();
  };

  const tools = medicalImage?.TargetAssetID
    ? CONTROLS.filter((control) => control !== 'ruler')
    : CONTROLS;

  const indexInSelected = selectedMedicalImages?.length
    ? selectedMedicalImages.findIndex(
        (item) => item.src === medicalImage?.src,
      ) + 1
    : 0;

  const groupTitle = isMedicalImagesTitle(medicalImage?.groupName)
    ? formatMessage(medicalImageTitles[medicalImage.groupName])
    : undefined;

  const onChangeCroppedMedicalImageAnnotations = (
    changedAnnotations: Annotation[],
  ) => {
    handleSetCroppedImageAnnotations(
      medicalImage?.localizationID ?? '',
      changedAnnotations,
    );
  };

  return (
    <Modal
      isOpen={visible}
      onCancel={handleClosePopup}
      title={groupTitle}
      containerClassName={styles.modalContainer}
      bodyClassName={styles.modalBody}
      hideFooter
    >
      {!!medicalImage?.strideMm && (
        <Description
          fontType="p2"
          label={
            <FormattedMessage
              id="report.slice_step"
              defaultMessage="Slice step"
            />
          }
          className={styles.thicknessAndStride}
        >
          {medicalImage.strideMm}
          <FormattedMessage id="global.mm" defaultMessage="mm" />,
        </Description>
      )}

      {!!medicalImage?.thicknessMm && (
        <Description
          fontType="p2"
          label={
            <FormattedMessage
              id="report.sliceThickness"
              defaultMessage="slice thickness"
            />
          }
          className={styles.thicknessAndStride}
        >
          {medicalImage.thicknessMm}
          <FormattedMessage id="global.mm" defaultMessage="mm" />
        </Description>
      )}

      <div className={styles.content}>
        <div className={styles.imageWrapper}>
          <div className={styles.imageContainer}>
            {medicalImage?.TargetAssetID ? (
              <div
                style={{ width: size.width, height: size.height }}
                className={cn(styles.cropContainer)}
              >
                <RCCropImageWithPTools
                  mode={mode}
                  currentAnnotation={currentAnnotation}
                  annotations={viewAnnotations ? annotations : []}
                  onAnnotationsChanged={onChangeCroppedMedicalImageAnnotations}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                  onLoaded={onImageLoaded}
                  onWWWCChanged={handleSetWWWC}
                  ww={currentWwwc?.ww}
                  wc={currentWwwc?.wc}
                  sharpness={sharpness}
                  inverted={medicalImage?.viewOptions?.invert || false}
                  onSharpnessChanged={handleSetSharpness}
                  src={{
                    url: getImageSrc(medicalImage.TargetAssetID, 'original'),
                    kind: 'raster',
                  }}
                  crop={crop}
                />
              </div>
            ) : (
              <UltimateMedicalImage
                src={{
                  url: medicalImage?.src,
                  kind: medicalImage.kind === 'dicom' ? 'dicom' : 'raster',
                }}
                mode={mode}
                currentAnnotation={currentAnnotation}
                annotations={viewAnnotations ? annotations : []}
                onAnnotationsChanged={handleSetAnnotations}
                style={{
                  width: size.width,
                  height: size.height,
                  objectFit: 'contain',
                }}
                onLoaded={onImageLoaded}
                onWWWCChanged={handleSetWWWC}
                ww={currentWwwc?.ww}
                wc={currentWwwc?.wc}
                sharpness={sharpness}
                inverted={medicalImage?.viewOptions?.invert || false}
                onSharpnessChanged={handleSetSharpness}
              />
            )}

            {!!indexInSelected && (
              <span className={cn(styles.medicalImageSelectedOrderText, 'p1')}>
                {indexInSelected}
              </span>
            )}

            <div className={styles.orientWrapper}>
              <div className={styles.orientContainer}>
                <span className={styles.medicalImageText}>
                  {medicalImage?.leftSideOrient}
                </span>
                <span className={styles.medicalImageText}>
                  {medicalImage?.rightSideOrient}
                </span>
              </div>
            </div>
          </div>

          <ImageToolbar
            controls={medicalImage?.kind === 'raster' ? RASTER_CONTROLS : tools}
            activeControl={activeControl}
            setActiveControl={handleSetActiveControl}
            className={styles.toolbar}
            tooltipPosition="right"
          />
        </div>

        <div className={styles.actions}>
          {isSelectionEnabled && (
            <Checkbox
              checked={isTheMedicalImageSelected ?? false}
              onChange={() =>
                toggleMedicalImageSelection({
                  toothID,
                  medicalImage,
                  selectedMedicalImages,
                })
              }
            >
              <FormattedMessage
                id="medicalImages.Selected"
                defaultMessage="Selected"
              />
            </Checkbox>
          )}

          {!!annotations.length && (
            <Switch
              checked={!viewAnnotations}
              onChange={toggleAnnotationsVisibility}
              className={styles.switch}
            >
              <FormattedMessage
                id="medicalImages.hideMarks"
                defaultMessage="Hide marks"
              />
            </Switch>
          )}
        </div>
      </div>
    </Modal>
  );
};
