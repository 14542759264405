import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  SetUserLanguageReq,
  SetUserLanguageResp,
  SetUserNameReq,
  SetUserNameResp,
} from '@/shared/api/protocol-ts/api/core/svc_user_pb';
import { createThunkGenerator } from '@/shared/lib';
import { SliceName } from '@/shared/config';
import api from '@/shared/api/api';
import { UserService } from '@/shared/api/protocol-ts/api/core/svc_user_connectweb';

import { UserLocalSettings } from '../lib/getInitialUserSettings';

type UserRequests = keyof typeof UserService.methods;

const generateAccessThunk = createThunkGenerator<UserRequests>(SliceName.user);

export const setSettings = createAsyncThunk(
  'user/setSettings',
  async (settings: Partial<UserLocalSettings>) => {
    Object.entries(settings).forEach(([key, value]) => {
      localStorage.setItem(key, value);
    });
    return settings;
  },
);

export const setUserLanguage = generateAccessThunk<
  SetUserLanguageReq,
  SetUserLanguageResp
>('setUserLanguage', api.user.setUserLanguage);

export const setUserName = generateAccessThunk<SetUserNameReq, SetUserNameResp>(
  'setUserName',
  api.user.setUserName,
);
