// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_report_type_ios_segmentation.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { IOSJawTypeClassification } from "./dto_report_segmentron_common_pb.js";

/**
 * @generated from message com.diagnocat.model.Report_IOS_Segmentation
 */
export const Report_IOS_Segmentation = proto3.makeMessageType(
  "com.diagnocat.model.Report_IOS_Segmentation",
  () => [
    { no: 1, name: "JawTypeClassifications", kind: "message", T: IOSJawTypeClassification, repeated: true },
  ],
);

