// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_asset_cbct_implant.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { CBCT_SliceOrientation, DerivedData_Image, File } from "./dto_asset_common_pb.js";

/**
 * @generated from message com.diagnocat.model.AssetContent_Generated_CBCT_Implant_Panorama
 */
export const AssetContent_Generated_CBCT_Implant_Panorama = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Generated_CBCT_Implant_Panorama",
  () => [
    { no: 1, name: "Panorama", kind: "message", T: File },
    { no: 2, name: "Annotated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 101, name: "Derived", kind: "message", T: DerivedData_Image },
  ],
);

/**
 * aka "cut"
 * key example cut-3-23
 * key structure "cut-<order>-<total_images>"
 * order maps to CBCT_Implant_PanoramaAnnotated.Cuts guides
 *
 * {"type": "File", "file": {"task_id": "5c23e643-51f6-91a8-62dd-1efbd113a76d", "key": "cut-3-23", "filename": "/tmp/tmp_i_sophg", "content_type": "application/dicom", "size": 49400, "meta": {"image_height": 200, "image_width": 121, "orientation_markers": {"left": "B", "right": "L"}, "image_annotations": {"length": {"data": [{"active": false, "length": 53.75872022286245, "visible": true, "invalidated": true, "handles": {"start": {"x": 98.0, "y": 72.0, "active": false, "highlight": true}, "end": {"x": 45.0, "y": 63.0, "active": false, "highlight": true}, "textBox": {"x": 103.0, "y": 70.5, "active": false, "hasMoved": true, "hasBoundingBox": true, "drawnIndependently": true, "movesIndependently": false, "allowedOutsideImage": false}}}, {"active": false, "length": 57.00476675659348, "visible": true, "invalidated": true, "handles": {"start": {"x": 58.27542167493328, "y": 122.00016157191547, "active": false, "highlight": true}, "end": {"x": 59, "y": 65, "active": false, "highlight": true}, "textBox": {"x": 64, "y": 135.00016157191547, "active": false, "hasMoved": true, "hasBoundingBox": true, "drawnIndependently": true, "movesIndependently": false, "allowedOutsideImage": false}}}]}}, "y_pix": [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96, 104, 112, 120, 128, 136, 144, 152, 160, 168, 176, 184, 192], "x_pix": [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96, 104, 112, 120], "y_num": [0.0, 2.0, 4.0, 6.0, 8.0, 10.0, 12.0, 14.0, 16.0, 18.0, 20.0, 22.0, 24.0, 26.0, 28.0, 30.0, 32.0, 34.0, 36.0, 38.0, 40.0, 42.0, 44.0, 46.0, 48.0], "x_num": [0.0, 2.0, 4.0, 6.0, 8.0, 10.0, 12.0, 14.0, 16.0, 18.0, 20.0, 22.0, 24.0, 26.0, 28.0, 30.0]}, "type": "File"}, "event": "execution-event"}
 *
 * @generated from message com.diagnocat.model.AssetContent_Generated_CBCT_Implant_Slice
 */
export const AssetContent_Generated_CBCT_Implant_Slice = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Generated_CBCT_Implant_Slice",
  () => [
    { no: 1, name: "Slice", kind: "message", T: File },
    { no: 5, name: "Order", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 6, name: "TotalImages", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 10, name: "RightSideOrient", kind: "enum", T: proto3.getEnumType(CBCT_SliceOrientation) },
    { no: 11, name: "LeftSideOrient", kind: "enum", T: proto3.getEnumType(CBCT_SliceOrientation) },
  ],
);

