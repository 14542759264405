// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file api/core/svc_patient.proto (package com.diagnocat.core, syntax proto3)
/* eslint-disable */

import { Empty, proto3, Timestamp } from "@bufbuild/protobuf";
import { Patient, PatientStatus } from "../../model/dto_patient_pb.js";
import { PatientTask } from "../../model/dto_patient_task_pb.js";
import { Study, StudyType } from "../../model/dto_study_pb.js";
import { Asset } from "../../model/dto_asset_pb.js";
import { Report } from "../../model/dto_report_pb.js";
import { Tooth } from "../../model/dto_report_tooth_pb.js";
import { Invitation } from "../../model/dto_access_pb.js";
import { User } from "../../model/dto_user_pb.js";
import { Attribute, Gender, PersonalData } from "../../model/dto_base_pb.js";

/**
 * @generated from enum com.diagnocat.core.PatientListStreamSortBy
 */
export const PatientListStreamSortBy = proto3.makeEnum(
  "com.diagnocat.core.PatientListStreamSortBy",
  [
    {no: 0, name: "PatientListStreamSortByFirstName"},
    {no: 1, name: "PatientListStreamSortByLastName"},
    {no: 2, name: "PatientListStreamSortByExternalID"},
    {no: 3, name: "PatientListStreamSortByDateOfBirth"},
  ],
);

/**
 * @generated from message com.diagnocat.core.PatientProfileStreamReq
 */
export const PatientProfileStreamReq = proto3.makeMessageType(
  "com.diagnocat.core.PatientProfileStreamReq",
  () => [
    { no: 1, name: "PatientID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.PatientProfileStreamResp
 */
export const PatientProfileStreamResp = proto3.makeMessageType(
  "com.diagnocat.core.PatientProfileStreamResp",
  () => [
    { no: 1, name: "HistoricalPatient", kind: "message", T: Patient, oneof: "Update" },
    { no: 2, name: "HistoricalPatientTask", kind: "message", T: PatientTask, oneof: "Update" },
    { no: 3, name: "EndOfHistoricalPatientTasks", kind: "message", T: Empty, oneof: "Update" },
    { no: 4, name: "HistoricalStudy", kind: "message", T: Study, oneof: "Update" },
    { no: 5, name: "EndOfHistoricalStudies", kind: "message", T: Empty, oneof: "Update" },
    { no: 6, name: "HistoricalAsset", kind: "message", T: Asset, oneof: "Update" },
    { no: 7, name: "EndOfHistoricalAssets", kind: "message", T: Empty, oneof: "Update" },
    { no: 8, name: "HistoricalReport", kind: "message", T: Report, oneof: "Update" },
    { no: 9, name: "EndOfHistoricalReports", kind: "message", T: Empty, oneof: "Update" },
    { no: 10, name: "HistoricalTooth", kind: "message", T: Tooth, oneof: "Update" },
    { no: 11, name: "EndOfHistoricalTeeth", kind: "message", T: Empty, oneof: "Update" },
    { no: 12, name: "HistoricalInvitation", kind: "message", T: Invitation, oneof: "Update" },
    { no: 13, name: "EndOfHistoricalInvitations", kind: "message", T: Empty, oneof: "Update" },
    { no: 14, name: "HistoricalSharingMember", kind: "message", T: User, oneof: "Update" },
    { no: 15, name: "EndOfHistoricalSharingMembers", kind: "message", T: Empty, oneof: "Update" },
    { no: 31, name: "UpdatedPatient", kind: "message", T: Patient, oneof: "Update" },
    { no: 32, name: "UpdatedPatientTask", kind: "message", T: PatientTask, oneof: "Update" },
    { no: 33, name: "UpdatedStudy", kind: "message", T: Study, oneof: "Update" },
    { no: 34, name: "UpdatedAsset", kind: "message", T: Asset, oneof: "Update" },
    { no: 35, name: "UpdatedReport", kind: "message", T: Report, oneof: "Update" },
    { no: 36, name: "UpdatedTooth", kind: "message", T: Tooth, oneof: "Update" },
    { no: 38, name: "UpdatedInvitation", kind: "message", T: Invitation, oneof: "Update" },
    { no: 39, name: "UpdatedSharingMember", kind: "message", T: User, oneof: "Update" },
    { no: 101, name: "Ping", kind: "message", T: Empty, oneof: "Update" },
  ],
);

/**
 * sorted by Last modified date in descending order
 *
 * @generated from message com.diagnocat.core.PatientListStreamReq
 */
export const PatientListStreamReq = proto3.makeMessageType(
  "com.diagnocat.core.PatientListStreamReq",
  () => [
    { no: 1, name: "ResumeToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "SearchString", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "AllDoctors", kind: "message", T: Empty, oneof: "Doctors" },
    { no: 22, name: "AllExceptDoctors", kind: "message", T: PatientListStreamReq_DoctorIDs, oneof: "Doctors" },
    { no: 23, name: "OnlyTheseDoctors", kind: "message", T: PatientListStreamReq_DoctorIDs, oneof: "Doctors" },
    { no: 31, name: "Sort", kind: "message", T: PatientListStreamSort, repeated: true },
    { no: 100, name: "StartFromPatientID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 101, name: "Limit", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * Returns only patients who include at least one of the `DoctorIDs`
 *
 * @generated from message com.diagnocat.core.PatientListStreamReq.DoctorIDs
 */
export const PatientListStreamReq_DoctorIDs = proto3.makeMessageType(
  "com.diagnocat.core.PatientListStreamReq.DoctorIDs",
  () => [
    { no: 1, name: "DoctorIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
  {localName: "PatientListStreamReq_DoctorIDs"},
);

/**
 * @generated from message com.diagnocat.core.PatientListStreamSort
 */
export const PatientListStreamSort = proto3.makeMessageType(
  "com.diagnocat.core.PatientListStreamSort",
  () => [
    { no: 1, name: "By", kind: "enum", T: proto3.getEnumType(PatientListStreamSortBy) },
    { no: 2, name: "Ascending", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * We do not send doctors participating in this patient 'cause you already have it from MyDataStream
 *
 * @generated from message com.diagnocat.core.PatientListStreamResp
 */
export const PatientListStreamResp = proto3.makeMessageType(
  "com.diagnocat.core.PatientListStreamResp",
  () => [
    { no: 1, name: "ResumeToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "InitialPatientsListItems", kind: "message", T: PatientListStreamResp_PatientsListItems, oneof: "Update" },
    { no: 11, name: "PatientUpdated", kind: "message", T: PatientListStreamResp_PatientItem, oneof: "Update" },
    { no: 21, name: "TotalOrganizationPatientCount", kind: "scalar", T: 13 /* ScalarType.UINT32 */, oneof: "Update" },
    { no: 22, name: "SharedWithMeCount", kind: "scalar", T: 13 /* ScalarType.UINT32 */, oneof: "Update" },
    { no: 23, name: "SharedByMeCount", kind: "scalar", T: 13 /* ScalarType.UINT32 */, oneof: "Update" },
    { no: 101, name: "Ping", kind: "message", T: Empty, oneof: "Update" },
  ],
);

/**
 * @generated from message com.diagnocat.core.PatientListStreamResp.PatientItem
 */
export const PatientListStreamResp_PatientItem = proto3.makeMessageType(
  "com.diagnocat.core.PatientListStreamResp.PatientItem",
  () => [
    { no: 1, name: "Patient", kind: "message", T: Patient },
    { no: 2, name: "Studies", kind: "message", T: PatientListStreamResp_PatientItem_StudyCount, repeated: true },
    { no: 3, name: "Tasks", kind: "message", T: PatientListStreamResp_PatientItem_TasksCounts },
  ],
  {localName: "PatientListStreamResp_PatientItem"},
);

/**
 * @generated from message com.diagnocat.core.PatientListStreamResp.PatientItem.StudyCount
 */
export const PatientListStreamResp_PatientItem_StudyCount = proto3.makeMessageType(
  "com.diagnocat.core.PatientListStreamResp.PatientItem.StudyCount",
  () => [
    { no: 1, name: "Modality", kind: "enum", T: proto3.getEnumType(StudyType) },
    { no: 2, name: "Count", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
  {localName: "PatientListStreamResp_PatientItem_StudyCount"},
);

/**
 * @generated from message com.diagnocat.core.PatientListStreamResp.PatientItem.TasksCounts
 */
export const PatientListStreamResp_PatientItem_TasksCounts = proto3.makeMessageType(
  "com.diagnocat.core.PatientListStreamResp.PatientItem.TasksCounts",
  () => [
    { no: 1, name: "All", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "Done", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "Overdue", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
  {localName: "PatientListStreamResp_PatientItem_TasksCounts"},
);

/**
 * @generated from message com.diagnocat.core.PatientListStreamResp.PatientsListItems
 */
export const PatientListStreamResp_PatientsListItems = proto3.makeMessageType(
  "com.diagnocat.core.PatientListStreamResp.PatientsListItems",
  () => [
    { no: 1, name: "Patients", kind: "message", T: PatientListStreamResp_PatientItem, repeated: true },
  ],
  {localName: "PatientListStreamResp_PatientsListItems"},
);

/**
 * @generated from message com.diagnocat.core.CreatePatientReq
 */
export const CreatePatientReq = proto3.makeMessageType(
  "com.diagnocat.core.CreatePatientReq",
  () => [
    { no: 1, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "PersonalData", kind: "message", T: PersonalData },
    { no: 3, name: "Gender", kind: "enum", T: proto3.getEnumType(Gender) },
    { no: 4, name: "ExternalID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "TreatingDoctorIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "Attributes", kind: "message", T: Attribute, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.CreatePatientResp
 */
export const CreatePatientResp = proto3.makeMessageType(
  "com.diagnocat.core.CreatePatientResp",
  () => [
    { no: 3, name: "Patient", kind: "message", T: Patient },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetPatientPersonalDataReq
 */
export const SetPatientPersonalDataReq = proto3.makeMessageType(
  "com.diagnocat.core.SetPatientPersonalDataReq",
  () => [
    { no: 1, name: "PatientID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "PersonalData", kind: "message", T: PersonalData },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetPatientPersonalDataResp
 */
export const SetPatientPersonalDataResp = proto3.makeMessageType(
  "com.diagnocat.core.SetPatientPersonalDataResp",
  () => [
    { no: 1, name: "Patient", kind: "message", T: Patient },
    { no: 2, name: "Invitations", kind: "message", T: Invitation, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetPatientExternalIDReq
 */
export const SetPatientExternalIDReq = proto3.makeMessageType(
  "com.diagnocat.core.SetPatientExternalIDReq",
  () => [
    { no: 1, name: "PatientID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ExternalID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetPatientExternalIDResp
 */
export const SetPatientExternalIDResp = proto3.makeMessageType(
  "com.diagnocat.core.SetPatientExternalIDResp",
  () => [
    { no: 1, name: "Patient", kind: "message", T: Patient },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetPatientGenderReq
 */
export const SetPatientGenderReq = proto3.makeMessageType(
  "com.diagnocat.core.SetPatientGenderReq",
  () => [
    { no: 1, name: "PatientID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Gender", kind: "enum", T: proto3.getEnumType(Gender) },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetPatientGenderResp
 */
export const SetPatientGenderResp = proto3.makeMessageType(
  "com.diagnocat.core.SetPatientGenderResp",
  () => [
    { no: 1, name: "Patient", kind: "message", T: Patient },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetPatientStatusReq
 */
export const SetPatientStatusReq = proto3.makeMessageType(
  "com.diagnocat.core.SetPatientStatusReq",
  () => [
    { no: 1, name: "PatientID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Status", kind: "enum", T: proto3.getEnumType(PatientStatus) },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetPatientStatusResp
 */
export const SetPatientStatusResp = proto3.makeMessageType(
  "com.diagnocat.core.SetPatientStatusResp",
  () => [
    { no: 1, name: "Patient", kind: "message", T: Patient },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetPatientClinicalCaseDescriptionReq
 */
export const SetPatientClinicalCaseDescriptionReq = proto3.makeMessageType(
  "com.diagnocat.core.SetPatientClinicalCaseDescriptionReq",
  () => [
    { no: 1, name: "PatientID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ClinicalCaseDescription", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetPatientClinicalCaseDescriptionResp
 */
export const SetPatientClinicalCaseDescriptionResp = proto3.makeMessageType(
  "com.diagnocat.core.SetPatientClinicalCaseDescriptionResp",
  () => [
    { no: 1, name: "Patient", kind: "message", T: Patient },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetPatientTreatmentDueDateReq
 */
export const SetPatientTreatmentDueDateReq = proto3.makeMessageType(
  "com.diagnocat.core.SetPatientTreatmentDueDateReq",
  () => [
    { no: 1, name: "PatientID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "TreatmentDueDate", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message com.diagnocat.core.SetPatientTreatmentDueDateResp
 */
export const SetPatientTreatmentDueDateResp = proto3.makeMessageType(
  "com.diagnocat.core.SetPatientTreatmentDueDateResp",
  () => [
    { no: 1, name: "Patient", kind: "message", T: Patient },
  ],
);

/**
 * @generated from message com.diagnocat.core.DeletePatientReq
 */
export const DeletePatientReq = proto3.makeMessageType(
  "com.diagnocat.core.DeletePatientReq",
  () => [
    { no: 1, name: "PatientID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.diagnocat.core.DeletePatientResp
 */
export const DeletePatientResp = proto3.makeMessageType(
  "com.diagnocat.core.DeletePatientResp",
  () => [
    { no: 1, name: "DeletedPatientID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "DeletedStudyIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "DeletedReportIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "DeletedToothIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "DeletedConditionIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "DeletedTeethLandmarkIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "DeletedAssetIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "DeletedInvitationIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

