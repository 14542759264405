import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { SliceName } from '@/shared/config';

import { StudyCount } from '../config/types';

export const studyCountAdapter = createEntityAdapter<StudyCount>({
  selectId: (count) => count.patientID as string,
});

const studyCountState = studyCountAdapter.getInitialState();

type StudyCountState = typeof studyCountState;

const studyCountSlice = createSlice({
  name: SliceName.studyCount,
  initialState: studyCountState,
  reducers: {
    setOne: (state, action: PayloadAction<StudyCount>) => {
      studyCountAdapter.setOne(state as StudyCountState, action.payload);
    },
    addMany: (state, action: PayloadAction<StudyCount[]>) => {
      studyCountAdapter.addMany(state as StudyCountState, action.payload);
    },
    setMany: (state, action: PayloadAction<StudyCount[]>) => {
      studyCountAdapter.setMany(state as StudyCountState, action.payload);
    },
  },
});

export const { actions } = studyCountSlice;

export default studyCountSlice.reducer;
