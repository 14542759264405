import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { AssetContent_Study_CBCT_CBCT_Metadata } from '@/shared/api/protocol-ts/model/dto_asset_pb';

import { i18n, SCAN_DATA_FIELDS, ScanDataFields } from '@/entities/study';

import styles from './ScanInfo.module.scss';

type ScanInfoProps = {
  className?: string;
  studyMeta: AssetContent_Study_CBCT_CBCT_Metadata;
};

export const ScanInfo: FC<ScanInfoProps> = (props) => {
  const { className, studyMeta } = props;
  const { formatMessage, formatDate } = useIntl();

  const getValueByDataFieldsKey = (
    key: ScanDataFields,
  ): string | number | number[] | undefined => {
    switch (key) {
      case 'date':
        return formatDate(studyMeta?.StudyTime?.toDate(), {
          dateStyle: 'medium',
          timeStyle: 'short',
        });
      case 'exposure':
        return studyMeta?.Exposure;
      case 'fov':
        return studyMeta?.FOV;
      case 'manufacturer':
        return studyMeta?.Manufacturer;
      case 'model':
        return studyMeta?.ManufacturerModelName;
      case 'voxel':
        return studyMeta?.VoxelSize;
      default:
        return undefined;
    }
  };

  return (
    <div className={cn(className, styles.container)}>
      {SCAN_DATA_FIELDS.map((dataKey) => (
        <div key={dataKey} className={styles.scanElemRow}>
          <p className={cn(styles.scanLabel, 'p2')}>
            {formatMessage(i18n.studyScanInfoMessages[dataKey])}
            {': '}
          </p>

          <p className={cn(styles.scanValue, 'p2')}>
            {getValueByDataFieldsKey(dataKey) ||
              formatMessage({
                id: 'global.notAvailable',
                defaultMessage: 'not available',
              })}
          </p>
        </div>
      ))}
    </div>
  );
};
