import { CBCT_SliceOrientation } from '@/shared/api/protocol-ts/model/dto_asset_common_pb';

export const getMedicalImageOrient = (orient: CBCT_SliceOrientation) => {
  switch (orient) {
    case CBCT_SliceOrientation.SliceOrientationMesial:
      return 'M';
    case CBCT_SliceOrientation.SliceOrientationLingual:
      return 'L';
    case CBCT_SliceOrientation.SliceOrientationBuccal:
      return 'B';
    case CBCT_SliceOrientation.SliceOrientationDistal:
      return 'D';
    default:
      return '';
  }
};
