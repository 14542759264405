import { FC, useState } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { Modal, ModalProps } from '@/shared/ui';
import { useAppDispatch } from '@/shared/hooks';

import { reportsModel } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';

import styles from './ConfirmSignReportModal.module.scss';

type ConfirmSignReportProps = {
  className?: string;
  reportID: string;
  isAllTeethApproved: boolean;
} & Pick<ModalProps, 'isOpen' | 'onCancel' | 'onOk'>;

export const ConfirmSignReportModal: FC<ConfirmSignReportProps> = (props) => {
  const {
    isOpen,
    reportID,
    onCancel: onClose,
    className,
    isAllTeethApproved,
  } = props;

  const [loading, setLoading] = useState(false);

  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();

  const title = isAllTeethApproved
    ? formatMessage({
        id: 'report.signReport',
        defaultMessage: 'Sign report',
      })
    : formatMessage({
        id: 'consfirmSignReportModal.approveAll',
        defaultMessage: 'Approve all teeth',
      });

  const handleSign = async () => {
    setLoading(true);
    try {
      const currentReport = await dispatch(
        reportsModel.thunks.approveAllTeethAndSignReport(reportID),
      ).unwrap();

      if (currentReport.Report) {
        dispatch(reportsModel.actions.setNewestOne(currentReport.Report));
      }

      dispatch(toothModel.actions.setMany(currentReport.Teeth));
    } finally {
      setLoading(false);
      if (onClose) {
        onClose();
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      onOk={handleSign}
      onCancel={onClose}
      className={cn(styles.container, className)}
      okButtonText="Yes, submit"
      okButtonProps={{ loading, disabled: !isAllTeethApproved }}
    >
      {isAllTeethApproved
        ? 'Are you sure you want to submit your evaluation?'
        : 'Some teeth are not approved. Please check that you provided your evaluation and approved each tooth.'}
    </Modal>
  );
};
