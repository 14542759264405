import cn from 'classnames';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { omit } from 'ramda';

import { MedicalImageRender } from '@/shared/graphics/medicalImageRender/MedicalImageRender';
import { Icon, IconNames, Tooltip } from '@/shared/ui';
import { ToolNames } from '@/shared/config';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { MedicalImageViewOptions } from '@/shared/api/protocol-ts/model/dto_common_image_view_options_pb';
import {
  ReportSettings,
  ReportType,
} from '@/shared/api/protocol-ts/model/dto_report_pb';
import { SetReportSettingsReq } from '@/shared/api/protocol-ts/api/core/svc_report_pb';

import { reportsModel, transformViewOptions } from '@/entities/reports';

import { ExitFromFullscreenButton } from '@/features/exitFromFullscreen';

import { ToolbarOptions } from '../../config/types';
import { toolsMessages } from '../../config/i18n';

import styles from './MedicalImageToolbar.module.scss';

export type MedicalImageToolbar = {
  className?: string;
  testID?: string;
  defaultControl: ToolNames;
  controlsOptions: ToolbarOptions[];
  multipleMode?: boolean;
};

const CONTROL_TOOLS_ICONS: Record<ToolNames, IconNames> = {
  ruler: 'ruler',
  arrow: 'arrow',
  angle: 'angle',
  eraser: 'erase',
  view: 'eyeFilled',
  brightness: 'brightness',
  expand: 'expand',
  invert: 'invert',
  reset: 'back',
  move: 'move',
  zoom: 'zoom',
  split: 'split',
  download: 'download',
  sharpness: 'sharpness',
  sharpening: 'sharpness',
  editNumbers: 'toothnum',
  ios: 'ios',
};

export const MedicalImageToolbar = (props: MedicalImageToolbar) => {
  const { className, testID, controlsOptions, defaultControl, multipleMode } =
    props;

  const dispatch = useAppDispatch();

  const [activeControl, setActiveControl] = useState<ToolNames>(defaultControl);

  const [isExpanded, setIsExpanded] = useState(false);

  const currentReport = useAppSelector(
    reportsModel.selectors.selectCurrentReport,
  );

  const isControlActive = (controlOption: ToolbarOptions) => {
    return (
      activeControl === controlOption.control ||
      (controlOption.control === 'split' &&
        currentReport?.Settings?.ShowPanoramaSplitInsteadOfSingle) ||
      (controlOption.control === 'invert' &&
        currentReport?.MedicalImageFeatures?.ViewOptions?.Invert) ||
      controlOption.isActive
    );
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      if (!document.fullscreenElement) {
        setIsExpanded(false);
      }
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  const { formatMessage } = useIntl();

  const handleReset = async () => {
    const { Report } = await dispatch(
      reportsModel.thunks.setReportMedicalImageViewOptions({
        ReportID: currentReport?.ID as string,
        MedicalImageViewOptions: {
          Invert: false,
        } as MedicalImageViewOptions,
      }),
    ).unwrap();

    if (Report) {
      dispatch(reportsModel.actions.setNewestOne(Report));
    }
  };

  const handleSplit = async () => {
    const { Report } = await dispatch(
      reportsModel.thunks.setReportSettings({
        ReportID: currentReport?.ID as string,
        Settings: {
          ...(currentReport?.Settings as ReportSettings),
          ShowPanoramaSplitInsteadOfSingle:
            !currentReport?.Settings?.ShowPanoramaSplitInsteadOfSingle,
        },
      } as SetReportSettingsReq),
    ).unwrap();

    if (Report) {
      dispatch(reportsModel.actions.setNewestOne(Report));
    }
  };

  const handleControlClick = async (control: ToolNames) => {
    switch (control) {
      case 'view':
        setActiveControl('view');
        MedicalImageRender.activateMode('mainMode');
        break;
      case 'expand':
        if (!isExpanded) {
          setIsExpanded(true);

          const fullscreenOverlay = document.getElementById('report_render');

          if (fullscreenOverlay) {
            fullscreenOverlay.requestFullscreen();
          }
        } else {
          setIsExpanded(false);
          document.exitFullscreen();
        }
        break;
      case 'split':
        await handleSplit();
        break;
      case 'sharpness':
        if (activeControl === 'sharpness') {
          setActiveControl('view');
          MedicalImageRender.activateMode('mainMode');
        } else {
          setActiveControl('sharpness');
          MedicalImageRender.activateMode(
            multipleMode ? 'sharpnessMultipleMode' : 'sharpnessMode',
          );
        }

        break;
      case 'brightness':
        if (activeControl === 'brightness') {
          setActiveControl('view');
          MedicalImageRender.activateMode('mainMode');
        } else {
          setActiveControl('brightness');
          MedicalImageRender.activateMode(
            multipleMode
              ? 'contrastBrightnessMultipleMode'
              : 'contrastBrightnessMode',
          );
        }
        break;
      case 'invert':
        break;
      case 'reset':
        MedicalImageRender.resetUndoStack();
        MedicalImageRender.activateMode('mainMode');
        setActiveControl('view');

        await handleReset();

        break;
      case 'editNumbers':
        break;
      default:
        break;
    }
  };

  const handleViewOptionsChange = (
    event:
      | {
          type: 'sharpnessChanged';
          id: string;
          sharpness: number;
        }
      | {
          type: 'screenSharpnessChanged';
          sharpness: number;
        }
      | {
          type: 'brightnessContrastChanged';
          id: string;
          brightness: number;
          contrast: number;
        }
      | {
          type: 'screenBrightnessContrastChanged';
          brightness: number;
          contrast: number;
        },
  ) => {
    const viewOptions = transformViewOptions(omit(['id', 'type'], event));

    switch (event.type) {
      case 'sharpnessChanged':
      case 'brightnessContrastChanged': {
        if (currentReport?.Type === ReportType.ReportType_CBCT_GP) {
          dispatch(
            reportsModel.thunks.setReportMedicalImageViewOptions({
              ReportID: currentReport?.ID as string,
              MedicalImageViewOptions: viewOptions as MedicalImageViewOptions,
            }),
          );
        } else {
          dispatch(
            reportsModel.thunks.setImageMetaViewOptions({
              ReportID: currentReport?.ID as string,
              ImageMetaID: event.id, // need to have image meta id in event object
              MedicalImageViewOptions: viewOptions as MedicalImageViewOptions,
            }),
          );
        }
        break;
      }
      case 'screenSharpnessChanged':
      case 'screenBrightnessContrastChanged': {
        dispatch(
          reportsModel.thunks.setReportMedicalImageViewOptions({
            ReportID: currentReport?.ID as string,
            MedicalImageViewOptions: viewOptions as MedicalImageViewOptions,
          }),
        );
        break;
      }
    }
  };

  const isRenderRunning = MedicalImageRender.isRunning();

  // set tools
  useEffect(() => {
    if (MedicalImageRender.isRunning()) {
      MedicalImageRender.addEventListener(
        'brightnessContrastChanged',
        handleViewOptionsChange,
      );
      MedicalImageRender.addEventListener(
        'sharpnessChanged',
        handleViewOptionsChange,
      );
      MedicalImageRender.addEventListener(
        'screenSharpnessChanged',
        handleViewOptionsChange,
      );
      MedicalImageRender.addEventListener(
        'screenBrightnessContrastChanged',
        handleViewOptionsChange,
      );
    }

    return () => {
      MedicalImageRender.removeEventListener(
        'brightnessContrastChanged',
        handleViewOptionsChange,
      );
      MedicalImageRender.removeEventListener(
        'sharpnessChanged',
        handleViewOptionsChange,
      );
      MedicalImageRender.removeEventListener(
        'screenSharpnessChanged',
        handleViewOptionsChange,
      );
      MedicalImageRender.removeEventListener(
        'screenBrightnessContrastChanged',
        handleViewOptionsChange,
      );
    };
  }, [isRenderRunning]);

  return (
    <>
      <div className={cn(styles.container, className)} data-testid={testID}>
        {controlsOptions.map((controlOption) => (
          <Tooltip.Primary
            key={controlOption.control}
            side="right"
            content={
              <span className="p3">
                {formatMessage(toolsMessages[controlOption.control])}
              </span>
            }
          >
            <Icon
              name={CONTROL_TOOLS_ICONS[controlOption.control] as IconNames}
              className={cn(
                styles.icon,
                isControlActive(controlOption) && styles.active,
              )}
              size={32}
              onClick={() => {
                if (controlOption.onClick) {
                  controlOption.onClick(controlOption.control);
                } else {
                  handleControlClick(controlOption.control);
                }
              }}
            />
          </Tooltip.Primary>
        ))}
      </div>
      {isExpanded && (
        <ExitFromFullscreenButton onClick={() => setIsExpanded(false)} />
      )}
    </>
  );
};
