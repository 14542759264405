import { Condition } from '@/shared/api/protocol-ts/model/dto_report_condition_pb';

import { CONDITION_CODES_BY_GROUPS } from '../config/const';

import { collectConditionCodesFromGroups } from './collectConditionCodesFromGroups';

export const sortConditionsByGroupOrder = (a: Condition, b: Condition) => {
  const conditionCodes = collectConditionCodesFromGroups(
    CONDITION_CODES_BY_GROUPS,
  );

  return conditionCodes.indexOf(a.Code) - conditionCodes.indexOf(b.Code);
};
