// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_asset_cbct_molar.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { CBCT_SectionOrientation, CBCT_SliceOrientation, DerivedData_Image, File } from "./dto_asset_common_pb.js";

/**
 * @generated from message com.diagnocat.model.AssetContent_Generated_CBCT_Molar_Panorama
 */
export const AssetContent_Generated_CBCT_Molar_Panorama = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Generated_CBCT_Molar_Panorama",
  () => [
    { no: 1, name: "Panorama", kind: "message", T: File },
    { no: 2, name: "CanalPenetration", kind: "enum", T: proto3.getEnumType(AssetContent_Generated_CBCT_Molar_Panorama_CanalPenetrationType) },
    { no: 101, name: "Derived", kind: "message", T: DerivedData_Image },
  ],
);

/**
 * {"type": "File", "file": {"task_id": "23ddc55e-d818-f8bd-4cb6-b37576bf4f61", "key": "pano", "filename": "/tmp/tmpb08cng3z", "content_type": "application/dicom", "size": 256600, "meta": {"canal_penetration": 0, "image_height": 213, "image_width": 600}, "type": "File"}, "event": "execution-event"}
 *
 * @generated from enum com.diagnocat.model.AssetContent_Generated_CBCT_Molar_Panorama.CanalPenetrationType
 */
export const AssetContent_Generated_CBCT_Molar_Panorama_CanalPenetrationType = proto3.makeEnum(
  "com.diagnocat.model.AssetContent_Generated_CBCT_Molar_Panorama.CanalPenetrationType",
  [
    {no: 0, name: "NotTouching"},
    {no: 1, name: "Touching"},
    {no: 2, name: "Penetration"},
  ],
);

/**
 * @generated from message com.diagnocat.model.AssetContent_Generated_CBCT_Molar_Guide
 */
export const AssetContent_Generated_CBCT_Molar_Guide = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Generated_CBCT_Molar_Guide",
  () => [
    { no: 1, name: "Guide", kind: "message", T: File },
    { no: 2, name: "Section", kind: "enum", T: proto3.getEnumType(CBCT_SectionOrientation) },
    { no: 11, name: "SliceStep", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 12, name: "SliceThickness", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * name example: vestibulooral-000
 * name template: <secton>-<order>
 * {"type": "File", "file": {"task_id": "23ddc55e-d818-f8bd-4cb6-b37576bf4f61", "key": "vestibulooral-000", "filename": "/tmp/tmp2p3divx4", "content_type": "application/dicom", "size": 42944, "meta": {"name": "vestibulooral-000", "type": "cross-section", "order": 0, "section": "vestibulooral", "orientation_markers": {"left": "L", "right": "B"}, "image_height": 196, "image_width": 107}, "type": "File"}, "event": "execution-event"}
 *
 * @generated from message com.diagnocat.model.AssetContent_Generated_CBCT_Molar_CrossSection
 */
export const AssetContent_Generated_CBCT_Molar_CrossSection = proto3.makeMessageType(
  "com.diagnocat.model.AssetContent_Generated_CBCT_Molar_CrossSection",
  () => [
    { no: 1, name: "CrossSection", kind: "message", T: File },
    { no: 2, name: "Section", kind: "enum", T: proto3.getEnumType(CBCT_SectionOrientation) },
    { no: 3, name: "Order", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 11, name: "RightSideOrient", kind: "enum", T: proto3.getEnumType(CBCT_SliceOrientation) },
    { no: 12, name: "LeftSideOrient", kind: "enum", T: proto3.getEnumType(CBCT_SliceOrientation) },
  ],
);

