import cn from 'classnames';
import { useContext } from 'react';

import { MOLAR_TOOTH_NUMBER, ToothStatus } from '@/shared/config';
import { blackAndWhiteToothImages } from '@/shared/config/blackAndWhiteToothImages';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { useAppSelector } from '@/shared/hooks';

import { organizationModel } from '@/entities/organization';

import { ToothChartSize } from '../../config/types';
import { ToothChartContext } from '../ToothChartRoot/ToothChartRoot';

import toothSprite from './toothSprite.svg';
import styles from './ToothChartIcon.module.scss';

const statusStyles: Record<ToothStatus, string> = {
  healthy: styles.statusHealthy,
  unhealthy: styles.statusUnhealthy,
  missing: styles.statusMissing,
  treated: styles.statusTreated,
  lowProbability: styles.statusLowProbability,
  notFound: styles.notFound,
};

type IconSizeConfig = Record<ToothChartSize, { height: number; width: number }>;

const sizes: {
  common: IconSizeConfig;
  molar: IconSizeConfig;
  missing: IconSizeConfig;
  notFound: IconSizeConfig;
} = {
  common: {
    default: {
      height: 48,
      width: 20,
    },
    small: {
      height: 32,
      width: 14,
    },
  },
  molar: {
    default: {
      height: 46,
      width: 26,
    },
    small: {
      height: 31,
      width: 17,
    },
  },
  missing: {
    default: {
      height: 12,
      width: 12,
    },
    small: {
      height: 16,
      width: 12,
    },
  },
  notFound: {
    default: {
      height: 17,
      width: 10,
    },
    small: {
      height: 21,
      width: 10,
    },
  },
};

type ToothChartIconProps = {
  className?: string;
  ISONumber: number;
  status: ToothStatus;
  blackAndWhite?: boolean;
};

export const ToothChartIcon = (props: ToothChartIconProps) => {
  const { className, ISONumber, status, blackAndWhite = false } = props;

  const size = useContext(ToothChartContext);

  const isFDA = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_FDA_SubmissionView,
    ),
  );

  const isMolar = MOLAR_TOOTH_NUMBER.includes(ISONumber);
  const isThirdMolar = ISONumber.toString().includes('8'); // It could be a bug. If the third molars are the only ones with a number that contains an 8

  switch (status) {
    case 'missing': {
      return (
        <svg
          role="presentation"
          aria-hidden="true"
          focusable="false"
          viewBox="0 0 12 12"
          width={sizes.missing[size].width}
          height={sizes.missing[size].height}
          className={cn(
            styles.container,
            styles.nonToothIcon,
            styles.statusMissing,
            (isThirdMolar || isFDA) && styles.thirdMolar,
            blackAndWhite && styles.blackAndWhite,
          )}
        >
          <use href={`${toothSprite}#toothMissed`} />
        </svg>
      );
    }

    case 'notFound': {
      return (
        <svg
          role="presentation"
          aria-hidden="true"
          focusable="false"
          viewBox="0 0 10 17"
          width={sizes.notFound[size].width}
          height={sizes.notFound[size].height}
          className={cn(
            styles.container,
            styles.nonToothIcon,
            styles.statusNotFound,
          )}
        >
          <use href={`${toothSprite}#toothNotFound`} />
        </svg>
      );
    }

    default: {
      return blackAndWhite ? (
        <img
          src={
            blackAndWhiteToothImages[
              status as Exclude<
                ToothStatus,
                ToothStatus.missing | ToothStatus.notFound
              >
            ][ISONumber]
          }
          alt={`tooth${ISONumber}`}
          role="presentation"
          aria-hidden="true"
          width={isMolar ? 27 : 20}
          height={isMolar ? 47 : 48}
          className={cn(className, styles.container)}
        />
      ) : (
        <svg
          role="presentation"
          aria-hidden="true"
          focusable="false"
          viewBox={isMolar ? '0 0 27 47' : '0 0 20 48'}
          width={isMolar ? sizes.molar[size].width : sizes.common[size].width}
          height={
            isMolar ? sizes.molar[size].height : sizes.common[size].height
          }
          className={cn(className, styles.container, statusStyles[status])}
        >
          <use href={`${toothSprite}#tooth${ISONumber}`} />
        </svg>
      );
    }
  }
};
