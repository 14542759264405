import { createAsyncThunk } from '@reduxjs/toolkit';
import { ConnectError } from '@bufbuild/connect';

import api from '@/shared/api/api';
import { SliceName } from '@/shared/config';

import type { RootState } from '@/app/model/store';

export const updateCurrentReportROI = createAsyncThunk(
  `${SliceName.tooth}/updateReportROI`,
  async (_, { rejectWithValue, getState }) => {
    const { tooth, reports } = getState() as RootState;
    const { localROITeethIDs } = tooth;
    const { currentReport } = reports;

    try {
      const response = await api.report.setROI({
        ReportID: currentReport?.ID,
        ROIToothIDs: localROITeethIDs,
      });

      return response;
    } catch (error) {
      const connectError = ConnectError.from(error);
      return rejectWithValue(connectError);
    }
  },
);
