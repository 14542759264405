// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_report_type_cbct_gp.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";
import { CBCTSeriesGeometryData } from "./dto_common_geometry_pb.js";
import { MaxFaxAnatomyLocalization } from "./dto_report_maxfax_anatomy_localization_pb.js";

/**
 * @generated from message com.diagnocat.model.Report_CBCT_GP
 */
export const Report_CBCT_GP = proto3.makeMessageType(
  "com.diagnocat.model.Report_CBCT_GP",
  () => [
    { no: 1, name: "OrthoScreening", kind: "message", T: Report_CBCT_GP_OrthoScreening },
    { no: 2, name: "CropGeometryData", kind: "message", T: CBCTSeriesGeometryData },
    { no: 3, name: "MaxFaxAnatomyLocalizations", kind: "message", T: MaxFaxAnatomyLocalization, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.model.Report_CBCT_GP_OrthoScreening
 */
export const Report_CBCT_GP_OrthoScreening = proto3.makeMessageType(
  "com.diagnocat.model.Report_CBCT_GP_OrthoScreening",
  () => [
    { no: 1, name: "AnglesClass", kind: "enum", T: proto3.getEnumType(Report_CBCT_GP_OrthoScreening_OrthoAnglesClass) },
    { no: 2, name: "Crowding", kind: "message", T: Report_CBCT_GP_OrthoScreening_OrthoToothPair, repeated: true },
    { no: 3, name: "Spacing", kind: "message", T: Report_CBCT_GP_OrthoScreening_OrthoToothPair, repeated: true },
    { no: 4, name: "CephalometricConditions", kind: "message", T: Report_CBCT_GP_OrthoScreening_OrthoCephalometricCondition, repeated: true },
  ],
);

/**
 * @generated from enum com.diagnocat.model.Report_CBCT_GP_OrthoScreening.OrthoAnglesClass
 */
export const Report_CBCT_GP_OrthoScreening_OrthoAnglesClass = proto3.makeEnum(
  "com.diagnocat.model.Report_CBCT_GP_OrthoScreening.OrthoAnglesClass",
  [
    {no: 0, name: "AnglesClassUnidentified"},
    {no: 1, name: "AnglesClass1"},
    {no: 2, name: "AnglesClass2"},
    {no: 3, name: "AnglesClass3"},
  ],
);

/**
 * @generated from enum com.diagnocat.model.Report_CBCT_GP_OrthoScreening.OrthoConditionCode
 */
export const Report_CBCT_GP_OrthoScreening_OrthoConditionCode = proto3.makeEnum(
  "com.diagnocat.model.Report_CBCT_GP_OrthoScreening.OrthoConditionCode",
  [
    {no: 0, name: "UnspecifiedCondition"},
    {no: 2000, name: "NormalMaxillaryPosition"},
    {no: 2001, name: "MaxillaryRetroPosition"},
    {no: 2002, name: "MaxillaryAntePosition"},
    {no: 2010, name: "NormalMandibularPosition"},
    {no: 2011, name: "MandibularRetroPosition"},
    {no: 2012, name: "MandibularAntePosition"},
    {no: 2020, name: "NormalMaxillaryAndMandibularSkeletalBasesRelation"},
    {no: 2021, name: "MesialMaxillaryAndMandibularSkeletalBasesRelation"},
    {no: 2022, name: "DistalMaxillaryAndMandibularSkeletalBasesRelation"},
    {no: 2030, name: "NormalInclination"},
    {no: 2031, name: "AnteInclination"},
    {no: 2032, name: "RetroInclination"},
    {no: 2040, name: "NormalDivergence"},
    {no: 2041, name: "HypoDivergence"},
    {no: 2042, name: "HyperDivergence"},
    {no: 2050, name: "UpperIncisorNormalPositionAccordingToU1SnAngle"},
    {no: 2051, name: "UpperIncisorRetrusionAccordingToU1SnAngle"},
    {no: 2052, name: "UpperIncisorProtrusionAccordingToU1SnAngle"},
    {no: 2060, name: "UpperIncisorNormalPositionAccordingToU1PpAngle"},
    {no: 2061, name: "UpperIncisorRetrusionAccordingToU1PpAngle"},
    {no: 2062, name: "UpperIncisorProtrusionAccordingToU1PpAngle"},
    {no: 2070, name: "LowerIncisorNormalPosition"},
    {no: 2071, name: "LowerIncisorRetrusion"},
    {no: 2072, name: "LowerIncisorProtrusion"},
    {no: 2080, name: "NormalOverjet"},
    {no: 2081, name: "IncreasedOverjet"},
    {no: 2082, name: "DecreasedOverjet"},
    {no: 2083, name: "AnteriorCrossbite"},
    {no: 2090, name: "NormalOverbite"},
    {no: 2091, name: "IncreasedOverbite"},
    {no: 2092, name: "DecreasedOverbite"},
    {no: 2093, name: "OpenBite"},
  ],
);

/**
 * @generated from message com.diagnocat.model.Report_CBCT_GP_OrthoScreening.OrthoToothPair
 */
export const Report_CBCT_GP_OrthoScreening_OrthoToothPair = proto3.makeMessageType(
  "com.diagnocat.model.Report_CBCT_GP_OrthoScreening.OrthoToothPair",
  () => [
    { no: 1, name: "FirstToothNumberISO", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "SecondToothNumberISO", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
  {localName: "Report_CBCT_GP_OrthoScreening_OrthoToothPair"},
);

/**
 * @generated from message com.diagnocat.model.Report_CBCT_GP_OrthoScreening.OrthoCephalometricCondition
 */
export const Report_CBCT_GP_OrthoScreening_OrthoCephalometricCondition = proto3.makeMessageType(
  "com.diagnocat.model.Report_CBCT_GP_OrthoScreening.OrthoCephalometricCondition",
  () => [
    { no: 1, name: "Rule", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "Unit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "ConditionCode", kind: "enum", T: proto3.getEnumType(Report_CBCT_GP_OrthoScreening_OrthoConditionCode) },
  ],
  {localName: "Report_CBCT_GP_OrthoScreening_OrthoCephalometricCondition"},
);

