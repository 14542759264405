import { FC, useCallback, useMemo } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormattedNumber } from 'react-intl/lib';
import { useNavigate } from 'react-router';

import { ProductModel } from '@/shared/api/protocol-ts/api/billing_new/dto_product_new_pb';
import { Button } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import {
  serviceGroupNamesDefaultMessage,
  serviceNamesDefaultMessage,
} from '@/shared/i18n/services';
import { openInNewBrowserTab, shouldAskAdditionalInfo } from '@/shared/lib';
import { PATHS } from '@/shared/config';
import { CreateOrderReq } from '@/shared/api/protocol-ts/api/billing_new/svc_billing_new_pb';
import { EffectSubscriptionAdd } from '@/shared/api/protocol-ts/api/billing_new/dto_order_new_pb';

import { billingModel } from '@/entities/billing';
import { ModalID, modalModel } from '@/entities/modal';

import styles from './SubscriptionCard.module.scss';

type SubscriptionCardProps = {
  product: ProductModel;
  className?: string;
  testID?: string;
};

export const SubscriptionCard: FC<SubscriptionCardProps> = (props) => {
  const { className, testID, product } = props;

  const { formatMessage } = useIntl();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { ID, BillingZones, BillingInformation } = useAppSelector(
    billingModel.selectors.selectAccount,
  );

  const billingLoading = useAppSelector(billingModel.selectors.selectLoading);

  const currentPlan = useAppSelector(
    billingModel.selectors.selectCurrentSubscription,
  );

  const selectedLifeTime = useAppSelector(
    billingModel.selectors.selectSubscriptionLifeTime,
  );

  const isCurrentProductBought = currentPlan?.Model?.SKU === product.SKU;

  const isMonthlyPlan = selectedLifeTime === 1;

  const currency = product.Currency;
  const price = Number(product.Price);
  const billingZone = BillingZones?.at(0);

  const shouldAskBillingInfo = useMemo(
    () => shouldAskAdditionalInfo(billingZone, BillingInformation),
    [billingZone, BillingInformation],
  );

  const handleChoosePlanClick = useCallback(async () => {
    if (shouldAskBillingInfo) {
      dispatch(
        modalModel.actions.openModal({
          modalID: ModalID.BillingInformationModal,
          data: {
            isEdit: false,
            effectToAdd: {
              SubscriptionAdd: new EffectSubscriptionAdd({
                Subscription: product,
              }),
            },
          },
        }),
      );
    } else {
      try {
        dispatch(billingModel.actions.setLoading('pending'));

        const { Order, InvoicePaymentURL } = await dispatch(
          billingModel.thunks.createOrder(
            new CreateOrderReq({
              AccountID: ID,
              Effect: {
                case: 'SubscriptionAdd',
                value: {
                  Subscription: product,
                  // TODO: [2\h] uncomment when backend implement pause/activate functionality
                  // IsManualActivation: true
                },
              },
            }),
          ),
        ).unwrap();

        if (Order) {
          dispatch(billingModel.actions.updateOrders(Order));
        }

        if (InvoicePaymentURL) {
          openInNewBrowserTab(InvoicePaymentURL);
          navigate(`${PATHS.companySettings}?tab=SubscriptionPlans`);
        }

        dispatch(billingModel.actions.setLoading('succeeded'));
      } catch (error) {
        dispatch(billingModel.actions.setLoading('failed'));
      }
    }
  }, [ID, shouldAskBillingInfo, product]);

  return (
    <div
      data-testid={testID}
      className={cn(
        styles.container,
        className,
        isCurrentProductBought && styles.currentPlan,
      )}
    >
      <div className={styles.innerContainer}>
        <header className={styles.header}>
          <h4 className={cn(styles.purpleText, 'h4')}>{product.Name}</h4>

          <p className="p2">
            <FormattedMessage
              id="billing.subscriptions.subscriptionCards.card.header.text"
              defaultMessage="Simplifies CBCT adoption and make it easy for both clinicians and patients"
            />
          </p>

          <div className={cn(styles.purpleText, styles.price, 'h3')}>
            <FormattedNumber
              maximumFractionDigits={0}
              value={price}
              style="currency"
              currencyDisplay="narrowSymbol"
              currency={currency}
            />
            <span className="h6">
              /
              {isMonthlyPlan ? (
                <FormattedMessage
                  id="illing.subscriptions.subscriptionCards.card.perMonth"
                  defaultMessage="month"
                />
              ) : (
                <FormattedMessage
                  id="illing.subscriptions.subscriptionCards.card.annual"
                  defaultMessage="year"
                />
              )}
            </span>
            {!isMonthlyPlan && (
              <span className={cn(styles.discount, 'p4')}>-25%</span>
            )}
          </div>

          <p className={cn(styles.greyText, 'p1')}>
            {isMonthlyPlan ? (
              <FormattedMessage
                id="billing.subscriptions.subscriptionCards.card.billedMonthly"
                defaultMessage="Billed monthly"
              />
            ) : (
              <FormattedMessage
                id="billing.subscriptions.subscriptionCards.card.billedAnnually"
                defaultMessage="Billed annually"
              />
            )}
          </p>
        </header>

        <ul className={styles.services}>
          {product.Services.map((service) => {
            const serviceName =
              service.Types.length > 1
                ? formatMessage(serviceGroupNamesDefaultMessage[service.Group])
                : formatMessage(serviceNamesDefaultMessage[service.Types[0]], {
                    amount: Number(
                      (service.Kind.case === 'Countable' &&
                        service.Kind.value.Limit) ??
                        0,
                    ),
                  });

            return (
              <li key={service.Types.toString()} className="p2">
                {serviceName}
              </li>
            );
          })}
        </ul>
      </div>

      {currentPlan?.ID ? (
        <Button
          disabled={isCurrentProductBought}
          onClick={() => window.open('mailto:sales@diagnocat.com')}
        >
          {isCurrentProductBought ? (
            <FormattedMessage
              id="billing.subscriptions.subscriptionCards.card.currentPlan"
              defaultMessage="Current plan"
            />
          ) : (
            <FormattedMessage
              id="billing.subscriptions.subscriptionCards.card.contactUs"
              defaultMessage="Contact us"
            />
          )}
        </Button>
      ) : (
        <Button
          onClick={handleChoosePlanClick}
          loading={billingLoading === 'pending'}
        >
          <FormattedMessage
            id="billing.subscriptions.subscriptionCards.card.choosePlan"
            defaultMessage="Choose plan"
          />
        </Button>
      )}
    </div>
  );
};
