import { FC } from 'react';
import { useIntl } from 'react-intl';

import {
  Study,
  StudyStatus,
} from '@/shared/api/protocol-ts/model/dto_study_pb';
import { StatusCatImage } from '@/shared/ui';

import { i18n } from '@/entities/study';

import { studyStatusData } from '@/widgets/StudyCard/model/constants';
import { studyCardUploadingStatusMessage } from '@/widgets/StudyCard/config';

import styles from './UploadingStatusFrame.module.scss';

type UploadingStatusFrameProps = {
  study: Study;
};
export const UploadingStatusFrame: FC<UploadingStatusFrameProps> = (props) => {
  const { study } = props;
  const { formatMessage } = useIntl();

  const { imageSrc, status } = studyStatusData[study.Status];

  return (
    <div className={styles.frame}>
      <StatusCatImage
        statusImageSrc={imageSrc}
        altText={formatMessage(i18n.studyTypes[study.Type])}
        isLoading={study.Status === StudyStatus.StudyStatus_Uploading}
      />

      <p className="p2">
        {formatMessage(studyCardUploadingStatusMessage[status])}
      </p>
    </div>
  );
};
