import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { RadioGroup } from '@/shared/ui';

import { billingModel } from '@/entities/billing';

import { SubscriptionCard } from '../SubscriptionCard/SubscriptionCard';
import { useSubscriptionLifeTimeOptions } from '../../hooks/useSubscriptionLifeTimeOptions';

import styles from './SubscriptionPlans.module.scss';

type SubscriptionPlansProps = {
  className?: string;
  testID?: string;
};

export const SubscriptionPlans: FC<SubscriptionPlansProps> = (props) => {
  const { className, testID } = props;

  const availableProducts = useAppSelector(
    billingModel.selectors.selectAvailableSubscriptions,
  );

  const selectedLifeTime = useAppSelector(
    billingModel.selectors.selectSubscriptionLifeTime,
  );

  const dispatch = useAppDispatch();

  const subscriptionLifeTimeOptions = useSubscriptionLifeTimeOptions();

  if (availableProducts.length === 0) {
    return null;
  }

  return (
    <div data-testid={testID} className={cn(styles.container, className)}>
      <h4 className="h4">
        <FormattedMessage
          id="billing.subscriptions.subscriptionCardsTab.title"
          defaultMessage="Subscription period"
        />
      </h4>

      <RadioGroup
        innerClassName={styles.tabs}
        variant="tab"
        items={subscriptionLifeTimeOptions}
        value={selectedLifeTime}
        onChange={(event) =>
          dispatch(
            billingModel.actions.setSubscriptionLifeTime(
              Number(event.target.value),
            ),
          )
        }
      />

      <div className={styles.cardsContainer}>
        {availableProducts
          .filter(
            (product) =>
              product.Kind.case === 'Subscription' &&
              product.Kind.value.LifeTimeMonths === selectedLifeTime,
          )
          .map((product) => (
            <SubscriptionCard key={product.SKU} product={product} />
          ))}
      </div>
    </div>
  );
};
