import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { SliceName } from '@/shared/config';
import { ReportDataStreamResp_AllowedToothConditionsHint } from '@/shared/api/protocol-ts/api/core/svc_report_pb';

export const allowedToothConditionsAdapter =
  createEntityAdapter<ReportDataStreamResp_AllowedToothConditionsHint>({
    selectId: (
      allowedToothConditions: ReportDataStreamResp_AllowedToothConditionsHint,
    ) => allowedToothConditions?.ToothID,
  });

const allowedToothConditionsState =
  allowedToothConditionsAdapter.getInitialState();

type AllowedToothConditionsState = typeof allowedToothConditionsState;

const allowedToothConditionsSlice = createSlice({
  name: SliceName.allowedToothConditions,
  initialState: allowedToothConditionsState,
  reducers: {
    setOne: (
      state,
      action: PayloadAction<ReportDataStreamResp_AllowedToothConditionsHint>,
    ) =>
      allowedToothConditionsAdapter.setOne(
        state as AllowedToothConditionsState,
        action.payload,
      ),
  },
});

export const { actions } = allowedToothConditionsSlice;

export default allowedToothConditionsSlice.reducer;
