import { defineMessages } from 'react-intl';

import { ReportGenerationErrorCode } from '@/shared/api/protocol-ts/model/dto_report_generation_error_codes_pb';

// TODO: [2|m] Move it to the enitites/report/config/i18n
export const reportGeneratingErrorMessages_DEPRECATED =
  defineMessages<ReportGenerationErrorCode>({
    [ReportGenerationErrorCode.ReportGenerationErrorCode_Unspecified]: {
      id: 'reportGenerationError.unexpectedError',
      defaultMessage:
        'Something went wrong. Try again later or contact our support team.',
    },
    [ReportGenerationErrorCode.ReportGenerationErrorCode_StudyUpload_Failed]: {
      id: 'reportGenerationError.studyUpload_errors',
      defaultMessage:
        'The upload of the study has been cancelled or has failed. Please try again later or contact our support team.',
    },
    [ReportGenerationErrorCode.ReportGenerationErrorCode_StudyUpload_Canceled]:
      {
        id: 'reportGenerationError.studyUpload_errors',
        defaultMessage:
          'The upload of the study has been cancelled or has failed. Please try again later or contact our support team.',
      },
    [ReportGenerationErrorCode.ReportGenerationErrorCode_GeneratedAssetUpload_Failed]:
      {
        id: 'reportGenerationError.unexpectedError',
        defaultMessage:
          'Something went wrong. Try again later or contact our support team.',
      },
    [ReportGenerationErrorCode.ReportGenerationErrorCode_FileCannotBeRead]: {
      id: 'reportGenerationError.fileError',
      defaultMessage:
        'The file or files cannot be read. Please ensure that you have uploaded appropriate files. If the problem persists contact our support team.',
    },
    [ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_IsNot2DImage]: {
      id: 'reportGenerationError.dicom_isNot2DImage',
      defaultMessage:
        'The uploaded file is not a 2D image. Please upload the correct file. If the problem persists contact our support team.',
    },
    [ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_IsNot3DImage]: {
      id: 'reportGenerationError.dicom_isNot3DImage',
      defaultMessage:
        'The uploaded file is not a 3D image. Please upload the correct file. If the problem persists contact our support team.',
    },
    [ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_InsufficientVolume]:
      {
        id: 'reportGenerationError.dicom_insufficientVolume',
        defaultMessage: 'Dicom insufficient volume',
      },
    [ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_DuplicateSlices]:
      {
        id: 'reportGenerationError.dicom_metadata_error',
        defaultMessage:
          'The file or files cannot be read due to DICOM metadata error. Please check that you’ve uploaded the correct file or files. If the problem persists contact our support team.',
      },
    [ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_Metadata_NonDerivableVoxelSize]:
      {
        id: 'reportGenerationError.dicom_metadata_error',
        defaultMessage:
          'The file or files cannot be read due to DICOM metadata error. Please check that you’ve uploaded the correct file or files. If the problem persists contact our support team.',
      },
    [ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_Metadata_NonDerivableRescaleInterceptAttribute]:
      {
        id: 'reportGenerationError.dicom_metadata_error',
        defaultMessage:
          'The file or files cannot be read due to DICOM metadata error. Please check that you’ve uploaded the correct file or files. If the problem persists contact our support team.',
      },
    [ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_Metadata_NonDerivableRescaleSlopeAttribute]:
      {
        id: 'reportGenerationError.dicom_metadata_error',
        defaultMessage:
          'The file or files cannot be read due to DICOM metadata error. Please check that you’ve uploaded the correct file or files. If the problem persists contact our support team.',
      },
    [ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_Metadata_NonDerivableImagePositionPatientAttribute]:
      {
        id: 'reportGenerationError.dicom_metadata_error',
        defaultMessage:
          'The file or files cannot be read due to DICOM metadata error. Please check that you’ve uploaded the correct file or files. If the problem persists contact our support team.',
      },
    [ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_Metadata_UnexpectedModality]:
      {
        id: 'reportGenerationError.dicom_metadata_error',
        defaultMessage:
          'The file or files cannot be read due to DICOM metadata error. Please check that you’ve uploaded the correct file or files. If the problem persists contact our support team.',
      },
    [ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_Metadata_NonDerivableSlicePosition]:
      {
        id: 'reportGenerationError.dicom_metadata_error',
        defaultMessage:
          'The file or files cannot be read due to DICOM metadata error. Please check that you’ve uploaded the correct file or files. If the problem persists contact our support team.',
      },
    [ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_Metadata_NoPixelData]:
      {
        id: 'reportGenerationError.dicom_metadata_error',
        defaultMessage:
          'The file or files cannot be read due to DICOM metadata error. Please check that you’ve uploaded the correct file or files. If the problem persists contact our support team.',
      },
    [ReportGenerationErrorCode.ReportGenerationErrorCode_InputData_UnexpectedStudyQuantity]:
      {
        id: 'reportGenerationError.fileError',
        defaultMessage:
          'The file or files cannot be read. Please ensure that you have uploaded appropriate files. If the problem persists contact our support team.',
      },
    [ReportGenerationErrorCode.ReportGenerationErrorCode_InputData_UnexpectedStudyContent]:
      {
        id: 'reportGenerationError.fileError',
        defaultMessage:
          'The file or files cannot be read. Please ensure that you have uploaded appropriate files. If the problem persists contact our support team.',
      },
    [ReportGenerationErrorCode.ReportGenerationErrorCode_InputData_BadTaskParameters]:
      {
        id: 'reportGenerationError.inputData_badTaskParameters',
        defaultMessage: 'Input data bad task parameters',
      },
    [ReportGenerationErrorCode.ReportGenerationErrorCode_Modeling_CbctIosMisalignment]:
      {
        id: 'reportGenerationError.modeling_cbctIosMisalignment',
        defaultMessage:
          'Input CBCT and IOS meshes do not match. Please check that CBCT and IOS studies are of decent quality and belong to the same patient, or contact our support team.',
      },
    [ReportGenerationErrorCode.ReportGenerationErrorCode_Modeling_IosWrongOcclusion]:
      {
        id: 'reportGenerationError.modeling_iosWrongOcclusion',
        defaultMessage:
          'Input IOS meshes are in wrong occlusion. Please select another IOS study or contact our support team for assistance.',
      },
    [ReportGenerationErrorCode.ReportGenerationErrorCode_Modeling_IosMissingJaw]:
      {
        id: 'reportGenerationError.modeling_iosMissingJaw',
        defaultMessage:
          'Input IOS meshes do not contain lower or upper jaw. Please select another IOS study or contact our support team for assistance.',
      },
    [ReportGenerationErrorCode.ReportGenerationErrorCode_Modeling_RequestedToothNotFound]:
      {
        id: 'reportGenerationError.modeling_requestedToothNotFound',
        defaultMessage:
          'The requested tooth is not found. Please select an existing tooth or contact our support team for assistance.',
      },
    [ReportGenerationErrorCode.ReportGenerationErrorCode_ValidTimeExpired]: {
      id: 'reportGenerationError.unexpectedError',
      defaultMessage:
        'Something went wrong. Try again later or contact our support team.',
    },
    [ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_MultipleStudies]:
      {
        id: 'reportGenerationError.unexpectedError',
        defaultMessage:
          'Something went wrong. Try again later or contact our support team.',
      },
    [ReportGenerationErrorCode.ReportGenerationErrorCode_Dicom_Metadata_InvalidVoxelSize]:
      {
        id: 'reportGenerationError.unexpectedError',
        defaultMessage:
          'Something went wrong. Try again later or contact our support team.',
      },
    [ReportGenerationErrorCode.ReportGenerationErrorCode_Modeling_CbctIosImpossibleSuperimposition]:
      {
        id: 'reportGenerationError.unexpectedError',
        defaultMessage:
          'Something went wrong. Try again later or contact our support team.',
      },
    [ReportGenerationErrorCode.ReportGenerationErrorCode_Modeling_CBCT_Endo_ToothRootsNotFound]:
      {
        id: 'reportGenerationError.unexpectedError',
        defaultMessage:
          'Something went wrong. Try again later or contact our support team.',
      },
  });
