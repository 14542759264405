import { groupBy } from 'lodash';

import { Asset } from '@/shared/api/protocol-ts/model/dto_asset_pb';
import { MedicalImageInterface } from '@/shared/config';
import { Localization } from '@/shared/api/protocol-ts/model/dto_report_localization_pb';
import { PolygonMask } from '@/shared/graphics/RenderComponents/RCPolygonMask/RCPolygonMask';
import { FloatPoint2D } from '@/shared/api/protocol-ts/model/dto_common_geometry_pb';
import { vec2 } from '@/shared/graphics/RenderComponents/vec2';

import {
  GroupConfigsType,
  PathologyGroup,
} from '../config/medicalImages.types';

import { getFileSrc } from './getFileSrc';

const maskPointsToVec2 = (points: FloatPoint2D): vec2 => ({
  x: points.X,
  y: points.Y,
});

const PATHOLOGIES = '#FF4C00'; // mandarin color

const localizationToMasks = (localization: Localization): PolygonMask => ({
  polygon: localization.PolygonalMask.flatMap((mask) =>
    mask?.Points.map(maskPointsToVec2),
  ),
  color: PATHOLOGIES,
  stroke: PATHOLOGIES,
  opacity: 0.2,
});

export const makePathologyGroup = (
  groupConfigs: GroupConfigsType,
  medicalImagesList: Asset[],
  masks?: Localization[],
): PathologyGroup => {
  const { groupName, groupCode } = groupConfigs;

  const groupedByAssetIDMasks = groupBy(masks, 'TargetAssetID');

  const medicalImages = medicalImagesList.reduce((acc, item) => {
    const generatedReportAsset =
      item.Kind.case === 'GeneratedReport' ? item.Kind.value : undefined;

    if (
      generatedReportAsset?.Content.case === 'CBCTToothPathologySlice' &&
      generatedReportAsset.Content.value.Pathology === groupCode
    ) {
      const pathologySlice = generatedReportAsset.Content.value;

      acc.push({
        id: pathologySlice.Slice?.ID || '',
        src: getFileSrc(pathologySlice.Slice?.ID || ''),
        path: pathologySlice.Slice?.Path,
        assetID: item.ID,
        masks: groupedByAssetIDMasks[item.ID]?.map(localizationToMasks),
        groupName,
      });
    }

    return acc;
  }, [] as MedicalImageInterface[]);

  return {
    type: groupName,
    medicalImages,
  };
};
