import { ReportType } from '@/shared/api/protocol-ts/model/dto_report_pb';
import { BASE_URI } from '@/shared/config';

import { getModelTypeForUrl } from './getModelTypeForUrl';

export const getSegmentronReportLink = (
  reportID: string,
  reportType: ReportType,
) => {
  const orderType = getModelTypeForUrl(
    reportType ?? ReportType.ReportType_CBCT_Segmentation,
  );

  return `${BASE_URI}/studio/${orderType}/?analysis_uid=${reportID}`;
};
