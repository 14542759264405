import { RCCropImageCrop } from "../RCCropImage/RCCropImage";
import { RCOffScreenCanvas, RCOffScreenCtx } from "./RCOffScreenCanvas";

/** 
 * The purpose of this function is to copy the image from inCanvas to outCanvas
 * 
 * RCImageBase was intended to be able to use difference repaint methods, but them are the same essentially,
 * so this function is to reuse the same code for both
 * 
 */
export const repaint = (crop: RCCropImageCrop, inCanvas: HTMLCanvasElement, outCanvas: HTMLCanvasElement, angle: number | undefined) => {

    const outCtx = outCanvas.getContext('2d');
    if (!outCtx) return

    // Note: only angle=0,90,180,270 are supported 
    if (angle) {
        // Render image to an off-screen canvas first
        // Then apply the rotation and redraw onto the main canvas
        const width = crop.w
        const height = crop.h

        RCOffScreenCanvas.width = width;
        RCOffScreenCanvas.height = height;

        RCOffScreenCtx.drawImage(inCanvas,
            crop.x, crop.y, crop.w, crop.h,
            0, 0, crop.w, crop.h);

        if (angle === 90 || angle === 270) {
            outCanvas.width = height;
            outCanvas.height = width;
        } else {
            outCanvas.width = width;
            outCanvas.height = height;
        }

        // Apply rotation to the main canvas
        outCtx.save();
        outCtx.translate(outCanvas.width / 2, outCanvas.height / 2);
        outCtx.rotate(angle * Math.PI / 180);
        outCtx.translate(-RCOffScreenCanvas.width / 2, -RCOffScreenCanvas.height / 2);

        // Draw the off-screen canvas onto the main canvas
        outCtx.drawImage(RCOffScreenCanvas, 0, 0);
        outCtx.restore();
    } else {
        // Render image to the canvas right away
        outCanvas.width = crop.w;
        outCanvas.height = crop.h;

        outCtx.drawImage(inCanvas,
            crop.x, crop.y, crop.w, crop.h,
            0, 0, crop.w, crop.h);
    }
}