import { Annotation as CroppedImageAnnotation } from '@/shared/api/protocol-ts/model/dto_common_image_annotations_pb';
import { Annotation } from '@/shared/graphics/RenderComponents/AnnotationsLayer/Annotation';

export const croppedImageAnnotationConvertor = (
  annotations: Annotation[] = [],
): CroppedImageAnnotation[] => {
  const croppedImageAnnotations = annotations.reduce((acc, annotation) => {
    if (annotation.kind === 'angle') {
      acc.push({
        Type: {
          case: 'Angle',
          value: {
            Start: {
              X: annotation.x1,
              Y: annotation.y1,
            },
            End: {
              X: annotation.x2,
              Y: annotation.y2,
            },
            Corner: {
              X: annotation.x3,
              Y: annotation.y3,
            },
          },
        },
      } as CroppedImageAnnotation);
    }

    if (annotation.kind === 'arrow') {
      acc.push({
        Type: {
          case: 'Arrow',
          value: {
            Start: {
              X: annotation.x1,
              Y: annotation.y1,
            },
            End: {
              X: annotation.x2,
              Y: annotation.y2,
            },
          },
        },
      } as CroppedImageAnnotation);
    }

    if (annotation.kind === 'ruler') {
      acc.push({
        Type: {
          case: 'Ruler',
          value: {
            Start: {
              X: annotation.x1,
              Y: annotation.y1,
            },
            End: {
              X: annotation.x2,
              Y: annotation.y2,
            },
          },
        },
      } as CroppedImageAnnotation);
    }

    return acc;
  }, [] as CroppedImageAnnotation[]);

  return croppedImageAnnotations;
};
