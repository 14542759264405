import { createSelector } from '@reduxjs/toolkit';
import { isEmpty } from 'ramda';
import { generatePath } from 'react-router';

import {
  Report,
  ReportType,
} from '@/shared/api/protocol-ts/model/dto_report_pb';

import { makeImageViewOptions } from '@/entities/assets';

import { RootState } from '@/app/model/store';

import {
  filterReportByStudyID,
  filterReportByStudyIDAndType,
  getReportPath,
  getReportSignStatus,
} from '../lib';
import { getPatientRecentReports } from '../lib/getPatientRecentReports';

import { reportsAdapter } from './reportSlice';

const reportsSelectors = reportsAdapter.getSelectors(
  (state: RootState) => state.reports,
);

export const { selectAll, selectById, selectIds, selectEntities, selectTotal } =
  reportsSelectors;

export const selectByID = (reportID: string) => (state: RootState) =>
  selectById(state, reportID);

export const selectByStudyID = (studyID: string) =>
  createSelector(selectAll, (reports) =>
    filterReportByStudyID(reports, studyID),
  );

export const selectPanowingDisplayAssetIDByReportID = (reportID: string) =>
  createSelector(
    selectByID(reportID),
    (report) =>
      report?.Specific.case === 'DataPanoBitewings' &&
      report?.Specific.value.PanoStudyAssetID,
  );

const isReportNotDeleted = (report: Report) => !report.Deleted?.Deleted;

const isReportHasStudyID = (studyIDs: string[]) => (report: Report) => {
  const reportStudyIDs = report.SourceStudyIDs;

  return (
    reportStudyIDs.length === studyIDs.length &&
    reportStudyIDs.every((id) => studyIDs.includes(id))
  );
};

const isReportTypeMatches = (
  firstReportType: ReportType,
  secondReportType: ReportType,
) => firstReportType === secondReportType;

export const selectActiveReportsByStudyID = (studyIDs: string[]) =>
  createSelector(selectAll, (reports) =>
    reports.filter(
      (report) =>
        isReportNotDeleted(report) && isReportHasStudyID(studyIDs)(report),
    ),
  );

export const selectMaskStyle = (state: RootState) =>
  state.reports.maskStyleType;

export const selectShowMasks = (state: RootState) => state.reports.showMasks;

export const selectByStudyIDAndType = (
  studyID: string,
  reportType: ReportType,
) =>
  createSelector(selectAll, (reports) =>
    filterReportByStudyIDAndType(reports, studyID, reportType),
  );

export const selectByPatientID = (patientID: string) =>
  createSelector(selectAll, (reports) =>
    reports.filter((report) => report.PatientID === patientID),
  );

export const selectAssetsOrientationAngle = (state: RootState) => {
  const currentReport = state.reports.currentReport;

  const dataIOXRayGP =
    currentReport?.Specific.case === 'DataIOXRayGP' &&
    currentReport?.Specific.value;

  if (!dataIOXRayGP) {
    return {};
  }

  const assetsOrietationAnglesByAssetID = dataIOXRayGP.ImagesMeta.reduce(
    (accum, meta) => {
      accum[meta.GeneratedAssetID || meta.StudyAssetID] = meta.OrientationAngle;
      return accum;
    },
    {} as Record<string, number | undefined>,
  );

  return assetsOrietationAnglesByAssetID;
};

export const selectPatientRecentReports = (
  patientID: string,
  enabledAsRecentTypes: ReportType[],
) =>
  createSelector(selectAll, (reports) =>
    getPatientRecentReports(reports, patientID, enabledAsRecentTypes),
  );

export const selectToothChartHeight = (state: RootState) =>
  state.reports.toothChartHeight;

export const selectNavigationMode = (state: RootState) =>
  state.reports.navigationMode;

export const selectActivePageNumber = (state: RootState) =>
  state.reports.activePageNumber;

export const selectActiveToothID = (state: RootState) =>
  state.reports.activeToothID;

export const selectActiveSubImagesIDs = (state: RootState) =>
  state.reports.activeSubImagesIDs;

export const selectDisabledSubImagesIDs = (state: RootState) =>
  state.reports.disabledSubImagesIDs;

export const selectFocusedImageMetaID = (state: RootState) =>
  state.reports.focusedMetaImageID;

export const selectHoveredMaskID = (state: RootState) =>
  state.reports.hoveredMaskID;

export const selectSubImagesIDs = (state: RootState) =>
  state.reports.subImagesIDs;

export const selectToolbarMultipleMode = (state: RootState) =>
  isEmpty(state.reports.activeToothID) &&
  isEmpty(state.reports.focusedMetaImageID);

export const selectReportViewOptions = (reportID: string) =>
  createSelector(
    (state: RootState) => reportsSelectors.selectById(state, reportID),
    (report) => makeImageViewOptions(report?.MedicalImageFeatures?.ViewOptions),
  );

export const selectToolbarActiveControl = (state: RootState) =>
  state.reports.toolbarActiveControl;

export const selectToolsMode = (state: RootState) => state.reports.toolsMode;

export const selectLastUpdatedCropID = (state: RootState) =>
  state.reports.lastUpdatedCropID;

export const selectCurrentReportType = (state: RootState) =>
  state.reports.currentReport?.Type;

export const selectShownSubImagesIDs = createSelector(
  [selectSubImagesIDs, selectDisabledSubImagesIDs],
  (subImagesIDs, disabledSubImagesIDs) =>
    subImagesIDs.filter(
      (subImageID) => !disabledSubImagesIDs.includes(subImageID),
    ),
);

export const selectReportBreadcrumbData = ({
  reportID,
  patientID,
}: {
  reportID: string;
  patientID: string;
}) =>
  createSelector(
    (state: RootState) => reportsSelectors.selectById(state, reportID),
    (report) => ({
      reportType: report?.Type,
      reportCreatedDate: report?.Created?.At,
      reportPath: report?.Type
        ? generatePath(getReportPath(report?.Type), {
            reportID,
            patientID,
          })
        : undefined,
    }),
  );

export const selectCurrentReport = (state: RootState) =>
  state.reports.currentReport;

export const selectCurrentReportID = (state: RootState) =>
  state.reports.currentReport?.ID;

export const selectLowProbabilityMode = (state: RootState) =>
  state.reports?.currentReport?.Settings?.LowProbabilityMode;

export const selectPreviewSettingsShowTopJaw = (state: RootState) =>
  state.reports?.currentReport?.PrintSettings?.ShowTopJaw;

export const selectPreviewSettingsShowBottomJaw = (state: RootState) =>
  state.reports?.currentReport?.PrintSettings?.ShowBottomJaw;

export const selectPreviewSettingsBlackAndWhiteMode = (state: RootState) =>
  state.reports?.currentReport?.PrintSettings?.BlackAndWhiteMode;

export const selectCurrentReportPanoAssetID = (state: RootState) =>
  state.reports.currentReport?.Specific.case === 'DataPanoBitewings' &&
  state.reports.currentReport?.Specific.value.PanoStudyAssetID;

export const selectCurrentReportSigned = (state: RootState) =>
  Boolean(state.reports.currentReport?.Signature?.UserSignatures.length);

export const selectCurrentReportViewOptions = (state: RootState) =>
  state.reports.currentReport?.MedicalImageFeatures?.ViewOptions;

export const selectCurrentReportCanChangeToothApproved = (state: RootState) =>
  state.reports.currentReport?.YourPermissions?.CanChangeToothApproved;

export const selectCurrentReportSignStatus = (state: RootState) => {
  if (state.reports.currentReport) {
    return getReportSignStatus(state.reports.currentReport);
  }
  return '';
};

export const selectCurrentReportImagesMeta = (state: RootState) => {
  const currentReport = state.reports.currentReport;

  if (currentReport?.Specific.case === 'DataPanoBitewings') {
    return currentReport.Specific.value.ImagesMeta;
  }

  if (currentReport?.Specific.case === 'DataIOXRayGP') {
    return currentReport.Specific.value.ImagesMeta;
  }

  return [];
};

export const selectNotDeletedReportsByPatientID = (patientID: string) =>
  createSelector(selectByPatientID(patientID), (reports) =>
    reports.filter((report) => !report.Deleted?.Deleted),
  );

export const selectLoading = (state: RootState) => state.reports.loading;

// NOTE: This are specific selectors which is breaks FSD rules on purpose. It's accessible to do it in entitities models.
export const selectReportReadyForRender = (state: RootState) =>
  state.reports.loading === 'succeeded' && state.assets.loading === 'succeeded';

export const selectReportPBLReadyForRender = (state: RootState) =>
  state.toothLandmarks.loading === 'succeeded' &&
  state.logicalCondition.loading === 'succeeded' &&
  state.reports.loading === 'succeeded' &&
  state.assets.loading === 'succeeded';

export const selectActiveReportsIDsByStudyIDAndType = (
  studyIDs: string[],
  reportType: ReportType,
) =>
  createSelector(selectActiveReportsByStudyID(studyIDs), (reports) =>
    reports
      .filter((report) => isReportTypeMatches(report.Type, reportType))
      .map((report) => report?.ID),
  );
