import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { Skeleton } from '@/shared/ui';
import { Report } from '@/shared/api/protocol-ts/model/dto_report_pb';

import { i18n } from '@/entities/reports';

import styles from './ReportHeading.module.scss';

type ReportProps = {
  report: Report;
  isReportLoading?: boolean;
  smallHeader?: boolean;
  className?: string;
};

export const ReportHeading: FC<ReportProps> = (props) => {
  const { report, isReportLoading = true, className } = props;

  const { formatMessage } = useIntl();

  if (!report) {
    return null;
  }

  return (
    <div className={cn(styles.container, className)}>
      <header className={styles.header}>
        {isReportLoading ? (
          <Skeleton width="75%" borderRadius="42px" height="87px" />
        ) : (
          <h1 className="h3">{formatMessage(i18n[report.Type])}</h1>
        )}
      </header>
    </div>
  );
};
