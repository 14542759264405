export enum ModalID {
  AddCondition = 'AddCondition',
  Confirm = 'Confirm',
  ZoomedMedicalImage = 'ZoomedMedicalImage',
  TeethNumberingModal = 'TeethNumberingModal',
  MPRFullScreenModal = 'MPRFullScreenModal',
  UploadStudy_DEPRECATED = 'UploadStudy',
  OrderReport = 'OrderReport',
  SharePatient = 'SharePatient',
  ProtectedSharing = 'ProtectedSharing',
  EditInfoModal = 'EditInfoModal',
  PermissionsModal = 'PermissionsModal',
  AddStaffModal = 'AddStaffModal',
  EditCompanyInfoModal = 'EditCompanyInfoModal',
  GetFullAccessModal = 'GetFullAccessModal',
  CongratsModal = 'CongratsModal',
  NewAccountModal = 'NewAccountModal',
  RevokeSharedPatientModal = 'RevokeSharedPatientModal',
  Order3DSegmentronModal = 'Order3DSegmentronModal',
  OrderOrthoORImplantModal = 'OrderOrthoORImplantModal',
  BillingInformationModal = 'BillingInformationModal',
  CancelSubscriptionModal = 'CancelSubscriptionModal',
  ResetPasswordErrorModal = 'ResetPasswordErrorModal',
  PatientRemovalConfirmationModal = 'PatientRemovalConfirmationModal',
  PatientModalForm = 'PatientModalForm',
  TopUpReportLimitsModal = 'TopUpReportLimitsModal',
  PauseOrActivateSubscriptionModal = 'PauseOrActivateSubscriptionModal',
  AboutLabelingModal = 'AboutLabelingModal',
  ReportInfo = 'ReportInfo',
}
