import { Study } from '@/shared/api/protocol-ts/model/dto_study_pb';

type SortedStudies = Record<
  'leftColumn' | 'rightColumn',
  { id: number; data: Study[] }[]
>;
export const getSortedStudiesByColumn = (
  collectedStudies: { id: number; data: Study[] }[],
  isSegmentronActive: boolean,
  hasSegmentronReports: boolean,
) =>
  collectedStudies.reduce(
    (acc, study, index) => {
      if (
        (!isSegmentronActive && index % 2) ||
        (isSegmentronActive && !(index % 2)) ||
        (hasSegmentronReports && isSegmentronActive)
      ) {
        return {
          ...acc,
          leftColumn: [...acc.leftColumn, study],
        };
      }

      return {
        ...acc,
        rightColumn: [...acc.rightColumn, study],
      };
    },
    {
      leftColumn: [],
      rightColumn: [],
    } as SortedStudies,
  );
