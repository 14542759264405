import { FC, ReactNode, useEffect, useLayoutEffect, useRef } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router';

import { CORS_POLICY, RenderPreviewSettings } from '@/shared/config';
import { useAppSelector } from '@/shared/hooks';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { MedicalImageRender } from '@/shared/graphics/medicalImageRender/MedicalImageRender';
import { MedicalImageViewOptions } from '@/shared/api/protocol-ts/model/dto_common_image_view_options_pb';

import { reportsModel } from '@/entities/reports';
import {
  IOXRayImagesInterfaceModel,
  IOXrayImageInterface,
} from '@/entities/IOXRayImagesMatrix';
import { toothModel } from '@/entities/tooth';
import { organizationModel } from '@/entities/organization';

import { hoveredConditionBBoxesModel } from '@/features/hoveredConditionBBoxes';
import { maskFiltersModel } from '@/features/renderMasks';
import { convertToMIRenderInterface } from '@/features/MIRenderInterface';

import styles from './PanoReportRender.module.scss';

type PanoReportRenderProps = {
  className?: string;
  children?: ReactNode;
  previewSettings?: RenderPreviewSettings;
};

export const PanoReportRender: FC<PanoReportRenderProps> = (props) => {
  const { className, children, previewSettings } = props;

  const { reportID } = useParams<{ reportID: string }>();

  const isPreviewMode = previewSettings?.isPreview;

  const mainViewRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const readyForRender = useAppSelector(
    reportsModel.selectors.selectReportReadyForRender,
  );
  const panoAssetID = useAppSelector(
    reportsModel.selectors.selectCurrentReportPanoAssetID,
  );

  const panoImageInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectPanoImageInterfaceByReportID(
      reportID as string,
    ),
  ) as IOXrayImageInterface;

  const panoMIRenderInterface = convertToMIRenderInterface(panoImageInterface);

  const reportViewOptions = useAppSelector(
    reportsModel.selectors.selectCurrentReportViewOptions,
  );

  // Hovered condition BBoxes
  const hoveredBBoxes = useAppSelector(
    hoveredConditionBBoxesModel.selectors.selectToothChartItems,
  )?.map((bbox) =>
    bbox.imageID === panoAssetID ? { ...bbox, imageID: 'pano' } : bbox,
  );

  // Masks
  const masks2DRenderData = useAppSelector(
    maskFiltersModel.selectors.selectReportActiveMasks,
  );
  const hideMasks = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Hide_ConditionsMasks,
    ),
  );
  const activeMaskFilters = useAppSelector(
    maskFiltersModel.selectors.selectActiveFilters,
  );

  useLayoutEffect(() => {
    MedicalImageRender.setViewRef(mainViewRef);
    MedicalImageRender.setCanvasRef(canvasRef);
  }, []);

  const teeth = useAppSelector(toothModel.selectors.selectAll);

  useEffect(() => {
    const shouldRunRender =
      !!reportID && !!panoMIRenderInterface && readyForRender;
    if (shouldRunRender) {
      MedicalImageRender.setCredentials(CORS_POLICY);

      const isRenderStartCorrect = MedicalImageRender.run(
        reportID,
        [[[panoMIRenderInterface]]],
        reportViewOptions as MedicalImageViewOptions,
        { [panoMIRenderInterface.ImageID]: { viewportType: 'pano' } },
      );

      if (!isRenderStartCorrect) {
        return;
      }

      if (isPreviewMode) {
        MedicalImageRender.layoutModes.fullScreenMatrix();
        MedicalImageRender.activateMode('printMode');
      } else {
        MedicalImageRender.setTeeth(teeth);
        MedicalImageRender.layoutModes.focus(panoMIRenderInterface.ImageID);
      }
    }
  }, [reportID, panoAssetID, readyForRender, isPreviewMode]);

  // BBoxes render
  useEffect(() => {
    if (MedicalImageRender.isRunning()) {
      if (hoveredBBoxes) {
        MedicalImageRender.deleteConditionBoxes();

        MedicalImageRender.addConditionBoxes(hoveredBBoxes);
      } else {
        MedicalImageRender.deleteConditionBoxes();
      }
    }
  }, [hoveredBBoxes]);

  // Render masks
  useEffect(() => {
    if (MedicalImageRender.isRunning() && readyForRender) {
      MedicalImageRender.deleteMasks();
      if (!hideMasks && masks2DRenderData.length > 0) {
        MedicalImageRender.addMasks(masks2DRenderData);
        MedicalImageRender.showMasks(activeMaskFilters);
      }
    }
  }, [masks2DRenderData, readyForRender, hideMasks, activeMaskFilters]);

  useEffect(
    () => () => {
      MedicalImageRender.shutdown();
    },
    [],
  );

  return (
    <div
      ref={mainViewRef}
      className={cn(
        styles.container,
        previewSettings?.isPreview ? styles.preview : '',
        className,
      )}
      id="report_render"
    >
      {children}
      <canvas ref={canvasRef} className={styles.canvas} />
    </div>
  );
};
