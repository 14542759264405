import { createAsyncThunk, DeepPartial } from '@reduxjs/toolkit';
import { CallOptions, ConnectError } from '@bufbuild/connect';

import { SliceName } from '../config';

type ThunkRequestFunction<Request, Response> = (
  request: Request,
  options?: CallOptions,
) => Promise<Response>;

export const createThunkGenerator =
  <RequestsNames>(sliceName: SliceName) =>
  <Request, Response>(
    requestName: RequestsNames,
    requestFunction: ThunkRequestFunction<Request, Response>,
  ) =>
    createAsyncThunk(
      `${sliceName}/${requestName}`,
      async (request: DeepPartial<Request>, { rejectWithValue }) => {
        try {
          const response = await requestFunction(request as Request);

          return response;
        } catch (error: unknown) {
          // const { message, type } = error as any;
          const connectErr = ConnectError.from(error);
          // connectErr.code;    // Code.InvalidArgument
          // connectErr.message; // "[invalid_argument] sentence cannot be empty"

          return rejectWithValue(connectErr);
        }
      },
    );
