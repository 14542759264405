// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_study.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Attribute, Created, Deleted, Revision } from "./dto_base_pb.js";
import { StudyPermissions } from "./dto_study_permissions_pb.js";

/**
 * @generated from enum com.diagnocat.model.StudyType
 */
export const StudyType = proto3.makeEnum(
  "com.diagnocat.model.StudyType",
  [
    {no: 0, name: "StudyType_InvalidValue"},
    {no: 1, name: "StudyType_CBCT"},
    {no: 2, name: "StudyType_IntraoralXRay"},
    {no: 3, name: "StudyType_PanoramicXRay"},
    {no: 4, name: "StudyType_IOS_Meshes"},
    {no: 5, name: "StudyType_DentalPhoto"},
  ],
);

/**
 * @generated from enum com.diagnocat.model.StudyStatus
 */
export const StudyStatus = proto3.makeEnum(
  "com.diagnocat.model.StudyStatus",
  [
    {no: 0, name: "StudyStatus_InvalidValue"},
    {no: 1, name: "StudyStatus_Uploading"},
    {no: 2, name: "StudyStatus_Finished"},
    {no: 3, name: "StudyStatus_Failed"},
  ],
);

/**
 * @generated from message com.diagnocat.model.Study
 */
export const Study = proto3.makeMessageType(
  "com.diagnocat.model.Study",
  () => [
    { no: 1, name: "ID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "Time", kind: "message", T: Timestamp },
    { no: 4, name: "OrganizationID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "PatientID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "Type", kind: "enum", T: proto3.getEnumType(StudyType) },
    { no: 7, name: "AssetIDs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "DisplayAssetID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "Status", kind: "enum", T: proto3.getEnumType(StudyStatus) },
    { no: 12, name: "UploadSessionStatuses", kind: "message", T: StudyUploadSessionStatus, repeated: true },
    { no: 1000, name: "Attributes", kind: "message", T: Attribute, repeated: true },
    { no: 10020, name: "YourPermissions", kind: "message", T: StudyPermissions },
    { no: 10030, name: "Created", kind: "message", T: Created },
    { no: 10040, name: "Revision", kind: "message", T: Revision },
    { no: 10050, name: "Deleted", kind: "message", T: Deleted },
  ],
);

/**
 * @generated from message com.diagnocat.model.StudyUploadSessionStatus
 */
export const StudyUploadSessionStatus = proto3.makeMessageType(
  "com.diagnocat.model.StudyUploadSessionStatus",
  () => [
    { no: 1, name: "UploadSessionID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "Status", kind: "enum", T: proto3.getEnumType(StudyStatus) },
    { no: 3, name: "ErrorMessage", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

