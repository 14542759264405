// @generated by protoc-gen-es v1.0.0 with parameter "target=js+dts,ts_nocheck=false"
// @generated from file model/dto_common_geometry.proto (package com.diagnocat.model, syntax proto3)
/* eslint-disable */

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message com.diagnocat.model.CBCTSeriesGeometryData
 */
export const CBCTSeriesGeometryData = proto3.makeMessageType(
  "com.diagnocat.model.CBCTSeriesGeometryData",
  () => [
    { no: 1, name: "WorkingCrop", kind: "message", T: BBox },
    { no: 2, name: "Shape", kind: "message", T: IntTuple3D },
    { no: 3, name: "Scale", kind: "message", T: FloatTuple3D },
  ],
);

/**
 * @generated from message com.diagnocat.model.FloatPoint2D
 */
export const FloatPoint2D = proto3.makeMessageType(
  "com.diagnocat.model.FloatPoint2D",
  () => [
    { no: 1, name: "X", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "Y", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message com.diagnocat.model.IntPoint3D
 */
export const IntPoint3D = proto3.makeMessageType(
  "com.diagnocat.model.IntPoint3D",
  () => [
    { no: 1, name: "Z", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "Y", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "X", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message com.diagnocat.model.FloatPoint3D
 */
export const FloatPoint3D = proto3.makeMessageType(
  "com.diagnocat.model.FloatPoint3D",
  () => [
    { no: 1, name: "Z", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "Y", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "X", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message com.diagnocat.model.OrderedLine2D
 */
export const OrderedLine2D = proto3.makeMessageType(
  "com.diagnocat.model.OrderedLine2D",
  () => [
    { no: 1, name: "Order", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "Start", kind: "message", T: FloatPoint2D },
    { no: 3, name: "End", kind: "message", T: FloatPoint2D },
  ],
);

/**
 * @generated from message com.diagnocat.model.IntTuple3D
 */
export const IntTuple3D = proto3.makeMessageType(
  "com.diagnocat.model.IntTuple3D",
  () => [
    { no: 1, name: "Z", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "Y", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "X", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message com.diagnocat.model.FloatTuple2D
 */
export const FloatTuple2D = proto3.makeMessageType(
  "com.diagnocat.model.FloatTuple2D",
  () => [
    { no: 1, name: "X", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "Y", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message com.diagnocat.model.FloatTuple3D
 */
export const FloatTuple3D = proto3.makeMessageType(
  "com.diagnocat.model.FloatTuple3D",
  () => [
    { no: 1, name: "Z", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "Y", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "X", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 *
 * 0 <= Min <= Max <= Size
 *
 * @generated from message com.diagnocat.model.AxisSlice
 */
export const AxisSlice = proto3.makeMessageType(
  "com.diagnocat.model.AxisSlice",
  () => [
    { no: 1, name: "Min", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "Max", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "Size", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * BBox describes a rectangular subportion of 2D or 3D image.
 * 3D BBox will have X, Y, Z defined. 2D BBox will have X, Y defined.
 *
 * @generated from message com.diagnocat.model.BBox
 */
export const BBox = proto3.makeMessageType(
  "com.diagnocat.model.BBox",
  () => [
    { no: 1, name: "Z", kind: "message", T: AxisSlice },
    { no: 2, name: "Y", kind: "message", T: AxisSlice },
    { no: 3, name: "X", kind: "message", T: AxisSlice },
  ],
);

/**
 * 4x4 projection transform as a single vector of with 16 items flattened
 * in a row-major order.
 *
 * @generated from message com.diagnocat.model.ProjectionTransform
 */
export const ProjectionTransform = proto3.makeMessageType(
  "com.diagnocat.model.ProjectionTransform",
  () => [
    { no: 1, name: "Matrix", kind: "scalar", T: 2 /* ScalarType.FLOAT */, repeated: true },
  ],
);

/**
 * @generated from message com.diagnocat.model.PlanePosition
 */
export const PlanePosition = proto3.makeMessageType(
  "com.diagnocat.model.PlanePosition",
  () => [
    { no: 1, name: "Origin", kind: "message", T: FloatPoint3D },
    { no: 2, name: "Normal", kind: "message", T: FloatPoint3D },
  ],
);

/**
 * @generated from message com.diagnocat.model.StudioColorProfile
 */
export const StudioColorProfile = proto3.makeMessageType(
  "com.diagnocat.model.StudioColorProfile",
  () => [
    { no: 1, name: "WindowWidth", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "WindowLevel", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * LEGACY, DO NOT USE
 *
 * @generated from message com.diagnocat.model.ClosedPolygon
 */
export const ClosedPolygon = proto3.makeMessageType(
  "com.diagnocat.model.ClosedPolygon",
  () => [
    { no: 1, name: "Points", kind: "message", T: FloatPoint2D, repeated: true },
  ],
);

/**
 * Will replace ClosedPolygon after full transition
 * Points = [x1, y1, x2, y2, ..., xn, yn]
 *
 * @generated from message com.diagnocat.model.ClosedPolygon_Upcoming
 */
export const ClosedPolygon_Upcoming = proto3.makeMessageType(
  "com.diagnocat.model.ClosedPolygon_Upcoming",
  () => [
    { no: 1, name: "Points", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, repeated: true },
  ],
);

/**
 * Anatomical basis originated in (0, 0, 0).
 * NOTE: By default there are no guarantees about basis orthogonality or orientation.
 *
 * @generated from message com.diagnocat.model.AnatomicalBasis3D
 */
export const AnatomicalBasis3D = proto3.makeMessageType(
  "com.diagnocat.model.AnatomicalBasis3D",
  () => [
    { no: 1, name: "MesioDistal", kind: "message", T: FloatPoint3D },
    { no: 2, name: "VestibuloOral", kind: "message", T: FloatPoint3D },
    { no: 3, name: "Axial", kind: "message", T: FloatPoint3D },
  ],
);

