import { Effect } from '@/shared/api/protocol-ts/api/billing_new/dto_order_new_pb';

export const getProductName = (product: Effect['Effect']): string => {
  switch (product.case) {
    case 'SubscriptionAdd':
      return product.value.Subscription?.Name ?? '';
    case 'PackageAdd':
      return product.value.Package?.Name ?? '';
    case 'ProductStateRemove':
      return product.value.RemoveProductStateID;
    case 'SubscriptionRenew':
      return product.value.RenewSubscriptionStateID;
    case 'SubscriptionReplace':
      return product.value.NewSubscription?.Name ?? '';
    case 'SubscriptionActivate':
      return product.value.ActivateSubscriptionStateID;
    case 'SubscriptionSetAutoRenewal':
      return product.value.SubscriptionStateID;
    case 'SubscriptionSetAutoCharge':
      return product.value.SubscriptionStateID;
    case 'AddonAdd':
    case 'BonusAdd':
      return product.value.Addon?.Name ?? '';
    default:
      return '';
  }
};
