import {
  Report,
  ReportType,
} from '@/shared/api/protocol-ts/model/dto_report_pb';

import { GroupedReportsBySourceStudyIDsAndType } from '../../../pages/PatientProfile/config';

export const getGroupedReportsBySourceStudyIDsAndType = (
  groupedReportsBySourceStudyIDs: Report[][],
): GroupedReportsBySourceStudyIDsAndType =>
  groupedReportsBySourceStudyIDs.map((group) => {
    const groupByType = (reports: Report[]) =>
      reports.reduce(
        (acc, report) => ({
          ...acc,
          [report.Type]: [...(acc[report.Type] || []), report],
        }),
        {} as Record<ReportType, Report[]>,
      );

    return {
      sourceStudyIDs: group[0].SourceStudyIDs,
      reportsByType: groupByType(group),
    };
  });
