import { useIntl } from 'react-intl';

import { AssetType } from '@/shared/api/protocol-ts/model/dto_asset_pb';

import { selectUploadStudyTypes } from '../config/i18n';

export const useSelectStudyOptions = () => {
  const { formatMessage } = useIntl();

  return [
    {
      label: formatMessage(
        selectUploadStudyTypes[AssetType.AssetType_Study_CBCT],
      ),
      value: AssetType.AssetType_Study_CBCT,
    },
    {
      label: formatMessage(
        selectUploadStudyTypes[AssetType.AssetType_Study_IntraoralXRay],
      ),
      value: AssetType.AssetType_Study_IntraoralXRay,
    },
    {
      label: formatMessage(
        selectUploadStudyTypes[AssetType.AssetType_Study_PanoramicXRay],
      ),
      value: AssetType.AssetType_Study_PanoramicXRay,
    },
    {
      label: formatMessage(
        selectUploadStudyTypes[AssetType.AssetType_Study_IOS_Meshes],
      ),
      value: AssetType.AssetType_Study_IOS_Meshes,
    },
  ];
};
