import { ReportAsset } from '@/shared/api/protocol-ts/model/dto_asset_pb';

export const getMedicalImageKind = (
  reportAssetContent: ReportAsset['Content'] | undefined,
) => {
  if (
    reportAssetContent?.case === 'UserUploadedToothSlice' &&
    reportAssetContent.value.Slice?.ID
  ) {
    return 'raster';
  }

  if (
    reportAssetContent?.case === 'UserUploadedMPRPlane' &&
    reportAssetContent.value.MPRPlane?.ID
  ) {
    return 'raster';
  }

  return 'dicom';
};
