import { FormattedMessage } from 'react-intl';

import { OrganizationUserRoleType } from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { object, string, number, array } from '@/shared/config/yup';
import { OrganizationJobPosition } from '@/shared/api/protocol-ts/model/dto_organization_job_position_pb';

export type StaffFormPayload = {
  firstName: string;
  lastName: string;
  email: string;
  accessLevel: OrganizationUserRoleType[];
  position: OrganizationJobPosition[];
};

export const getValidationSchema = (validateEmail: boolean) =>
  object({
    firstName: string().required(),
    lastName: string().required(),
    email: validateEmail ? string().email().required() : string().required(),
    accessLevel: array()
      .of(number())
      .required()
      .min(1, () => (
        <FormattedMessage
          id="addStaffModal.accessLevelError"
          defaultMessage="accessLevel field must have at least 1 items"
        />
      ))
      .nullable(),
    position: array()
      .of(number())
      .required()
      .min(1, () => (
        <FormattedMessage
          id="addStaffModal.jobPositionError"
          defaultMessage="position field must have at least 1 items"
        />
      ))
      .nullable(),
  });
