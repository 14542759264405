import { Asset } from '@/shared/api/protocol-ts/model/dto_asset_pb';

interface MedicalImageInfo {
  medicalImageID: string | undefined;
  medicalImagePath: string | undefined;
}

export const getMedicalImageInfo = (
  medicalImage: Asset | undefined,
): MedicalImageInfo => {
  const defaultResult: MedicalImageInfo = {
    medicalImageID: undefined,
    medicalImagePath: undefined,
  };

  if (!medicalImage) {
    return defaultResult;
  }

  const { Kind } = medicalImage;

  switch (Kind.case) {
    case 'GeneratedReport': {
      const { Content } = Kind.value;

      switch (Content.case) {
        case 'CBCTGPToothSlice':
        case 'CBCTToothPathologySlice':
          return {
            medicalImageID: Content.value.Slice?.ID,
            medicalImagePath: Content.value.Slice?.Path,
          };

        default:
          return defaultResult;
      }
    }
    case 'Report': {
      const { Content } = Kind.value;

      switch (Content.case) {
        case 'UserUploadedToothSlice':
          return {
            medicalImageID: Content.value.Slice?.ID,
            medicalImagePath: Content.value.Slice?.Path,
          };

        case 'UserUploadedMPRPlane':
          return {
            medicalImageID: Content.value.MPRPlane?.ID,
            medicalImagePath: Content.value.MPRPlane?.Path,
          };

        default:
          return defaultResult;
      }
    }
    default:
      return defaultResult;
  }
};
